import Vue from 'vue';

import VueTheMask from 'vue-the-mask';
import Toasted from 'vue-toasted';
import Vuelidate from 'vuelidate';
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import StarRating from 'vue-star-rating';
import VueObserveVisibility from 'vue-observe-visibility';
import vueVimeoPlayer from 'vue-vimeo-player';
import vueCountryRegionSelect from 'vue-country-region-select'
import VueYoutube from 'vue-youtube';
import VueTelInput from 'vue-tel-input'



import vueSmoothScroll from 'vue2-smooth-scroll';
import VueHead from 'vue-head';
import VueCarousel from 'vue-carousel';

import 'moment/locale/uk'
import 'moment/locale/ru'


//use
Vue.use(Toasted,{duration:5000});
Vue.use(VueTheMask);
Vue.use(Vuelidate);
Vue.use(VueFlatPickr);
Vue.use(vueSmoothScroll);
Vue.use(VueHead);
Vue.use(VueCarousel);
Vue.use(StarRating);
Vue.use(VueObserveVisibility);
Vue.use(vueVimeoPlayer);
Vue.use(vueCountryRegionSelect);
Vue.use(VueYoutube);
Vue.use(VueTelInput);

