
export default {
  name: 'tech-and-tools',
  props: {
    list: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
}
