
const coursesWhatYouGet = [
  {
    id: 0,
    title: '150 часов практики',
    description: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
  },
  {
    id: 1,
    title: 'Бесплатная библиотека',
    description: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
  },
  {
    id: 2,
    title: 'Чат студента и преподователя',
    description: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
  },
  {
    id: 3,
    title: 'Заряд мотивации',
    description: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
  },
  {
    id: 4,
    title: 'Поддержку на любом этапе',
    description: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
  },
  {
    id: 5,
    title: 'Готовые проекты в портфолио',
    description: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
  }
];

export default coursesWhatYouGet;
