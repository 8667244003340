// import {globalLoader} from '@/helpers/variables/index';
import {$http} from '@/utils/http'
import {
  SET_USER_DATA,
  LOADING_USER_DATA,
  UPDATE_USER_DATA_LOADING,
  SHOW_POPUP_UPDATE_PASSWORD,
  UPDATE_USER_PASSWORD_LOADING,
} from '../mutation-types';

import {
  FETCH_USER_DATA,
  UPDATE_USER_DATA,
  UPDATE_USER_PASSWORD,
} from '../action-types';


const state = {
  user: null,
  loadingUser: false,
  updateUserLoading: false,
  showPopupUpdatePassword: false,
  updatePasswordLoading: false,
};

const getters = {
  updatePasswordLoading: state => state.updatePasswordLoading,
  showPopupUpdatePassword: state => state.showPopupUpdatePassword,
  user: state => state.user,
  loadingUser: state => state.loadingUser,
  updateUserLoading: state => state.updateUserLoading,
};

const actions = {
  [FETCH_USER_DATA]: async ({commit}) => {
    try {
      commit(LOADING_USER_DATA, true);

      const response = await $http.get('v1/me');
      commit(SET_USER_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_USER_DATA, false);
    }
  },
  [UPDATE_USER_DATA]: async ({commit}, payload) => {
    try {
      commit(UPDATE_USER_DATA_LOADING, true);
      const response = await $http.post('v1/faq', payload);
      commit(SET_USER_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_DATA_LOADING, false);
    }
  },
  [UPDATE_USER_PASSWORD]: async ({commit}, payload) => {
    try {
      commit(UPDATE_USER_PASSWORD_LOADING, true);
      const response = await $http.post('v1/faq', payload);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_PASSWORD_LOADING, false);
    }
  },
};

const mutations = {
  [SET_USER_DATA](state, data) {
    state.user = data;
  },
  [LOADING_USER_DATA](state, status) {
    state.loadingUser = status;
  },
  [UPDATE_USER_DATA_LOADING](state, status) {
    state.updateUserLoading = status;
  },
  [SHOW_POPUP_UPDATE_PASSWORD](state, status) {
    state.showPopupUpdatePassword = status;
  },
  [UPDATE_USER_PASSWORD_LOADING](state, status) {
    state.updatePasswordLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
