import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'main-footer',
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      menuList: 'setting/menu'
    }),
  },
  methods: {
    ...mapMutations({}),
    changeFooterLogo() {
      if (this.$route.name === 'home' || this.$route.name === 'marathon') {
        window.scrollTo({top: 0, behavior: 'smooth'});
      } else {
        this.$router.push({name: 'home'}).catch(() => {
          console.log();
        });
      }
    }
  }
}
