export default {
  filter: 'Фильтры',
  myCourses: 'Мои курсы',
  cookies: 'Cookies',
  cookiesMessage: 'Используя этот сайт, вы автоматически соглашаетесь с тем, что мы используем файлы cookie.',
  cookiesButton: 'Принять',
  enterCorrectPhone: 'Введите правильный телефон',
  successCv: 'Ваша заявка успешно принята',
  vacancyWork: 'Карьера',
  record: 'Записаться',
  successUserRegister: 'Регистрация прошла успешно!',
  successRecordToEvent: 'Вы успешно записались на консультацию. Наш менеджер свяжется с вами в течении дня.',
  successRecordToEventHint: 'Менеджер свяжется с вами в ближайшее время и расскажет детали участия на курсе! ',
  successSendTestResult: 'Результаты теста успешно отправлены вам на почту!',
  successAuth: 'Авторизация прошла успешно',
  share: 'Поделиться',
  email: "Email",
  successSendContactForm: 'Спасибо за обращение. Сообщение успешно отправлено!',
  successActivationEmail: 'Ваш емейл активирован',
  letterSubject: 'Тема письма',
  letter: 'Письмо',
  contactForm: 'Контактная форма',
  teamProject: 'Команда проекта',
  profile: 'Профиль',
  yourName: "Твое имя",
  yourEmail: "Твой емейл",
  yourPhone: "Телефон",
  yourLang: "Вырать язык",
  yourPassword: "Пароль",
  ruLang: "Русский",
  enLang: "Английский",
  uaLang: "Украинский",
  pantoLang: "Язык жестов",
  agreeDataProcess: "Даю согласие на хранение и обработку моих персональных данных",
  promocode: "Промокод",
  validateAcceptAgreement: "Установите галочку на соглашении",
  totalInformation: 'Общая информация',
  noDataNow: 'Данных нет',
  fieldRequired: "Поле :name обязательно для заполнения",
  maxLengthField: "Максимальное количество символов поля :field — :count",
  name: "Имя",
  next: "Далее",
  noData: "Нет данных",
  or: "или",
  all: 'Все',
  city: 'Город',
  country: "Страна",
  countryWord1:'Стран',
  directions: "Направления",
  navigation: "Навигация",
  contacts: "Контакты",
  phone: "Номер телефона",
  validationLengthPhone: "Количество символов в поле телефон должно быть - 12",
  validationEmail: "Введите корректный email",
  successRegisterToEvent: "Вы успешно зарегистрировались на мероприятие",
  remindMe: "Напомнить мне",
  remindinfo: "Уведомление придет вам на почту",
  selectCountry: "Выбрать страну",
  selectCity: "Выбрать город",
  from: "c",
  validationRequired: "Заполните это поле",
  validationMax: "Максимальное количество символов :count",
  validationMin: "Минимальное количество символов :count",
  validationSameAs: "Пароли не совпадают!",
  toDefault: 'По умолчанию',
  sort: 'Сортировка',
  sortByAlphabetical: 'По алфавиту (А-Я)',
  sortByAlphabeticalReverse: 'По алфавиту (Я-А)',
  sortByDate: 'От новым к старым',
  sortByDateReverse: 'От старых к новым',

  //header translations
  header: {
    courses: 'Курсы',
    news: 'Новости',
    about: 'О нас',
    speakers: 'Спикеры',
    logIn: 'Войти',
    profile: 'Профиль'
  },

  //footer translations
  footer: {
    title: 'Запланируй обучение прямо сейчас, не упусти момент',
    directions: 'Направления',
    navigation: 'Навигация',
    main: 'Главная',
    about: 'О нас',
    news: 'Новости',
    courses: 'Курсы',
    speakers: 'Спикеры',
    contacts: 'Контакты',
    vacancy: 'Карьера',
    contactTitle: 'Учителем йоги или бизнес-консультантом с феноменальной интуицией.Ты можешь быть невероятным дизайнером или крутым программистом, менеджером или маркетологом.',
    contactNumber1: '+38 (044) 334 61 16',
    contactNumber2: '+7 (495) 266 67 03',
    contactAdress: '01024, г. Киев, ул. Большая Васильковская 13',
  },

  //home translations
  home: {
    //promo
    promoTitle1: 'LIFTA.SPACE',
    promoTitle2: 'NO LIMITS',
    promoButton: 'Смотри марафон сейчас!',

    //development
    trendTitle: 'ТВОЙ ВЕКТОР РАЗВИТИЯ',
    marketing: 'Маркетинг',
    marketingDesc: 'Мы не ограничиваем маркетинг бытовым понятием «реклама». Маркетинг — это продуманная философия, тактика и стратегия мышления с сотнями нюансов и подходов к клиенту.\n' +
      'Концепции маркетинга позволяют реализовать себя как создателя продукта, экономиста, аналитика или эксперта по продажам.\n' +
      'Десятки маркетинговых специальностей обеспечат место в индустрии и  новичкам, и профессионалам.\n' +
      'Найди свою профессию и развивайся в маркетинге вместе с экспертами LIFTA.',
    TagMarketing1: '#Метрики',
    TagMarketing2: '#Лиды',
    TagMarketing3: '#Стратегия',
    design: 'Дизайн',
    designDesc: 'Нас окружают миллионы предметов. Каждый из них – стол, компьютер, смартфон, рубашка, блокнот, украшения, кондиционер, дом напротив и рама окна – продукт дизайна, выполненный конкретным человеком. \n' +
      'Дизайн наполняет нашу жизнь и во многом определяет ее, задавая функциональные и эстетические тренды. \n' +
      'Дизайнеры-профессионалы востребованы и в маркетинге, и на производствах, а потому легко найдут себя в офлайн- и онлайн-индустрии.\n' +
      'Проектировать с нуля и прокачать художественные навыки, по-новому взглянуть на профессию помогут наши гайд-LIFTERы.',
    TagDesign1: '#UI',
    TagDesign2: '#UX',
    TagDesign3: '#Прототип',
    management: 'Менеджмент',
    managementDesc: 'В прогрессивном и эффективном управлении нуждаются все: предприятия, государственные институты, некоммерческие организации.\n' +
      'Управление коллективом, составление алгоритмов для оптимальных решений, умение предсказать их последствия — только малый набор функций менеджера, которые применимы не только в работе, но и в личной жизни.\n' +
      'Освоенный в LIFTA менеджмент — комплексная и развивающаяся система на стыке точных и социальных наук — улучшит ваши позиции на рынке вне зависимости от сегодняшнего статуса.',
    TagManagement1: '#Люди',
    TagManagement2: '#Управление',
    TagManagement3: '#Время',
    TagManagement4: '#Agile',
    development: 'Разработка',
    developmentDesc: 'Сегодня большинство технологических процессов невозможно представить без соответствующего программного обеспечения: от функционирования банковских систем и безопасности государств до простой переписки.\n' +
      'Программирование многопланово и включает создание компьютерных программ, их тестирование, оптимизацию и внедрение, а также управление и анализ этих процессов.\n' +
      'Эксперты LIFTA расскажут, как реализоваться в программировании, расширить профессиональные возможности или внедрить программный продукт в ваш бизнес.',
    TagDevelopment1: '#Front-end',
    TagDevelopment2: '#Backend',
    TagDevelopment3: '#Иновации',
    TagDevelopment4: '#Тестирование',
    self: 'Саморазвитие',
    selfDesc: 'Чтобы реализоваться в профессии, отношениях с собой и другими людьми, нам необходим личностный рост.\n' +
      'Этот непрерывный процесс затрагивает различные области жизни — физиология, дисциплина, знания, скрытые психологические конфликты и ценности, взаимоотношения. Он напрямую влияет на качество нашей жизни и представление о мире и самом себе.\n' +
      'Личностный рост усилит наши потенциальные возможности, скорректирует недостатки, упростит анализ и выбор решений в той или иной ситуации.\n' +
      'Саморазвитие в нужном тебе векторе — ключевой концепт LIFTA.SPACE!',
    TagSelf1: '#Движение',
    TagSelf2: '#Изучение',
    TagSelf3: '#Продвижение',
    TagSelf4: '#Осознание',

    //start
    startTitle: 'НЕ ЗНАЕШЬ С ЧЕГО НАЧАТЬ?',
    startSubtitle: 'Узнай свой вектор сэлф-LIFTa',
    startDescription: 'Бывает непросто ответить себе на вопрос: а чего я на самом деле хочу? Многообразие выбора нередко сбивает с толку. Чтобы точнее определить точку старта, пройди LIFTA-тест. Всего несколько минут — и его результаты подскажут оптимальное направление роста с учетом твоих личностных качеств.',
    startButton: 'ПРОЙТИ ТЕСТ',

    //speakers
    speakerTitle: 'НАШИ НАСТАВНИКИ',
    all: 'Все',

    //calendar
    orderCourse: 'Записаться на курс',
    calendarTitle: 'КАЛЕНДАРЬ СОБЫТИЙ',
    calendarSubtitle: 'Запланируй обучение прямо сейчас, не упусти момент',
    calendarAll: 'Посмотреть все',
    calendarDesc: 'Онлайн-марафон transformACTION 2021 — это место, где мы вливаемся в темп современной реальности',
    calendarBtn: 'Записаться на марафон',
    //about
    aboutTitle: 'ЧТО ТАКОЕ',
    aboutToxicTitle: ' LIFTA.SPACE?',
    aboutItem1Title: 'Безграничные возможности роста',
    aboutItem1Text: 'Материалы LIFTA.SPACE универсальны и подходят всем',
    aboutItem2Title: 'Простота и удобство',
    aboutItem2Text: 'LIFTA.SPACE всегда под рукой: актуальные курсы и все твои достижения доступны на всех устройствах',
    aboutItem3Title: 'Опытные спикеры',
    aboutItem3Text: 'Эксперты LIFTA.SPACE — источник актуальных практических знаний и наработок',
    aboutItem4Title: 'Практические навыки со старта обучения',
    aboutItem4Text: 'Новая информация в LIFTA.SPACE трансформируется в индивидуальную практику и реальные кейсы',
    aboutItem5Title: 'Разнообразие форматов',
    aboutItem5Text: 'LIFTA.SPACE предлагает семинары и вебинары, информацию в текстовом формате, организовывает практикумы и интенсивы',
    aboutItem6Title: 'Живая экосистема',
    aboutItem6Text: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии',

    //knowwhere
    knowTitle: 'КОГДА И ГДЕ УГОДНО',
    knowDesc1: 'Развивайся, общайся и лифти в мобильном приложении LIFTA.SPACE. Установи LIFTA-приложение и получи уникальную возможность расти в любой точке пространства, не теряя драгоценного времени.',
    knowDesc2: 'Приложение LIFTA для устройств ',
    knowToxic: 'iOS и Android',
    knowDesc3: ' откроет твой атлас развития на странице новых достижений, сохранит предыдущие и вдохновит развернутой маршрутной картой.',
    knowHint: 'LIFTA-приложение — глобус поиска и роста в твоем смартфоне.',
  },

  //about translations
  about: {
    //promo
    we: 'МЫ',
    promoTitle: 'Команда из более 1000 сотрудников',
    targetItem1Title: '350+',
    targetItem1Text: 'Спикеров',
    targetItem2Title: '1325',
    targetItem2Text: 'Курсов',
    targetItem3Title: '100 000',
    targetItem3Text: 'Lifters',
    targetItem4Title: '200+',
    targetItem4Text: 'Событий',
    //solution
    solutionTitle: 'Мы создаем быстрые и удобные решения для пользователей',
    solutionLeftBar: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана\n' +
      '          (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агентат) и Меровингиана (Ламбер Вильсон). Хьюго Уивинг\n' +
      '          не смог вернуться к роли агента',
    solutionRightBar: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана\n' +
      '          (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости, а Лоренса Фишберна никто\n' +
      '          пригласить и не подумал, хотя все еще можно рассчитывать на появление молодой версии Морфеуса.Зрители вновь\n' +
      '          увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон.',
    solutionItem1Title: 'Лучшее образование',
    solutionItem1Text: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и\n' +
      '              Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
    solutionItem2Title: 'Только практика',
    solutionItem2Text: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и\n' +
      '              Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
    solutionItem3Title: 'Генерируйте новые идеи',
    solutionItem3Text: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и\n' +
      '              Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
    solutionItem4Title: 'Новый опыт',
    solutionItem4Text: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и\n' +
      '              Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
    //how
    howTitle: 'Как мы это делаем?',
    howText1: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    howSubtitle1: 'Чат студента и преподователя',
    howText2: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    howSubtitle2: 'Готовые проекты в портфолио',
    howText3: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    howSubtitle3: 'Поддержку на любом этапе',
    howText4: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    howSubtitle4: 'Только практика',
    howText5: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    //team
    teamTitle: 'НАША КОМАНДА',


    title1: 'Давай начнем диалог уже сейчас',
    title2: 'Не откладывай идеи и вопросы в долгий ящик. Мы рады знакомству с тобой!',
    title3: 'Мы действительно хотим знать, что ты думаешь!',
    mainDesc: 'LIFTA.SPACE — гибкое пространство без границ. Мы всегда открыты новым взглядам, идеям и предложениям! Обратная связь — это бесценный источник инсайтов и помощник в определении зон роста. Возникли вопросы? Есть предложения? Хотите поделиться своей идеей? — мы рады новому диалогу и всегда готовы ответить, обсудить и услышать. Спасибо, что лифтишь нас!',
  },

  //sign-up-form
  signUpForm: {
    toxicTitle: 'Оформи подписку',
    title: ' и получи скидку на курс 5%',
    hint: 'Подпишись сейчас и получай актуальную информацию на почту*'
  },

  // translations
  translations: {
    locales: {
      en: 'Original',
      ru: 'Русский',
      ua: 'Українська',
    },
    banner: {
      image: '/img/translations/banners/translations_banner_ru.png',
      external_link: 'https://lifta.space/ru/courses',
    }
  },

  //courses
  courses: {
    staticTitle: 'Профессия: IT-рекрутер',
    staticTitle1: 'Гибкое управление проектами',
    staticTitle2: 'Эффективный руководитель',
    staticDesc: 'Построй карьеру в амбициозной сфере, которая будет актуальной в ближайшие десятилетия. Стань востребованным специалистом в IT.',
    staticDesc1: 'Как сократить количество документов и увеличить продуктивность команд? Как перестать фантазировать о результатах и начать систематически их достигать? Присоединяйся к курсу, внедряй полученные знания и ты увидишь, как гибкий подход приближает бизнес к поставленным целям',
    staticDesc2: '9 недель, 9 занятий, 9 практических навыков, которые повысят твою эффективность, и благодаря этому — продуктивность команды',
    coursesListTitle: 'Все курсы',
    coursesListSubTitle: 'Получи уникальную возможность',
    coursesListDesc: 'Развивайся, общайся и лифти в мобильном приложении LIFTA.SPACE. Установи LIFTA-приложение и получи уникальную возможность расти',
    searchPlaceholder: 'Поиск...',
    allDirections: 'Все направления',
    meta: {
      name: 'Все курсы',
      title: 'Все курсы',
      description: 'Развивайся, общайся и лифти в мобильном приложении LIFTA.SPACE. Установи LIFTA-приложение и получи уникальную возможность расти',
      keywords: 'курсы, список, все направления',
    }
  },

  //choose-assistance
  choose: {
    title1: 'НЕ ЗНАЕТЕ ЧТО',
    title2: 'ВЫБРАТЬ?',
    desc: 'Наши менеджеры помогут определиться с професией, оставьте свой номер телефона, имя и почту.',
    name: 'Имя',
    phone: 'Телефон',
    mail: 'Почта',
    hint: 'Подпишись сейчас и получай актуальную информацию на почту*',
    button: 'Отправить',
  },

  //speakers-list
  speakers: {
    title: 'Cпикеры',
    subTitle: 'Список всех доступных спикеров на сервисе',
  },

  //speaker-sample
  speaker: {
    aboutTitle: 'О спикере',
    personalTitle: 'Все курсы',
    personalDesc: 'Запланируй обучение прямо сейчас, не упусти момент',
    personalLookAll: 'Посмотреть все',
    speakerListTitle: 'Выбери своего спикера',
  },

  //news-main
  mainNews: {
    title: 'Блог',
    subTitle1: 'Новости,',
    subTitle2: 'бесплатные',
    subTitle3: 'курсы, скидки каждый день.',
    itemList: {
      item1: 'Все',
      item2: 'Маркетинг',
      item3: 'Скидки',
      item4: 'Дизайн',
      item5: 'Образование',
      item6: 'Дизайн',
      item7: 'Скидки',
      item8: 'Дизайн',
      item9: 'Бесплатный курс',
      item10: 'Образование',
      item11: 'Бесплатный курс',
      item12: 'Дизайн',
      item13: 'Дизайн',
      item14: 'Образование',
      item15: 'Дизайн',
      item16: 'Скидки',
      item17: 'Дизайн',
      item18: 'Бесплатный курс',
      item19: 'Образование',
      item20: 'Бесплатный курс',
      item21: 'Дизайн',
    },
    lookPost: 'Посмотреть статью',
    popularTitle: 'популярные новости',
    seeAll: 'Посмотреть все',
    topicTitle: 'Топик',
  },

  //newsList
  newsList: {
    title: 'ВСЕ НОВОСТИ',
    subTitle: 'Откройте для себя мир новых профессий',
    total: 'Всего',
    news: 'новостей.',
  },

  //popups
  popup:{
    haveAccount: 'Уже есть аккаунт?',
    haveNotAccount: "Нет учетной записи?",
    signIn: 'Вход',
    register: 'Регистрация',
    password: 'Пароль',
    passwordConfirm: 'Подтвердите пароль',
    forgotPassword: 'Забыли пароль?',
    backToLogin: 'Назад на Вход',
    email: 'Email',
    phone: 'Телефон',
    restorePassword: 'Востановление пароля',
    send: 'Отправить',
    logOut: 'Выход',
    managerInfo: 'После регистрации наш менеджер свяжется с вами!',
    subscribeSuccessInfo: 'Спасибо за регистрацию. Наш менеджер свяжется с Вами в ближайшее время!'
  },

  //event
  event:{
    registerCourse: 'Зарегистрироваться',
    downloadPreview: 'Скачать бесплатный гайд',
    registerAndPayment: 'Регистрация и оплата',
    priceCourseTo: 'Цена курса До',
    priceMessage: 'Цена указана за весь курс, никаких дополнительных платежей',
    aboutEvent: 'О мероприятии',
    howYouGet: 'Что ты получишь в итоге?',
    eventWhy: 'зачем тебе это?',
    coursesProgram: 'Дорожная карта курса:',
    yourEventIf: 'Это для тебя, если...',
    level: 'Уровень',
    subscribeConcult: 'связаться с менеджером',
    leaveRequest: 'оставить заявку',
    watchWebinar: 'смотреть вебинар',
    speakers: 'С кем ты лифтишь?',
    goBack: 'Вернуться назад',
    start: 'старт',
    to: 'до',
    after: 'после',
    specialProposition: 'Специальное предложение',
    aboutCourse: 'О курсе',
    when: 'Когда',
    program: 'Программа',
    time: 'Время Проведения',
    price: 'Стоимость',
    youSpeaker: 'Твой спикер',
    register: 'Регистрация',
    coursePrice: 'Стоимость курса',
    withOutDiscount: 'без скидки',
    discountEnd: 'Скидка закончится',
    promocodeMessage: 'У меня есть промокод',
    yourSpeaker: 'С кем ты лифтишь?',
    watchTrailer: 'Просмотреть трейлер курса',
    speakerWorld1: 'спикер',
    speakerWorld2: 'спикера',
    speakerWorld3: 'спикеров',
    hintDesc1: 'Специальные условия действуют для участников только сегодня!',
    hintDesc2: 'Со скидкой -10%',
    hintDesc3: 'Специальное предложение действует только сегодня!',
    hintDesc4: 'Со скидкой -30%',
    hintTitle: 'Получи скидку в 30% на профессию IT - Рекрутер',
  },

  //vacancy
  vacancy:{
    desc0: `LIFTA.SPACE — новое digital-пространство роста, развития и достижения профессиональных и личностных качеств. Проект — больше, чем база знаний. Это — настоящая образовательная экосистема, революционная культура обмена опытом, навыками и взглядами. Комплексный подход к комплексному развитию человека.<br><br>` +
      `Наша цель — не просто создать уникальное пространство для самореализации и саморазвития каждого, помогать исследовать новые возможности для роста и преодолевать внутренние и внешние барьеры, а в целом изменить подход и культуру несовершенной системы образования и развития настоящего. Мы — проект нового поколения, который стирает все лишние барьеры. Потому что возможно все.<br><br>` +
      'NO LIMITS — это больше чем концепция или мотивирующий слоган платформы. Это действительность, которая воплощается с каждым новым шагом образовательного пространства LIFTA.SPACE.\n',
    title1: 'Будь кем захочешь, найди работу мечты!',
    desc1: `LIFTA.SPACE не только вдохновляет, но и предлагает безграничный простор возможностей.<br><br>` +
      'Let’s lift together!',
    btn1: 'Стань частью команды!',
    title2: 'Возможности — безграничны!',
    desc2: 'Если выше не нашлось предложения, которое попало бы тебе прямо в сердце, не беда! Один клик — и мы расскажем о тебе своим партнерам, крутым компаниям, талантливым HR-ам, потому что ты владеешь скилами и идеями, которые сегодня ценны, как никогда. О тебе должен знать весь мир!',
    btn2: 'Все возможности мира',
    firstName:'Имя',
    lastName: 'Фамилия',
    letter: 'Сопроводительное письмо',
    link : 'Ссылка на',
    cvMessage: 'Прикрепите резюме (файл, форматы .doc, .docx, .pdf, .txt, .pptx)\n',
    titleMessage1: 'Форма для заполнения вакансии',
    titleMessage2: 'Форма для заполнения без вакансии',
    noVacancyMessage: 'Свободных вакансий нет',
  },


  // payment
  payment:{
    coursePayment: 'Оплата курса',
    coursePrice: 'Цена курса',
    paymentSystem: 'Система оплаты',
    paymentSystemName: 'Fondy',
    successBuyProduct: 'Вы оплатили курс ":title" успешно. Наш менеджер свяжется с вами в течении рабочего дня. Спасибо',
    errorBuyProduct: `Вы не смогли оплатить курс ":title". Попробуйте ,пожалуйста, снова или же свяжитесь с нами:<br><br>` +
      `- в живом чате на сайте<br><br>` +
      `- на почту support@lifta.space<br><br>` +
      `- по телефону +38 (044) 334 61 16 или +7 (495) 266 67 03`,
    pendingBuyProduct: 'Ваш запрос в режиме ожидания.',
    buyCourse: 'Купить курс',
    orderCourse: 'Забронировать',
    orderForCourse: 'Получить скидку',
    buy: 'Купить',
  },


  // translationForm
  translation: {
    title: 'Привет, лифтэр! \n' +
      'За 7 дней марафона ты собрал 7 цифр кода, чтобы принять участие в розыгрыше MacBook Air 2020.\n' +
      'Вводи код сюда и жди результатов 22 февраля. \n' +
      'И пусть удача будет с тобой!\n',
    firstName: 'Имя',
    lastName: 'Фамилия',
    phone: 'Номер телефона',
    code: 'Код марафона',
    button: 'Выиграть MacBook Air',
    successSendCode: 'Спасибо, Ваш код зарегистрирован. Ожидайте результатов розыгрыша.',
  },


  // test module
  test: {
    passTest: 'Пройти тест',
    prevButton: 'Назад',
    nextButton: 'Далее',
    send: 'Отправить',
    meResult: 'мне результаты',
  },
  //privacy
  privacy: {
    content: `<h1>Политика конфиденциальности</h1>
        <p>Если определение не указано в применимой Политике конфиденциальности,
аналогичные термины используются в значении, указанном в Условиях обслуживания
(«Условия»).</p>
        <h2>Операторы данных</h2>
        <p>Термины «Lifta.Space», «мы» или «нас» означают ООО «ББ Технолоджи» или другое
агентство, которое несет ответственность за ваши данные в соответствии с
применимой Политикой конфиденциальности («Оператор данных»).</p>
        <p>Эта страница информирует вас о нашей политике в отношении сбора, использования и
раскрытия пользовательских данных нашим веб-сайтом или мобильным
приложением, о процедуре предоставления пользователями согласия на
использование таких данных и о том, каких данных Lifta.Space является контроллером.</p>
        <p>Кроме того, в этой политике конфиденциальности мы информируем вас о типе,
объеме и цели сбора и использования данных, которые могут собираться
автоматически при использовании вами веб-сайта https://lifta.space и / или
мобильного приложения. Актуальная версия политики конфиденциальности
размещена на нашем сайте <a href="https://lifta.space/privacy_policy.pdf" target="_blank">https://lifta.space/privacy_policy.pdf</a></p>
        <p>Если вы покидаете наш веб-сайт или приложение для ссылки на другой онлайн-ресурс
или посещаете наш сайт на платформе социальной сети эта политика
конфиденциальности не распространяется на сбор, использование и хранение ваших
данных контроллерами других ресурсов в Интернете.</p>
        <p>Обратите внимание, что программное обеспечение на вашем мобильном устройстве
может получить доступ к вашим данным. Наши продукты или услуги могут ссылаться
на веб-сайты и услуги других компаний, у которых есть собственная политика
конфиденциальности, с которой вам может потребоваться ознакомиться при
использовании таких услуг.</p>
        <p>Если наши продукты или услуги встроены в продукты и
услуги наших партнеров, мы требуем, чтобы наши партнеры обеспечивали вам
прозрачность, необходимую для сбора и использования вашей информации. Это
может включать ссылки на настоящую Политику конфиденциальности и связанные с
ней приложения или прозрачность интегрированных и встроенных коммуникаций,
которые идентифицируют нас как поставщика услуг или контроллера данных.</p>
        <p>Если вы не согласны с условиями этой политики или не понимаете условия этой
политики, мы советуем вам не использовать наш веб-сайт или мобильное
приложение. Получая доступ или используя этот веб-сайт и / или мобильное
приложение, вы соглашаетесь с условиями настоящей Политики конфиденциальности.</p>
        <p>2.2. Оператор обрабатывает персональные данные Пользователей исключительно в
следующих целях:</p>
        <p>2.2.1. Регистрация Пользователя на Сайте, предоставление Пользователю возможности
полноценного использования сервисов Сайта.</p>
        <p>2.2.2. Идентификация Пользователя на Сайте.</p>
        <p>2.2.3. Отображение профиля Пользователя для иных Пользователей Сайта в целях
поддержания коммуникации, в том числе при оказании услуг дистанционным
способом.</p>
        <p>2.2.4. Установление и поддержание связи между Пользователем и Оператором,
консультирование по вопросам оказания услуг.</p>
        <p>2.2.5. Исполнение Оператором обязательств перед Пользователем по соглашениям,
заключенным с Оператором (в частности, Пользовательскому соглашению, иным
договорам, исполняемым на Сайте Оператора, размещенным и доступным по адресу
https://lifta.space ). Исполнение обязательств, в частности, включает в себя
информирование о дате и времени проведения занятий (в том числе путем обзвона и
направления смс-сообщений); направление документов об освоении образовательной
программы и т.д.</p>
        <p>2.2.6. Направление Оператором на адрес электронной почты Пользователя сообщений
рекламного характера; таргетирование рекламных материалов.</p>
        <p>2.2.7. Размещение на Онлайн-платформе Оператора, в официальных группах
социальных сетей и иных сообществах Оператора в сети Интернет, прочих рекламных
и информационных источниках, в целях, не связанных с установлением личности
Пользователя:
— видеоматериалов, полученных в процессе оказания услуг;
— оставленных Пользователем отзывов об услугах, оказываемых Оператором.</p>
        <p>2.2.8. Улучшение качества обслуживания Пользователей и модернизация Сайта
Оператора путем обработки запросов и заявок от Пользователя.</p>
        <p>2.2.9. Статистические и иные исследования на основе обезличенной информации,
предоставленной Пользователем.</p>
        <h2>3. Условия и порядок предоставления согласия
на обработку персональных данных Оператором</h2>
        <p>3.1. Оператор не проверяет предоставляемые Пользователем персональные данные. В
связи с этим Оператор исходит из того, что при предоставлении персональных данных на
Сайте Пользователь:</p>
        <p>3.1.1. Является дееспособным лицом. В случае недееспособности лица, использующего
Сайт, согласие на обработку персональных данных предоставляется законным
представителем Пользователя, который ознакомился и принял условия обработки
персональных данных, указанные в настоящей Политике.</p>
        <p>3.1.2. Указывает достоверную информацию о себе или о представляемом недееспособном
лице (п. 3.1.1) в объемах, необходимых для использования Сайта. Пользователь
самостоятельно поддерживает предоставленные персональные данные в актуальном
состоянии. Последствия предоставления Пользователем недостоверной или
недостаточной информации определены в Пользовательском соглашении.</p>
        <p>3.1.3. Осознает, что информация на Сайте, размещаемая Пользователем о себе, может
становиться доступной для других Пользователей Сайта, может быть скопирована и
распространена такими Пользователями в случаях и на условиях предусмотренных
Политикой.</p>
        <p>3.2. Пользователь принимает условия Политики и дает Оператору информированное и
осознанное согласие на обработку своих персональных данных на условиях,
предусмотренных Политикой и применимым законодательством.</p>
        <p>3.3. Оператор собирает, хранит и обрабатывает такую информацию о Пользователе:</p>
        <ul>
          <li>фамилия и имя;</li>
          <li>адрес электронной почты;</li>
          <li>номер телефона;</li>
          <li>Данные про устройство, с которого Пользователь использует услуги Оператора;</li>
          <li>Cookies;</li>
          <li>Другую статистическую не персонализированную информацию о Пользователе;</li>
          <li>Оплаченные курсы/занятия и просмотренные бесплатные уроки .</li>
        </ul>
        <h2>4. Обработка персональных данных</h2>
        <p>4.1. Обработка персональных данных Пользователя производится Оператором с
использованием баз данных на территории Украины.</p>
        <p>4.2. Персональные данные обрабатываются с использованием автоматизированных
систем, за исключением случаев, когда неавтоматизированная обработка персональных
данных необходима в связи с исполнением требований законодательства.</p>
        <p>4.3. Обработка персональных данных Пользователя включает совершение Оператором
следующих действий: сбор, запись, систематизация, накопление, хранение, уточнение
(обновление, изменение), извлечение, использование, передача (распространение,
предоставление, доступ), обезличивание, блокирование, удаление, уничтожение.</p>
        <p>4.4. Сбор персональных данных Пользователя осуществляется в случаях, указанных в п.
3.2 Политики.</p>
        <p>4.5. Хранение персональных данных Пользователей осуществляется на электронных
носителях. При обработке персональных данных с целью исполнения обязательств по
соглашениям с Пользователем Оператор может извлекать персональные данные и
хранить их на материальных носителях. Хранение таких персональных данных
осуществляется в течение срока, установленного законодательством Украины. Хранение
персональных данных осуществляется (в зависимости от того, какое событие наступит
раньше):</p>
        <p>— до момента их удаления Пользователем в соответствующем разделе;</p>
        <p>— до момента их уничтожения Оператором</p>
        <p>— в случае поступления от Пользователя
отзыва согласия на обработку персональных данных или требования об уничтожении
персональных данных;</p>
        <p>— до момента истечения срока действия согласия.</p>
        <p>4.6. Уточнение персональных данных может осуществляться Пользователем
самостоятельно в соответствующих разделах либо Оператором по требованию
Пользователя.</p>
        <p>4.7. Распространение персональных данных может осуществляться Оператором
исключительно в следующих случаях:</p>
        <p>4.7.1. При обработке персональных данных с целью отображение профиля Пользователя
для иных Пользователей Сайта для поддержания коммуникации, в том числе при оказании
услуг дистанционным способом. В этом случае персональные данные Пользователя могут
быть доступны Пользователям, зарегистрированным на Сайте.</p>
        <p>4.7.2. С целью размещения отзывов об услугах, оказываемых Оператором, оставленных
Пользователями, в различных источниках информации.</p>
        <p>4.7.3. С целью размещения видео-материалов, полученных в процессе оказания услуг, в
различных источниках информации.</p>
        <p>4.8. Оператор вправе осуществлять передачу персональных данных третьим лицам с
соблюдением следующих условий:</p>
        <p>— Третье лицо осуществляет обработку персональных данных с использованием баз
данных на территории Украины.</p>
        <p>— Третье лицо обеспечивает конфиденциальность персональных данных при их
обработке и использовании; обязуется не раскрывать иным лицам, а также не
распространять персональные данные Пользователей без их согласия.</p>
        <p>— Третье лицо гарантирует соблюдение следующих мер по обеспечению безопасности
персональных данных при их обработке: использование средств защиты информации;
обнаружение и фиксация фактов несанкционированного доступа к персональным данным
и принятие мер по восстановлению персональных данных; ограничение доступа к
персональным данным; регистрация и учет действий с персональными данными; контроль
и оценка эффективности применяемых мер по обеспечению безопасности персональных
данных.</p>
        <p>— Передавать статистические не персонализированные данные по которым невозможно
идентифицировать Пользователя для улучшения уровня предоставляемых услуг,
маркетинговых компаний других рекламных и прочих целях.</p>
        <p>4.8.1. Цели передачи персональных данных:</p>
        <p>— Оптимизация оператором рассылки сообщений информационного и рекламного
характера. В этом случае третьему лицу передаются следующие персональные данные
Пользователей: фамилия, имя, отчество; номер телефона; адрес электронной почты.</p>
        <p>— Направление Пользователям информационных рассылок о новых возможностях в
сфере образования и развития. В этом случае третьему лицу передаются следующие
персональные данные Пользователей: фамилия, имя, отчество; адрес электронной почты.
Каждая информационная рассылка предоставляет Пользователю возможность отказаться
от получения таких рассылок.</p>
        <p>— Исполнение условий договора перед Пользователями Сайта с привлечением третьих
лиц. Объем передаваемых данных определяется в условиях договора.</p>
        <p>— В других рекламных целях либо в целях улучшения качества обслуживания.</p>
        <p>4.8.2. Перечень разрешенных способов обработки персональных данных: сбор, запись,
систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение,
использование, передача (распространение, предоставление, доступ), обезличивание,
блокирование, удаление, уничтожение. Третьему лицу запрещено осуществлять передачу
и распространение персональных данных.</p>
        <p>4.8.3. Уничтожение персональных данных осуществляется Оператором в следующих
случаях:</p>
        <p>— удаление Пользователем персональных данных в соответствующем разделе;</p>
        <p>— поступление от Пользователя отзыва согласия на обработку персональных данных;</p>
        <p>— получение от Пользователя требования об уничтожении персональных данных;</p>
        <p>— истечение срока действия согласия.</p>
        <h2>5. Меры для защиты персональных данных</h2>
        <p>Оператор принимает необходимые и достаточные правовые, организационные и
технические меры для защиты информации, предоставляемой Пользователями, от
неправомерного или случайного доступа, уничтожения, изменения, блокирования,
копирования, распространения, а также от иных неправомерных действий с ней третьих
лиц. Такие действия, в частности, включают:</p>
        <p>5.1. Назначение лица, ответственного за обработку персональных данных;</p>
        <p>5.2. Применение организационных и технических мер по обеспечению безопасности
персональных данных при их обработке в информационных системах;</p>
        <p>5.3. Контроль фактов несанкционированного доступа к персональным данным и принятие
мер по недопущению подобных инцидентов в дальнейшем;</p>
        <p>5.4. Контроль за принимаемыми мерами по обеспечению безопасности персональных
данных и уровнем защищенности информационных систем персональных данных.</p>
        <h2>6. Права Пользователей</h2>
        <p>Пользователь вправе:</p>
        <p>6.1. По своему усмотрению предоставлять Оператору персональные данные для их
обработки на условиях, указанных в Политике;</p>
        <p>6.2. Самостоятельно вносить изменения и исправления в свои персональные данные в
личном кабинете при условии, что такие изменения и исправления содержат актуальную и
достоверную информацию;</p>
        <p>6.3. Удалять персональные данные в личном кабинете;</p>
        <p>6.4. Обращаться к Оператору с требованиями, в том числе об уточнении персональных
данных; о блокировке или уничтожении персональных данных, если такие данные
являются неполными, устаревшими, недостоверными, незаконно полученными или не
являются необходимыми для заявленной цели обработки.</p>
        <p>6.5. На основании запроса получать от Оператора информацию, касающуюся обработки
его персональных данных и предусмотренную соответствующим законодательством.</p>
        <h2>7. Обращения Пользователей</h2>
        <p>7.1. Пользователь вправе направлять Оператору свои запросы и требования (далее
– Обращение), в том числе относительно использования его персональных данных, а
также отзыва согласия на обработку персональных данных. Обращение может быть
направлено следующими способами:</p>
        <p>7.1.1. В письменной форме по адресу Оператора;</p>
        <p>7.1.2. В форме электронного документа (скан-, фотокопия документа). Документ должен
быть направлен с адреса электронной почты Пользователя, указанного им при
регистрации на Сайте или в договоре в качестве уполномоченного адреса электронной
почты, по адресу электронной почты Оператора: lifta.space@bg.solutions</p>
        <p>7.2. Такое обращение должно содержать:
1) ФИО заявителя.
2) Детальное описание сути Обращения.
3) Правовые или фактические основания для реализации действий, указанных в
обращении.</p>
        <p>7.3. Оператор рассматривает Обращение Пользователя в следующем порядке:</p>
        <p>7.3.1 Проверяется наличие всех обязательных реквизитов Обращения;</p>
        <p>7.3.2. Проверяется обоснованность Обращения;</p>
        <p>7.3.3. Предоставляется ответ на Обращение. Ответ может направлять в той же форме что
и Обращение либо в удобной Оператору форме.</p>
        <p>7.4. Оператор рассматривает все Обращения и предоставляет на них ответы (выполняет
Обращение) в течении 30 дней.</p>
        <h2>8. Изменение Политики</h2>
        <p>8.1. Оператор оставляет за собой право вносить изменения в Политику. На Пользователе
лежит обязанность при каждом использовании Сайта знакомиться с текстом Политики.</p>
        <p>8.2. Новая редакция Политики вступает в силу с момента ее размещения в
соответствующем разделе сайта Оператора. Продолжение пользования Сайтом или его
сервисами после публикации новой редакции Политики означает принятие Политики и ее
условий Пользователем. В случае несогласия с условиями Политики Пользователь
должен незамедлительно прекратить использование Сайта и его сервисов.</p>`
  },
  terms: {
    content: `<h1>Пользовательское соглашение</h1>
        <p>Внимание! Если Вы не согласны с условиями настоящего пользовательского соглашения,
не регистрируйтесь на Сайте и не используйте его сервисы.</p>
        <h2>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
        <p>1.1. В настоящем пользовательском соглашении («Соглашение»), если из его текста прямо
не вытекает иное, следующие термины будут иметь указанные ниже значения:</p>
        <p>ТОВ «ББ Технолоджі» («Lifta.Space», «мы», «нас», «наш», «Администратор»)
предоставляет вам свои услуги (описанные ниже) через свой веб-сайт, расположенный по
адресу https://lifta.space и через его мобильные приложения и связанные службы («Сайт»),
что включает в себя совокупность информации, текстов, графических элементов, дизайна,
изображений, фото, аудио и видеоматериалов, и иных охраняемых результатов
интеллектуальной деятельности и средств индивидуализации Администратора, в
соответствии со следующими Пользовательским соглашением. ПОЖАЛУЙСТА,
ВНИМАТЕЛЬНО ПРОЧИТАЙТЕ ДАННЫЕ УСЛОВИЯ ОБСЛУЖИВАНИЯ, ТАК КАК ОНИ
РЕГУЛИРУЮТ ВАШЕ ИСПОЛЬЗОВАНИЕ САЙТА И УСЛУГ.</p>
        <p>«Пользователь», «Конечный потребитель» - Лицо, заключившее с Администратором
настоящее пользовательское соглашение путем акцепта оферты, расположенной в сети
Интернет по сетевому адресу https://lifta.space/terms_of_use.pdf.
«Автор» - Пользователь, чьи статьи (произведения) опубликованы на Сайте в разделе
Статьи. Автором может стать только Пользователь, принявший условия публикации
на Сайте и соответствующий требованиям, установленным Администратором.</p>
        <p>«Преподаватель» - Физическое лицо, выступающее в качестве докладчика, лектора,
эксперта, тренера, автора, проводящее занятие в рамках программ обучения или курсов,
представляющее информацию по теме занятий и, если применимо, комментирующее
выполнение Пользователем заданий, являющихся практической частью соответствующего
занятия.</p>
        <p>«Партнер» - Физическое и/или юридическое лицо, разместившее свое предложение об
оказании услуг Пользователям. Условия оказания услуг каждый Партнер определяет
самостоятельно.</p>
        <p>«Видеокурсы» - Совокупность аудиовизуальных (видеолекции), текстовых, графических
материалов и программ для ЭВМ (программные средства для выполнения тестовых
заданий), объединенных общей темой, предназначенных для обучения Пользователя либо
Конечного потребителя с целью получения ими новых или развития имеющихся навыков и
знаний по указанной теме.
Видеокурс не является полностью или в части Программой обучения</p>
        <p>«Программа обучения» - Совокупность аудиовизуальных (вебинары),
текстовых, графических материалов и программ для ЭВМ (программные средства для
выполнения тестовых заданий), объединенных общей темой, предназначенных для
обучения Пользователя с целью получения им новых или развития имеющихся навыков
и знаний, необходимых для освоения указанной в теме программы обучения.</p>
        <p>«Бесплатное занятие» - Аудиовизуальный материал (вебинар), доступ к которому, а
также ко всем материалам которого, предоставляется Администратором бесплатно для
всех Пользователей, зарегистрировавшихся на участие в таком вебинаре. Администратор
проводит бесплатные занятия для всех заинтересованных Пользователей.
Бесплатное занятие не является полностью или в части Программой обучения.</p>
        <p>«Личный кабинет» - Совокупность защищенных страниц Сайта, созданных в результате
регистрации Пользователя, используя которые Пользователь имеет возможность оплаты
доступа к Библиотеке курсов и программам обучения Администратора, возможность
доступа к бесплатным занятиям, возможность получения доступа к Библиотеке курсов
и программ обучения в полном объеме после оплаты такого доступа, возможность
получения информации о них, возможность изменения сведений о Пользователе, пароля, а
также осуществления иных действий, предусмотренных явными функциями Личного
кабинета.
Доступ в Личный кабинет осуществляется путем внесения учетных данных в
предусмотренные для этого поля на Сайте.</p>
        <p>«Центр развития карьеры» - Раздел Сайта, предназначенный для помощи Пользователям
и иным третьим лицам в профессиональной ориентации и в трудоустройстве после
прохождения Программ обучения Администратора. Центр развития также
предназначен для помощи работодателям в поиске квалифицированных сотрудников среди
успешно завершивших Программы обучения Пользователей.</p>
        <p>1.2. Все остальные термины и определения, встречающиеся в тексте Соглашения,
толкуются Сторонами в соответствии с применимым законодательством и сложившимися в
сети Интернет обычными правилами толкования соответствующих терминов</p>
        <p>1.3. Названия заголовков (статей) Соглашения предназначены исключительно для
удобства пользования текстом Соглашения и буквального юридического значения
не имеют.</p>
        <h2>2. ПРЕДМЕТ СОГЛАШЕНИЯ</h2>
        <p>2.1. Администратор предоставляет Пользователю право использовать Сайт, личный
кабинет, сервисы Сайта по их прямому назначению, в том числе путем осуществления
доступа к Сайту, Личному кабинету с помощью персональных компьютеров и мобильных
устройств и использования явных функций Сайта, личного кабинета на условиях
безвозмездной простой (неисключительной) лицензии на территории доступа к Сайту,
личному кабинету и к их функциям, на срок, в течение которого Сайт, личный кабинет
и их функции остаются доступны для Пользователя.</p>
        <p>2.2. Администратор оказывает Пользователю услуги по предоставлению доступа к
Сайту, видеокурсам, программам обучения, бесплатным занятиям, статьям с целью
организации самообучения Пользователя навыкам по определенной теме или
необходимых для освоения</p>
        <h2>3. ПОРЯДОК РЕГИСТРАЦИИ НА САЙТЕ</h2>
        <p>3.1. Администратор предоставляет Пользователю до его регистрации на Сайте доступ
к информации о Сайте, курсах, программах обучения, о библиотеке курсов, о бесплатных
занятиях, а также доступ к обзорам и статьям, размещенным на сайте в разделе «Полезные
материалы».</p>
        <p>3.2. После регистрации на Сайте Пользователю в дополнение к доступу, указанному в
пункте 3.1 Соглашения, предоставляется доступ к бесплатным занятиям, предоставляется
возможность приобретения за плату доступа к программам обучения и к библиотеке курсов,
доступ и участие в рейтинге Пользователей, периодическая подписка на услуги, а также
доступ к иным функциям Сайта и Личного кабинета.</p>
        <p>3.3. По завершении регистрации на Сайте Пользователю предоставляется доступ к
Личному кабинету по учетным данным – адресу электронной почты и паролю. Пароль может
быть изменен Пользователем в любое время после регистрации.</p>
        <p>3.4. На Пользователе лежит обязанность обеспечить безопасность и сохранность пароля.
При утрате либо компрометации пароля, а также в случае незаконного завладения третьими
лицами доступа к Личному кабинету, Пользователь обязан незамедлительно сообщить об
этом Администратору по телефону либо на адрес электронной почты
lifta.space@bg.solutions. До момента поступления такого сообщения все действия,
совершенные с использованием Личного кабинета Пользователя будут считаться
совершенными самим Пользователем.</p>
        <h2>4. ПОРЯДОК ПОЛЬЗОВАНИЯ ЛИЧНЫМ КАБИНЕТОМ И САЙТОМ</h2>
        <p>4.1. По завершении регистрации Пользователь получает доступ к Личному кабинету. В 
Личном кабинете Пользователя отображается информация о начатых и завершенных
Пользователем курсах и программах обучения, о возможности доступа Пользователя к
Библиотеке курсов за плату и к бесплатным занятиям, о стимулирующих мероприятиях, о
партнерах Администратора, предоставляющих Пользователям бесплатно или со скидкой
указанные на Сайте товары и/или услуги, о предлагаемых вакансиях
партнеров Администратора, о произведенных платежах в счет оплаты доступа к библиотеке
курсов и программам обучения.</p>
        <p>4.2. В Личном кабинете Пользователь имеет возможность оплатить доступ к библиотеке
курсов и программам обучения, а также оплатить месячную либо годовую подписку на наши
Услуги.</p>
        <p>4.3. Пользователю доступны статьи и иные материалы, представленные на Сайте,
возможность оставлять комментарии к таким статьям и материалам.</p>
        <p>4.4. Для доступа к определенным курсам, программам обучения и бесплатным занятиям
от Пользователя может потребоваться соблюдение специальных технических
требований Администратора, размещенных на Сайте.</p>
        <p>4.5. Администратор вправе в любое время по своему усмотрению в отношении курсов и
программ обучения, в том числе в отношении бесплатных занятий, изменять темы
отдельных занятий, менять содержание занятий, количество, наименование и вид
материалов, входящих в состав курсов, программ обучения, бесплатных занятий, даты и
время проведения соответствующих занятий и их продолжительность, а также, в случае
необходимости, заменять Преподавателей по отдельным занятиям. При этом случае
Администратор гарантирует, что подобные изменения не приведут к ухудшению качества
курсов, программ обучения и бесплатных занятий. Информация о таких изменениях
публикуется на Сайте непосредственно до начала проведения соответствующего занятия,
в котором произошли такие изменения.</p>
        <p>4.6. Пользователь имеет возможность участвовать в партнерской программе
Администратора на условиях и в порядке, определенных Администратором и указанных на
соответствующей странице Сайта.</p>
        <p>4.7. Пользователь обязан пользоваться Сайтом и Личным кабинетом добросовестно, не
нарушая применимое законодательство, права и свободы третьих лиц, нормы морали
и нравственности. В случае необходимости Корпоративный пользователь обязуется
обеспечить соблюдение применимого законодательства, норм морали и нравственности
Конечными потребителями.</p>
        <h2>5. УСЛОВИЯ И ПОРЯДОК ДОСТУПА К ВИДЕОКУРСАМ</h2>
        <p>5.1. Пользователю доступна информация о каждом видеокурсе, его описание, о темах
и продолжительности занятий, о продолжительности курса, Преподавателях курса,
о рейтинге курса. Пользователю доступна информация о стоимости курсов и доступна
возможность оплаты каждого видеокурса.</p>
        <p>5.2. Доступ к каждому видеокурсу предоставляется Пользователям, оплатившим в
порядке, установленном Соглашением и указанном на соответствующей странице Сайта,
стоимость видеокурса. Доступ предоставляется через личный кабинет.</p>
        <p>5.3. Курсы предусматривают видеолекции и программные средства (домашние задания) в
виде теста для проверки Пользователем усвоенной информации, предоставленной в
видеолекции. Тестирование проводит Преподаватель в ручном режиме.</p>
        <p>5.4. По завершению курса Пользователь вправе оценить курс и оставить комментарий о
курсе. Администратор оставляет за собой право удалить комментарии Пользователей,
признанные Администратором противоречащими применимому законодательству либо по
собственному усмотрению.</p>
        <h2>6. ПОРЯДОК ДОСТУПА К ПРОГРАММАМ ОБУЧЕНИЯ</h2>
        <p>6.1. Пользователю доступна информация о программах обучения, их краткое описание,
описание в виде часто задаваемых вопросов и ответов, информация о Преподавателях
программы обучения, о составе программы обучения, содержащей наименование,
продолжительность, состав каждого из этапов (тем) программы обучения, включающей 
количество и название занятий, дату и время их проведения, информацию о
Преподавателях по таким занятиям. Пользователю доступна подробная информация о
каждом занятии программы обучения, описание его теоретической и практической части, а
также навыках, которые предлагается освоить Пользователю в рамках такого занятия.
Доступ к программам обучения предоставляется Пользователям, оплатившим такой доступ
в установленном Соглашением и соответствующей офертой, расположенной по сетевому
адресу https://lifta.space/terms_of_use.pdf, порядке.</p>
        <p>6.2. Программы обучения, на которые Пользователь зарегистрировался, но которые не
оплатил, отображаются в Личном кабинете как неоплаченные. Программы обучения, на
которые Пользователь зарегистрировался и которые оплатил, доступны в Личном кабинете
в полном объеме со всеми соответствующими материалами с учетом предстоящих
и пройденных занятий.</p>
        <p>6.3. Каждое занятие программы обучения представляет собой видеоурок (или запись
вебинара), дата и время проведения которого отображается на соответствующей странице
на Сайте и в Личном кабинете, а также иные материалы, в том числе средства проверки
приобретенных навыков. Прошедшие видеоуроки могут сохраняться в Личном кабинете в
виде видеозаписей в зависимости от условий программы обучения. Участие пользователя
в вебинаре, просмотр видеозаписи вебинаров, доступ к материалам и прохождение
тестов и выполнение домашних заданий осуществляется посредством Личного кабинета.</p>
        <p>6.4. В отношении каждого занятия программы обучения могут быть предусмотрены
методические материалы, содержащие список литературы, рекомендуемой для усвоения
информации, полученной Пользователем в таком занятии. При этом Стороны признают и
понимают, что такой список литературы не является рекламой. Указанные материалы
доступны Пользователю, оплатившему соответствующую программу обучения в течение
проведения программы обучения и после – в течение срока, устанавливаемого
Администратором.</p>
        <h2>7. ПОРЯДОК ДОСТУПА К БЕСПЛАТНЫМ ЗАНЯТИЯМ</h2>
        <p>7.1. Информация о предстоящих бесплатных занятиях, темах таких занятий, дате и времени
их проведения, Преподавателях и количестве участников размещается на
соответствующей странице Сайта.</p>
        <p>7.2. Пользователь имеет возможность зарегистрироваться на предстоящее бесплатное
занятие, перейдя по соответствующей ссылке и заполнив регистрационную форму. Об
успешной регистрации на бесплатное занятие Пользователь информируется сообщением в
открывшейся после перехода по ссылке странице Сайта и сообщением, направленным на
указанный в Личном кабинете адрес электронной почты. </p>
        <p>7.3. Информация о прошедших бесплатных занятиях, содержащих видеозапись
вебинара бесплатного занятия и иные материалы бесплатного занятия, равно как и темы
таких занятий, дата и время их проведения, Преподаватели и количество участников
размещаются на соответствующей странице Сайта.</p>
        <h2>9. ПОРЯДОК ДОСТУПА К СТАТЬЯМ. ПУБЛИКАЦИЯ В РАЗДЕЛЕ СТАТЬИ</h2>
        <p>9.1. В разделе Статьи Пользователи а также любые иные третьи лица могут ознакомиться
с информационными и информационно-аналитическими материалами, публикуемыми в
разделе. Доступ к таким материалам не ограничен.</p>
        <p>9.2. Пользователь, желающий опубликовать свои материалы в разделе Статьи,
должен ознакомиться с условиями публикации, подать заявку на публикацию в разделе,
согласовать свои материалы с редактором раздела.</p>
        <p>9.3. Пользователь, материалы которого опубликованы в разделе Статьи, является
Автором.</p>
        <p>9.4. Публикация прошедших согласование с редактором раздела материалов
производится Администратором по своему усмотрению и на безвозмездной основе, то есть
без взимания платы с Автора. Администратор вправе отказать Пользователю в публикации 
как несогласованных, так и предварительно согласованных с редактором раздела
материалов без объяснения причин.</p>
        <p>9.5. Пользователь понимает и соглашается с тем, что Администратор не выплачивает и
не обязуется выплатить Авторам какое-либо вознаграждение за публикацию материалов.</p>
        <h2>10. ЦЕНТР РАЗВИТИЯ КАРЬЕРЫ</h2>
        <p>10.1. Центр развития карьеры предназначен для помощи Пользователям, успешно
окончившим программы обучения, в профессиональной ориентации, а также для помощи
работодателям (юридическим лицам и индивидуальным предпринимателям) в поиске
работников среди Пользователей, успешно окончивших программы обучения.</p>
        <p>10.2. Пользователи, работодатели, а также любые третьи лица вправе задать
Администратору любой вопрос относительно профориентации и поиска работы, нажав
кнопку “Написать сообщение” и заполнив все необходимые поля формы.</p>
        <p>10.3. Администратор вправе не отвечать на вопрос без объяснения причин.</p>
        <p>10.4. Нажимая кнопку “Отправить” в форме “Сообщение в центр развития карьеры”
физическое лицо, указавшее в данной форме свои персональные данные (имя и e-mail),
дает согласие на обработку таких персональных данных Администратором в целях
реализации функций раздела центр развития карьеры.</p>
        <h2>12. СТИМУЛИРУЮЩИЕ МЕРОПРИЯТИЯ. ПАРТНЕРЫ</h2>
        <p>12.1. Администратор вправе проводить любые стимулирующие мероприятия (в любой
форме и с применением любых технологий), размещая информацию о таких мероприятиях
на любых страницах и в любых разделах Сайта по своему усмотрению.</p>
        <p>12.2. Партнеры вправе размещать, в том числе, льготные предложения, предназначенные
только для Пользователей. Администратор не несет какой-либо ответственности за
содержание предложений Партнеров о продаже товаров и/или оказании услуг
Пользователям, а также не отвечает за качество таких товаров/услуг.</p>
        <h2>13. ФИНАНСОВЫЕ УСЛОВИЯ</h2>
        <p>13.1. Администратор предоставляет доступ без взимания платы к бесплатным занятиям,
к материалам в разделе Статьи, к предложениям Партнеров, к функционалу разделов
Центр развития карьеры и Эксперты, а также ко всем иным функциональным возможностям
Сайта, в описании которых отсутствует или из сути которых не предполагается взимание
какой-либо платы за использование.</p>
        <p>13.2. Администратор устанавливает стоимость каждой программы обучения путем
публикации такой стоимости на странице с описанием этой программы обучения.
Администратор вправе изменять стоимость программы обучения в любое время по своему
усмотрению. При этом стоимость такой программы обучения, уже оплаченная
Пользователем, не меняется ни в сторону увеличения, ни в сторону уменьшения. Новая
цена распространяется на правоотношения Администратора и Пользователя, возникшие
после ее изменения.</p>
        <p>13.3. Администратор устанавливает стоимость видеокурса путем публикации такой
стоимости на соответствующей странице Сайта. Администратор вправе изменять
стоимость видеокурса в любое время по своему усмотрению. При этом стоимость такого
видеокурса, уже оплаченная Пользователем, не меняется ни в сторону увеличения, ни в
сторону уменьшения. Новая цена распространяется на правоотношения Администратора и
Пользователя, возникшие после ее изменения.</p>
        <p>13.4. Администратор вправе предоставлять отдельным Пользователям скидки на оплату
стоимости программ обучения или видеокурсов. Размер скидки, порядок и условия ее
предоставления Администратор устанавливает по своему усмотрению.</p>
        <p>13.5. Администратор вправе оказывать Пользователю любые дополнительные услуги без
взимания отдельной платы.</p>
        <h2>ПОРЯДОК ОПЛАТЫ ПРОГРАММЫ ОБУЧЕНИЯ</h2>
        <p>13.6. По общему правилу приобретение Пользователем доступа к программе 
обучения осуществляется на условиях предоплаты в размере полной стоимости программы
обучения.</p>
        <p>13.7. В зависимости от даты совершения оплаты программы обучения стоимость
программы обучения может изменяться. Информация об актуальной стоимости
указывается на странице соответствующей программы обучения.</p>
        <p>13.8. На странице программы обучения Пользователь имеет возможность
зарегистрироваться на программу обучения, введя достоверные данные (имя, фамилия,
номер телефона, адрес электронной почты), указав, кто будет производить оплату
(Пользователь или юридическое лицо).</p>
        <p>13.9. Если Пользователь указал, что оплата будет производиться Пользователем, то
в открывшемся окне Пользователь выбирает способ оплаты (систему платежей) и
совершает действия, предусмотренные правилами соответствующих электронных систем
платежей и процессинговых компаний. Моментом оплаты в этом случае считается момент
положительного результата авторизации платежа в выбранной системе платежей.
13.7. Соглашения.</p>
        <p>13.11. Если Пользователь указал, что оплата будет производиться юридическим лицом, то
Пользователь направляет Администратору информацию, которую Администратор запросил
у Пользователя в письме, направленном Пользователю после перехода по ссылке
«Записаться». Пользователь направляет указанную в запросе информацию
Администратору по адресу и способом, указанным в том же письме.
Оплата производится в согласованной валюте путем перечисления денежных средств на
расчетный счет Администратора, указанный в присланном Пользователю счете в срок,
указанный в том же счете. Моментом оплаты считается момент поступления денежных
средств на расчетный счет Администратора.</p>
        <p>13.12. Пользователь вправе единовременно приобрести и оплатить как одну программу
обучения, так и сразу несколько программ.</p>
        <p>13.13. Стороны имеют право согласовать другие уникальные условия по письменному
согласию Сторон.</p>
        <h2>ПОРЯДОК ОПЛАТЫ ВИДЕОКУРСОВ</h2>
        <p>13.14. На странице соответствующего видеокурса Пользователь имеет возможность
оплатить доступ к одному видеокурсу. Доступ к каждому видеокурсу оплачивается
отдельно.</p>
        <p>13.15. После последовательного нажатия Пользователем кнопок “Купить доступ к курсу” на
странице соответствующего видеокурса, Пользователь автоматически перенаправляется
на страницу оплаты и после выбора сервиса для оплаты совершает
действия, предусмотренные правилами соответствующих электронных систем платежей и
процессинговых компаний. Моментом оплаты в этом случае считается момент
положительного результата авторизации платежа в выбранной системе платежей.</p>
        <h2>ПОРЯДОК ВОЗВРАТА ДЕНЕЖНЫХ СРЕДСТВ</h2>
        <p>13.16. Пользователь может запросить возврат средств в случае их ошибочной оплаты, либо
по своему одностороннему желанию, но в любом случае не позже 3х дней до начала
видеокурса и/или программы обучения. Пользователь принимает, что банки и/или
процессинговые системы могут снимать дополнительные комиссии за обработку
транзакций, что будет осуществлено за счет клиента и финальная сумма может отличаться
от ранее оплаченной Пользователем.</p>
        <h2>ПОЛИТИКА БЕЗОПАСНОСТИ ПЕРЕДАЧИ РЕКВИЗИТОВ ПЛАТЕЖНОЙ КАРТЫ.</h2>
        <p>13.17. При оплате банковской картой, обработка платежа (включая ввод номера карты)
происходит на защищенной странице процессинговой системы. Это означает, что Ваши
конфиденциальные данные (реквизиты карты, регистрационные данные и др.) не поступают
к нам, их обработка полностью защищена, и никто не может получить персональные и 
банковские данные клиента. При работе с данными платежных карт применяется стандарт
информационной безопасности (Data Security Standard (PCI DSS), разработанный
международными платежными системами Visa и MasterCard. Data Security Standard (PCI
DSS) призван обеспечивать безопасную обработку реквизитов держателей платежных карт.</p>
        <h2>14. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ И ОГРАНИЧЕНИЯ ПРИ ПОЛЬЗОВАНИИ
САЙТОМ</h2>
        <p>14.1. Используя Сайт, Пользователь признает и соглашается с тем, что все без
изъятий содержимое Сайта (в том числе, но не ограничиваясь: аудиовизуальные
произведения, текстовые и графические материалы, программы для ЭВМ, товарные
знаки/знаки обслуживания, логотипы), структура содержимого Сайта, программный код
Сайта и/или его частей являются результатами интеллектуальной деятельности,
исключительное право на которые в полном объеме принадлежит Администратору.
Исключительное право а также иные интеллектуальные права (если применимо) на
вышеуказанные результаты интеллектуальной деятельности не переходят к Пользователю
в результате пользования Сайтом и заключения Соглашения.</p>
        <p>14.2. Регистрируясь на сайте, подавая заявку Администратору на публикацию материалов
в разделе Статьи и отправляя любые материалы на проверку и согласование
Администратору, Автор передает не исключительную лицензию, без оплатную, без
ограничения по территории, на любые такие материалы Администратору в полном объеме.
При этом Автор признает и подтверждает, что с момента перехода к Администратору такой
лицензии на материал, Администратор вправе распространять, копировать,
перерабатывать и иным образом неограниченно использовать материал и/или его части по
своему усмотрению.
Автор и Администратор также понимают и признают, что право авторства, а также
иные неимущественные интеллектуальные права на материал неотчуждаемы и
принадлежат Автору.</p>
        <p>14.3. Пользователю запрещается копировать, модифицировать, изменять, удалять,
дополнять, публиковать, передавать содержащиеся на Сайте результаты
интеллектуальной деятельности, создавать производные работы, изготавливать или
продавать товары/оказывать услуги на их основе, воспроизводить, отображать или любым
другим образом эксплуатировать или использовать такие результаты интеллектуальной
деятельности без прямого разрешения Администратора.
При цитировании материалов Сайта, если это прямо предусмотрено функциями
Сайта, Пользователь обязуется указывать ссылку на Сайт.</p>
        <p>14.4.Во избежание сомнений, Пользователю запрещается:</p>
        <p> копировать и/или распространять какую-либо информацию (включая части и
компоненты занятий по программе обучения, видеокурсов, статей, логотипов и товарных
знаков/знаков обслуживания), полученную на Сайте, кроме случаев, когда такая функция
прямо предусмотрена на Сайте (например, “Поделиться”/ “Сделать репост”);</p>
        <p>- использовать информацию, полученную на Сайте, для осуществления коммерческой
деятельности, извлечения прибыли, либо для использования любым противоречащим
закону способом, за исключением навыков, приобретенных на основе полученной в
соответствии с Соглашением информации;</p>
        <p>- копировать, либо иным способом использовать программную часть (программный код
или часть кода) Сайта, а также его дизайн;
- размещать на Сайте персональные данные третьих лиц без их согласия, в том числе, но
не ограничиваясь: домашние адреса, телефоны, паспортные данные, адреса
электронной почты;</p>
        <p>- размещать на Сайте коммерческую рекламу, коммерческие предложения, агитационную
информацию и любую другую навязчивую информацию, кроме случаев, когда размещение
такой информации прямо согласовано с Администратором;</p>
        <p> размещать на Сайте коммерческую рекламу, коммерческие предложения, агитационную
информацию и любую другую навязчивую информацию, кроме случаев, когда размещение
такой информации прямо согласовано с Администратором;</p>
        <p>- изменять каким бы то ни было способом программную часть Сайта, совершать действия,
направленные на изменение функционирования и работоспособности Сайта;</p>
        <p>- оскорблять и иным образом нарушать права и свободы других пользователей
Сайта, третьих лиц, а также групп лиц;</p>
        <p>- использовать нецензурную брань, осуществлять либо распространять
информацию, содержащую призывы к массовым беспорядкам, осуществлению
экстремистской деятельности, участию в массовых (публичных) мероприятиях, проводимых
с нарушением установленного порядка, распространять информацию, необходимую для
получения результатов интеллектуальной деятельности.</p>
        <h2>15. ПЕРСОНАЛЬНЫЕ ДАННЫЕ И ИХ ИСПОЛЬЗОВАНИЕ</h2>
        <p>15.1. Цель, порядок, сроки и иные существенные условия, при которых
Администратор обрабатывает персональные данные Пользователей, определены в
Политике Конфиденциальности, расположенной по сетевому адресу
https://lifta.space/privacy_policy.pdf .</p>
        <h2>16. ОТВЕТСТВЕННОСТЬ</h2>
        <p>16.1. В случае нарушения Пользователем условий Соглашения, применимого
законодательства, норм морали и нравственности, либо технических требований
Администратор вправе заблокировать или удалить личный кабинет, запретить либо
ограничить доступ по учетным данным Пользователя к определенным или всем функциям
Сайта, программам обучения, курсам, бесплатным занятиям.</p>
        <p>16.2. В случае нарушения Пользователем порядка оплаты, установленного разделом 13
Соглашения, Администратор вправе по своему усмотрению полностью заблокировать
доступ Пользователя к программам обучения со дня, следующего за днем просрочки
оплаты, расторгнуть в одностороннем порядке договор об оказании платных
образовательных услуг и возвратить Пользователю излишне уплаченные денежные
средства пропорционально количеству занятий, доступных Пользователю в личном
кабинете.</p>
        <p>16.3. При выявлении случаев предоставления Пользователем доступа к личному кабинету
третьим лицам, Администратор по своему выбору применяет меры, предусмотренные
пунктом 16.1. Соглашения. При этом Администратор вправе полностью заблокировать
доступ Пользователя к личному кабинету, программам обучения и курсам, в том числе,
заранее оплаченным Пользователем.</p>
        <p>16.4. Нарушение Пользователем условий Соглашения, повлекшее неблагоприятные
последствия для Администратора (ущерб, административная и иная ответственность,
предупреждения правоохранительных и иных органов исполнительной власти, претензии
третьих лиц), является основанием для Администратора прекратить доступ Пользователя к
видеокурсу, при этом денежные средства в счет оплаты курса возврату не подлежат и
являются штрафом за действия Пользователя. Аналогичные меры могут быть применены
Администратором в отношении программы обучения.</p>
        <p>16.5. В случае нарушения Пользователем интеллектуальных прав Администратора, а
также ограничений по использованию Сайта, указанных в разделе 14 Соглашения,
Администратор вправе в любой момент без предупреждения заблокировать такому
Пользователю полность или частично доступ на Сайт и в Личный кабинет, а также, по
своему усмотрению, обратиться к соответствующему Пользователю с требованием об
устранении нарушений и/или потребовать устранить нарушения в судебном порядке.</p>
        <p>16.6. Администратор не отвечает за работоспособность Сайта и не гарантирует его
бесперебойной работы. Администратор также не гарантирует сохранности информации,
размещенной на Сайте и возможности бесперебойного доступа к программам
обучения, курсам и иным материалам.</p>
        <p>16.7. Пользователь использует Сайт в том виде, в каком он представлен, на собственный
риск. Администратор не гарантирует Пользователю достижения каких-либо результатов
вследствие использования Сайта.</p>
        <h2>17. ОСОБЫЕ УСЛОВИЯ</h2>
        <p>17.1. Сайт может содержать ссылки на другие сайты в сети Интернет (сайты третьих
лиц). Указанные третьи лица и содержание их сайтов/контента не проверяются 
Администратором на соответствие тем или иным требованиям (достоверности, полноты,
законности и т.п.). Администратор не несет ответственности за любую информацию,
материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ
с использованием Сайта, в том числе, за любые мнения или утверждения, выраженные на
сайтах третьих лиц, рекламу и т.п., а также за доступность таких сайтов или контента
и последствия их использования Пользователем.</p>
        <p>17.2. Администратор не гарантирует, что Сайт соответствует требованиям Пользователя,
что доступ к Сайту будет предоставляться непрерывно, быстро, надежно и без
ошибок.</p>
        <p>17.3. Программно-аппаратные ошибки как на стороне Администратора, так и на
стороне Пользователя, приведшие к невозможности получения Пользователем доступа к
Сайту и/или видеокурсам и программам обучения, являются обстоятельствами
непреодолимой силы и основанием освобождения от ответственности за неисполнение
обязательств Администратором по Соглашению.</p>
        <h2>18. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h2>
        <p>18.1. Все споры, разногласия и претензии, которые могут возникнуть в связи с
заключением, исполнением, изменением, расторжением или признанием
недействительным Соглашения, Стороны будут стремиться решить путем переговоров.
Сторона, у которой возникли претензии и/или разногласия, направляет другой Стороне
сообщение с указанием возникших претензий и/или разногласий.
Сообщение Пользователя о возникших претензиях и/или разногласиях должно быть
направлено Пользователем Администратору по электронному адресу
lifta.space@bg.solutions , а также продублировано в письменном виде по адресу
Администратора, указанному в настоящем Соглашении.
Сообщение Администратора о возникших претензиях и/или разногласиях
направляется Администратором Пользователю по электронному адресу Пользователя,
указанному при регистрации на Сайте, либо иным образом, позволяющим
зафиксировать факт отправки.</p>
        <p>18.2. В случае, если ответ на сообщение не будет получен направившей сообщение
Стороной в течение 30 (тридцати) рабочих дней с даты направления соответствующего
сообщения, либо если Стороны не придут к соглашению по возникшим претензиям и/или
разногласиям, спор подлежит разрешению в судебном порядке по месту нахождения
Администратора.</p>
        <h2>19. ИЗМЕНЕНИЕ УСЛОВИЙ СОГЛАШЕНИЯ</h2>
        <p>19.1. Администратор вправе в любой момент по своему усмотрению в одностороннем
порядке изменять условия Соглашения, при этом такие изменения вступают в силу в момент
опубликования новой версии Соглашения на Сайте.</p>
        <p>19.2. При каждом последующем посещении Сайта до начала пользования Личным
кабинетом и/или иными функциональными возможностями Сайта Пользователь обязуется
знакомиться с новой версией Соглашения. Продолжение использования Сайта и Личного
кабинета будет означать согласие Пользователя с условиями новой версии
Соглашения.</p>
        <p>19.3. Если Пользователь не согласен с условиями новой версии Соглашения, он обязан
прекратить пользоваться Сайтом.</p>
        <p>19.4. В том случае, если Пользователь оплатил программы обучения и видеокурс, но не
согласен с новой версией Соглашения, то такой Пользователь обязан уведомить об этом
Администратора, отправив сообщение на адрес электронной почты
lifta.space@bg.solutions. </p>
        <h2>20. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
        <p>20.1. Соглашение и все возникающие из него правоотношения регулируются применимым
законодательством. Все возникающие споры разрешаются в соответствии с применимым 
законодательством.</p>
        <p>20.2. Признание судом какого-либо положения Соглашения недействительным или не
подлежащим принудительному исполнению не влечет недействительности иных
положений Соглашения</p>
        <p>20.3. Бездействие со стороны Администратора в случае нарушения кем-либо из
Пользователей положений Соглашения не лишает Администратора права предпринять
позднее соответствующие действия в защиту своих интересов и защиту интеллектуальных
прав на охраняемые в соответствии с законодательством результаты интеллектуальной
деятельности, размещенные на Сайте.</p>
        <p>20.4. Пользователь подтверждает, что ознакомился со всеми положениями Соглашения,
понимает и принимает их.</p>`
  },
}

// {{$t('selectCountry')}}
