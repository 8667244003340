import VueRangeSlider from 'vue-range-component'
import 'vue-range-component/dist/vue-range-slider.css'

export default {
  name: 'input-range',
  components: {
    VueRangeSlider
  },
  props: {
    name: {
      type: String,
      default: 'input-range',
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 1,
    },
    value: {
      type: Array,
      default: [0, 1],
    },
  },
  data() {
    return {
      bgStyle: {
        backgroundColor: '#343434',
      },
      sliderStyle: {
        backgroundColor: '#ffffff',
      },
      labelStyle: {
        backgroundColor: '#ffffff',
      },
      tooltipStyle: {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
      },
      processStyle: {
        backgroundColor: '#B1FA00',
      }
    }
  },
}
