import {mapGetters, mapActions, mapMutations} from 'vuex';
import SignupForm from '../../components/signup-form/index.vue';

export default {
  name: 'signup',
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
    }),
  },
  components: {
    SignupForm
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),
  },
  beforeDestroy() {
  },
}
