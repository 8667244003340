import StarRating from 'vue-star-rating'

export default {
  name: 'speakers-sample-full',
  components: {
    StarRating,
  },
  props: {
    data: {
      type: Object,
      default: null,
    }
  },
}
