<template>
  <div class="loader">
    <div class="loader__content"
         :data-size="size"
         :data-theme="theme">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'loader',
  props: {
    size: {
      type: String,
      default: 'middle', // small || middle || large
    },
    theme: {
      type: String,
      default: 'dark', // light || dark
    }
  }
}
</script>

<style lang="scss">
$count: 10;
$time: 2; //in seconds
$size: 50;

.loader {
  display: block;

  &__content {
    /* size */
    width: $size + px;
    height: $size + px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: rotatePreloader $time + s infinite ease-in;

    &[data-size='small'] {
      width: 30px;
      height: 30px;
    }

    &[data-size='middle'] {
      width: 50px;
      height: 50px;
    }

    &[data-size='large'] {
      width: 70px;
      height: 70px;
    }

    &[data-theme="light"] > div::before {
      background-color: #FFFFFF;
    }

    &[data-theme="dark"] > div::before {
      background-color: #B1FA00;
    }

    & > div {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        width: 10%;
        height: 10%;
        background-color: $black;
        transform: translateX(-50%);
        border-radius: 50%;
      }
    }
  }
}

@keyframes rotatePreloader {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
  }
}

@for $i from 1 through $count {
  .loader__content div:nth-child(#{$i}) {
    transform: rotateZ(((360 / $count) * ($i - 1)) + deg);
    animation: rotateCircle + $i $time + s infinite linear;
    z-index: $count - $i;
  }
  @keyframes rotateCircle#{$i} {
    #{percentage(((50 / $count) * ($i - 1)) / 100)} {
      opacity: 0;
    }
    #{percentage((((50 / $count) + 0.0001) * ($i - 1)) / 100)} {
      opacity: 1;
      transform: rotateZ((0 - ((360 / $count) * ($i - 2))) + deg);
    }
    #{percentage((((50 / $count) * ($i - 0)) + 2) / 100)} {
      transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
    }
    #{percentage(((50 + ((50 / $count) * ($i - 0))) + 2) / 100)} {
      transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
    }
    100% {
      transform: rotateZ((0 - ((360 / $count) * ($count - 1))) + deg);
      opacity: 1;
    }
  }
}
</style>
