import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'server-not-found',
  data() {
    return {}
  },
  components: {},
  computed: {
    ...mapGetters({})
  },
  created() {
  },
  methods: {
    ...mapActions({}),
  }
}
