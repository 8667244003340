import {mapGetters, mapActions, mapMutations} from 'vuex';
import Moment from 'moment';
import Slider from '../../components/slider/index.vue';

export default {
  name: 'home-promo',
  props:['item'],
  data() {
    return {
      width: false,
      finalPrice: 0,
      priceItemCount: 0,
      utm_medium: '',
      utm_source: ''
    }
  },
  created() {
    this.setUtm();
  },
  watch: {
    'isAuthenticated'(){
      if( this.isAuthenticated && this.isPaymentPage){
        setTimeout(()=>{
          this.buyEvent();
        },100)
      }
    },
    'item':{
      immediate: true,
      handler(){
        this.priceItemCount = this.item.payment_variables.length;
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      buyEventLoading: 'event/buyEventLoading',
      isPaymentPage: 'payment/isPaymentPage',

      abCampaign: 'setting/abCampaign',
    }),
  },
  mounted() {
    const maxWidth = 500;
    if(window.innerWidth <= maxWidth){
      this.width = true
    }
  },
  components: {
    Slider
  },
  methods: {
    ...mapActions({
      fetchSliderList: `home/GET_MAIN_SLIDER`,
      buyProduct: `event/BUY_PRODUCT`,
    }),
    ...mapMutations({
      changeTransitionName: `system/CHANGE_TRANSITION_NAME`,
      changeIndex: `system/CHANGE_PAGE_INDEX`,
      changeShowSubscribePopup: `marathon/CHANGE_SHOW_SUBSCRIBE_POPUP`,
      changePopupSignIn: `auth/CHANGE_POPUP_SIGN_IN`,
      changeBuyPopupContent: `event/SHOW_POPUP_BUY_FORM`,
      changeShowRecordPopup: `event/CHANGE_SHOW_RECORD_POPUP`,
      changeRecordPopupContent: `event/CHANGE_RECORD_POPUP_INFO`,
      changeShowSubscribeEventPopup: `event/CHANGE_SHOW_SUBSCRIBE_EVENT_POPUP`,
      changeSubscribeEventPopupInfo: `event/CHANGE_RECORD_POPUP_INFO`,
      changeShowPopupBuyForm: `event/CHANGE_SHOW_POPUP_BUY_FORM`,
      changeShowBuyPopup: `event/SHOW_POPUP_BUY_FORM`,
      changeDownload: `event/CHANGE_DOWNLOAD`,
      changeDiscount: `event/CHANGE_DISCOUNT`,
      changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
      changePopupRegisterInfo: `auth/CHANGE_POPUP_REGISTER_INFO`,

      changePaymentPage: `payment/CHANGE_PAYMENT_PAGE`,
    }),
    goToBuySection(item){
      this.changeIndex(7);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false,
      });
    },
    goBack(){
      history.back()
    },
    scrollDown(item){
      this.changeIndex(2);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false,
        offset: -90,
      });
    },
    buyEvent(price){
      this.changePaymentPage(true);
      let newPrice = [];
      for (let i = 0; i < this.item.payment_variables.length; i++) {
        let dif =  Moment(Moment()).isBefore(Moment(this.item.payment_variables[i].date, 'DD.MM'));
        console.log('dif', dif);
        if(dif){
          newPrice.push(this.item.payment_variables[i]);
        }
      }
      console.log('newPrice.length', newPrice.length);
      if( newPrice.length ){
        this.finalPrice = newPrice[0].price_uah;
        console.log('newPrice',this.finalPrice);
      }else{
        this.finalPrice = this.item.payment_variables[this.item.payment_variables.length - 1].price_uah;
      }
      console.log('finalPrice------',this.finalPrice)
      if( this.isAuthenticated && this.finalPrice ){
        let obj = {};
        obj.slug = this.item.slug;
        obj.price = this.finalPrice;
        obj.currency = 'UAH';
        if( this.utm_medium ){
          obj.utm_medium = this.utm_medium;
          obj.utm_source = this.utm_source;
          obj.site_url = window.location.origin+window.location.pathname;
        }
        this.buyProduct(obj)
          .then(() => {

            this.changeShowBuyPopup({price: this.finalPrice, title: this.item.title});
            setTimeout(()=> {
              this.changeShowPopupBuyForm(true);
            })
          }).catch(error => {
          if (error.response.status === 418) {
            this.$toasted.error(error.response.data.message);
          }
        });
      }else{
        if(this.finalPrice){
          this.changePopupRegister(true);
          this.changePopupRegisterInfo({course: this.item.title});
        }
      }

      this.gtm(this.item);
    },
    getRecord(status, discount){
      if(status === true){
        this.changeDownload(true)
      }
      if(discount === true){
        this.changeDiscount(true)
      }
      this.changeRecordPopupContent({title: this.item.title})
      setTimeout(()=> {
        this.changeShowRecordPopup(true);
      },200)
    },
    gtm(item) {
      this.$gtm.trackEvent({
        'event': 'addToCart',
        'ecommerce': {
          'currencyCode': 'EUR',
          'add': {
            'products': [{
              'name': item.title,
              'id': item.id,
              'price': item.final_price,
              'category': item.category,
              'autor': item.speakers.data[0].name,
              'type': item.status,
              'quantity': 1
            }],
          }
        }
      });
    },
    setUtm(){
      this.utm_medium = this.$route.query.utm_medium;
      this.utm_source = this.$route.query.utm_source;
    }
  },
  beforeDestroy() {
  },
}
