import {$http} from "@/utils/http";
import objectIsEmpty from "@/utils/object-is-empty";
import {objectToEncodedQueryString} from "@/utils/object-to-query-string";

import {
  COURSES_FETCH_CATEGORIES_LIST_DATA_TRIGGER, COURSES_FETCH_FAQ_LIST_DATA_TRIGGER,
  COURSES_FETCH_FILTERS_LIST_DATA_TRIGGER, COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_TRIGGER,
  COURSES_FETCH_LIST_DATA_TRIGGER,
  COURSES_FETCH_SAMPLE_DATA_TRIGGER,
  COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_TRIGGER,
  COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_TRIGGER,
  REDIRECT_TO_TEACHBASE
} from "@/store/action-types";

import {
  REDIRECT_TO_TEACHBASE_LOADING,
  COURSES_FETCH_CATEGORIES_LIST_DATA_FAILURE,
  COURSES_FETCH_CATEGORIES_LIST_DATA_FULFILLED,
  COURSES_FETCH_CATEGORIES_LIST_DATA_START,
  COURSES_FETCH_CATEGORIES_LIST_DATA_SUCCESS,
  COURSES_FETCH_FAQ_LIST_DATA_FAILURE,
  COURSES_FETCH_FAQ_LIST_DATA_FULFILLED,
  COURSES_FETCH_FAQ_LIST_DATA_START,
  COURSES_FETCH_FAQ_LIST_DATA_SUCCESS,
  COURSES_FETCH_FILTERS_LIST_DATA_FAILURE,
  COURSES_FETCH_FILTERS_LIST_DATA_FULFILLED,
  COURSES_FETCH_FILTERS_LIST_DATA_START,
  COURSES_FETCH_FILTERS_LIST_DATA_SUCCESS,
  COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_FAILURE,
  COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_FULFILLED,
  COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_START,
  COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_SUCCESS,
  COURSES_FETCH_LIST_DATA_FAILURE,
  COURSES_FETCH_LIST_DATA_FULFILLED,
  COURSES_FETCH_LIST_DATA_START,
  COURSES_FETCH_LIST_DATA_SUCCESS,
  COURSES_FETCH_SAMPLE_DATA_FAILURE,
  COURSES_FETCH_SAMPLE_DATA_FULFILLED,
  COURSES_FETCH_SAMPLE_DATA_START,
  COURSES_FETCH_SAMPLE_DATA_SUCCESS,
  COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_FAILURE,
  COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_FULFILLED,
  COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_START,
  COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_SUCCESS,
  COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_FAILURE,
  COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_FULFILLED,
  COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_START,
  COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_SUCCESS,
  CHANGE_COURSE_PAGE
} from "@/store/mutation-types";
import coursesCategories from "@/assets/static/courses/courses-categories";
import coursesFilters from "@/assets/static/courses/courses-filters";
import coursesList from "@/assets/static/courses/courses-list";
import coursesSample from "@/assets/static/courses/courses-sample";
import coursesFaq from "@/assets/static/courses/courses-faq";
import coursesWhatYouGet from "@/assets/static/courses/courses-what-you-get";
import coursesHowItWorks from "@/assets/static/courses/courses-how-it-works";
import coursesTechAndTools from "@/assets/static/courses/courses-tech-and-tools";
import {GET_EVENT_ITEM} from "../action-types";
import {SET_EVENT_ITEM, SET_EVENT_ITEM_LOADING} from "../mutation-types";

const state = {
  categories: {
    data: [],
    loading: false,
    done: false,
    error: null,
  },
  list: {
    data: [],
    loading: false,
    done: false,
    error: null,
    filters: {
      data: [],
      loading: false,
      done: false,
      error: null,
    },
    pagination: {
      offset: 0,
      limit: 10, // by default
      total: 1,
    }
  },
  sample: {
    data: null,
    loading: false,
    done: false,
    error: null,
  },
  whatYouGet: {
    data: [],
    loading: false,
    done: false,
    error: null,
  },
  howItWorks: {
    data: [],
    loading: false,
    done: false,
    error: null,
  },
  technologiesAndTools: {
    data: [],
    loading: false,
    done: false,
    error: null,
  },
  faq: {
    data: [],
    loading: false,
    done: false,
    error: null,
  },

  redirectLoading: false,
  isCourseProduct: false
};

const getters = {
  // categories
  categoriesListData: state => state.categories.data,
  categoriesListLoading: state => state.categories.loading,
  categoriesListFetched: state => state.categories.done,
  categoriesListError: state => state.categories.error,

  // list
  listData: state => state.list.data,
  listLoading: state => state.list.loading,
  listFetched: state => state.list.done,
  listError: state => state.list.error,

  // list pagination
  listPaginationOffset: state => state.list.pagination.offset,
  listPaginationLimit: state => state.list.pagination.limit,
  listPaginationTotal: state => state.list.pagination.total,

  // list filters
  filtersListData: state => state.list.filters.data,
  filtersListLoading: state => state.list.filters.loading,
  filtersListFetched: state => state.list.filters.done,
  filtersListError: state => state.list.filters.error,

  // sample
  sampleData: state => state.sample.data,
  sampleLoading: state => state.sample.loading,
  sampleFetched: state => state.sample.done,
  sampleError: state => state.sample.error,

  // what you get
  whatYouGetListData: state => state.whatYouGet.data,
  whatYouGetListLoading: state => state.whatYouGet.loading,
  whatYouGetListFetched: state => state.whatYouGet.done,
  whatYouGetListError: state => state.whatYouGet.error,

  // how it works
  howItWorksListData: state => state.howItWorks.data,
  howItWorksListLoading: state => state.howItWorks.loading,
  howItWorksListFetched: state => state.howItWorks.done,
  howItWorksListError: state => state.howItWorks.error,

  // technologies and tools
  techAndToolsListData: state => state.technologiesAndTools.data,
  techAndToolsListLoading: state => state.technologiesAndTools.loading,
  techAndToolsListFetched: state => state.technologiesAndTools.done,
  techAndToolsListError: state => state.technologiesAndTools.error,

  // faq
  faqListData: state => state.faq.data,
  faqListLoading: state => state.faq.loading,
  faqListFetched: state => state.faq.done,
  faqListError: state => state.faq.error,


  redirectLoading: state => state.redirectLoading,
  isCourseProduct: state => state.isCourseProduct,
}

const actions = {
  // fetch categories list
  [COURSES_FETCH_CATEGORIES_LIST_DATA_TRIGGER]: async ({ commit }) => {
    try {
      commit(COURSES_FETCH_CATEGORIES_LIST_DATA_START);

      const response = await $http.get('v1/catalog/categories');
      commit(COURSES_FETCH_CATEGORIES_LIST_DATA_SUCCESS, response.data.data);

      // mock data
      // commit(COURSES_FETCH_CATEGORIES_LIST_DATA_SUCCESS, coursesCategories);
    } catch (error) {
      commit(COURSES_FETCH_CATEGORIES_LIST_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(COURSES_FETCH_CATEGORIES_LIST_DATA_FULFILLED);
    }
  },

  // fetch filters list
  [COURSES_FETCH_FILTERS_LIST_DATA_TRIGGER]: async ({ commit }) => {
    try {
      commit(COURSES_FETCH_FILTERS_LIST_DATA_START);

      // const response = await $http.get('v1/courses/filters');
      // commit(COURSES_FETCH_FILTERS_LIST_DATA_SUCCESS, response.data.data);

      // mock data
      commit(COURSES_FETCH_FILTERS_LIST_DATA_SUCCESS, coursesFilters);
    } catch (error) {
      commit(COURSES_FETCH_FILTERS_LIST_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(COURSES_FETCH_FILTERS_LIST_DATA_FULFILLED);
    }
  },

  // fetch list
  [COURSES_FETCH_LIST_DATA_TRIGGER]: async ({ commit }, params) => {
    try {
      commit(COURSES_FETCH_LIST_DATA_START);

      // params query formatting
      const query = params && !objectIsEmpty(params) ? `?${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`v1/catalog/products${query}&include=speakers,page`);

      let filteredCoursesList = response.data.data;

      // if ('category' in params) {
      //   filteredCoursesList = filteredCoursesList
      //     .filter(coursesSample => {
      //       console.log('coursesSample',coursesSample);
      //       console.log('params',params);
      //
      //     });
      // }
      //
      // if ('search' in params) {
      //   filteredCoursesList = filteredCoursesList
      //     .filter(coursesSample => coursesSample.title.toLocaleLowerCase()
      //     .indexOf(params.search.toLowerCase()) !== -1)
      // }

      const offset = params.offset ? parseInt(params.offset) : 0;
      const limit = 10;

      // const slicedCoursesList = filteredCoursesList.slice(offset, offset + limit);
      const meta = {
        offset,
        limit,
        total: response.data.meta.total,
      };

      commit(COURSES_FETCH_LIST_DATA_SUCCESS, { data: filteredCoursesList, meta });
    } catch (error) {
      commit(COURSES_FETCH_LIST_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(COURSES_FETCH_LIST_DATA_FULFILLED);
    }
  },

  // fetch sample
  [COURSES_FETCH_SAMPLE_DATA_TRIGGER]: async ({ rootGetters, commit, dispatch }, { slug, params }) => {
    try {
      commit(COURSES_FETCH_SAMPLE_DATA_START);

      // set ab campaign hash (if doesn't exist)
      await dispatch('setting/SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_TRIGGER', null, { root: true });

      const query = params && !objectIsEmpty(params) ? `?${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`v1/catalog/products/${slug}${query}`);
      commit(COURSES_FETCH_SAMPLE_DATA_SUCCESS, response.data.data);

      // data mock
      // commit(COURSES_FETCH_SAMPLE_DATA_SUCCESS, coursesSample);
    } catch (error) {
      commit(COURSES_FETCH_SAMPLE_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(COURSES_FETCH_SAMPLE_DATA_FULFILLED);
    }
  },

  // fetch what you get
  [COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_TRIGGER]: async ({ commit }) => {
    try {
      commit(COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_START);

      // const response = await $http.get(`v1/courses/what-you-get`);
      // commit(COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_SUCCESS, response.data.data);

      // data mock
      commit(COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_SUCCESS, coursesWhatYouGet);
    } catch (error) {
      commit(COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_FULFILLED);
    }
  },

  // how it works
  [COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_TRIGGER]: async ({ commit }) => {
    try {
      commit(COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_START);

      // const response = await $http.get(`v1/courses/how-it-works`);
      // commit(COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_SUCCESS, response.data.data);

      // data mock
      commit(COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_SUCCESS, coursesHowItWorks);
    } catch (error) {
      commit(COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_FULFILLED);
    }
  },

  // fetch technologies and tools
  [COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_TRIGGER]: async ({ commit }) => {
    try {
      commit(COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_START);

      // const response = await $http.get(`v1/courses/technologies-and-tools`);
      // commit(COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_SUCCESS, response.data.data);

      // data mock
      commit(COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_SUCCESS, coursesTechAndTools);
    } catch (error) {
      commit(COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_FULFILLED);
    }
  },

  // fetch faq
  [COURSES_FETCH_FAQ_LIST_DATA_TRIGGER]: async ({ commit }) => {
    try {
      commit(COURSES_FETCH_FAQ_LIST_DATA_START);

      // const response = await $http.get(`v1/courses/faq`);
      // commit(COURSES_FETCH_FAQ_LIST_DATA_SUCCESS, response.data.data);

      // data mock
      commit(COURSES_FETCH_FAQ_LIST_DATA_SUCCESS, coursesFaq);
    } catch (error) {
      commit(COURSES_FETCH_FAQ_LIST_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(COURSES_FETCH_FAQ_LIST_DATA_FULFILLED);
    }
  },

  // how it works
  [REDIRECT_TO_TEACHBASE]: async ({commit}, payload) => {
    commit(REDIRECT_TO_TEACHBASE_LOADING, true);
    try {
      const response = await $http.get(`oauth/redirect?token=${payload.token}`);
      console.log(response)
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(REDIRECT_TO_TEACHBASE_LOADING, false);
    }
  },
};

const mutations = {
  // categories list
  [COURSES_FETCH_CATEGORIES_LIST_DATA_START] (state) {
    state.categories.loading = true;
    state.categories.done = false;
    state.categories.error = null;
  },
  [COURSES_FETCH_CATEGORIES_LIST_DATA_SUCCESS] (state, data) {
    state.categories.data = data;
    state.categories.done = true;
  },
  [COURSES_FETCH_CATEGORIES_LIST_DATA_FAILURE] (state, error) {
    state.categories.error = error;
  },
  [COURSES_FETCH_CATEGORIES_LIST_DATA_FULFILLED] (state) {
    state.categories.loading = false;
  },

  // filters list
  [COURSES_FETCH_FILTERS_LIST_DATA_START] (state) {
    state.list.filters.loading = true;
    state.list.filters.done = false;
    state.list.filters.error = null;
  },
  [COURSES_FETCH_FILTERS_LIST_DATA_SUCCESS] (state, data) {
    state.list.filters.data = data;
    state.list.filters.done = true;
  },
  [COURSES_FETCH_FILTERS_LIST_DATA_FAILURE] (state, error) {
    state.list.filters.error = error;
  },
  [COURSES_FETCH_FILTERS_LIST_DATA_FULFILLED] (state) {
    state.list.filters.loading = false;
  },

  // list
  [COURSES_FETCH_LIST_DATA_START] (state) {
    state.list.loading = true;
    state.list.done = false;
    state.list.error = null;
  },
  [COURSES_FETCH_LIST_DATA_SUCCESS] (state, { data, meta }) {
    state.list.data = data;
    state.list.pagination.offset = meta.offset;
    state.list.pagination.limit = meta.limit;
    state.list.pagination.total = meta.total;

    state.list.done = true;
  },
  [COURSES_FETCH_LIST_DATA_FAILURE] (state, error) {
    state.list.error = error;
  },
  [COURSES_FETCH_LIST_DATA_FULFILLED] (state) {
    state.list.loading = false;
  },

  // sample
  [COURSES_FETCH_SAMPLE_DATA_START] (state) {
    state.sample.loading = true;
    state.sample.done = false;
    state.sample.error = null;
  },
  [COURSES_FETCH_SAMPLE_DATA_SUCCESS] (state, data) {
    state.sample.data = data;
    state.sample.done = true;
  },
  [COURSES_FETCH_SAMPLE_DATA_FAILURE] (state, error) {
    state.sample.error = error;
  },
  [COURSES_FETCH_SAMPLE_DATA_FULFILLED] (state) {
    state.sample.loading = false;
  },

  // what you get
  [COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_START] (state) {
    state.whatYouGet.loading = true;
    state.whatYouGet.done = false;
    state.whatYouGet.error = null;
  },
  [COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_SUCCESS] (state, data) {
    state.whatYouGet.data = data;
    state.whatYouGet.done = true;
  },
  [COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_FAILURE] (state, error) {
    state.whatYouGet.error = error;
  },
  [COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_FULFILLED] (state) {
    state.whatYouGet.loading = false;
  },

  // how it works
  [COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_START] (state) {
    state.howItWorks.loading = true;
    state.howItWorks.done = false;
    state.howItWorks.error = null;
  },
  [COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_SUCCESS] (state, data) {
    state.howItWorks.data = data;
    state.howItWorks.done = true;
  },
  [COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_FAILURE] (state, error) {
    state.howItWorks.error = error;
  },
  [COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_FULFILLED] (state) {
    state.howItWorks.loading = false;
  },

  // technologies and tools
  [COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_START] (state) {
    state.technologiesAndTools.loading = true;
    state.technologiesAndTools.done = false;
    state.technologiesAndTools.error = null;
  },
  [COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_SUCCESS] (state, data) {
    state.technologiesAndTools.data = data;
    state.technologiesAndTools.done = true;
  },
  [COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_FAILURE] (state, error) {
    state.technologiesAndTools.error = error;
  },
  [COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_FULFILLED] (state) {
    state.technologiesAndTools.loading = false;
  },

  // faq
  [COURSES_FETCH_FAQ_LIST_DATA_START] (state) {
    state.faq.loading = true;
    state.faq.done = false;
    state.faq.error = null;
  },
  [COURSES_FETCH_FAQ_LIST_DATA_SUCCESS] (state, data) {
    state.faq.data = data;
    state.faq.done = true;
  },
  [COURSES_FETCH_FAQ_LIST_DATA_FAILURE] (state, error) {
    state.faq.error = error;
  },
  [COURSES_FETCH_FAQ_LIST_DATA_FULFILLED] (state) {
    state.faq.loading = false;
  },

  [REDIRECT_TO_TEACHBASE_LOADING](state, status) {
    state.redirectLoading = status;
  },

  [CHANGE_COURSE_PAGE](state, status) {
    state.isCourseProduct = status
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
