import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'home-promo',
  props:['newsInfo'],
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      index: 'news/listPageIndex',
    }),
  },
  components: {},
  methods: {
    ...mapActions({}),
    ...mapMutations({
      changeTransitionName: `system/CHANGE_TRANSITION_NAME`,
      changeIndex: `news/CHANGE_LIST_PAGE_INDEX`,
      changeShowSubscribePopup: `marathon/CHANGE_SHOW_SUBSCRIBE_POPUP`,
    }),
    scrollDown(item) {
      this.changeIndex(2);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false,
        offset: -90,
      });
    }
  },
  beforeDestroy() {
  },
}
