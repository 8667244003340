import Vue from 'vue'
import {$http} from '@/utils/http'
import {
  CHANGE_USER_INFO,
  CHANGE_SHOW_SUBSCRIBE_POPUP,
  CHANGE_POPUP_INFO,
  SEND_SUBSCRIBE_DATA_LOADING,
  CLEAR_USER_INFO_DATA
} from '../mutation-types';

import {
  SEND_SUBSCRIBE_DATA
} from '../action-types';


const state = {
  userInfo: {},
  showSubscribePopup: {
    show: false,
    eventId: null
  },
  isChangePopupInfo: false,
  sendSubscribeLoading: false
};

const getters = {
  userInfo: state => state.userInfo,
  showSubscribePopup: state => state.showSubscribePopup,
  isChangePopupInfo: state => state.isChangePopupInfo,
  sendSubscribeLoading: state => state.sendSubscribeLoading,
};

const actions = {
  [SEND_SUBSCRIBE_DATA]: async ({commit} , payload) => {
    try {
      commit(SEND_SUBSCRIBE_DATA_LOADING, true);
      const response = await $http.post(`marathon/v1/subscribe`, payload);
      console.log(response)
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SEND_SUBSCRIBE_DATA_LOADING, false);
    }
  },
};

const mutations = {
  [CHANGE_USER_INFO](state, data) {
    Object.assign(state.userInfo, data)
  },
  [CHANGE_POPUP_INFO](state, status) {
    state.isChangePopupInfo = status;
  },
  [SEND_SUBSCRIBE_DATA_LOADING](state, status) {
    state.sendSubscribeLoading = status;
  },
  [CLEAR_USER_INFO_DATA](state) {
    for (let i in state.userInfo) {
      state.userInfo[i] = '';
    }
  },
  [CHANGE_SHOW_SUBSCRIBE_POPUP](state, params) {
    state.showSubscribePopup = params
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
