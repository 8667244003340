
const coursesFilters = [
  {
    id: 0,
    type: 'radio',
    name: 'ord',
    title: 'sort',
    options: {
      '': 'toDefault',
      'alphabet': 'sortByAlphabetical',
      'alphabet_reverse': 'sortByAlphabeticalReverse',
      'latest': 'sortByDate',
      'oldest': 'sortByDateReverse',
    }
  },
  // {
  //   id: 1,
  //   type: 'radio',
  //   name: 'complexity',
  //   title: 'Сложность',
  //   options: {
  //     'any': 'Любая',
  //     'low': 'Новичкам',
  //     'high': 'Профи',
  //   }
  // },
  // {
  //   id: 2,
  //   type: 'range',
  //   name: 'duration',
  //   title: 'Продолжительность',
  //   min: 1,
  //   max: 24,
  // }
];

export default coursesFilters;
