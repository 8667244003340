export default {
  filter: 'Filters',
  myCourses: 'My courses',
  cookies: 'Cookies',
  cookiesMessage: 'By using this website, you automatically accept that we use cookies.',
  cookiesButton: 'Understood',
  enterCorrectPhone: 'Enter correct phone',
  successCv: 'Your request was successfully accepted',
  vacancyWork: 'Career',
  record: 'Record',
  successUserRegister: 'Registration completed successfully!',
  successRecordToEvent: 'You have successfully signed up for a consultation. Our manager will contact you during the day.',
  successRecordToEventHint: 'Our manager will contact you shortly and tell you the details of participation in the course!',
  successSendTestResult: 'The test results successfully sent to you mail!',
  successAuth: 'Authorization was successful',
  share: 'Share',
  email: "Email",
  name: "Name",
  successSendContactForm: 'Thanks for contacting us. Message sent successfully!',
  letterSubject: 'Letter subject',
  letter: 'Letter',
  contactForm: 'Contact form',
  teamProject: 'Team project',
  yourEmail: "Your email",
  yourPhone: "Phone",
  yourLang: "Choose language",
  yourName: "Your name",
  yourPassword: "Password",
  ruLang: "Russian",
  enLang: "English",
  uaLang: "Ukrainian",
  pantoLang: "Sign language",
  promocode: "Promo Code",
  agreeDataProcess: "I consent to the storage and processing of my personal data",
  validateAcceptAgreement: "Check this box on the deal",
  successActivationEmail: 'Ваш емейл активирован',
  profile: 'Профиль',
  totalInformation: 'Общая информация',
  noDataNow: 'No data now',
  next: "Далее",
  noData: "No data now",
  all: 'All',
  or: "или",
  city: 'City',
  country: "Country",
  register: "Register",
  registerInfo: "Subscribe now and receive up-to-date information by mail",
  directions: "Directions",
  navigation: "Navigation",
  phone: "Phone",
  validationLengthPhone: "The number of characters in the telephone field must be 12",
  fieldRequired: "Field :name is required",
  maxLengthField: "Maximum field characters :field - :count",
  validationEmail: "Please enter a valid email",
  successRegisterToEvent: "You have successfully registered for the event",
  remindMe: "Remind me",
  remindinfo: "You will receive a notification by email",
  selectCountry: "Select country",
  selectCity: "Select city",
  from: "from",
  validationRequired: "This field is required",
  validationMax: "Maximum number of characters :count",
  validationMin: "Minimum number of characters :count",
  validationSameAs: "Passwords do not match!",
  toDefault: 'To default',
  sort: 'Sorting',
  sortByAlphabetical: 'Alphabetically (A-Z)',
  sortByAlphabeticalReverse: 'Alphabetically (Z-A)',
  sortByDate: 'From new to old',
  sortByDateReverse: 'From old to new',

  //header translations
  header: {
    courses: 'Courses',
    news: 'News',
    about: 'About us',
    speakers: 'Speakers',
    logIn: 'Log in',
    profile: 'Profile'
  },

  //footer translations
  footer: {
    title: 'Schedule your training right now, don`t miss the moment',
    directions: 'Directions',
    navigation: 'Navigation',
    main: 'Main',
    about: 'About us',
    news: 'News',
    courses: 'Courses',
    speakers: 'Speakers',
    contacts: 'Contacts',
    vacancy: 'Career',
    contactTitle: 'A yoga teacher or business consultant with phenomenal intuition. You can be an incredible designer or a cool programmer, manager or marketer.',
    contactNumber1: '+38 (044) 334 61 16',
    contactNumber2: '+7 (495) 266 67 03',
    contactAdress: '01024, Kyiv , Bolshaya Vasylkovskaya st. 13',
  },

  //home translations
  home: {
    //promo
    promoTitle1: 'LIFTA.SPACE',
    promoTitle2: 'NO LIMITS',
    promoButton: 'Watch Stream Now!',

    //development
    trendTitle: 'YOUR TRAJECTORY OF DEVELOPMENT',
    marketing: 'Marketing',
    marketingDesc: 'We don\'t limit marketing to simple advertising. Marketing is an elaborate philosophy, tactic and strategy of thinking with hundreds of nuances and approaches to the customer.\n' +
      'Marketing concepts allow you to perform yourself as a product creator, economist, analyst or sales expert.\n' +
      'Dozens of marketing specialities will provide a place in the industry for beginners and professionals alike.\n' +
      'Find your profession and grow in marketing with the experts at LIFTA.',
    TagMarketing1: '#Metrics',
    TagMarketing2: '#Leads',
    TagMarketing3: '#Strategy',
    design: 'Design',
    designDesc: 'We are surrounded by millions of objects. Every one of them--the desk, the computer, the smartphone, the shirt, the laptop, the jewelry, the air conditioner, the house across the street, and the window frame--is a product of design made by a specific person. \n' +
      'Design fills our lives and in many ways, defines them by setting functional and aesthetic trends. \n' +
      'Design professionals are in demand in both marketing and manufacturing, and therefore can easily find themselves in the offline and online industry.\n' +
      'Our guide-LIFTERs will help you design from scratch, develop your artistic skills, and take a fresh look at the profession.\n',
    TagDesign1: '#UI',
    TagDesign2: '#UX',
    TagDesign3: '#Prototype',
    management: 'Management',
    managementDesc: 'Everyone needs progressive and effective management: enterprises, state institutions, non-profit organisations.\n' +
      'Team management, making algorithms for optimal decisions, can predict their consequences - only a small set of manager\'s functions that are applicable not only in work but also in personal life.\n' +
      'The management mastered in LIFTA, a complex and developing system at the intersection of exact and social sciences will improve your market position regardless of your current status.',
    TagManagement1: '#People',
    TagManagement2: '#Management',
    TagManagement3: '#Time',
    TagManagement4: '#Agile',
    development: 'Development',
    developmentDesc: 'Today, we cannot imagine most technological processes without appropriate software: from the functioning of banking systems and states’ security to simple correspondence.\n' +
      'Programming is multidimensional and includes creating computer programs, their testing, optimisation and implementation, as well as management and analysis of these processes.\n' +
      'LIFTA experts will tell you how to become proficient in programming, expand your professional opportunities or implement a software product in your business.',
    TagDevelopment1: '#Front-end',
    TagDevelopment2: '#Backend',
    TagDevelopment3: '#Innovation',
    TagDevelopment4: '#Testing',
    self: 'Self-development',
    selfDesc: 'We all need personal growth to fulfil ourselves in our professions and in our relationships with ourselves and others.\n' +
      'This continuous process touches on different life areas - physiology, discipline, knowledge, hidden psychological conflicts and values, and relationships. It directly affects our quality of life and perception of the world and ourselves.\n' +
      'Personal growth will enhance our potentialities and correct deficiencies, making it easier to analyse and choose solutions in any given situation.\n' +
      'Self-development in the vector you need is the key concept of LIFTA.SPACE!',
    TagSelf1: '#Movement',
    TagSelf2: '#Learning',
    TagSelf3: '#Promotion',
    TagSelf4: '#Awareness',

    //start
    startTitle: 'DON\'T KNOW WHERE TO START?',
    startSubtitle: 'Find out your Self-LIFT vector.',
    startDescription: 'It can be challenging to answer the question: What do I really want? The variety of choices is often confusing. To find your starting point, take the LIFTA test. In just a few minutes, the results will tell you the best direction for your personal growth.',
    startButton: 'PASS A TEST',

    //speakers
    speakerTitle: 'OUR GUIDES',
    all: 'All',

    //calendar
    orderCourse: 'Sign up for a course',
    calendarTitle: 'CALENDAR',
    calendarSubtitle: 'Schedule your training right now, don\'t miss the moment',
    calendarAll: 'View all',
    calendarDesc: 'The transformACTION 2021 online marathon is where we infuse into the stream flow of modern reality',
    calendarBtn: 'Sign on marathon',

    //about
    aboutTitle: 'WHAT IS',
    aboutToxicTitle: ' LIFTA.SPACE?',
    aboutItem1Title: 'Limitless growth potential',
    aboutItem1Text: 'LIFTA.SPACE materials are versatile and suitable for everyone',
    aboutItem2Title: 'Simplicity and convenience',
    aboutItem2Text: 'LIFTA.SPACE is always at your fingertips: up-to-date courses and all your achievements are available via all devices',
    aboutItem3Title: 'Experienced Speakers',
    aboutItem3Text: 'LIFTA.SPACE experts is a source of up-to-date practical knowledge and experience',
    aboutItem4Title: 'Practical skills from the start of learning',
    aboutItem4Text: 'New information in LIFTA.SPACE is transformed into individual practice and real cases',
    aboutItem5Title: 'Multiple formats',
    aboutItem5Text: 'LIFTA.SPACE offers seminars and webinars, the information in text format, organises workshops and intensives',
    aboutItem6Title: 'Lifelike ecosystem',
    aboutItem6Text: 'Communicating in the LIFTA space will allow you to share ideas and build social contacts that will help you realise your new profession',

    //knowwhere
    knowTitle: 'WHENEVER & WHEREVER YOU ARE',
    knowDesc1: 'Grow, socialise and lift yourself with the LIFTA.SPACE mobile app. Install the LIFTA app and get a unique opportunity to grow anywhere in space without losing precious time.',
    knowDesc2: 'The LIFTA app for ',
    knowToxic: 'iOS and Android',
    knowDesc3: ' devices will open your development atlas to a page of new achievements, save your previous ones and inspire you with a detailed road map.',
    knowHint: 'The LIFTA app is a globe of exploration and growth on your smartphone.',
  },

  //about translations
  about: {
    //promo
    we: 'МЫ',
    promoTitle: 'Команда из более 1000 сотрудников',
    targetItem1Title: '350+',
    targetItem1Text: 'Спикеров',
    targetItem2Title: '1325',
    targetItem2Text: 'Курсов',
    targetItem3Title: '100 000',
    targetItem3Text: 'Lifters',
    targetItem4Title: '200+',
    targetItem4Text: 'Событий',
    //solution
    solutionTitle: 'Мы создаем быстрые и удобные решения для пользователей',
    solutionLeftBar: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана\n' +
      '          (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агентат) и Меровингиана (Ламбер Вильсон). Хьюго Уивинг\n' +
      '          не смог вернуться к роли агента',
    solutionRightBar: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана\n' +
      '          (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости, а Лоренса Фишберна никто\n' +
      '          пригласить и не подумал, хотя все еще можно рассчитывать на появление молодой версии Морфеуса.Зрители вновь\n' +
      '          увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон.',
    solutionItem1Title: 'Лучшее образование',
    solutionItem1Text: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и\n' +
      '              Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
    solutionItem2Title: 'Только практика',
    solutionItem2Text: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и\n' +
      '              Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
    solutionItem3Title: 'Генерируйте новые идеи',
    solutionItem3Text: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и\n' +
      '              Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
    solutionItem4Title: 'Новый опыт',
    solutionItem4Text: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и\n' +
      '              Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
    //how
    howTitle: 'Как мы это делаем?',
    howText1: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    howSubtitle1: 'Чат студента и преподователя',
    howText2: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    howSubtitle2: 'Готовые проекты в портфолио',
    howText3: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    howSubtitle3: 'Поддержку на любом этапе',
    howText4: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    howSubtitle4: 'Только практика',
    howText5: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    //team
    teamTitle: 'НАША КОМАНДА',

    title1: 'Let\'s start a dialogue now',
    title2: 'Don\'t put ideas and questions on the back burner. We are glad to meet you!\n',
    title3: 'We really want to know what you think!',
    mainDesc: 'LIFTA.SPACE - flexible space without borders. We are always open to new views, ideas and suggestions! Feedback is an invaluable source of insight and aids in identifying growth areas. Do you have any questions? Any suggestions? Want to share your idea? - we are glad to new dialogue and are always ready to answer, discuss and hear. Thank you for lifting us!',
  },

  //sign-up-form
  signUpForm: {
    toxicTitle: 'Make a subscription',
    title: ' and get a 5% discount',
    hint: 'Subscribe now and receive up-to-date information by mail *'
  },

  // translations
  translations: {
    locales: {
      en: 'Original',
      ru: 'Русский',
      ua: 'Українська',
    },
    banner: {
      image: '@/assets/img/translations/banners/translations_banner_en.png',
      external_link: 'https://lifta.space/en/courses',
    }
  },

  //courses
  courses: {
    staticTitle: 'Profession: IT Recruiter',
    staticTitle1: 'Agile project management',
    staticTitle2: 'Effective manager',
    staticDesc: 'Start a career in an ambitious industry that will remain relevant for decades to come. Become an in-demand IT professional.',
    staticDesc1: 'How can you reduce the number of documents and increase the productivity of teams? How to stop fantasizing about results and start achieving them systematically? Join the course, implement the acquired knowledge and you will see how a flexible approach brings the business closer to the set goals',
    staticDesc2: '9 weeks, 9 lessons, 9 practical skills that will increase your efficiency, and thanks to this - the productivity of the team',
    coursesListTitle: 'All courses',
    coursesListSubTitle: 'Get an unique opportunity',
    coursesListDesc: 'Develop, communicate and lift in the LIFTA.SPACE mobile application. Install the LIFTA app and get an unique opportunity to grow',
    searchPlaceholder: 'Search...',
    allDirections: 'All directions',
    meta: {
      name: 'All courses',
      title: 'All courses',
      description: 'Develop, communicate and lift in the LIFTA.SPACE mobile application. Install the LIFTA app and get an unique opportunity to grow',
      keywords: 'courses, list, all directions',
    }
  },

  //choose-assistance
  choose: {
    title1: 'DON\'T KNOW WHAT TO',
    title2: 'CHOOSE?',
    desc: 'Our managers will help you decide on a profession, leave your phone number, name and mail.',
    name: 'Name',
    phone: 'Phone',
    mail: 'Email',
    hint: 'Subscribe now and receive up-to-date information by mail *',
    button: 'Send',
  },

  //speakers-list
  speakers: {
    title: 'Speakers',
    subTitle: 'List of all available speakers on the service',
  },

  //speaker-sample
  speaker: {
    aboutTitle: 'About speaker',
    personalTitle: 'All courses',
    personalDesc: 'Schedule your training right now, don\'t miss the moment',
    personalLookAll: 'Look all',
    speakerListTitle: 'Choose your speaker',
  },

  //news-main
  mainNews: {
    //promo
    title: 'Blog',
    subTitle1: 'News,',
    subTitle2: 'free',
    subTitle3: 'courses, sales every day.',
    itemList: {
      item1: 'All',
      item2: 'Marketing',
      item3: 'Sales',
      item4: 'Design',
      item5: 'Education',
      item6: 'Design',
      item7: 'Sales',
      item8: 'Design',
      item9: 'Free course',
      item10: 'Education',
      item11: 'Free course',
      item12: 'Design',
      item13: 'Design',
      item14: 'Education',
      item15: 'Design',
      item16: 'Sales',
      item17: 'Design',
      item18: 'Free course',
      item19: 'Education',
      item20: 'Free course',
      item21: 'Design',
    },
    lookPost: 'See post',
    popularTitle: 'Popular news',
    seeAll: 'See all',
    topicTitle: 'Topic',
  },

  //newsList
  newsList: {
    title: 'ALL NEWS',
    subTitle: 'Discover a world of new professions',
    total: 'Total',
    news: 'news.',
  },

  //popups
  popup:{
    haveAccount: 'Already have an account?',
    haveNotAccount: "Don't have an account?",
    signIn: 'Sign In',
    register: 'Sign Up',
    password: 'Password',
    passwordConfirm: 'Confirm password',
    forgotPassword: 'Forgot password?',
    backToLogin: 'Back to Sign In',
    email: 'Email',
    phone: 'Phone',
    restorePassword: 'Password recovery',
    send: 'Send',
    logOut: 'Log out',
    managerInfo: 'After registration, our manager will contact you!',
    subscribeSuccessInfo: 'Thank you for registration. Our manager will contact you shortly!'
  },

  //event
  event:{
    registerCourse: 'Register',
    downloadPreview: 'Download free guide',
    registerAndPayment: 'Register and payment',
    priceCourseTo: 'courses price to',
    priceMessage: 'The price is for the entire course, no additional fees',
    aboutEvent: 'About event',
    howYouGet: 'What will you end up with?',
    eventWhy: 'why do you need it?',
    coursesProgram: 'Course Roadmap:',
    yourEventIf: 'It\'s for you, if...',
    level: 'Level',
    subscribeConcult: 'contact the manager',
    leaveRequest: 'leave a request',
    watchWebinar: 'watch webinar',
    speakers: 'Speakers',
    goBack: 'Go back',
    start: 'start',
    to: 'to',
    after: 'after',
    specialProposition: 'Special proposition',
    aboutCourse: 'About the course',
    when: 'When',
    program: 'The program',
    time: 'Time',
    price: 'Cost',
    youSpeaker: 'Your speaker',
    register: 'Register',
    coursePrice: 'Course price',
    withOutDiscount: 'without discount',
    discountEnd: 'discount end',
    promocodeMessage: 'I have a promo code',
    yourSpeaker: 'Who do you lift with?',
    watchTrailer: 'Watch course trailer',
    speakerWorld1: 'speakers',
    speakerWorld2: 'speakers',
    speakerWorld3: 'speakers',
    hintDesc1: 'A special condition is valid for webinar participants only today!',
    hintDesc2: 'With a discount -10%',
    hintDesc3: 'The special offer is valid only today!',
    hintDesc4: 'With a discount of -30%',
    hintTitle: 'Get a 30% discount on the IT profession - Recruiter',
  },

  //vacancy
  vacancy:{
    desc0: `LIFTA.SPACE is a new digital environment for growth, development, and improvement of professional and personal qualities. The project is more than a knowledge base. It is a real educational ecosystem, a revolutionary culture of sharing experiences, skills, and attitudes. A comprehensive approach to comprehensive human development.<br><br>` +
      `Our goal is not only to create a unique space for self-actualization and help everyone explore new growth opportunities but to generally change the approach and culture of an imperfect existing system of education and development. We are a new generation project that erases all unnecessary barriers. Because everything is possible.<br><br>` +
      'NO LIMITS is more than a concept or a motivating slogan of the platform. It\'s a reality that comes true with every new step of the LIFTA.SPACE educational environment.\n',
    title1: 'Be whoever you want, find your dream job!',
    desc1: `LIFTA.SPACE not only inspires but also offers endless opportunities.<br><br>` +
      'Let\'s lift together!',
    btn1: 'Become part of the team!',
    title2: 'The possibilities are endless!',
    desc2: 'If there is no offer above that would go straight to your heart, it doesn\'t matter! One click - and we will tell our partners, cool companies, talented HR-s about you, because you own the skills and ideas that are more valuable today than ever. The whole world should know about you!',
    btn2: 'All possibilities of the world',
    firstName:'First name',
    lastName: 'Last name',
    letter: 'Cover letter\n',
    link : 'Link to',
    cvMessage: 'Attach CV (file, .doc, .docx, .pdf, .txt, .pptx formats)',
    titleMessage1: 'Form for filling a vacancy',
    titleMessage2: 'Form to fill out without vacancy',
    noVacancyMessage: 'No vacancies available',
  },

  // payment
  payment:{
    coursePayment: 'Payment for the course',
    coursePrice: 'Course price',
    paymentSystem: 'Payment system: ',
    paymentSystemName: 'Fondy',
    successBuyProduct: 'You have purchase course ":title" successfully. Our manager will contact you during the working day. Thank you',
    errorBuyProduct: `You did not purchase ":title" course. Please try again or contact us:<br><br>` +
      `- in live chat on the site<br><br>` +
      `- by mail support@lifta.space<br><br>` +
      '- by phone +38 (044) 334 61 16 or +7 (495) 266 67 03\n',
    pendingBuyProduct: 'Your request is on standby.',
    buyCourse: 'Buy course',
    orderCourse: 'Book now',
    orderForCourse: 'Get a discount',
    buy: 'Buy',
  },

  // translationForm
  translation: {
    title: "Hey Lifter!  In seven days of the marathon, you've collected a 7-digit code to take part in the lucky draw of a MacBook Air 2020. Enter the code here and wait for the results on February 22. May fortune be with you!" ,
    firstName: 'Name',
    lastName: 'Second name',
    phone: 'Phone number',
    code: 'Marathon code',
    button: 'Get MacBook Air',
    successSendCode: 'Thank you, your code is registered. Expect the results of the draw.',
  },

  // test module
  test: {
    passTest: 'Pass a test',
    prevButton: 'Previous',
    nextButton: 'Next',
    send: 'Send',
    meResult: 'me result',
  },
  //privacy
  privacy: {
    content: `<h1>Privacy Policy</h1>
        <p>Unless defined in the applicable Privacy Policy, similar terms are used with the meaning specified in the Terms of Service ("Terms").</p>
        <h2>2. Data Operators</h2>
        <p>The terms "Lifta.Space", "we", or "us" mean BB Technology LLC or another agency that is responsible for your data following the applicable Privacy Policy ("Data Operator").</p>
        <p>This page informs you about our Policy regarding collecting, using and disclosing user data by our website or mobile application, and how users consent to using such data and which data is controlled by Lifta.Space.</p>
        <p>In addition, in the privacy policy, we inform you about the type, scope, and purpose of collecting and using data that may be collected automatically when you use the https://lifta.space website and/or mobile application. The current version of the privacy policy is posted on our website <a href="https://lifta.space/privacy_policy.pdf" target="_blank">https://lifta.space/privacy_policy.pdf</a></p>
        <p>Suppose you leave our website or application to link to another online resource or visit our site on a social networking platform. In that case, this privacy policy does not apply to collecting, using, and storing your data by controllers of other online resources.</p>
        <p>Please note that your mobile software device may access your data. Our products or services may link to websites and services of other companies with their privacy policies, which you may need to read when using such services.</p>
        <p>If our products or services are embedded in our partners' products and services, we require our partners to provide you with all the transparency needed to collect and use your information. That may include links to this Privacy Policy and related applications or the transparency of integrated and embedded communications that identify us as service providers or data controllers.</p>
        <p>If you do not agree with the terms of this Policy or do not understand the terms of this Policy, we advise you not to use our website or mobile application. By accessing or using this website and/or mobile application, you agree to the terms of this Privacy Policy.</p>
        <p>2.2. The Operator processes the User's personal data solely for the following purposes:</p>
        <p>2.2.1. Registration of the User on the website, providing the User with the opportunity to fully use the site's services.</p>
        <p>2.2.2. User identification on the site.</p>
        <p>2.2.3. Displaying the User profile for other Site Users to maintain communication, including when providing services remotely.</p>
        <p>2.2.4. Establishing and maintaining communication between the User and the Operator, advising on the provision of services.</p>
        <p>2.2.5. The Operator's fulfilment of obligations to the User according to agreements entered with the Operator (in particular, the User Agreement, other agreements executed on the Operator's Website, posted and available at https://lifta.space). Fulfilment of obligations, in particular, includes informing about the date and time of classes (including by calling and sending SMS messages), sending of documents on the development of the educational program, etc.</p>
        <p>2.2.6. Sending advertising messages by the Operator to the User's e-mail address; targeting advertising materials.</p>
        <p>2.2.7. Posting on the Operator's Online Platform, in the official groups of social networks and other communities of the Operator on the Internet, other advertising and information sources, for purposes not related to the User's identification: 
- video materials recorded in the process of providing services; 
- feedback left by the User about the services offered by the Operator.</p>
        <p>2.2.8. Improving the quality of service for Users and modernizing the Operator's Site by processing User's requests and applications.</p>
        <p>2.2.9. Statistical and other studies based on anonymized information provided by the User.</p>
        <h2>3. Conditions and procedure for providing consent to the processing of personal data by the Operator</h2>
        <p>3.1. The Operator does not verify the personal data provided by the User. In this regard, the Operator proceeds from the fact that when providing personal data on the site, the User:</p>
        <p>3.1.1. Is a competent person. In the event of the person's incapacity using the site, consent to the processing of personal data is provided by the User's legal representative who has read and accepted the conditions for processing personal data specified in this Policy.</p>
        <p>3.1.2. Provides us with reliable information about himself or about the represented incapacitated person (clause 3.1.1) in the amount necessary to use the site. The User independently maintains the provided personal data up to date. The consequences of the provision by the User of inaccurate or insufficient information are defined in the User Agreement.</p>
        <p>3.1.3. Realizes that the information on the site posted by the User about himself may become available to other Site Users, may be copied and distributed by such Users in the cases and under the conditions stipulated by the Policy.</p>
        <p>3.2. The User accepts the terms of the Policy and gives the Operator informed consent to the processing of personal data on the conditions provided for by the Policy and applicable law.</p>
        <p>3.3. The Operator collects, stores and processes the following information about the User:</p>
        <ul>
          <li>Last name and first name;</li>
          <li>E-mail address;</li>
          <li>Phone number;</li>
          <li>Data about the device used by User to access Operator's services;</li>
          <li>Cookies;</li>
          <li>Other statistical non-personalized information about the User;</li>
          <li>aid courses/classes and watched free lessons.</li>
        </ul>
        <h2>4. Processing of personal data</h2>
        <p>4.1. The processing of the User's data is carried out by the Operator using databases on the territory of Ukraine.</p>
        <p>4.2. Personal data is processed using automated systems, except for cases when manual processing of personal information is necessary to fulfil legal requirements.</p>
        <p>4.3. The processing of the User's data includes the following Operator's actions: collection, recording, systematization, accumulation, storage, clarification (update, change), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction.</p>
        <p>4.4. The collection of the User's data is carried out in the cases specified in clause 3.2 of the Policy.</p>
        <p>4.5. The storage of the User's personal data is carried out on electronic media. When processing personal data to fulfil obligations according to agreements with the User, the Operator can extract personal data and store them on tangible media. The storage of such personal data is carried out within the period established by the legislation of Ukraine. The storage of personal information is carried out (depending on which event occurs earlier):</p>
        <p>- until the User deletes them in the appropriate section;</p>
        <p>- until the Operator destroys them;</p>
        <p>- in case of User's request of a withdrawal of consent to the personal data processing or a request for the personal data destruction;</p>
        <p>- until the expiry date of the consent.</p>
        <p>4.6. Clarification of personal data can be carried out by the User independently in the relevant sections or by the Operator at the User's request.</p>
        <p>4.7. The Operator can carry out the dissemination of personal data only in the following cases:</p>
        <p>4.7.1. When personal data processing to display the User's profile for other Site Users to maintain communication, including when providing services remotely. In this case, the User's data may be available to users registered on the site.</p>
        <p>4.7.2. To post reviews about the services provided by the Operator, left by Users, in various sources of information.</p>
        <p>4.7.3. To post video materials obtained in the process of providing services in various sources of information.</p>
        <p>4.8. The Operator has the right to transfer personal data to third parties in compliance with the following conditions:</p>
        <p>- A third party processes personal data using databases on the territory of Ukraine.</p>
        <p>- The third party ensures the confidentiality of personal data during their processing and use; undertakes not to disclose to other persons and not to distribute users' personal data without their consent.</p>
        <p>- The third-party guarantees compliance with the following measures to ensure the security of personal data during their processing: use of information protection tools; detecting and recording the facts of unauthorized access to personal data and taking measures to restore personal data; restricting access to personal data; registration and accounting of actions with personal data; monitoring and evaluating the effectiveness of the measures applied to ensure the security of personal data.</p>
        <p>- To transmit statistical non-personalized data on which it is impossible to identify the User to improve the level of services provided, marketing campaigns for other advertising and other purposes.</p>
        <p>4.8.1. Purposes of transferring personal data:</p>
        <p>- Optimization by the Operator of sending messages of informational and advertising nature. In this case, the users' personal data is transferred to the third party: first and last name, phone number, E-mail address.</p>
        <p>- Sending information mailings to Users about new opportunities in the field of education and development. In this case, the following personal data of the Users is transferred to the third party: first and last name; E-mail address. Each newsletter provides the User with the opportunity to opt-out of receiving such mailings.</p>
        <p>- Fulfillment of the terms of the agreement with the involvement of third parties. The amount of data transferred is determined in terms of the contract.</p>
        <p>- For other advertising purposes or to improve the quality of service.</p>
        <p>4.8.2. The list of permitted methods of personal data processing: collection, recording, systematization, accumulation, storage, clarification (update, change), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction. The third-party is prohibited from transferring and distributing personal data.</p>
        <p>4.8.3 The Operator destroys personal data in the following cases: </p>
        <p>- deletion of personal data by the User in the relevant section; </p>
        <p>- Receipt of the User's withdrawal of consent to the processing of personal data;</p>
        <p>- Receipt of a request from the User for the destruction of personal data;</p>
        <p>- Expiration of the agreement.</p>
        <h2>5. Measures for the protection of personal data</h2>
        <p>The Operator shall take necessary and sufficient legal, organizational and technical measures to protect the information provided by Users from unauthorized or accidental access, destruction, modification, blocking, copying, distribution, and other unlawful actions with it by third parties. Such measures include, but are not limited to:</p>
        <p>5.1 Appointment of a person responsible for the processing of personal data;</p>
        <p>5.2 Application of organizational and technical measures to ensure the security of personal data during its processing in information systems;</p>
        <p>5.3 Control of the facts of unauthorized access to personal data and taking measures to prevent such incidents in the future;</p>
        <p>5.4 Control over measures taken to ensure the security of personal data and level of protection of personal data information systems.</p>
        <h2>6. Rights of Users</h2>
        <p>Users shall have the right:</p>
        <p>6.1 At its discretion, provide the Operator with personal data for its processing under the conditions specified in the Policy;</p>
        <p>6.2 Independently make changes and corrections to their personal data in the Personal Area, provided that such changes and modifications contain relevant and reliable information;</p>
        <p>6.3 Delete personal data in the Personal profile;</p>
        <p>6.4 To apply to the Operator with requests, including to clarify personal data; to block or destroy personal data, if such data is incomplete, outdated, unreliable, illegally obtained or not necessary for the stated purpose of processing.</p>
        <p>6.5 To receive information from the Operator based on a request concerning the processing of his personal data and provided by the relevant legislation.</p>
        <h2>7. Appeals of Users</h2>
        <p>7.1 The User shall be entitled to send to the Operator their requests and demands (hereinafter referred to as the application), including those regarding the use of their personal data, as well as withdrawal of consent to the processing of personal data. The Appel may be sent in the following ways:</p>
        <p>7.1.1. In writing to the Address of the Operator;</p>
        <p>7.1.2. In the form of an electronic document (scan, photocopy). The document shall be sent from the User's e-mail address, which the User specified when registering at the website or in the agreement as an authorized e-mail address, to the Operator's e-mail address: lifta.space@bg.solutions.</p>
        <p>7.2 Such request shall contain: 1) The name of the applicant. 2) Detailed description of the essence of the Address. 3) Legal or factual grounds for the implementation of actions specified in the Address. 7.3.</p>
        <p>7.3. The Operator considers the User's Application in the following order:</p>
        <p>7.3.1 The presence of all mandatory details of the Address is checked;</p>
        <p>7.3.2 The validity of the application is checked;</p>
        <p>7.3.3 The answer to the Address is provided. The answer may be sent in the same form as the Address or convenient for the Operator. 7.4. </p>
        <p>7.4. The Operator considers all Addresses and provides answers to them (performs the Address) within 30 days.</p>
        <h2>8. Change of the Policy</h2>
        <p>8.1 The Operator reserves the right to change the Policy. The User is obliged to get acquainted with the text of the Policy each time he/she uses the site.</p>
        <p>8.2 The new version of the Policy comes into force from the moment of its posting in the relevant section of the Operator's website. Continued use of the site or its services after the publication of the new version of the Policy means the User's acceptance of the Policy and its terms. In case of disagreement with the terms of the Policy, the User must immediately stop using the site and its services.</p>`
  },
  terms: {
    content: `<h1>Terms of use</h1>
        <p>Attention! Do not register on the Site and do not use its services.</p>
        <h2>1. TERMS AND DEFINITIONS</h2>
        <p>1.1. In this user agreement ("Agreement"), unless it directly follows from its text, the following terms will have the basis below the meaning:</p>
        <p>"BB Technologies" Ltd ("Lifta.Space", "we", "us", "our", "Administrator") provides you with its services (described below) through its Website located at https: // lifta .space and through its mobile applications and related services (the "Site"), which includes a collection of information, texts, graphic elements, designs, images, photos, audio and video materials, and protected intellectual property and individualization of the Administrator, following its User Agreement. PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY REGULATE YOUR USE OF THE SITE AND THE SERVICES.</p>
        <p>"User", "End consumer" - concluded with the help of this user agreement by accepting an offer located on the network at the network address https://lifta.space/terms_of_use.pdf. "Author" - a User whose articles are published on the Site in the Articles section. An author can only become a User who has accepted the terms of publication on the Site and the relevant requirements established by the Administrator.</p>
        <p>"Teacher" – is an individual acting as a speaker, lecturer, expert, trainer, conducting a lesson in the framework of training programs or courses, providing information on the topic of the class and, if applicable, commenting on the performance of the Tasks that are a practical part of the relevant lessons.</p>
        <p>"Partner" - an individual and/or legal entity that has placed its offer to provide services to Users. Each Partner determines the conditions for the provision of services independently.</p>
        <p>"Video courses" - A set of audiovisual (video lectures), text, graphic materials and computer programs (software tools for performing test tasks), the achievement of a general topic, intended for training relevant to the topic. The video course is not fully or in part a training program.</p>
        <p>"Training program" - A set of audiovisual (webinars), text, graphic materials and computer programs (software tools for performing test tasks), united by a common topic, intended for training, mastering the subject of the training program.</p>
        <p>"Free lesson" - Audiovisual material (webinar), access to which the Administrator provides free of charge for all Users registered to participate in such a webinar. The Administrator conducts free classes for all users. A free lesson is not fully or in part a training program.</p>
        <p>"Personal Account" - a set of protected pages of the Site, created as a result of the registration of the User using the option to pay for access to the Library of courses and training programs of the Administrator, the ability to access free classes, the ability to access the Library of courses and training programs in full after payment for access data, the ability to use information about them, the ability to change information about the User, as well as to perform actions included in the explicit functions of the Personal Account. Access to the Personal Account is carried out by entering credentials in the fields provided for this on the Site.</p>
        <p>"Career Development Center" - a section of the Site designed to help Users and other third parties in vocational guidance and employment after completing the Administrator's Training Programs. The Development Center is also designed to help employers find qualified employees among those who have successfully completed the User Training Programs.</p>
        <p>1.2. the Rules interpret all other terms and definitions found in the Agreement's text following the applicable rules and the usual rules for interpreting the corresponding terms prevailing in the network.</p>
        <p>1.3. The titles of the headings (articles) of the Agreement are intended solely for the convenience of using the text of the Agreement and have no literal legal meaning.</p>
        <h2>2. SUBJECT MATTER OF THE AGREEMENT </h2>
        <p>2.1. Administrator grants to the User the right to use the Site, personal account, Site services for their direct purpose, including by access to the Site, personal account using personal computers and mobile devices and use of explicit functions of the Site, personal account on terms of the royalty-free simple (non-exclusive) license for the territory of access to the Site, personal account and their functions, for the period, during which the Site, personal account and their functions remain available to the User.</p>
        <p>2.2 Administrator provides services to User access to the Site, video courses, training programs, free classes, articles about organizing self-training User skills on a particular topic or necessary for mastering</p>
        <h2>3. ORDER OF REGISTRATION ON THE SITE</h2>
        <p>3.1 Before his registration at the Site, the Administrator, gives the User access to information about the Site, courses, training programs, the Library of courses, free classes, and access to the reviews and articles posted on the Site in the "Useful Materials" section.</p>
        <p>3.2 After registering on the Site, in addition to the access specified in paragraph 3.1 of the Agreement, the User access free training sessions are provided, purchase access to training programs and courses, access and participation in the User Rating, periodic subscriptions to services, as well as access to other functions of the Site and myAlpari.</p>
        <p>3.3 Upon completion of registration on the Site, the User is given access to myAlpari using the credentials - e-mail address and password. The User can change the password at any time after registration. </p>
        <p>3.4 The User shall be obliged to ensure the security and safety of the password. In case of loss or compromise of the password and unauthorized access of third parties to the Personal Account, the User shall immediately notify the Administrator by phone or e-mail lifta.space@bg.solutions. Until receipt of such notification, all actions performed using the User's Personal Account will be considered as performed by the User himself/herself.</p>
        <h2>4. PROCEDURE FOR USING THE PERSONAL ACCOUNT AND THE SITE </h2>
        <p>4.1 After completing the registration, the User gets access to the Personal Account. In the Personal Account of the User, there are displayed: information about the courses and training programs that the User began and completed, the possibility of access to the Library of courses for a fee and free classes, incentives, the Administrator's partners providing free or discounted goods and/or services to Users specified on the Site, the proposed vacancies of partners of the Administrator, the payments made to pay for access to the library courses and training programs.</p>
        <p>4.2 In Personal Account, the User has the opportunity to pay for access to the Library of courses and training programs and pay for a monthly or annual subscription to our Services.</p>
        <p>4.3 The User has access to articles and other materials presented on the Site to leave comments on such articles and materials.</p>
        <p>4.4. For access to certain courses, training programs and free classes, the User may be required to comply with special technical requirements of the Administrator placed on the Site.</p>
        <p>4.5 The Administrator has the right at any time and at his discretion for courses and training programs, including free classes, to change the subjects of individual lessons, change the content of classes, the number, name and type of materials included in the courses, training programs, free classes, the date and time of the relevant classes and their duration, as well as, if necessary, to replace the teachers of individual classes. In this case, the Administrator guarantees that such changes will not lead to a deterioration in the quality of courses, training programs and free classes. Information about any changes is published on the Site immediately before starting the relevant class where changes have occurred.</p>
        <p>4.6. User has the opportunity to participate in an Administrator's affiliate program on the terms and the manner determined by the Administrator and specified on the relevant page of the Site.</p>
        <p>4.7. User shall use the Site and Personal Cabinet in good faith, without violating the applicable law, the rights and freedoms of third parties, standards of morality and ethics. If necessary, the Corporate User undertakes to ensure compliance with applicable laws, morality, and ethics standards by End Users.</p>
        <h2>5. CONDITIONS AND PROCEDURE OF ACCESS TO VIDEO COURSES</h2>
        <p>5.1 Users have access to information about each video course, its description, topics and duration of classes, course duration, Course Instructors, and course rating. User can access information about the cost of the course and is available to pay for each video course.</p>
        <p>5.2 Access to each video course is available to users who have paid, in the manner prescribed by the Agreement and indicated on the relevant page of the Site, the cost of the video course. Access is provided through a personal account.</p>
        <p>5.3 Courses provide video lectures and software (homework) in the form of a test to test the User assimilated information provided in the video lectures. Testing is carried out by the Teacher in manual mode.</p>
        <p>5.4 At the end of the course, the User has the right to evaluate the course and leave a comment about the course. The Administrator reserves the right to delete User comments found by the Administrator to be contrary to applicable law or at its sole discretion.</p>
        <h2>6. PROCEDURE FOR ACCESS TO TRAINING PROGRAMS</h2>
        <p>6.1 Users have access to information about the training programs, their brief description, a description in the form of frequently asked questions and answers, information about the Teachers of the training program, the composition of the training program containing the name, duration, the composition of each of the stages (topics) of the training program, including the number and name of classes, the date and time of their implementation, information about the Teachers of such classes. In addition, the User has access to detailed information about each lesson of the training program, a description of its theoretical and practical part, as well as the skills that are offered to the User in the framework of such a lesson. Access to training programs shall be provided to Users who have paid for such access in accordance with the procedure established by the Agreement and the relevant offer located at the web address https://lifta.space/terms_of_use.pdf.</p>
        <p>6.2 Training programs for which the User has registered, but has not paid for, are displayed in myAlpari as unpaid. Training programs that the User has registered for and paid for are available in myAlpari in their entirety with all the relevant materials, taking into account the upcoming and completed classes.</p>
        <p>6.3 Each session of the training program is a video lesson (or recording of a webinar), the date and time of which is displayed on the relevant page on the Site and in myAlpari, as well as other materials including means of testing the acquired skills. Past video lessons may be saved in myAlpari as video recordings, depending on the terms of the training program. User participation in webinars, viewing webinar videos, accessing materials and taking tests and homework assignments are done through myAlpari.</p>
        <p>6.4 For each lesson of the training program, methodological materials containing a list of literature recommended for assimilation of the information received by the User in such a lesson may be provided. At that, the Parties acknowledge and understand that a list of literature is not an advertisement. These materials are available to the User, who has paid for the corresponding training program during the training program and afterwards - for the period set by the Administrator.</p>
        <h2>7. ORDER OF ACCESS TO FREE CLASSES</h2>
        <p>7.1 Information about upcoming free classes, the topics of such classes, the date and time, their teachers, and the number of participants are posted on the Site's appropriate page.</p>
        <p>7.2 The User has the opportunity to register for the upcoming free lesson by clicking on the appropriate link and filling out the registration form. The User is informed of successful registration for the free class with a message on the Site page that opens after clicking the link and with a letter sent to the e-mail address indicated in myAlpari.</p>
        <p>7.3 Information about past free sessions, which contain a video recording and other materials of the free lesson, as well as the topics of such sessions, the date and time of their conduct, the Teachers and the number of participants are posted on the appropriate page of the Website.</p>
        <h2>9. ORDER OF ACCESS TO ARTICLES. PUBLISHING IN THE ARTICLE SECTION</h2>
        <p>9.1 Users and any other third parties can review informational, informational and analytical materials published in the section of Articles. Access to such materials is not limited.</p>
        <p>9.2 Users wishing to publish their materials in the section of Articles should read the terms of publication, apply for publication in the section, coordinate their materials with the section's editor.</p>
        <p>9.3 The User whose materials are published in the Articles section is the Author.</p>
        <p>9.4 Publication of materials approved by the section editor is made by the Administrator at its own discretion and on a free basis, i.e. without charging the Author. However, the Administrator reserves the right to refuse the User to publish the materials, both unsolicited and previously agreed with the section's editor, without explaining the reasons. </p>
        <p>9.5 The User understands and agrees that the Administrator does not pay and is not obliged to pay the Authors any remuneration for the publication of materials.</p>
        <h2>10. CAREER DEVELOPMENT CENTER</h2>
        <p>10.1 The Career Development Center is designed to assist Users who have completed training programs in career guidance and help employers (legal entities and individual entrepreneurs) find employees among Users who have successfully completed training programs.</p>
        <p>10.2 Users, employers, and any third parties shall have the right to ask the Administrator any question regarding career guidance and job search by pressing the "Write a Message" button and fill in all required fields of the form. </p>
        <p>10.3 The Administrator has the right not to answer the question without explaining the reasons.</p>
        <p>10.4. by clicking the button "Send" in the form "Message to the Career Development Center", the individual, who indicated in this form his personal data (name and e-mail), gives consent to the processing of such personal data by the Administrator for implementation of the functions of the Career Development Center section.</p>
        <h2>12. STIMULATING ACTIVITIES. PARTNERS</h2>
        <p>12.1. Administrator has the right to hold any promotional events (in any form and using any technologies), placing information about such events on any pages and sections of the Site at his discretion. </p>
        <p>12.2 Partners shall be entitled to place, inter alia, promotional offers intended only for Users. Administrator shall not be responsible for the content of offers of Partners to sell goods and/or render services to Users and shall not be liable for the quality of such goods/services.</p>
        <h2>13. FINANCIAL TERMS</h2>
        <p>13.1. The Administrator provides access without charging any fees: to free classes; to the materials in the Articles section; to the Partners' offers; to the functionality of the Career Development Center and Experts sections, and to all other functionalities of the Website, the description of which does not include or does not imply charging any fees for their use.</p>
        <p>13.2 The Administrator sets the cost of each training program by publishing such cost on the page with the description of this training program. The Administrator has the right to change the cost of the training program at any time at its discretion. At the same time, the cost of the training program already paid by the User does not change either upward or downward. The new price applies to the legal relations of the Administrator and the User that have arisen after its change.</p>
        <p>13.3. The Administrator establishes the cost of the video course by publishing such cost on the Site's relevant page. The Administrator has the right to change the price of the video course at any time at its sole discretion. In this case, the price of the video course already paid by the User does not change either upward or downward. The new price shall apply to the legal relations of the Administrator and the User that have arisen after its change.</p>
        <p>13.4 The Administrator has the right to provide discounts to individual Users to pay for training programs or video courses. The size of the discount, the procedure and the terms of its provision is set by the Administrator at its sole discretion.</p>
        <p>13.5. The Administrator has the right to provide any additional services to the User without charging a separate fee.</p>
        <h2>PAYMENT PROCEDURE FOR THE TRAINING PROGRAM</h2>
        <p>13.6. As a general rule, the acquisition by the User of access to the training program is carried out on a prepayment basis in the amount of the full cost of the training program.</p>
        <p>13.7. Depending on the date of payment for the training program, the cost of the training program may vary. Information about the current price is indicated on the page of the corresponding training program.</p>
        <p>13.8. On the page of the training program, the User has the opportunity to register for the training program by entering reliable data (name, surname, phone number, e-mail address), indicating who will pay (User or legal entity).</p>
        <p>13.9. If the User has indicated that the User will make payment, then in the window that opens, the User selects a payment method (payment system) and performs the actions provided for by the rules of the relevant electronic payment systems and processing companies. In this case, the moment of payment is considered a positive result of payment authorization in the selected payment system.</p>
        <p>13.11. Suppose the User has indicated that a legal entity will make payment. In that case, the User sends the Administrator the information that the Administrator requested from the User in a letter sent to the User after clicking on the "Sign up" link. The User sends the information specified in the request to the Administrator at the address and in the manner specified in the same letter. Payment is made in the agreed currency by transferring funds to the Administrator's current account specified in the invoice sent to the User within the time period specified in the same invoice. The moment of payment is the moment of receipt of funds to the account of the Administrator.</p>
        <p>13.12. The User has the right to purchase and pay for both one training program and several programs at once.</p>
        <p>13.13. The Parties have the right to agree on other unique conditions with the written consent of the Parties.</p>
        <h2>PAYMENT PROCEDURE FOR VIDEO COURSES</h2>
        <p>13.14. On the corresponding video course page, the User has the opportunity to pay for access to one video course. Access to each video course is paid separately.</p>
        <p>13.15. After the User sequentially clicks the buttons "Buy access to the course" on the page of the corresponding video course, the User is automatically redirected to the payment page and, after choosing a payment service, performs the actions provided for by the rules of the relevant electronic payment systems and processing companies. In this case, the moment of payment is considered a positive result of payment authorization in the selected payment system.</p>
        <h2>REFUND PROCEDURE</h2>
        <p>13.16. The User can request a refund in the event of an erroneous payment or at his unilateral request, but in any case, no later than 3 (three) days before the start of the video course and/or training program. The User accepts that banks and/or processing systems may charge additional commissions for processing transactions, which will be carried out at the client's expense. The final amount may differ from the one previously paid by the User.</p>
        <h2>SECURITY POLICY FOR TRANSFER OF PAYMENT CARD DETAILS</h2>
        <p>13.17. When paying with a bank card, payment processing (including entering the card number) occurs on a secure page of the processing system. It means that your confidential data (card details, registration data, etc.) do not come to us. Their processing is completely protected, and no one can receive the client's personal and banking data. When working with payment card data, the information security standard (Data Security Standard (PCI DSS), developed by the international payment systems Visa and MasterCard) is applied. Data Security Standard (PCI DSS) is designed to ensure the secure processing of payment card holders' details.</p>
        <h2>14. INTELLECTUAL PROPERTY AND RESTRICTIONS ON USE OF THE SITE</h2>
        <p>14.1. Using the Site, the User acknowledges and agrees that all, without exception, the content of the Site (including, but not limited to: audiovisual works, text and graphic materials, computer programs, trademarks/service marks, logos), the structure of the content of the Site, the program code of the Site and/or its parts are the results of intellectual activity, the exclusive right to which belongs in full to the Administrator. The exclusive right and other intellectual rights (if applicable) to the above results of intellectual activity do not pass to the User as a result of using the Site and concluding the Agreement.</p>
        <p>14.2. By registering on the Site, submitting an application to the Administrator to publish materials in the Articles section and sending any materials for verification and approval to the Administrator, the Author transfers a non-exclusive license, without payment, without restrictions on the territory, for any such materials to the Administrator in full. At the same time, the Author acknowledges and confirms that from the moment such a license for the material is transferred to the Administrator, the Administrator has the right to distribute, copy, process and otherwise use the material and/or its parts unrestrictedly at his own discretion. The Author and the Administrator also understand and acknowledge that the copyright and other non-property intellectual rights to the material are inalienable and belong to the Author.</p>
        <p>14.3. The User is prohibited from copying, modifying, changing, deleting, supplementing, publishing, transmitting the results of intellectual activity contained on the Site, creating derivative works, making or selling goods / providing services based on them, reproduce, display or in any other way exploit or use such intellectual results — activities without the direct permission of the Administrator. When citing materials from the Site, if this is directly provided for by the Site's functions, the User undertakes to provide a link to the Site.</p>
        <p>14.4. For the avoidance of doubt, the User is prohibited from:</p>
        <p>- copy and/or distribute any information (including parts and components of training program lessons, video courses, articles, logos and trademarks/service marks) received on the Site, unless such a function is directly provided on the Site (for example, "Share"/"Repost");</p>
        <p>- use the information obtained on the Site to carry out commercial activities, to make a profit, or to use in any way contrary to the law, except for skills acquired based on information received following the Agreement;</p>
        <p>- copy or otherwise use the software part (program code or part of the code) of the Site and its design;</p>
        <p>- post personal data of third parties on the Site without their consent, including, but not limited to: home addresses, phone numbers, passport data, e-mail addresses;</p>
        <p>- post on the Site commercial advertisements, commercial offers, campaign information and any other intrusive information, unless the placement of such information is expressly agreed with the Administrator;</p>
        <p>- change in any way the software part of the Site, take actions aimed at changing the functioning and performance of the Site;</p>
        <p>- insult and otherwise violate the rights and freedoms of other users of the Site, third parties, as well as groups of persons;</p>
        <p>- use obscene language, carry out or disseminate information containing calls for mass riots, the implementation of extremist activities, participation in mass (public) events held in violation of the established procedure, disseminate information necessary to obtain the results of intellectual activity.</p>
        <h2>15. PERSONAL DATA AND ITS USE</h2>
        <p>15.1. The purpose, procedure, terms, and other essential conditions under which the Administrator processes users' personal data are defined in the Privacy Policy located at the network address https://lifta.space/privacy_policy.pdf.</p>
        <h2>16. LIABILITY</h2>
        <p>16.1. In case of violation by the User of the terms of the Agreement, applicable law, morality and ethics, or technical requirements, the Administrator has the right to block or delete the personal account, prohibit or restrict access to certain or all of the Site's functions, training programs, courses, free classes using the User's credentials.</p>
        <p>16.2. In case of violation by the User of the payment procedure established by section 13 of the Agreement, the Administrator has the right, at his discretion, to completely block the User's access to training programs from the day following the day of delay in payment, to terminate the contract for the provision of paid educational services unilaterally and return the overpaid funds to the User. in proportion to the number of classes available to the User in the personal account.</p>
        <p>16.3. When identifying cases where the User provides access to the personal account to third parties, the Administrator, at his choice, applies the measures provided for in clause 16.1. Agreements. At the same time, the Administrator has the right to completely block the User's access to the personal account, training programs and courses, including those paid in advance by the User.</p>
        <p>16.4. Violation by the User of the terms of the Agreement, which entailed adverse consequences for the Administrator (damage, administrative and other liability, warnings of law enforcement and other executive authorities, claims of third parties), is the basis for the Administrator to terminate the User's access to the video course. In contrast, the funds in payment for the return course are not subject to and are a penalty for the User's actions. The Administrator can apply similar measures concerning the training program.</p>
        <p>16.5. In case of violation by the User of the intellectual rights of the Administrator, as well as restrictions on the use of the Site specified in section 14 of the Agreement, the Administrator has the right at any time without warning to block such User in whole or in part access to the Site and the Personal Account, as well as, at his discretion, apply to the relevant User with a request to eliminate violations and/or demand that violations be eliminated in court.</p>
        <p>16.6. The Administrator is not responsible for the Site's performance and does not guarantee its uninterrupted operation. The Administrator also does not ensure the safety of information posted on the Site and the possibility of continuous access to training programs, courses and other materials.</p>
        <p>16.7. The User uses the Site in the form in which it is presented at his own risk. The Administrator does not guarantee the User to achieve any results as a result of using the Site.</p>
        <h2>17. SPECIAL CONDITIONS</h2>
        <p>17.1. The Site may contain links to other sites on the Internet (third party sites). The Administrator does not check these third parties and the content of their sites/content for compliance with certain requirements (reliability, completeness, legality, etc.). The Administrator uses materials posted on third-party sites to which the User gains access from the Site, including for any opinions or statements expressed on third-party sites, advertising, etc., and the availability of such sites or content, and the consequences of their use by the User.</p>
        <p>17.2. The Administrator does not guarantee that the Site meets the User's requirements, that access to the Site will be provided continuously, quickly, reliably and without errors.</p>
        <p>17.3. Hardware and software errors both on the Administrator's side and on the User's side, which led to the inability of the User to use access to the Site and / video courses and training programs, are circumstances of force majeure and the basis for exemption from liability for failure to fulfil the Administrator's obligations under the Agreement.</p>
        <h2>18. DISPUTE RESOLUTION PROCEDURE.</h2>
        <p>18.1. All disputes, disagreements, and claims that arise in connection with the conclusion, execution, modification, termination, or invalidation of the Agreement will seek to resolve by Agreement. The Party that has claims and/or disagreements shall send a message to the other Party indicating the claims and/or disputes that have arisen. The User must send the User's message about allegations and/or disagreements that have occurred to the Administrator at lifta.space@bg.solutions and duplicated in writing at the Administrator's address specified in this Agreement. The Administrator sends the Administrator's message about claims and/or disagreements that have arisen to the User's e-mail address specified during registration on the Site or in any other way that allows the fact of sending to be recorded.</p>
        <p>18.2. If the reply to the message is not received by the Party that sent the message within 30 (thirty) business days from the onset of the corresponding message, or if the parties do not agree on the claims and/or disagreements that have arisen, the dispute shall be resolved in court at the Administrator's location.</p>
        <h2>19. CHANGE OF TERMS OF AGREEMENT</h2>
        <p>19.1. The Administrator has the right at any time, at his discretion, to unilaterally change the terms of the Agreement, while such changes come into force at the time the new version of the Agreement is published on the Site.</p>
        <p>19.2. At each subsequent visit to the Site, before using the Site's Personal Account and/or other functionality, the User undertakes to familiarize himself with the new version of the Agreement. Continued use of the Site and Personal Account will mean the User agrees with the new version of the Agreement.</p>
        <p>19.3. If the User disagrees with the terms of the new version of the Agreement, he must stop using the Site.</p>
        <p>19.4. If the User has paid for training programs and a video course but does not agree with the new version of the Agreement, the User is obliged to notify the Administrator by sending a message to the e-mail address lifta.space@bg.solutions.</p>
        <h2>20. FINAL PROVISIONS</h2>
        <p>20.1. The Agreement and all legal relationships arising from it are governed by applicable law. All disputes arising are resolved following applicable law.</p>
        <p>20.2. The recognition by the court of any provision of the Agreement as invalid or not subject to enforceability does not entail the invalidity of other provisions of the Agreement.</p>
        <p>20.3. Inaction on the part of the Administrator in case of violation by any of the Users of the provisions of the Agreement does not deprive the Administrator of the right to take later appropriate actions in defence of his interests and protection of intellectual rights to the results of intellectual activity protected following the law, posted on the Site.</p>
        <p>20.4. The User confirms that he has read all the Agreement's provisions, understands and accepts them.</p>`
  },

}




// {{$t('ruLang')}}
