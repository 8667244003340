import {$http} from '@/utils/http'

import {
  SET_TEST_LIST,
  SET_TEST_LIST_LOADING,
  INCREMENT_TEST_COUNTER,
  DECREMENT_TEST_COUNTER,
  CHANGE_TEST_COUNTER,
  SET_TEST_RESULT_LOADING,
  SET_TEST_RESULT,
  SET_SEND_RESULT_LOADING
} from '../mutation-types';

import {
  GET_TEST_LIST,
  GET_TEST_RESULT,
  SEND_RESULT_TO_MAIL
} from '../action-types';


const state = {
  testList: null,
  testListLoading: false,
  testResult: null,
  testResultLoading: false,
  sendResultToEmail: false,
  activeStep: 0
};

const getters = {
  testList: state => state.testList,
  testListLoading: state => state.testListLoading,
  testResult: state => state.testResult,
  testResultLoading: state => state.testResultLoading,
  activeStep: state => state.activeStep,
  sendResultToEmail: state => state.sendResultToEmail,
};

const actions = {
  [GET_TEST_LIST]: async ({commit}) => {
    commit(SET_TEST_LIST_LOADING, true);
    try {
      const response = await $http.get('/v1/quiz');
      console.log(response);
      commit(SET_TEST_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_TEST_LIST_LOADING, false);
    }
  },
  [GET_TEST_RESULT]: async ({commit}, payload) => {
    commit(SET_TEST_RESULT_LOADING, true);
    try {
      const response = await $http.post(`/v1/quiz/${payload.slug}`, payload.result);
      commit(SET_TEST_RESULT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_TEST_RESULT_LOADING, false);
    }
  },
  [SEND_RESULT_TO_MAIL]: async ({commit}, payload) => {
    commit(SET_SEND_RESULT_LOADING, true);
    try {
      const response = await $http.post(`/v1/quiz/send`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_SEND_RESULT_LOADING, false);
    }
  },
};

const mutations = {
  [SET_TEST_LIST](state, list) {
    state.testList = list;
  },
  [SET_TEST_LIST_LOADING](state, status) {
    state.testListLoading = status
  },
  [SET_SEND_RESULT_LOADING](state, status) {
    state.sendResultToEmail = status
  },
  [SET_TEST_RESULT](state, list) {
    state.testResult = list;
  },
  [SET_TEST_RESULT_LOADING](state, status) {
    state.testResultLoading = status
  },
  [SET_TEST_LIST_LOADING](state, status) {
    state.testListLoading = status
  },
  [CHANGE_TEST_COUNTER](state,index) {
    state.activeStep = index;
  },
  [INCREMENT_TEST_COUNTER](state) {
    state.activeStep = state.activeStep + 1;
  },
  [DECREMENT_TEST_COUNTER](state) {
    if( state.activeStep > 0 ){
      state.activeStep = state.activeStep - 1;
    }
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
