import {Carousel, Slide} from 'vue-carousel';
import StarRating from 'vue-star-rating'

export default {
  name: 'calendar-slider',
  props: ['sliders', 'items'],
  data() {
    return {
      currentSlide: 0,
    }
  },
  components: {
    Carousel,
    Slide,
    StarRating
  },
  methods: {
    changeArrow(value) {
      const carousel = this.$refs['slider-items'];
      const currentPage = carousel.currentPage;
      const pageCount = carousel.pageCount;
      if (value === 'prev') {
        currentPage !== 0 ? carousel.goToPage(currentPage - 1) : carousel.goToPage(pageCount - 1);
      } else {
        currentPage < pageCount - 1 ? carousel.goToPage(currentPage + 1) : carousel.goToPage(0);
      }
    },
    gotToSlide(index) {
      const carousel = this.$refs['slider-items'];
      carousel.goToPage(index)
    },
    pageChange(slide) {
      this.currentSlide = slide;
    },

  }
}
