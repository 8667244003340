import {mapGetters, mapActions, mapMutations} from 'vuex';
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {camelCase} from "lodash";
import Vue from "vue";

export default {
  name: 'sign-up-form',
  data() {
    return {
      payload: {
        email: '',
      },
      validationErrors: {}
    }
  },
  validations: {
    payload: {
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      sendSubscribeFormLoading: 'contactForm/sendSubscribeFormLoading',
    }),
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('email')))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('maxLengthField').replace(':field', this.$t('email')).replace(':count', 60));
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    }
  },
  components: {
  },
  methods: {
    ...mapActions({
      sendSubscribeData: `contactForm/SEND_SUBSCRIBE_FORM`,
    }),
    resetForm(){
      this.$v.$reset();
      this.payload.email = '';
    },
    submit(){
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let obj = {};
        obj.email = this.payload.email;
        this.sendSubscribeData(obj).then((response) => {
          this.$toasted.success(this.$t('Вы успешно подписались на рассылку.'));
          this.resetForm();
        }).catch(error => {
          console.log(error);
          if (error.response.status === 418) {
            this.$toasted.error(error.response.data.message);
            const errors = error.response.data.errors;
            for (const i in errors) {
              Vue.set(this.validationErrors, camelCase(i), errors[i]);
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            const errors = error.response.data.errors;
            for (const i in errors) {
              Vue.set(this.validationErrors, camelCase(i), errors[i]);
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        });
      }
    }
  },
  beforeDestroy() {
  },
}
