import {$http} from '@/utils/http';
import {translation} from '@/helpers/translation';
import {globalLoader} from '@/helpers/variables/index';

import {
  SET_TRANSLATIONS,
  SET_CONFIG,
  SET_CATALOG_MENU,
  SET_CATALOG_MENU_LOADING,
  SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_START,
  SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_SUCCESS,
  SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_FAILURE, SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_FULFILL
} from '../mutation-types';

import {
  GET_TRANSLATIONS,
  GET_CONFIG,
  GET_CATEGORIES_MENU, SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_TRIGGER,
} from '../action-types';

const state = {
  translations: null,
  config: null,
  menu: [],
  menuLoading: false,
  campaigns: {
    ab: {
      data: {
        hash: localStorage.getItem('campaign:ab-hash') !== null ? localStorage.getItem('campaign:ab-hash') : null,
        type: localStorage.getItem('campaign:ab-type') !== null ? localStorage.getItem('campaign:ab-type') : null,
      },
      loading: false,
      done: false,
      error: null,
    }
  }
};

const getters = {
  translations: state => state.translations,
  config: state => state.config,
  menu: state => state.menu,
  menuLoading: state => state.menuLoading,
  abCampaign: state => state.campaigns.ab,
};

const actions = {
  [GET_TRANSLATIONS]: async ({commit}) => {
    globalLoader(true);
    try {
      // const response = await $http.get('v1/faq');
      let response = { data: {'name': 'asd'} };
      let result = Object.assign(response.data, translation);
      commit(SET_TRANSLATIONS, result);
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [GET_CONFIG]: async ({commit}) => {
    try {
      const result = await $http.get('v1/configs');
      commit(SET_CONFIG, result.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_CATEGORIES_MENU]: async ({commit}) => {
    commit(SET_CATALOG_MENU_LOADING, true);
    try {
      const response = await $http.get('v1/catalog/categories');
      commit(SET_CATALOG_MENU, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_CATALOG_MENU_LOADING, false);
    }
  },
  // fetch sample campaign ab hash
  [SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_TRIGGER]: async ({ commit }) => {
    const campaignAbHash = localStorage.getItem('campaign:ab-hash');
    const campaignAbType = localStorage.getItem('campaign:ab-type');

    // if hash & type already exist - we don't need request new ones
    if (campaignAbHash !== null && campaignAbType !== null) return;

    try {
      commit(SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_START);

      const response = await $http.get(`v1/hash`);
      commit(SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_SUCCESS, response.data);
    } catch (error) {
      commit(SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_FAILURE, error);
      throw error;
    } finally {
      commit(SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_FULFILL);
    }
  },
};

const mutations = {
  [SET_TRANSLATIONS](state, status) {
    state.translations = status;
  },
  [SET_CONFIG](state, config) {
    state.config = config;
  },
  [SET_CATALOG_MENU](state, data) {
    state.menu = data;
  },
  [SET_CATALOG_MENU_LOADING](state, status) {
    state.menuLoading = status;
  },
  // sample campaign ab hash
  [SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_START] (state) {
    state.campaigns.ab.loading = true;
    state.campaigns.ab.done = false;
    state.campaigns.ab.error = null;
  },
  [SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_SUCCESS] (state, data) {
    state.campaigns.ab.data.hash = data.hash;
    state.campaigns.ab.data.type = data.type;

    // save to localstorage
    localStorage.setItem('campaign:ab-hash', data.hash);
    localStorage.setItem('campaign:ab-type', data.type);

    state.campaigns.ab.done = true;
  },
  [SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_FAILURE] (state, error) {
    state.campaigns.ab.error = error;
  },
  [SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_FULFILL] (state) {
    state.campaigns.ab.loading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
