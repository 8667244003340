import {mapGetters, mapActions, mapMutations} from 'vuex';

import Slider from '../../../../components/slider/index.vue';

export default {
  name: 'home-promo',
  props: [
    'item'
  ],
  data() {
    return {
    }
  },
  created() {
    if( !this.mainSlider ){
      this.fetchSliderList();
    }
  },
  mounted() {
    // lazy loading video
    // --------------------------------------------------------------------------
    // let lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'))
    // if ('IntersectionObserver' in window) {
    //   let lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
    //     entries.forEach(function (video) {
    //       if (video.isIntersecting) {
    //         for (let source in video.target.children) {
    //           let videoSource = video.target.children[source]
    //           if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
    //             videoSource.src = videoSource.dataset.src
    //           }
    //         }
    //
    //         video.target.load()
    //         video.target.classList.remove('lazy')
    //         lazyVideoObserver.unobserve(video.target)
    //       }
    //     })
    //   })
    //
    //   lazyVideos.forEach(function (lazyVideo) {
    //     lazyVideoObserver.observe(lazyVideo)
    //   })
    // }
  },
  computed: {
    ...mapGetters({
      mainSlider: 'home/mainSlider',
      mainSliderLoading: 'home/mainSliderLoading',
    }),
  },
  components: {
    Slider
  },
  methods: {
    ...mapActions({
      fetchSliderList: `home/GET_MAIN_SLIDER`,
    }),
    ...mapMutations({
      changeTransitionName: `system/CHANGE_TRANSITION_NAME`,
      changeIndex: `system/CHANGE_PAGE_INDEX`,
      changeShowSubscribePopup: `marathon/CHANGE_SHOW_SUBSCRIBE_POPUP`,
    }),
    scrollDown(item){
      this.changeIndex(2);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false,
        offset: -90,
      });
    }
  },
  beforeDestroy() {
  },
}
