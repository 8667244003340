import faqSample from './sample/index.vue';

export default {
  name: 'faq-section',
  components: {
    faqSample
  },
  props: {
    list: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
}
