// system
export const SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_TRIGGER = 'SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_TRIGGER';

// auth
export const GET_TOKEN = 'GET_TOKEN';
export const REGISTRATION = 'REGISTRATION';
export const LOGOUT = 'LOGOUT';
export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const ACTIVATION_EMAIL = 'ACTIVATION_EMAIL';
export const CHECK_EMAIL = 'CHECK_EMAIL';

// home
export const GET_MAIN_SLIDER = 'GET_MAIN_SLIDER';
export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_SPEAKERS_LIST = 'GET_SPEAKERS_LIST';
export const GET_SPEAKERS_CATEGORY_LIST = 'GET_SPEAKERS_CATEGORY_LIST';
export const GET_HOME_LIST = 'GET_HOME_LIST';
export const GET_CALENDAR_SLIDER = 'GET_CALENDAR_SLIDER';
export const GET_ACTIVE_STREAM_VIDEO = 'GET_ACTIVE_STREAM_VIDEO';


// settings
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_CONFIG = 'GET_CONFIG';
export const GET_CATEGORIES_MENU = 'GET_CATEGORIES_MENU';

// news
export const GET_NEWS_LIST = 'GET_NEWS_LIST';
export const GET_NEWS_ITEM = 'GET_NEWS_ITEM';
export const GET_RANDOM_NEWS_LIST = 'GET_RANDOM_NEWS_LIST';
export const GET_TOP_NEWS_LIST = 'GET_TOP_NEWS_LIST';
export const GET_POPULAR_NEWS_LIST = 'GET_POPULAR_NEWS_LIST';
export const GET_NEWS_INFO = 'GET_NEWS_INFO';

// blog
export const GET_BLOG_LIST = 'GET_BLOG_LIST';
export const GET_BLOG_ITEM = 'GET_BLOG_ITEM';
export const GET_RANDOM_BLOG_LIST = 'GET_RANDOM_BLOG_LIST';

//faq
export const GET_FAQ_LIST = 'GET_FAQ_LIST';
export const SEND_ASK = 'SEND_ASK';
//program
export const GET_PROGRAM_LIST = 'GET_PROGRAM_LIST';
// profile
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';

// catalog
export const GET_CATEGORY_PRODUCTS_LIST = 'GET_CATEGORY_PRODUCTS_LIST';
export const GET_CATALOG_ITEM = 'GET_CATALOG_ITEM';
export const GET_RANDOM_CATALOG_LIST = 'GET_RANDOM_CATALOG_LIST';
export const GET_RELATED_LIST = 'GET_RELATED_LIST';
export const GET_CATALOG_LIST = 'GET_CATALOG_LIST';
export const GET_COURSES_INFO = 'GET_COURSES_INFO';

//speakers
export const GET_SPEAKERS_ITEM = 'GET_SPEAKERS_ITEM';
export const GET_SPEAKERS_LIST_BY_CATEGORY = 'GET_SPEAKERS_LIST_BY_CATEGORY';
export const GET_SPEAKERS_INFO = 'GET_SPEAKERS_INFO';

// speakers new
export const SPEAKERS_FETCH_CATEGORIES_LIST_DATA_TRIGGER = 'SPEAKERS_FETCH_CATEGORIES_LIST_DATA_TRIGGER';
export const SPEAKERS_FETCH_FILTERS_LIST_DATA_TRIGGER = 'SPEAKERS_FETCH_FILTERS_LIST_DATA_TRIGGER';
export const SPEAKERS_FETCH_LIST_DATA_TRIGGER = 'SPEAKERS_FETCH_LIST_DATA_TRIGGER';
export const SPEAKERS_FETCH_SAMPLE_DATA_TRIGGER = 'SPEAKERS_FETCH_SAMPLE_DATA_TRIGGER';
export const SPEAKERS_FETCH_FAQ_LIST_DATA_TRIGGER = 'SPEAKERS_FETCH_FAQ_LIST_DATA_TRIGGER';

// marathon
export const SEND_SUBSCRIBE_DATA = 'SEND_SUBSCRIBE_DATA';
export const GET_CATEGORY_ITEM = 'GET_CATEGORY_ITEM';

// courses
export const COURSES_FETCH_CATEGORIES_LIST_DATA_TRIGGER = 'COURSES_FETCH_CATEGORIES_LIST_DATA_TRIGGER';
export const COURSES_FETCH_FILTERS_LIST_DATA_TRIGGER = 'COURSES_FETCH_FILTERS_LIST_DATA_TRIGGER';
export const COURSES_FETCH_LIST_DATA_TRIGGER = 'COURSES_FETCH_LIST_DATA_TRIGGER';
export const COURSES_FETCH_SAMPLE_DATA_TRIGGER = 'COURSES_FETCH_SAMPLE_DATA_TRIGGER';


export const BUY_PRODUCT = 'BUY_PRODUCT';

export const COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_TRIGGER = 'COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_TRIGGER';
export const COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_TRIGGER = 'COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_TRIGGER';
export const COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_TRIGGER = 'COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_TRIGGER';
export const COURSES_FETCH_FAQ_LIST_DATA_TRIGGER = 'COURSES_FETCH_FAQ_LIST_DATA_TRIGGER';

// translations
export const TRANSLATIONS_FETCH_SAMPLE_DATA_TRIGGER = 'TRANSLATIONS_FETCH_SAMPLE_DATA_TRIGGER';

export const TRANSLATIONS_USER_INIT_ENTRY_TRIGGER = 'TRANSLATIONS_USER_INIT_ENTRY_TRIGGER';
export const TRANSLATIONS_USER_REGISTRATION_TRIGGER = 'TRANSLATIONS_USER_REGISTRATION_TRIGGER';
export const TRANSLATIONS_USER_SIGN_IN_TRIGGER = 'TRANSLATIONS_USER_SIGN_IN_TRIGGER';

export const TRANSLATIONS_COMETCHAT_INIT_TRIGGER = 'TRANSLATIONS_COMETCHAT_INIT_TRIGGER';
export const TRANSLATIONS_COMETCHAT_USER_LOGIN_TRIGGER = 'TRANSLATIONS_COMETCHAT_USER_LOGIN_TRIGGER';

export const TRANSLATIONS_FETCH_BANNERS_LIST_TRIGGER = 'TRANSLATIONS_FETCH_BANNERS_LIST_TRIGGER';

export const SEND_CODE = 'SEND_CODE';

// upload
export const UPLOAD_IMAGES_SAMPLE_TRIGGER = 'UPLOAD_IMAGES_SAMPLE_TRIGGER';

//contact
export const SEND_SUBSCRIBE_FORM = 'SEND_SUBSCRIBE_FORM';
export const SEND_HELP_FORM = 'SEND_HELP_FORM';

//event
export const GET_EVENT_ITEM = 'GET_EVENT_ITEM';
export const RECORD_TO_CONSULTATION = 'RECORD_TO_CONSULTATION';
export const REDIRECT_TO_TEACHBASE = 'REDIRECT_TO_TEACHBASE';
export const SEND_CLICK_REQUEST = 'SEND_CLICK_REQUEST';

//test
export const GET_TEST_LIST = 'GET_TEST_LIST';
export const GET_TEST_RESULT = 'GET_TEST_RESULT';
export const SEND_RESULT_TO_MAIL = 'SEND_RESULT_TO_MAIL';

//resume - vacancy
export const SEND_RESUME = 'SEND_RESUME';
export const GET_VACANCY_LIST = 'GET_VACANCY_LIST';
export const GET_VACANCY_INFO = 'GET_VACANCY_INFO';

// about
export const GET_ABOUT_LIST = 'GET_ABOUT_LIST';


