<template>
  <div id="app" class="app">
    <div class="app-container">
      <div class="wrapper" :class="{ 'promo': $route.name === 'promo' }">
        <div class="wrapper-top">
          <main-header v-if="$route.name !== 'lift'"/>
          <div class="content-wrap">
            <transition name="component-fade" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
      </div>
    </div>
    <hidden/>
    <div class="page-loader">
      <div class="page-loader__wrap">
        <div class="page-loader__bg">
          <div id='stars4'></div>
          <div id='stars2'></div>
          <div id='stars'></div>
          <div id='stars3'></div>
        </div>
      </div>
    </div>
    <div class="global-loader" v-show="globalLoader">
      <loader/>
    </div>
  </div>
</template>

<!--// global styles-->
<style src="@/assets/scss/main.scss" lang="scss"></style>

<!--// scripts-->
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import mainHeader from '@/components/features/header/index.vue';
import hidden from '@/components/hidden/index.vue';
import coursesList from "@/assets/static/courses/courses-list";

// utils
import detectUserLanguage from "@/utils/detect-user-language";
import filterAvailableLanguage from "@/utils/filter-available-language";
import changeHtmlLang from "@/utils/change-html-lang";

import {isMobile, isIPad} from '@/helpers/variables/index'

export default {
  name: 'App',
  data() {
    return {}
  },
  components: {
    mainHeader,
    hidden
  },
  watch: {
    '$route.query'() {
      setTimeout(() => {
        if (this.$route.query.hasOwnProperty('restore')) {
          this.changePopupNewPassword(true);
        }


        if (this.$route.query.hasOwnProperty('activation')) {
          this.changePopupActivationEmail(true);
        }


        if (this.$route.query.hasOwnProperty('status')) {
          let paymentStatus = this.$route.query.status;
          let productSlug = this.$route.query.product;
          switch (paymentStatus) {
            case "succes":
              this.changeContentPaymentPopup({
                text: this.$t('payment.successBuyProduct').replace(':title', this.getProductName(productSlug)),
                id: 'pay_course_success'
              });
              this.gtm(productSlug);
              setTimeout(()=> {
                this.changeShowPaymentPopup(true);
              },200)
              break;
            case "error":
              this.getProductName(productSlug);
              this.changeContentPaymentPopup({
                text: this.$t('payment.errorBuyProduct'),
                id: 'pay_course_fail'
              });
              setTimeout(()=> {
                this.changeShowPaymentPopup(true);
              },200)
              break;
            case "pending":
              this.getProductName(productSlug);
              this.changeContentPaymentPopup({
                text: this.$t('payment.pendingBuyProduct'),
                // title: this.getProductName(productSlug)
              });
              setTimeout(()=> {
                this.changeShowPaymentPopup(true);
              },200)
              break;
          }
        }
      }, 300)
    }
  },
  created() {
    this.getLanguageFromLocalstorage();
    this.fetchConfig();
    this.fetchMenu();

    if(this.isAuthenticated){
      this.fetchUser();
    }

    if (this.$route.query.hasOwnProperty('restore')) {
      this.changePopupNewPassword(true);
    }
    if (this.$route.query.hasOwnProperty('activation')) {
      this.changePopupActivationEmail(true);
    }

    if( !localStorage.getItem('isAcceptCookies') ){
      this.changeShowCookiesPopup(true);
    }

    if (this.$route.query.hasOwnProperty('status')) {
      let paymentStatus = this.$route.query.status;
      let productSlug = this.$route.query.product;
      switch (paymentStatus) {
        case "succes":
          this.changeContentPaymentPopup({
            text: this.$t('payment.successBuyProduct').replace(':title', this.getProductName(productSlug)),
            id: 'pay_course_success'
          });
          this.gtm(productSlug);
          setTimeout(()=> {
            this.changeShowPaymentPopup(true);
          },200)
          break;
        case "error":
          this.getProductName(productSlug);
          this.changeContentPaymentPopup({
            text: this.$t('payment.errorBuyProduct'),
            id: 'pay_course_fail'
          });
          setTimeout(()=> {
            this.changeShowPaymentPopup(true);
          },200)
          break;
        case "pending":
          this.getProductName(productSlug);
          this.changeContentPaymentPopup({
            text: this.$t('payment.pendingBuyProduct'),
          });
          setTimeout(()=> {
            this.changeShowPaymentPopup(true);
          },200)
          break;
      }
    }
  },

  mounted() {
    this.setUtmSession()
    if (!isMobile.any()) {
      document.querySelector('body').classList.add('hover');
    }
    if (!isIPad.iOS()) {
      document.querySelector('body').classList.add('iPad');
    }

  },
  computed: {
    ...mapGetters({
      globalLoader: 'system/globalLoader',
      isAuthenticated: 'auth/isAuthenticated',
    })
  },
  methods: {
    ...mapActions({
      fetchConfig: `setting/GET_CONFIG`,
      fetchMenu: `setting/GET_CATEGORIES_MENU`,
      fetchUser: `profile/FETCH_USER_DATA`,
    }),
    ...mapMutations({
      changePopupNewPassword: `auth/CHANGE_POPUP_NEW_PASSWORD`,
      changePopupActivationEmail: `auth/CHANGE_POPUP_ACTIVATION_EMAIL`,
      changeContentPaymentPopup: `payment/PAYMENT_POPUP_TEXT_CONTENT`,
      changeShowPaymentPopup: `payment/CHANGE_SHOW_PAYMENT_POPUP`,
      changeShowCookiesPopup: `system/CHANGE_SHOW_COOKIES_POPUP`,
    }),
    setUtmSession(){
      sessionStorage.setItem('X-UTM-SOURCE', JSON.stringify(this.$route.query.utm_source) ? JSON.stringify(this.$route.query.utm_source) : '')
      sessionStorage.setItem('X-UTM-MEDIUM', JSON.stringify(this.$route.query.utm_medium) ? JSON.stringify(this.$route.query.utm_medium) : '')
      sessionStorage.setItem('X-UTM-CAMPAIGN', JSON.stringify(this.$route.query.utm_campaign) ? JSON.stringify(this.$route.query.utm_campaign) : '')
    },
    getLanguageFromLocalstorage () {
      const $routeParamsLang = this.$route.params.lang;
      const $localStorageLocalization = localStorage.getItem('localization');
      const $userBrowserLocalization = detectUserLanguage();

      let $resultLanguage = 'ru';

      if ($routeParamsLang) {
        $resultLanguage = $routeParamsLang;
      } else if ($localStorageLocalization !== null && $localStorageLocalization.length > 0) {
        $resultLanguage = $localStorageLocalization;
      } else {
        $resultLanguage = $userBrowserLocalization;
      }

      $resultLanguage = filterAvailableLanguage($resultLanguage);

      console.log($resultLanguage);

      this.$setLanguage($resultLanguage);
      localStorage.setItem('localization', $resultLanguage);
      changeHtmlLang($resultLanguage);
    },
    getProductName(slug){
      for (let i in coursesList) {
        if (coursesList[i].slug === slug) {
          return coursesList[i].title;
        }
      }
    },
    getActiveProduct(slug){
      for (let i in coursesList) {
        if (coursesList[i].slug === slug) {
          return coursesList[i];
        }
      }
    },
    gtm(slug) {
      let item = this.getActiveProduct(slug);
      this.$gtm.trackEvent({
        'ecommerce': {
          'purchase':{
            'actionField': {
              'id': 'T12345',
              'revenue': '35.43',
            },
          },
          'products': [{
            'name': item.title,
            'id': item.id,
            'price': item.uah_price,
            'category': item.category_type,
            'autor': item.speaker_name,
            'type': item.status,
            'quantity': 1
          }],
        }
      });
    }
  }
}
</script>
