import {$http} from '@/utils/http'
import {
  SET_NEWS_LIST,
  SET_NEWS_LIST_LOADING,
  SET_NEWS_ITEM,
  SET_NEWS_ITEM_LOADING,
  CHANGE_SKIP,
  SET_LIST_LENGTH,
  CHANGE_CATEGORY,
  SET_RANDOM_NEWS_LIST_LOADING,
  SET_RANDOM_NEWS_LIST,
  SET_RANDOM_LIST_LENGTH,
  CHANGE_PAGE_INDEX,
  SET_POPULAR_NEWS_LIST,
  SET_POPULAR_NEWS_LIST_LOADING,
  SET_TOP_NEWS_LIST,
  SET_TOP_NEWS_LIST_LOADING,
  CHANGE_LIST_PAGE_INDEX,
  SET_NEWS_INFO_LOADING,
  SET_NEWS_INFO
} from '../mutation-types';

import {
  GET_NEWS_LIST,
  GET_NEWS_ITEM,
  GET_RANDOM_NEWS_LIST,
  GET_POPULAR_NEWS_LIST,
  GET_TOP_NEWS_LIST,
  GET_NEWS_INFO
} from '../action-types';
import objectIsEmpty from "@/utils/object-is-empty";
import {objectToEncodedQueryString} from "@/utils/object-to-query-string";


const state = {
  list: {
    data: [],
    loading: false,
    pagination: {
      offset: 0,
      limit: 9, // by default
      total: 1,
    }
  },

  newsItem: null,
  newsItemLoading: false,

  randomNewsList: null,
  randomNewsListLoading: false,
  randomNewsListLength: 0,

  topNewsList: null,
  topNewsListLoading: false,
  topNewsListLength: 0,

  popularNewsList: null,
  popularNewsListLoading: false,
  popularNewsListLength: 0,

  offset: 0,
  limit: 6,
  listLimit: 9,
  listLength: 0,
  activeCategory: '',
  pageIndex: 1,
  listPageIndex: 1,

  newsInfo: null,
  newsInfoLoading: false
};

const getters = {
  listData: state => state.list.data,
  listLoading: state => state.list.loading,

  // list pagination
  listPaginationOffset: state => state.list.pagination.offset,
  listPaginationLimit: state => state.list.pagination.limit,
  listPaginationTotal: state => state.list.pagination.total,

  topNewsList: state => state.topNewsList,
  topNewsListLoading: state => state.topNewsListLoading,
  topNewsListLength: state => state.topNewsListLength,

  popularNewsList: state => state.popularNewsList,
  popularNewsListLoading: state => state.popularNewsListLoading,
  popularNewsListLength: state => state.popularNewsListLength,

  newsItem: state => state.newsItem,
  newsItemLoading: state => state.newsItemLoading,

  randomNewsList: state => state.randomNewsList,
  randomNewsListLength: state => state.randomNewsListLength,
  randomNewsListLoading: state => state.randomNewsListLoading,
  randomLimit: state => state.randomLimit,
  offset: state => state.offset,
  limit: state => state.limit,
  listLength: state => state.listLength,
  activeCategory: state => state.activeCategory,
  pageIndex: state => state.pageIndex,
  listPageIndex: state => state.listPageIndex,
  listLimit: state => state.listLimit,


  newsInfo: state => state.newsInfo,
  newsInfoLoading: state => state.newsInfoLoading,
};

const actions = {
  [GET_NEWS_LIST]: async ({commit},payload) => {
    commit(SET_NEWS_LIST_LOADING, true);
    try {
      const query = payload && !objectIsEmpty(payload) ? `?${objectToEncodedQueryString(payload)}` : '';
      const response = await $http.get(`v1/news${query}`);
      commit(SET_NEWS_LIST, response.data );
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_NEWS_LIST_LOADING, false);
    }
  },
  [GET_TOP_NEWS_LIST]: async ({commit}) => {
    commit(SET_TOP_NEWS_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/news?limit=${state.limit}&offset=0&tag=top`);
      commit(SET_TOP_NEWS_LIST, response.data.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_TOP_NEWS_LIST_LOADING, false);
    }
  },
  [GET_POPULAR_NEWS_LIST]: async ({commit}) => {
    commit(SET_POPULAR_NEWS_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/news?limit=${state.limit}&offset=0&tag=popular`);
      commit(SET_POPULAR_NEWS_LIST, response.data.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_POPULAR_NEWS_LIST_LOADING, false);
    }
  },
  [GET_RANDOM_NEWS_LIST]: async ({commit}, payload) => {
    commit(SET_RANDOM_NEWS_LIST_LOADING, true);
    try {
      const response = await $http.post(`v1/random-articles?count=${state.randomLimit}&except=${payload.id}`);
      commit(SET_RANDOM_NEWS_LIST, response.data.data);
      commit(SET_RANDOM_LIST_LENGTH, response.data.data.length);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_RANDOM_NEWS_LIST_LOADING, false);
    }
  },
  [GET_NEWS_ITEM]: async ({commit}, payload) => {
    commit(SET_NEWS_ITEM_LOADING, true);
    try {
      const response = await $http.get(`v1/news/${payload.slug}?include=same`);
      commit(SET_NEWS_ITEM, response.data.data);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_NEWS_ITEM_LOADING, false);
    }
  },
  [GET_NEWS_INFO]: async ({commit},payload) => {
    commit(SET_NEWS_INFO_LOADING, true);
    try {
      const response = await $http.get(`v1/pages/news-list`);
      commit(SET_NEWS_INFO, response.data.data );
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_NEWS_INFO_LOADING, false);
    }
  },
};

const mutations = {
  [SET_NEWS_LIST](state, data) {
    state.list.data = data.data;

    state.list.pagination.offset = data.meta.offset;
    state.list.pagination.limit = data.meta.limit;
    state.list.pagination.total = data.meta.total;
  },

  [SET_NEWS_LIST_LOADING](state, status) {
    state.list.loading = status;
  },



  [SET_RANDOM_NEWS_LIST](state, list) {
    state.randomNewsList = list;
  },
  [SET_RANDOM_NEWS_LIST_LOADING](state, status) {
    state.randomNewsListLoading = status;
  },
  [SET_RANDOM_LIST_LENGTH](state, data) {
    state.listLength = data;
  },


  [SET_NEWS_ITEM](state, data) {
    state.newsItem = data;
  },
  [SET_NEWS_ITEM_LOADING](state, status) {
    state.newsItemLoading = status;
  },



  [CHANGE_SKIP](state,count) {
    state.offset = count;
  },


  [CHANGE_CATEGORY](state, category) {
    state.activeCategory = category;
    state.skip = 3;
    state.listLength = 0;
    state.list = null;
  },


  [CHANGE_PAGE_INDEX](state, index) {
    state.pageIndex = index;
  },


  [CHANGE_LIST_PAGE_INDEX](state, index) {
    state.listPageIndex = index;
  },


  [SET_POPULAR_NEWS_LIST](state, list) {
    state.popularNewsList = list;
  },
  [SET_POPULAR_NEWS_LIST_LOADING](state, status) {
    state.popularNewsListLoading = status;
  },


  [SET_TOP_NEWS_LIST](state, list) {
    state.topNewsList = list;
  },
  [SET_TOP_NEWS_LIST_LOADING](state, status) {
    state.topNewsListLoading = status;
  },

  [SET_NEWS_INFO](state, list) {
    state.newsInfo = list;
  },
  [SET_NEWS_INFO_LOADING](state, status) {
    state.newsInfoLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
