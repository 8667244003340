// store
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {
  TRANSLATIONS_FETCH_SAMPLE_DATA_TRIGGER,
  TRANSLATIONS_COMETCHAT_INIT_TRIGGER,
  TRANSLATIONS_COMETCHAT_USER_LOGIN_TRIGGER, TRANSLATIONS_FETCH_BANNERS_LIST_TRIGGER,
} from "@/store/action-types";

// components
import { CometChatUI } from '@/components/widgets/cometchat'
import translationsBannersCarousel from '../../components/translations-banners-carousel/index.vue';

// global
import mainFooter from '@/components/features/footer/index.vue';
import translationForm from '../../components/form/index.vue';
import translationsSample from "@/assets/static/translations/sample";

export default {
  name: 'translations-sample',
  components: {
    mainFooter,
    CometChatUI,
    translationsBannersCarousel,
    translationForm
  },
  data() {
    return {
      currentLocaleIndex: -1,
      playerEnabled: false,
      currentTime: 0,
      isSeeking: false,
    }
  },
  computed: {
    ...mapGetters({
      // sample
      translationsSampleData: 'translations/sampleData',
      translationsSampleLoading: 'translations/sampleLoading',
      translationsSampleFetched: 'translations/sampleFetched',
      translationsSampleError: 'translations/sampleError',

      // user
      userCredentials: 'translations/userCredentials',
      userData: 'translations/userData',
      userLogin: 'translations/userLogin',
      userRegistration: 'translations/userRegistration',

      // chat
      chatInitLoading: 'translations/chatInitLoading',
      chatInitDone: 'translations/chatInitDone',
      chatInitError: 'translations/chatInitError',

      chatLoginLoading: 'translations/chatLoginLoading',
      chatLoginDone: 'translations/chatLoginDone',
      chatLoginError: 'translations/chatLoginError',

      // banners
      bannersListData: 'translations/bannersData',
      bannersTimeout: 'translations/bannersTimeout',
      bannersListLoading: 'translations/bannersLoading',
      bannersListDone: 'translations/bannersDone',
      bannersListError: 'translations/bannersError',
    }),

    player: function () {
      return this.$refs.player;
    },

    userCredentialsUnion() {
      return `${JSON.stringify(this.userCredentials)}|${JSON.stringify(this.translationsSampleData)}`
    }
  },
  created() {
    this.fetchSampleData({ slug: this.$route.params.slug });
    this.fetchBannersListData();
  },
  watch: {
    userCredentialsUnion: {
      immediate: true,
      handler(newCredentialsUnion, oldCredentialsUnion) {
        let [userCredentials, translationsSampleData] = newCredentialsUnion.split('|');
        const userHasBeenRegistered =
          localStorage.getItem('chat_user_has_been_registered') !== null
            ? JSON.parse(localStorage.getItem('chat_user_has_been_registered'))
            : false;

        userCredentials = JSON.parse(userCredentials);
        translationsSampleData = JSON.parse(translationsSampleData);

        console.log(userCredentials);
        console.log(translationsSampleData);

        if (translationsSampleData) {
          if (userCredentials && userCredentials.length > 0) {
            const foundCredential = userCredentials.find(credential => credential.broadcast === this.translationsSampleData.id);

            console.log('found cred: ', foundCredential);

            if (foundCredential && 'authToken' in foundCredential) {
              console.log('before init chat');
              this.initChat();
            } else {
              userHasBeenRegistered
                ? this.changeChatSignInAvailabilityStatus(true)
                : this.changeChatRegistrationAvailabilityStatus(false);
            }
          } else {
            userHasBeenRegistered
              ? this.changeChatSignInAvailabilityStatus(true)
              : this.changeChatRegistrationAvailabilityStatus(false);
          }
        }
      },
    },

    chatInitDone: function (newValue) {
      console.log('before login', newValue);

      if (newValue) {
        this.loginChatUser();
      }
    },

    translationsSampleData: function (translationData) {
      if (translationData && translationData.translations
        && translationData.translations.data && translationData.translations.data.length > 0)  {
        this.currentLocaleIndex = 0;

        setTimeout(() => {
          this.playerEnabled = true;
        }, 500);
      }
    }
  },
  methods: {
    ...mapActions({
      fetchSampleData: `translations/${TRANSLATIONS_FETCH_SAMPLE_DATA_TRIGGER}`,
      fetchBannersListData: `translations/${TRANSLATIONS_FETCH_BANNERS_LIST_TRIGGER}`,
      initChat: `translations/${TRANSLATIONS_COMETCHAT_INIT_TRIGGER}`,
      loginChatUser: `translations/${TRANSLATIONS_COMETCHAT_USER_LOGIN_TRIGGER}`,
    }),
    ...mapMutations({
      changeChatSignInAvailabilityStatus: 'system/CHAT_SIGN_IN_POPUP_CHANGE_AVAILABILITY_STATUS',
      changeChatRegistrationAvailabilityStatus: 'system/CHAT_REGISTRATION_POPUP_CHANGE_AVAILABILITY_STATUS',
      changeChatRestorePasswordAvailabilityStatus: 'system/CHAT_RESTORE_PASSWORD_POPUP_CHANGE_AVAILABILITY_STATUS',
    }),

    changeLocale (localeIndex) {
      this.playerEnabled = false;
      this.currentTime = 0;

      setTimeout(() => {
        this.currentLocaleIndex = localeIndex;
        this.playerEnabled = true;
      }, 500);
    },

    signInButtonClickHandler () {
      this.changeChatSignInAvailabilityStatus(true);
    }
  }
}
