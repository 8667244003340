import {mapGetters, mapActions, mapMutations} from 'vuex';
import Accordion from '../../components/accordion/index.vue'
export default {
  name: 'event-program',
  props:['item'],
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
    })
  },
  components: {
    Accordion,
  },
  methods: {
    ...mapActions({
    })
  },
  beforeDestroy() {
  },
}
