import {mapGetters, mapActions, mapMutations} from 'vuex';

import Slider from './components/slider/index.vue';
import homePagination from './components/marathon-sidebar/index.vue';

//sections
import promoSection from '@/modules/marathon/section/promo/index.vue';
import eventSection from '@/modules/marathon/section/event/index.vue';
import developmentSection from '@/modules/marathon/section/development/index.vue';
import signupSection from '@/modules/marathon/section/signup/index.vue';
import speakersSection from '@/modules/marathon/section/speakers/index.vue';
import calendarSection from '@/modules/marathon/section/calendar/index.vue';
import missionSection from '@/modules/marathon/section/mission/index.vue';
import partnersSection from '@/modules/marathon/section/partners/index.vue';
import faqSection from '@/modules/marathon/section/faq/index.vue';
import footerSection from '@/modules/marathon/section/lift/index.vue';

export default {
  name: 'home',
  data() {
    return {
      routesArray: [
        'marathon-promo',
        'marathon-event',
        'marathon-development',
        'marathon-speakers',
        'marathon-calendar',
        'marathon-mission',
        'marathon-parthners',
        'marathon-faq',
        'signup-section',
        'marathon-lift'
      ],
      intersectionOptions: {
        threshold: [0.25, 0.75]
      }
    }
  },
  created() {

  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      index: 'system/pageIndex',
    }),
  },
  components: {
    Slider,
    homePagination,
    promoSection,
    developmentSection,
    signupSection,
    eventSection,
    speakersSection,
    calendarSection,
    missionSection,
    partnersSection,
    footerSection,
    faqSection
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({
      changeIndex: `system/CHANGE_PAGE_INDEX`,
    }),
    goToPage(index, item) {
      this.changeIndex(index);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false
      });
    },
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        switch (entry.target.id) {
          case 'marathon-promo': {
            this.changeIndex(1);
            break;
          }
          case 'marathon-event': {
            this.changeIndex(2);
            break;
          }
          case 'marathon-development': {
            this.changeIndex(3);
            break;
          }
          case 'marathon-speakers': {
            this.changeIndex(4);
            break;
          }
          case 'marathon-calendar': {
            this.changeIndex(5);
            break;
          }
          case 'marathon-mission': {
            this.changeIndex(6);
            break;
          }
          case 'marathon-parthners': {
            this.changeIndex(7);
            break;
          }
          case 'marathon-faq': {
            this.changeIndex(8);
            break;
          }
          case 'marathon-lift': {
            this.changeIndex(9);
            break;
          }
        }
      }
    }
  },
  destroyed() {
  },
}
