import {$http} from '@/utils/http'
import eventList from "@/assets/static/event/event-list";

import {
  CHANGE_PAGE_INDEX,
  SET_EVENT_ITEM,
  SET_EVENT_ITEM_LOADING,
  SET_BUY_PRODUCT_LOADING,
  SET_PRODUCT_BUY_FORM,
  SHOW_POPUP_BUY_FORM,
  CHANGE_SHOW_POPUP_BUY_FORM,
  CHANGE_RECORD_POPUP_INFO,
  CHANGE_SHOW_RECORD_POPUP,
  CHANGE_SHOW_SUBSCRIBE_EVENT_POPUP,
  RECORD_TO_CONSULTATION_LOADING,
  CHANGE_TRAILER_POPUP_INFO,
  CHANGE_SHOW_TRAILER_POPUP,
  CHANGE_DOWNLOAD,
  CHANGE_DISCOUNT,

} from '../mutation-types';

import {
  GET_EVENT_ITEM,
  BUY_PRODUCT,
  RECORD_TO_CONSULTATION
} from '../action-types';
import {fixBody} from "../../helpers/variables";


const state = {
  eventItem: null,
  eventItemLoading: false,
  pageIndex: 1,
  buyEventLoading: false,
  buyForm: null,
  download: false,
  discount: false,

  isShowPopupBuyProduct: false,
  popupBuyProduct: {
    title: '',
    price: ''
  },
  recordEventPopup: {
    title: '',
    price: ''
  },
  isShowRecordEventPopup: false,
  isShowSubscribeEventPopup: false,

  recordToConsultationLoading: false,

  popupTrailerInfo: {
    videoId: null,
    title: ''
  },
  isShowPopupTrailer: false
};

const getters = {
  eventItem: state => state.eventItem,
  eventItemLoading: state => state.eventItemLoading,
  pageIndex: state => state.pageIndex,
  buyEventLoading: state => state.buyEventLoading,
  buyForm: state => state.buyForm,
  download: state => state.download,
  discount: state => state.discount,
  popupBuyProduct: state => state.popupBuyProduct,
  isShowPopupBuyProduct: state => state.isShowPopupBuyProduct,

  recordEventPopup: state => state.recordEventPopup,
  isShowRecordEventPopup: state => state.isShowRecordEventPopup,

  isShowSubscribeEventPopup: state => state.isShowSubscribeEventPopup,

  recordToConsultationLoading: state => state.recordToConsultationLoading,

  //trailer popup
  popupTrailerInfo: state => state.popupTrailerInfo,
  isShowPopupTrailer: state => state.isShowPopupTrailer,
};

const actions = {
  [GET_EVENT_ITEM]: async ({rootGetters, commit, dispatch}, payload) => {
    commit(SET_EVENT_ITEM_LOADING, true);

    try {
      // set ab campaign hash (if doesn't exist)
      await dispatch('setting/SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_TRIGGER', null, { root: true });

      const response = await $http.get(`v1/catalog/products/${payload.slug}?include=speakers`);
      commit(SET_EVENT_ITEM, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_EVENT_ITEM_LOADING, false);
    }
  },
  [BUY_PRODUCT]: async ({commit}, payload) => {
    commit(SET_BUY_PRODUCT_LOADING, true);
    try {
      const response = await $http.post('v1/catalog/products/buy', payload);
      commit(SET_PRODUCT_BUY_FORM, response.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_BUY_PRODUCT_LOADING, false);
    }
  },
  [RECORD_TO_CONSULTATION]: async ({commit} , payload) => {
    try {
      commit(RECORD_TO_CONSULTATION_LOADING, true);
      const response = await $http.post(`v1/lifta/consult/course`, payload);
      console.log(response)
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(RECORD_TO_CONSULTATION_LOADING, false);
    }
  },
};

const mutations = {
  [SET_EVENT_ITEM](state, list) {
    state.eventItem = list;
  },
  [SET_PRODUCT_BUY_FORM](state, data) {
    state.buyForm = data;
  },
  [SET_EVENT_ITEM_LOADING](state, status) {
    state.eventItemLoading = status;
  },
  [SET_BUY_PRODUCT_LOADING](state, status) {
    state.buyEventLoading = status;
  },


  [SHOW_POPUP_BUY_FORM](state, params) {
    state.popupBuyProduct = params;
  },
  [CHANGE_SHOW_POPUP_BUY_FORM](state, status) {
    state.isShowPopupBuyProduct = status;
  },


  [CHANGE_PAGE_INDEX](state, index) {
    state.pageIndex = index;
  },

  [CHANGE_RECORD_POPUP_INFO](state, info) {
    state.recordEventPopup = info;
  },
  [CHANGE_SHOW_RECORD_POPUP](state, status) {
    state.isShowRecordEventPopup = status;
  },
  [CHANGE_DOWNLOAD](state, status) {
    state.download = status;
  },
  [CHANGE_DISCOUNT](state, status) {
    state.discount = status;
  },

  [CHANGE_SHOW_SUBSCRIBE_EVENT_POPUP](state, status) {
    state.isShowSubscribeEventPopup = status;
  },

  [RECORD_TO_CONSULTATION_LOADING](state, status) {
    state.recordToConsultationLoading = status;
  },

  [CHANGE_TRAILER_POPUP_INFO](state, info) {
    state.popupTrailerInfo = info;
  },
  [CHANGE_SHOW_TRAILER_POPUP](state, status) {
    state.isShowPopupTrailer = status;
    fixBody(status);
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
