import {VueSlideToggle} from 'vue-slide-toggle';
import {mapGetters, mapMutations} from "vuex";
export default {
  name: 'accordion',
  props: ['item'],
  data() {
    return {
      expanded: false
    }
  },
  components: {
    VueSlideToggle
  },
  computed: {
    ...mapGetters({
      vacancyList: 'vacancy/vacancyList',
      vacancyListLoading: 'vacancy/vacancyListLoading',
    }),
  },
  methods: {
    ...mapMutations({
      changeShowVacancyPopup: `vacancy/CHANGE_SHOW_FORM_WITH_VACANCY`,
    }),
    toggleCardState() {
      this.expanded = !this.expanded;
    },
  },
}
