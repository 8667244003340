import {Carousel, Slide} from 'vue-carousel';

export default {
  name: 'slider',
  props: ['sliders', 'items'],
  data() {
    return {
      currentSlide: 0,
    }
  },
  components: {
  },
  methods: {

  }
}
