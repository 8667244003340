import {$http} from '@/utils/http'
import {
  SET_CATEGORY_ITEM_LOADING,
  SET_CATEGORY_ITEM,
  SET_CATEGORY_LIST,
  SET_CATEGORY_LIST_LOADING,
  SET_RELATED_LIST_LOADING,
  SET_RELATED_LIST,
  CHANGE_PAGE_INDEX,
  PRODUCTS_CHANGE_SKIP,
  PRODUCTS_CHANGE_LIMIT,
  PRODUCTS_SET_COUNT
} from '../mutation-types';

import {
  GET_CATEGORY_ITEM,
  GET_CATEGORY_PRODUCTS_LIST,
  GET_RELATED_LIST
} from '../action-types';


const state = {
  pageIndex: 1,
  categoryItem: null,
  categoryItemLoading: false,
  categoryList: null,
  categoryListLoading: false,
  relatedList: null,
  relatedListLoading: false,


  skip: 0,
  count: 0,
  limit: 6,
};

const getters = {
  pageIndex: state => state.pageIndex,
  categoryItem: state => state.categoryItem,
  categoryItemLoading: state => state.categoryItemLoading,
  categoryList: state => state.categoryList,
  categoryListLoading: state => state.categoryListLoading,
  relatedList: state => state.relatedList,
  relatedListLoading: state => state.relatedListLoading,

  skip: state => state.skip,
  count: state => state.count,
  limit: state => state.limit,
};

const actions = {
  [GET_CATEGORY_ITEM]: async ({commit}, payload) => {
    commit(SET_CATEGORY_ITEM_LOADING, true);
    try {
      const response = await $http.get(`v1/catalog/categories/${payload.slug}`);
      commit(SET_CATEGORY_ITEM, response.data.data[0]);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_CATEGORY_ITEM_LOADING, false);
    }
  },
  [GET_CATEGORY_PRODUCTS_LIST]: async ({commit}, payload) => {
    commit(SET_CATEGORY_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/product/by_category/${payload.category}`);
      commit(SET_CATEGORY_LIST, response.data.data);
      console.log(response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(SET_CATEGORY_LIST_LOADING, false);
    }
  },
  [GET_RELATED_LIST]: async ({commit}, payload) => {
    commit(SET_RELATED_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/catalog/categories/marketing`);
      // commit(SET_CATEGORY_LIST, response.data.data);
      commit(SET_RELATED_LIST, [
        {
          id: 1,
          img: 'https://picsum.photos/seed/picsum/1920/1080',
          title:'Исследователь',
          speaker_item: '12',
          type: 'online',
          duration:'12month',
          item_type: 'course',
          user: 'beginner',
          rating: 3.5,
          final_price: 50,
          discount_price: 0,
          desc:'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.'
        },
        {
          id: 2,
          img: 'https://picsum.photos/seed/picsum/1920/1080',
          title:'Title1',
          speaker_item: '12',
          type: 'online',
          duration:'12month',
          item_type: 'course',
          rating: 3.5,
          final_price: 50,
          discount_price: 39,
          user: 'beginner',
          desc:'Развивайся, общайся и лифти в мобильном приложении LIFTA.SPACE. Установи LIFTA-приложение и получи уникальную возможность расти в'
        },
        {
          id: 3,
          img: 'https://picsum.photos/seed/picsum/1920/1080',
          title:'Title1',
          speaker_item: '12',
          type: 'online',
          duration:'12month',
          item_type: 'course',
          user: 'beginner',
          rating: 3.5,
          final_price: 50,
          discount_price: 0,
          desc:'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.'
        }
      ]);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_RELATED_LIST_LOADING, false);
    }
  },
};

const mutations = {
  [SET_CATEGORY_ITEM](state, data) {
    state.categoryItem = data;
  },
  [SET_CATEGORY_ITEM_LOADING](state, status) {
    state.categoryItemLoading = status;
  },
  [SET_CATEGORY_LIST](state, data) {
    state.categoryList = data;
  },
  [SET_CATEGORY_LIST_LOADING](state, status) {
    state.categoryListLoading = status;
  },
  [SET_RELATED_LIST](state, data) {
    state.relatedList = data;
  },
  [SET_RELATED_LIST_LOADING](state, status) {
    state.relatedListLoading = status;
  },
  [CHANGE_PAGE_INDEX](state, index) {
    state.pageIndex = index;
  },
  [PRODUCTS_CHANGE_SKIP](state, skip) {
    state.skip = skip;
  },
  [PRODUCTS_CHANGE_LIMIT](state, limit) {
    state.limit = limit;
  },
  [PRODUCTS_SET_COUNT](state, count) {
    state.count = count;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
