import {fixBody} from "@/helpers/variables";
import {$http} from "@/utils/http";


import {
  CHANGE_SHOW_FORM_WITH_VACANCY,
  SEND_RESUME_LOADING,
  SET_VACANCY_LIST,
  SET_VACANCY_LIST_LOADING,
  SET_VACANCY_INFO_LOADING,
  SET_VACANCY_INFO
} from '../mutation-types';


import {
  SEND_RESUME,
  GET_VACANCY_LIST,
  GET_VACANCY_INFO
}
from "../action-types";


const state = {
  isShowFormWithVacancy: {
    show: false,
    position_field: false,
    vacancy: '',
    vacancy_id: null
  },
  sendResumeLoading: false,

  // list
  vacancyList: [],
  vacancyListLoading: false,

  //info
  vacancyInfo: [],
  vacancyInfoLoading: false
};

const getters = {
  isShowFormWithVacancy: state => state.isShowFormWithVacancy,
  sendResumeLoading: state => state.sendResumeLoading,

  //list
  vacancyList: state => state.vacancyList,
  vacancyListLoading: state => state.vacancyListLoading,

  //list
  vacancyInfo: state => state.vacancyInfo,
  vacancyInfoLoading: state => state.vacancyInfoLoading,
};

const actions = {
  [GET_VACANCY_LIST]: async ({commit} , payload) => {
    try {
      commit(SET_VACANCY_LIST_LOADING, true);
      const response = await $http.get(`v1/vacancy`, payload);
      commit(SET_VACANCY_LIST, response.data.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_VACANCY_LIST_LOADING, false);
    }
  },
  [SEND_RESUME]: async ({commit} , payload) => {
    try {
      commit(SEND_RESUME_LOADING, true);
      const response = await $http.post(`v1/cv`, payload);
      console.log(response)
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SEND_RESUME_LOADING, false);
    }
  },
  [GET_VACANCY_INFO]: async ({commit} , payload) => {
    try {
      commit(SET_VACANCY_INFO_LOADING, true);
      const response = await $http.get(`v1/pages/carier`, payload);
      commit(SET_VACANCY_INFO, response.data.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_VACANCY_INFO_LOADING, false);
    }
  },
};


const mutations = {
  [CHANGE_SHOW_FORM_WITH_VACANCY](state, params) {
    state.isShowFormWithVacancy = params;
    fixBody(state.isShowFormWithVacancy.show);
  },
  [SEND_RESUME_LOADING](state,status){
    state.sendResumeLoading = status;
  },
  [SET_VACANCY_LIST](state,data){
    state.vacancyList = data;
  },
  [SET_VACANCY_LIST_LOADING](state,status){
    state.vacancyListLoading = status;
  },
  [SET_VACANCY_INFO](state,data){
    state.vacancyInfo = data;
  },
  [SET_VACANCY_INFO_LOADING](state,status){
    state.vacancyInfoLoading = status;
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
