import StarRating from 'vue-star-rating'
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'courses-sample',
  components: {
    StarRating,
  },
  props: {
    data: {
      type: Object,
      default: {},
    }
  },
  computed:{
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      isCoursePage: `courses/isCourseProduct`,
    }),
  },
  watch: {
    'isAuthenticated'(){
      if( this.isAuthenticated && this.isCoursePage){
        setTimeout(()=>{
          let token = localStorage.getItem('user_token');
          window.location.href = `https://api.lifta.space/api/oauth/redirect?token=${token}`;
        },100)
      }
    }
  },
  methods: {
    ...mapMutations({
      changePopupSignIn: `auth/CHANGE_POPUP_SIGN_IN`,
      changeCoursePage: `courses/CHANGE_COURSE_PAGE`,
    }),
    ...mapActions({
      goRedirect: `courses/REDIRECT_TO_TEACHBASE`,
    }),
    selectCourseSample(id, slug, link) {
      if(!(this.data.link === null)){
        window.open(link, "_blank");
      }
      else {
        this.changeCoursePage(true);

        if (this.data.category_type === 'marafon-2021') {
          if (!this.isAuthenticated) {
            this.changePopupSignIn(true);
          } else {
            let token = localStorage.getItem('user_token');
            window.location.href = `https://api.lifta.space/api/oauth/redirect?token=${token}`;
          }
        } else {
          this.$router.push({ name: 'event', params: { slug: this.data.slug }});
        }
      }
    },
    declensionWord(count) {
      let titles = [this.$t('event.speakerWorld1'), this.$t('event.speakerWorld2'), this.$t('event.speakerWorld3')];
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]];
    },
  },
  destroyed() {
    this.changeCoursePage(false);
  }
}
