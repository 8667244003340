export default {
  name: 'input-radio',
  props: {
    name: {
      type: String,
      default: 'radio-input',
    },
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: {},
    },
  },
  data () {
    return {
      localValue: this.value,
    }
  },
  watch: {
    value: function (newValue) {
      this.localValue = newValue ? newValue : '';
    }
  },
  methods: {
    changeHandler (name) {
      this.$emit('change', name)
    }
  }
}
