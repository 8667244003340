import {mapGetters, mapActions, mapMutations} from 'vuex';

import newsItem from '../../components/news-item/index.vue'

//sections
import promoSection from '@/modules/news/pages/main/section/promo/index.vue';
import topNewsSection from '@/modules/news/pages/main/section/top-news/index.vue';
import popularSection from '@/modules/news/pages/main/section/popular/index.vue';
import topicSection from '@/modules/news/pages/main/section/topic/index.vue';
import mainFooter from '@/components/features/footer/index.vue'
import signUpForm from '@/components/features/sign-up-form/index.vue'


export default {
  name: 'news',
  data() {
    return {
      routesArray: [
        'news-promo',
        'news-top',
        'news-popular',
        'news-topic',
        'sign-up-form',
        'footer'
      ],
      intersectionOptions: {
        threshold: [0.25, 0.75]
      }
    }
  },
  created() {
    // if( !this.newsList ){
    //   this.fetchNewsList({slug: '', tag: ''});
    // }

    if(!this.topNewsList){
      this.fetchTopList();
    }

    if(!this.popularNewsList){
      this.fetchPopularList();
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      newsList: 'news/newsList',
      newsListLoading: 'news/newsListLoading',
      index: 'news/pageIndex',
      topNewsList: 'news/topNewsList',
      topNewsListLoading: 'news/topNewsListLoading',
      topNewsListLength: 'news/topNewsListLength',
      popularNewsList: 'news/popularNewsList',
      popularNewsListLoading: 'news/popularNewsListLoading',
      popularNewsListLength: 'news/popularNewsListLength',
    })
  },
  components: {
    newsItem,
    promoSection,
    topNewsSection,
    popularSection,
    topicSection,
    mainFooter,
    signUpForm
  },
  methods: {
    ...mapActions({
      fetchNewsList: `news/GET_NEWS_LIST`,
      fetchTopList: `news/GET_TOP_NEWS_LIST`,
      fetchPopularList: `news/GET_POPULAR_NEWS_LIST`
    }),
    ...mapMutations({
      changeIndex: `news/CHANGE_PAGE_INDEX`,
    }),
    goToPage(index, item) {
      this.changeIndex(index);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false
      });
    },
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        switch (entry.target.id) {
          case 'news-promo': {
            this.changeIndex(1);
            break;
          }
          case 'news-top': {
            this.changeIndex(2);
            break;
          }
          case 'news-popular': {
            this.changeIndex(3);
            break;
          }
          case 'news-topic': {
            this.changeIndex(4);
            break;
          }
          case 'sign-up-form': {
            this.changeIndex(5);
            break;
          }
          case 'footer': {
            this.changeIndex(6);
            break;
          }
        }
      }
    }
  },
  beforeDestroy() {
  },
}
