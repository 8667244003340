import { VueSlideToggle } from 'vue-slide-toggle';

export default {
  name: 'lectures-sample',
  components: {
    VueSlideToggle,
  },
  props: ['data'],
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded;
    }
  }
}
