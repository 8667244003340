import inputRadio from '@/components/inputs/radio/index.vue'
import inputRange from '@/components/inputs/range/index.vue'
import category from "../../../../store/modules/category";
export default {
  name: 'courses-catalogue-filters',
  components: {
    inputRadio,
    inputRange,
  },
  data() {
    return{
      sortlist: [
        {
          name: this.$t('toDefault'),
          key: null,
          id: 0
        },
        {
          name: this.$t('sortByAlphabetical'),
          key: 'alphabet',
          id: 1
        },
        {
          name: this.$t('sortByAlphabeticalReverse'),
          key: 'alphabet_reverse',
          id: 2
        },
        {
          name: this.$t('sortByDate'),
          key: 'latest',
          id: 3
        },
        {
          name: this.$t('sortByDateReverse'),
          key: 'oldest',
          id: 4
        },
      ]
    }
  },
  computed: {
    formattedFiltersList: function() {
      let updatedFiltersList = [];

      if (this.data && this.values) {
        updatedFiltersList = this.data.map(filter => {
          Object.keys(this.values).map(key => {
            if (filter.name === key) {
              filter.value = this.values[key];
            }
          })

          return filter;
        })
      }

      return updatedFiltersList;
    }
  },
  methods: {
    changeHandler (value) {
      // const payload = {value};
      const updatedQuery = _.cloneDeep(this.$route.query);
      delete updatedQuery.ord;
      if (value) {
        updatedQuery.ord = value
      }
      console.log(value);
      this.$router.push({name: 'courses', query: updatedQuery, params: {category: this.$route.params.category}}).catch(() =>{
        console.log('')
      })

    }
  }
}
