import {mapGetters, mapActions, mapMutations} from 'vuex';

import mainFooter from "@/components/features/footer/index.vue";
export default {
  name: 'privacy',
  data() {
    return {

    }
  },

  computed: {

  },
  components: {
    mainFooter
  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
  destroyed() {
  },
}
