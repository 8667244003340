import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'about',
  props: ['item'],
  data() {
    return {
      pageConfig: {
        identifier: null,
        category_id: null,
        url:  window.location.href
      }
    }
  },
  watch: {
    item: function (newVal) {
      if (newVal) {
        var self = this
        self.pageConfig.identifier = newVal.slug;
        self.pageConfig.category_id = newVal.id;
      }
    },
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  components: {
  },
  methods: {
  },
  beforeDestroy() {
  },
}
