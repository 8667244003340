import {mapGetters, mapActions, mapMutations} from 'vuex';

import Slider from '../../components/slider/index.vue';

export default {
  name: 'home-promo',
  data() {
    return {
      optionsSmall: {
        muted: 1,
        autoplay: 1,
        rel: 0,
        showinfo: 0,
        controls: 0,
        modestbranding: 1,
      },
    }
  },

  created() {
    if( !this.mainSlider ){
      this.fetchSliderList();
    }

    if( !this.homeList ){
      this.getHomeList();
    }


    // if( !this.activeStreamVideo ){
    //   this.getActiveStreamVideo();
    // }

  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      mainSlider: 'home/mainSlider',
      mainSliderLoading: 'home/mainSliderLoading',
      activeStreamVideo: 'home/activeStreamVideo',
      activeStreamVideoLoading: 'home/activeStreamVideoLoading',
      homeList: 'home/newsList'
    }),
  },
  components: {
    Slider
  },
  methods: {
    ...mapActions({
      getHomeList: 'home/GET_HOME_LIST',
      fetchSliderList: `home/GET_MAIN_SLIDER`,
      getActiveStreamVideo: `home/GET_ACTIVE_STREAM_VIDEO`,
    }),
    ...mapMutations({
      changeTransitionName: `system/CHANGE_TRANSITION_NAME`,
      changeIndex: `system/CHANGE_PAGE_INDEX`,
    }),
    scrollDown(item){
      this.changeIndex(2);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false,
        offset: -90,
      });
    },
    // changeCourses(){
    //   this.$router.push({name: 'transformaction-sample', params: {slug: 1}}).catch(()=> {console.log()})
    // },
    ready() {
      let player1 = this.$refs.youtubePlayer1;
      player1.player.mute();
    },
  },
  beforeDestroy() {
  },
}
