import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import {VueSlideToggle} from 'vue-slide-toggle';
import mainFooter from '@/components/features/footer/index.vue';
import inputSearch from '@/components/inputs/search/index.vue';
import chooseAssistance from '@/components/features/choose-assistance/index.vue';
import coursesSample from '@/components/features/courses-sample/index.vue';
import pagination from '@/components/features/pagination/index.vue';
import sort from '../../components/sort/index.vue';
import catalogueCategories from '@/components/features/catalogue-categories/index.vue';
import coursesCatalogueFilters from '../../components/filters/index.vue';
import objectIsEmpty from "@/utils/object-is-empty";

export default {
  name: 'courses',
  components: {
    mainFooter,
    inputSearch,
    chooseAssistance,
    coursesSample,
    pagination,
    sort,
    catalogueCategories,
    coursesCatalogueFilters,
    VueSlideToggle
  },

  data() {
    return {
      lang: '',
      expanded: true,
      width: false,
      queryParams: {},
      metaParams: {
        title: '',
        description: '',
        keywords: '',
        name: ''
      },
    }
  },

  head: {
    title: function () {
      return {
        inner: 'Lifta.space',
        separator: ' | ',
        complement: this.metaParams.title,
      }
    },
    meta: function () {
      let meta = [
        { n: 'keywords', c: this.metaParams.keywords, id: 'keywords' },
        { n: 'description', c: this.metaParams.description, id: 'description' },
        {p: 'og:title', c: this.metaParams.title, id: 'og:title'},
        {p: 'og:url', c: window.location.origin + this.$route.path, id: 'og:url'},
        {p: 'og:type', c: "website", id: 'og:type'},
        {p: 'og:description', c: this.metaParams.description, id: 'og:description'},
        {p: 'twitter:title', c: this.metaParams.title, id: 'twitter:title'},
        {p: 'twitter:description', c: this.metaParams.description, id: 'twitter:description'},
      ]

      if (Object.keys(this.$route.query).length > 0) {
        meta.push({ n: 'robots', c: 'noindex, nofollow' })
      }

      return meta
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ua/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' },
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'uk-UA', href: window.location.origin+ '/ua' + route, id: 'uk-UA'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    }
  },

  created() {
    this.fetchCategoriesList();
  },
  mounted() {
    if(!(this.$route.params.lang === undefined)){
      this.lang = this.$route.params.lang
    }
    if(this.$route.params.lang === null){
      this.lang = ''
    }
    var navbar = document.querySelector('#aside');
    window.onscroll = function() {myFunction()};

// Get the navbar


// Get the offset position of the navbar
    var sticky = navbar.offsetTop;

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
      const maxWidth = 500;
      if(window.innerWidth <= maxWidth){
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky")
      } else {
        navbar.classList.remove("sticky");
      }
      }
    }
    const maxWidth = 500;
    if(window.innerWidth <= maxWidth){
      this.expanded = false
      this.width = true
    }
  },
  computed: {
    ...mapGetters({
      // categories list
      categoriesListData: 'courses/categoriesListData',
      categoriesListLoading: 'courses/categoriesListLoading',
      categoriesListFetched: 'courses/categoriesListFetched',
      categoriesListError: 'courses/categoriesListError',

      // filters list
      filtersListData: 'courses/filtersListData',
      filtersListLoading: 'courses/filtersListLoading',
      filtersListFetched: 'courses/filtersListFetched',
      filtersListError: 'courses/filtersListError',

      // courses list
      coursesListData: 'courses/listData',
      coursesListLoading: 'courses/listLoading',
      coursesListFetched: 'courses/listFetched',
      coursesListError: 'courses/listError',

      // courses list pagination
      coursesListPaginationOffset: 'courses/listPaginationOffset',
      coursesListPaginationLimit: 'courses/listPaginationLimit',
      coursesListPaginationTotal: 'courses/listPaginationTotal',
    }),

    currentCategory () {
      return this.categoriesListData.find(category => category.slug === this.$route.params.category);
    }
  },

  watch: {
    '$route.fullPath': {
      immediate: true,
      handler(newValue, oldValue) {
        const categoryParams = this.$route.params.category;
        this.queryParams = this.$route.query;

        const payloadParams = {
          ...this.queryParams,
          ...(categoryParams ? { category: categoryParams } : {}),
          limit: this.coursesListPaginationLimit,
        };

        this.fetchCoursesList(payloadParams);
        this.fetchCoursesFiltersList();
        this.scrollToTop();
      }
    },

    currentCategory: {
      immediate: true,
      handler(newValue, oldValue) {
        this.updateMeta(newValue);
      }
    },
  },

  methods: {
    ...mapActions({
      fetchCategoriesList: `courses/COURSES_FETCH_CATEGORIES_LIST_DATA_TRIGGER`,
      fetchCoursesList: `courses/COURSES_FETCH_LIST_DATA_TRIGGER`,
      fetchCoursesFiltersList: `courses/COURSES_FETCH_FILTERS_LIST_DATA_TRIGGER`,
    }),
    toggleCardState() {
      this.expanded = !this.expanded;
    },
    scrollToTop() {
      // scroll to top
      const $coursesSectionEl = this.$refs['courses-section'];

      if ($coursesSectionEl)  {
        this.$smoothScroll({
          scrollTo: $coursesSectionEl,
          offset: -100,
        })
      }
    },

    updateMeta(category) {
      if (category) {
        this.metaParams.name = category.page_title;
        this.metaParams.title = category.meta_title;
        this.metaParams.description = category.meta_description;
        this.metaParams.keywords = category.meta_keyword;
      } else {
        this.metaParams.name = this.$t('courses.meta.name');
        this.metaParams.title = this.$t('courses.meta.title');
        this.metaParams.description = this.$t('courses.meta.description');
        this.metaParams.keywords = this.$t('courses.meta.keywords');
      }

      this.$emit('updateHead')
    },

    updateQueryParams({ name, value }) {
      let categoryParams = this.$route.params.category;
      const updatedQueryParams = {...this.queryParams};

      if (name) {
        if (value && value.toString().length > 0) {
          updatedQueryParams[name] = value;
        } else {
          delete updatedQueryParams[name];
        }

        if (name === 'search') {
          delete updatedQueryParams.offset;
          categoryParams = null;
        }

        this.queryParams = updatedQueryParams;
      }

      this.$router.replace({
        name: 'courses',
        ...(categoryParams ? { params: { category: categoryParams } } : {}),
        ...(!objectIsEmpty(this.queryParams) ? { query: this.queryParams } : {}),
      });
    },

    gtm (item) {
      this.$gtm.trackEvent({
        'ecommerce': {
          'currencyCode': 'USD',
          'impressions': {
            'name': item.title,
            'id': item.id,
            'price': item.final_price,
            'category': item.category,
            'autor': item.speakers?.data[0]?.name,
            'type': item.status,
          }
        }
      });
    }
  }
}
