import {Carousel, Slide} from 'vue-carousel';
import StarRating from 'vue-star-rating'

export default {
  name: 'speaker-slider',
  props: {
    sliders: {
      type: Number,
      default: null
    },
    items: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      currentSlide: 0,
      itemsCount: 0,
      isMobileVersion: false
    }
  },
  components: {
    Carousel,
    Slide,
    StarRating
  },
  watch:{
    items: function (newVal) {
      if (newVal) {
        this.itemsCount =Math.ceil(this.items.length / this.sliders);
      }
    },
  },
  mounted() {
     this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize, {passive: true});
    })

    this.itemsCount =Math.ceil(this.items.length / this.sliders);
  },
  methods: {
    changeArrow(value) {
      const carousel = this.$refs['slider-items'];
      const currentPage = carousel.currentPage;
      const pageCount = carousel.pageCount;
      if (value === 'prev') {
        currentPage !== 0 ? carousel.goToPage(currentPage - 1) : carousel.goToPage(pageCount - 1);
      } else {
        currentPage < pageCount - 1 ? carousel.goToPage(currentPage + 1) : carousel.goToPage(0);
      }
    },
    gotToSlide(index) {
      const carousel = this.$refs['slider-items'];
      carousel.goToPage(index)
    },
    pageChange(slide) {
      this.currentSlide = slide;
    },
    onResize() {
      this.isMobileVersion = window.innerWidth < 1100;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
}
