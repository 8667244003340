import {$http} from '@/utils/http'
import {
  SET_CATALOG_LIST,
  SET_CATALOG_LIST_LOADING,
  SET_CATALOG_ITEM,
  SET_CATALOG_ITEM_LOADING,
  CHANGE_SKIP,
  SET_LIST_LENGTH,
  CHANGE_CATEGORY,
  SET_RANDOM_CATALOG_LIST_LOADING,
  SET_RANDOM_CATALOG_LIST,
  SET_RANDOM_LIST_LENGTH,
  SET_COURSES_INFO_LOADING,
  SET_COURSES_INFO
} from '../mutation-types';

import {
  GET_CATALOG_LIST,
  GET_CATALOG_ITEM,
  GET_RANDOM_CATALOG_LIST,
  GET_COURSES_INFO
} from '../action-types';


const state = {
  catalogList: null,
  catalogListLoading: false,
  catalogListLength: 0,

  catalogItem: null,
  catalogItemLoading: false,

  randomCatalogList: null,
  randomCatalogListLoading: false,
  randomCatalogListLength: 0,

  skip: 0,
  limit: 6,
  randomLimit: 5,
  listLength: 0,
  activeCategory: '',

  coursesInfo: [],
  coursesInfoLoading: false
};

const getters = {
  catalogList: state => state.catalogList,
  catalogListLoading: state => state.catalogListLoading,
  catalogListLength: state => state.catalogListLength,

  catalogItem: state => state.catalogItem,
  catalogItemLoading: state => state.catalogItemLoading,

  randomCatalogList: state => state.randomCatalogList,
  randomCatalogListLength: state => state.randomCatalogListLength,
  randomCatalogListLoading: state => state.randomCatalogListLoading,
  randomLimit: state => state.randomLimit,
  skip: state => state.skip,
  limit: state => state.limit,
  listLength: state => state.listLength,
  activeCategory: state => state.activeCategory,


  coursesInfo: state => state.coursesInfo,
  coursesInfoLoading: state => state.coursesInfoLoading,
};

const actions = {
  [GET_CATALOG_LIST]: async ({commit}) => {
    commit(SET_CATALOG_LIST_LOADING, true);
    try {
      commit(CHANGE_SKIP);
      // const response = await $http.get(`v1/translations`);
      // commit(SET_LIST_LENGTH, response.data.meta.total);
      commit(SET_CATALOG_LIST, [
        {
          id: 0,
          title: "TEST CATEGORY"
        },
        {
          id: 1,
          title: "TEST CATEGORY 1"
        },
        {
          id: 3,
          title: "TEST CATEGORY 2"
        }
      ]);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_CATALOG_LIST_LOADING, false);
    }
  },
  [GET_RANDOM_CATALOG_LIST]: async ({commit}, payload) => {
    commit(SET_RANDOM_CATALOG_LIST_LOADING, true);
    try {
      const response = await $http.post(`v1/random-articles?count=${state.randomLimit}&except=${payload.id}`);
      commit(SET_RANDOM_CATALOG_LIST, response.data.data);
      commit(SET_RANDOM_LIST_LENGTH, response.data.data.length);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_RANDOM_CATALOG_LIST_LOADING, false);
    }
  },
  [GET_CATALOG_ITEM]: async ({commit}, payload) => {
    commit(SET_CATALOG_ITEM_LOADING, true);
    try {
      // const response = await $http.get(`v1/translations`);
      commit(SET_CATALOG_ITEM, [
        {
          id: 0,
          title: "TEST CATEGORY"
        },
        {
          id: 1,
          title: "TEST CATEGORY 1"
        },
        {
          id: 3,
          title: "TEST CATEGORY 2"
        }
      ]);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_CATALOG_ITEM_LOADING, false);
    }
  },
  [GET_COURSES_INFO]: async ({commit}, payload) => {
    commit(SET_COURSES_INFO_LOADING, true);
    try {
      const response = await $http.get(`v1/pages/courses-list`);
      commit(SET_COURSES_INFO, response.data.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_COURSES_INFO_LOADING, false);
    }
  },
};

const mutations = {
  [SET_CATALOG_LIST](state, list) {
    state.catalogList = list;
  },
  [SET_CATALOG_LIST_LOADING](state, status) {
    state.catalogListLoading = status;
  },
  [SET_RANDOM_CATALOG_LIST](state, list) {
    state.randomCatalogList = list;
  },
  [SET_RANDOM_CATALOG_LIST_LOADING](state, status) {
    state.randomCatalogListLoading = status;
  },
  [SET_RANDOM_LIST_LENGTH](state, data) {
    state.listLength = data;
  },
  [SET_CATALOG_ITEM](state, data) {
    state.catalogItem = data;
  },
  [SET_CATALOG_ITEM_LOADING](state, status) {
    state.catalogItemLoading = status;
  },
  [SET_LIST_LENGTH](state, data) {
    state.listLength = data;
  },
  [CHANGE_SKIP](state) {
    state.skip += state.limit;
  },
  [CHANGE_CATEGORY](state, category) {
    state.activeCategory = category;
    state.skip = 3;
    state.listLength = 0;
    state.list = null;
  },

  [SET_COURSES_INFO](state, list) {
    state.coursesInfo = list;
  },
  [SET_COURSES_INFO_LOADING](state, status) {
    state.coursesInfoLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
