
const coursesHowItWorks = [
  {
    id: 0,
    image: require('../../img/courses/how-it-works/how-it-works-1.svg'),
    title: 'Начало занятий',
    description: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит)',
  },
  {
    id: 1,
    image: require('../../img/courses/how-it-works/how-it-works-2.svg'),
    title: 'Работа в команде',
    description: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит ) увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада ',
  },
  {
    id: 2,
    image: require('../../img/courses/how-it-works/how-it-works-3.svg'),
    title: 'Online-чат',
    description: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон). ',
  },
  {
    id: 3,
    image: require('../../img/courses/how-it-works/how-it-works-4.svg'),
    title: 'Выпуск',
    description: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит ) увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада ',
  },
];

export default coursesHowItWorks;
