import {email, maxLength, minLength, required } from "vuelidate/lib/validators";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: 'choose-assistance',
  props:['list'],
  data() {
    return {
      payload: {
        name: '',
        email: '',
        title: '',
        message: ''
      },
      validationErrors: {}
    }
  },
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(60),
      },
      title: {
        required,
        maxLength: maxLength(100),
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
      message:{
        required,
        minLength: minLength(10),
        maxLength: maxLength(500),
      }
    }
  },
  components: {
  },
  computed:{
    ...mapGetters({
      sendHelpFormLoading: 'contactForm/sendHelpFormLoading',
    }),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'));
      }

      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
    titleErrors() {
      let error = [];
      if (!this.$v.payload.title.$dirty) {
        return error;
      }
      if (!this.$v.payload.title.required) {
        error.push(this.$t('validationRequired'));
      }

      if (!this.$v.payload.title.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 100));
      }
      if (this.validationErrors.title) {
        this.validationErrors.title.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
    messageErrors() {
      let error = [];
      if (!this.$v.payload.message.$dirty) {
        return error;
      }
      if (!this.$v.payload.message.required) {
        error.push(this.$t('validationRequired'));
      }

      if (!this.$v.payload.message.minLength) {
        error.push(this.$t('validationMin').replace(':count', 10));
      }

      if (!this.$v.payload.message.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 500));
      }
      if (this.validationErrors.message) {
        this.validationErrors.message.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
  },
  methods: {
    ...mapActions({
      sendHelpForm: `contactForm/SEND_HELP_FORM`,
    }),
    ...mapMutations({
      changeShowThanksPopupShow: `system/CHANGE_FORM_THANKS_POPUP`,
      changeContent: `system/POPUP_TEXT_CONTENT`,
      changeContentPopup: `system/POPUP_TEXT_CONTENT`,
      changeShowContentPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
    }),
    resetForm(){
      this.$v.$reset();
      this.payload.name = '';
      this.payload.email = '';
      this.payload.title = '';
      this.payload.message = '';
    },
    submitForm() {

      this.$v.$touch();
      if (!this.$v.$invalid) {

        let obj = {};
        obj.name = this.payload.name;
        obj.email = this.payload.email;
        obj.title = this.payload.title;
        obj.message = this.payload.message;

        this.sendHelpForm(obj).then((response) => {
          this.resetForm();
          this.changeContentPopup({
            text: this.$t('successSendContactForm'),
          });
          setTimeout(()=> {
            this.changeShowContentPopup(true);
          },250)
        }).catch(error => {
          console.log(error);
          if (error.response.status === 418) {
            this.$toasted.error(error.response.data.message);
            const errors = error.response.data.errors;
            for (const i in errors) {
              Vue.set(this.validationErrors, camelCase(i), errors[i]);
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            const errors = error.response.data.errors;
            for (const i in errors) {
              Vue.set(this.validationErrors, camelCase(i), errors[i]);
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        });
      }
    }
  }
}
