import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'translations-banners-carousel',
  components: {
    Carousel,
    Slide,
  },
  props: {
    list: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 10,
    },
  },
}
