export default {
  filter: 'Фільтри',
  myCourses: 'Мої курси',
  cookies: 'Cookies',
  cookiesMessage: 'Використовуючи цей веб-сайт, ви автоматично приймаєте, що ми використовуємо файли cookie.',
  cookiesButton: 'Зрозуміло',
  enterCorrectPhone: 'Введіть правильний телефон',
  successCv: 'Ваша заявка успішно прийнята',
  vacancyWork: 'Кар\'єра',
  record: 'Записатись',
  successUserRegister: 'Реєстрація пройшла успішно!',
  successRecordToEvent: 'Ви успішно записалися на консультацію. Наш менеджер зв\'яжеться з вами протягом дня.',
  successRecordToEventHint: 'Менеджер зв\'яжеться з вами найближчим часом і розповість деталі участі на курсі!',
  successSendTestResult: 'Результати тесту успішно відправлені вам на пошту !',
  successAuth: 'Авторизація пройшла успішно',
  share: 'Поділитись',
  email: "Email",
  successActivationEmail: 'Ваш емейл активовано',
  successSendContactForm: 'Спасибі за звернення. Повідомлення успішно відправлено!',
  profile: 'Профіль',
  letterSubject: 'Тема листа',
  letter: 'Лист',
  contactForm: 'Контактна форма',
  teamProject: 'Команда проєкта',
  yourName: "Твоє ім'я",
  yourEmail: "Твій емейл",
  yourPhone: "Телефон",
  yourLang: "Обрати мову",
  yourPassword: "Пароль",
  ruLang: "Російська",
  enLang: "Англійська",
  uaLang: "Українська",
  pantoLang: "Мова жестів",
  promocode: "Промокод",
  agreeDataProcess: "Даю згоду на зберігання і обробку моїх персональних даних",
  validateAcceptAgreement: "Встановіть галочку на угоді",
  totalInformation: 'Загальна інформація',
  noDataNow: 'Дані відсутні',
  fieldRequired: "Поле :name обов'язкове для заповнення",
  maxLengthField: "Максимальна кількість символів поля :field — :count",
  name: "Ім'я",
  next: "Далі",
  noData: "Дані відсутні",
  or: "або",
  all: 'Всі',
  city: 'Місто',
  country: "Країна",
  subscribe: "Підписатись",
  register: "Зареєструватись",
  directions: "Напрямки",
  navigation: "Навігація",
  contacts: "Контакти",
  phone: "Номер телефону",
  validationLengthPhone: "Кількість символів в поле телефон має бути - 12",
  validationEmail: "Введіть коректний email",
  successRegisterToEvent: "Ви успішно зареєструвались на захід",
  remindMe: "Нагадати мені",
  remindinfo: "Нагадування прийде вам на пошту",
  selectCountry: "Оберіть країну",
  selectCity: "Оберіть місто",
  from: "з",
  validationMax: "Максимальна кількість символів :count",
  validationMin: "Мінімальна кількість символів :count",
  validationRequired: "Заповніть це поле!",
  validationSameAs: "Паролі не співпадають!",
  toDefault: 'По замовчуванню',
  sort: 'Сортування',
  sortByAlphabetical: 'По алфавіту (А-Я)',
  sortByAlphabeticalReverse: 'По алфавіту (Я-А)',
  sortByDate: 'Від нових до старих',
  sortByDateReverse: 'Від старих до нових',

  //header translations
  header: {
    courses: 'Курси',
    news: 'Новини',
    about: 'Про нас',
    speakers: 'Спікери',
    logIn: 'Увійти',
    profile: 'Профіль'
  },

  //footer translations
  footer: {
    title: 'Заплануй навчання прямо зараз, не пропусти момент',
    directions: 'Направлення',
    navigation: 'Навігація',
    main: 'Головна',
    about: 'Про нас',
    vacancy: 'Кар\'єра',
    news: 'Новини',
    courses: 'Курси',
    speakers: 'Спікери',
    contacts: 'Контакты',
    contactTitle: 'Учителем йоги або бізнес-консультантом з феноменальною інтуіцією. Ти можеш бути неймовірним дизайнером або крутим програмістом, менеджером або маркетологом.',
    contactNumber1: '+38 (044) 334 61 16',
    contactNumber2: '+7 (495) 266 67 03',
    contactAdress: '01024, м.Київ, вул. ВЕЛИКА ВАСИЛЬКІВСЬКА, 13',
  },

  //home translations
  home: {
    //promo
    promoTitle1: 'LIFTA.SPACE',
    promoTitle2: 'NO LIMITS',
    promoButton: 'Дивись марафон Зараз!',

    //development
    trendTitle: 'ТВІЙ ВЕКТОР РОЗВИТКУ',
    marketing: 'Маркетинг',
    marketingDesc: 'Ми не обмежуємо маркетинг побутовим терміном «реклама». Маркетинг — це продумана філософія, тактика та стратегія мислення із сотнями нюансів та підходів до клієнта.\n' +
      'Концепції маркетинга дозволяють реалізувати себе як творця продукту, економіста, аналітика чи експерта з продажів.\n' +
      'Десятки маркетингових спеціальностей забезпечать місце в індустрії як новачкам, так і професіоналам.\n' +
      'Знайди свою професію та розвивайся у маркетингу разом з експертами LIFTA.\n',
    TagMarketing1: '#Метрики',
    TagMarketing2: '#Ліди',
    TagMarketing3: '#Стратегія',
    design: 'Дизайн',
    designDesc: 'Нас оточують мільйони предметів. Кожен з них — стіл, компьютер, смартфон, сорочка, блокнот, прикраси, кондиціонер, будинок навпроти та віконна рама — продукт дизайну, який виконала певна людина. \n' +
      'Дизайн наповнює наше життя та багато в чому визначає його, задаючи функціональні та естетичні тренди. \n' +
      'Дизайнери-професіонали є затребуваними і в маркетингу, і на виробництвах, а тому з легкістю знайдуть себе  в онлайн- і в офлайн-індустрії.\n' +
      'Проектувати з нуля і прокачати художні навички, по-новому поглянути на професію допоможуть наші гайд-LIFTERи.\n',
    TagDesign1: '#UI',
    TagDesign2: '#UX',
    TagDesign3: '#Прототип',
    management: 'Менеджмент',
    managementDesc: 'Прогресивного та ефективного управління потребують усі: підприємства, державні інститути, некомерційні організації.\n' +
      'Управління колективом, складення алгоритмів для оптимальних рішень, уміння передбачити їхні наслідки — це лиш малий набір функцій менеджера, котрий можна застосувати не тільки в роботі, а й в особистому житті.\n' +
      'Менеджмент, що опановується в LIFTA — комплексна зростаюча система на зіткненні точних та соціальних наук — покращить ваші позиції на ринку, незалежно від сьогоднішнього статусу.\n',
    TagManagement1: '#Люди',
    TagManagement2: '#Управління',
    TagManagement3: '#Час',
    TagManagement4: '#Agile',
    development: 'Розробка',
    developmentDesc: 'Сьогодні більшу частину технологічних процесів неможливо уявити без відповідного програмного забезпечення: від функціонування банківських систем та безпеки держав до простого листування.\n' +
      'Програмування є багатоплановим та включає в себе створення комп’ютерних програм, їхнє тестування, оптимізацію та впровадження, а також управління та аналіз цих процесів.\n' +
      'Експерти LIFTA розкажуть, як реалізуватися в програмуванні, розширити професійні можливості, або запровадити програмний продукт у ваш бізнес.\n',
    TagDevelopment1: '#Front-end',
    TagDevelopment2: '#Backend',
    TagDevelopment3: '#Інновації',
    TagDevelopment4: '#Тестування',
    self: 'Саморозвиток',
    selfDesc: 'Для того, аби реалізувати себе у професії, стосунках із собою та іншими людьми, необхідне особистісне зростання.\n' +
      'Цей безперервний процес торкається різних сфер життя — фізіології, дисципціни, знаннь, прихованих психологічних конфліктів та цінностей, взаємовідносин. Він безпосередньо впливає на якість нашого життя та уявлення про світ та самих себе.\n' +
      'Особистісне зростання посилить наші потенційні можливості, скорегує недоліки, спростить аналіз та вибір рішень у тій чи іншій ситуації.\n' +
      'Саморозвиток у потрібному тобі векторі — ключовий концепт LIFTA!',
    TagSelf1: '#Рух',
    TagSelf2: '#Вивчення',
    TagSelf3: '#Просування',
    TagSelf4: '#Усвідомлення',

    //start
    startTitle: 'НЕ ЗНАЄШ ІЗ ЧОГО РОЗПОЧАТИ?',
    startSubtitle: 'Знайди власний вектор селф-LIFTу',
    startDescription: 'Буває складно відповісти собі на питання: а чого я насправді хочу? Різноманіття вибору нерідко збиває з пантелику. Для більш точного визначення точки старту, проходь LIFTA-тест. Всього кілька хвилин —  і його результати підкажуть оптимальний напрямок розвитку з урахуванням твоїх особистісних якостей.',
    startButton: 'ПРОЙТИ ТЕСТ',

    //speakers
    speakerTitle: 'НАШІ НАСТАВНИКИ',
    all: 'Усі',

    //calendar
    orderCourse: 'Записатись на курс',
    calendarTitle: 'КАЛЕНДАР ПОДІЙ',
    calendarSubtitle: 'Заплануй навчання прямо зараз, не пропусти момент',
    calendarAll: 'Подивитись все',
    calendarDesc: 'Онлайн-марафон transformACTION 2021 — це місце, де ми вливаємось у темп сучасної реальності',
    calendarBtn: 'Записатись на марафон',

    //about
    aboutTitle: 'ЩО ТАКЕ',
    aboutToxicTitle: ' LIFTA.SPACE?',
    aboutItem1Title: 'Необмежені можливості росту',
    aboutItem1Text: 'Матеріали LIFTA.SPACE універсальні та підходять кожному',
    aboutItem2Title: 'Простота та зручність',
    aboutItem2Text: 'LIFTA.SPACE завжди під рукою: актуальні курси та всі твої досягнення доступні на всіх пристроях',
    aboutItem3Title: 'Досвідчені спікери',
    aboutItem3Text: 'Експерти LIFTA.SPACE — джерело актуальних практичних знань та напрацювань',
    aboutItem4Title: 'Практичні навички від початку навчання',
    aboutItem4Text: 'Нова інформація в LIFTA.SPACE трансформується в індивідуальну практику та реальні кейси',
    aboutItem5Title: 'Різноманіття форматів',
    aboutItem5Text: 'LIFTA.SPACE пропонує семінари та вебінари, інформацію в текстовому форматі, організує практикуми та інтенсиви',
    aboutItem6Title: 'Жива екосистема',
    aboutItem6Text: 'Спілкування у просторі LIFTA дозволить ділитися ідеями та нарощувати соціальні контакти, за допомогою яких ти реалізуєшся у новій професії.',

    //knowwhere
    knowTitle: 'КОЛИ І ДЕ ЗАВГОДНО',
    knowDesc1: 'Розвивайся, спілкуйся та ліфтуй у мобільному додатку LIFTA.SPACE. Встанови LIFTA-додаток і отримай унікальну можливість рости у бідь-якій точці простору, не гаючи дорогоцінного часу.',
    knowDesc2: 'Додаток LIFTA для пристроїв ',
    knowToxic: 'iOS та Android',
    knowDesc3: ' відкриє твій атлас розвитку на сторінці нових досягнень, збереже попередні та надихне розгорнутою маршрутною картою.',
    knowHint: 'LIFTA-додаток — глобус пошуку та росту у твоєму смартфоні.',
  },

  //about translations
  about: {
    //promo
    we: 'МЫ',
    promoTitle: 'Команда из более 1000 сотрудников',
    targetItem1Title: '350+',
    targetItem1Text: 'Спикеров',
    targetItem2Title: '1325',
    targetItem2Text: 'Курсов',
    targetItem3Title: '100 000',
    targetItem3Text: 'Lifters',
    targetItem4Title: '200+',
    targetItem4Text: 'Событий',
    //solution
    solutionTitle: 'Мы создаем быстрые и удобные решения для пользователей',
    solutionLeftBar: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана\n' +
      '          (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агентат) и Меровингиана (Ламбер Вильсон). Хьюго Уивинг\n' +
      '          не смог вернуться к роли агента',
    solutionRightBar: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана\n' +
      '          (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости, а Лоренса Фишберна никто\n' +
      '          пригласить и не подумал, хотя все еще можно рассчитывать на появление молодой версии Морфеуса.Зрители вновь\n' +
      '          увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон.',
    solutionItem1Title: 'Лучшее образование',
    solutionItem1Text: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и\n' +
      '              Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
    solutionItem2Title: 'Только практика',
    solutionItem2Text: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и\n' +
      '              Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
    solutionItem3Title: 'Генерируйте новые идеи',
    solutionItem3Text: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и\n' +
      '              Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
    solutionItem4Title: 'Новый опыт',
    solutionItem4Text: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и\n' +
      '              Меровингиана (Ламбер Вильсон). Хьюго Уивинг не смог вернуться к роли агента Смита из-за занятости',
    //how
    howTitle: 'Как мы это делаем?',
    howText1: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    howSubtitle1: 'Чат студента и преподователя',
    howText2: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    howSubtitle2: 'Готовые проекты в портфолио',
    howText3: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    howSubtitle3: 'Поддержку на любом этапе',
    howText4: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    howSubtitle4: 'Только практика',
    howText5: 'Зрители вновь увидят Нео (Киану Ривз), Тринити (Кэрри-Энн Мосс), Ниобе (Джада Пинкетт Смит) и Меровингиана (Ламбер Вильсон).Зрители вновь увидят Нео (Киану Ривз)',
    //team
    teamTitle: 'НАША КОМАНДА',

    title1: 'Давай почнемо діалог вже зараз',
    title2: ' Не відкладай ідеї і питання в довгий ящик. Ми раді знайомству з тобою!',
    title3: 'Ми дійсно хочемо знати, що ти думаєш!',
    mainDesc: 'LIFTA.SPACE - гнучкий простір без кордонів. Ми завжди відкриті новим поглядам, ідеям і пропозиціям! Зворотній зв\'язок - це безцінне джерело інсайдів і помічник у визначенні зон зростання. Виникли питання? Є пропозиції? Хочете поділитися своєю ідеєю? - ми раді новому діалогу і завжди готові відповісти, обговорити і почути. Спасибі, що ліфтиш нас!',
  },

  //sign-up-form
  signUpForm: {
    toxicTitle: 'Оформи підписку',
    title: ' і отримай знижку на курс 5%',
    hint: 'Підпишись зараз і отримуй актуальну інформацію на пошту*'
  },

  // translations
  translations: {
    locales: {
      en: 'Original',
      ru: 'Русский',
      ua: 'Українська',
    },
    banner: {
      image: '@/assets/img/translations/banners/translations_banner_uk.png',
      external_link: 'https://lifta.space/ua/courses',
    }
  },

  //courses
  courses: {
    staticTitle: 'Професія: IT-рекрутер',
    staticTitle1: 'Гнучке керування проектами',
    staticTitle2: 'Ефективний керівник',
    staticDesc: 'Побудуй кар\'єру в амбітній сфері, яка буде актуальною в найближчі десятиліття. Стань потрібним спеціалістом в IT.',
    staticDesc1: 'Як скоротити кількість документів та збільшити продуктивність команд? Як перестати фантазувати про результати і почати систематично їх досягати?\n' +
      'Приєднуйся до курсу, впроваджуй отримані знання і ти побачиш, як гнучкий підхід наближає бізнес до визначених цілей.',
    staticDesc2: '9 тижнів, 9 занять, 9 практичних навичок, які підвищать твою ефективність, і завдяки цьому — продуктивність команди',
    coursesListTitle: 'Усі курси',
    coursesListSubTitle: 'Отримай унікальну можливість',
    coursesListDesc: 'Розвивайся, спілкуйся і ліфти в мобільному додатку LIFTA.SPACE. Установи LIFTA-додаток і отримай унікальну можливість рости',
    searchPlaceholder: 'Пошук...',
    allDirections: 'Усі напрямки',
    meta: {
      name: 'Усі курси',
      title: 'Усі курси',
      description: 'Розвивайся, спілкуйся і ліфти в мобільному додатку LIFTA.SPACE. Установи LIFTA-додаток і отримай унікальну можливість рости',
      keywords: 'курси, список, усі напрямки',
    }
  },

  //choose-assistance
  choose: {
    title1: 'НЕ ЗНАЄТЕ ЩО',
    title2: 'ОБРАТИ?',
    desc: 'Наші менеджери допоможуть визначитися с професією, залиште свій номер телефону, ім`я ти пошту.',
    name: 'Ім`я',
    phone: 'Телефон',
    mail: 'Пошта',
    hint: 'Підпишись зараз і отримуй актуальну інформацію на пошту*',
    button: 'Відправити',
  },

  //speakers-list
  speakers: {
    title: 'Спікери',
    subTitle: 'Список усіх доступних спікерів на сервісі',
  },

  //speaker-sample
  speaker: {
    aboutTitle: 'Про спікера',
    personalTitle: 'Усі курси',
    personalDesc: 'Заплануй навчання прямо зараз, не пропусти момент',
    personalLookAll: 'Подивитись все',
    speakerListTitle: 'Обери свого спікера',
  },

  //news-main
  mainNews: {
//promo
    title: 'Блог',
    subTitle1: 'Новини,',
    subTitle2: 'безкоштовні',
    subTitle3: 'курси, знижки кожного дня.',
    itemList: {
      item1: 'Усі',
      item2: 'Маркетинг',
      item3: 'Знижки',
      item4: 'Дизайн',
      item5: 'Освіта',
      item6: 'Дизайн',
      item7: 'Знижки',
      item8: 'Дизайн',
      item9: 'Безкоштовний курс',
      item10: 'Освіта',
      item11: 'Безкоштовний курс',
      item12: 'Дизайн',
      item13: 'Дизайн',
      item14: 'Освіта',
      item15: 'Дизайн',
      item16: 'Знижки',
      item17: 'Дизайн',
      item18: 'Безкоштовний курс',
      item19: 'Освіта',
      item20: 'Безкоштовний курс',
      item21: 'Дизайн',
    },
    lookPost: 'Переглянути статтю',
    popularTitle: 'популярні новини',
    seeAll: 'Подивитись всі',
    topicTitle: 'Топік',
  },

  //newsList
  newsList: {
    title: 'УСІ НОВИНИ',
    subTitle: 'Відкрийте для себя світ нових професій',
    total: 'Усього',
    news: 'новин.',
  },

  //popups
  popup:{
    haveAccount: 'Вже маєте аккаунт',
    haveNotAccount: "Немає аккаунту?",
    signIn: 'Увiйти',
    register: 'Реєстрування',
    password: 'Пароль',
    passwordConfirm: 'Підтвердіть пароль',
    forgotPassword: 'Забули пароль?',
    backToLogin: 'Назад на Вхід',
    email: 'Email',
    phone: 'Телефон',
    restorePassword: 'Вiдновлення паролю',
    send: 'Вiдправити',
    logOut: 'Вихід',
    managerInfo: "Після реєстрації наш менеджер зв'яжеться з вами!",
    subscribeSuccessInfo: "Дякую за реєстрацію . Наш менеджер зв'яжеться з Вами найближчим часом!",
  },

  event: {
    registerCourse: 'Зареєструватися',
    downloadPreview: 'Завантажити безкоштовний гайд',
    registerAndPayment: 'Реєстрація і оплата',
    priceCourseTo: 'ціна курсу До',
    priceMessage: 'Ціна вказана за весь курс, ніяких додаткових платежів',
    aboutEvent: 'Про івент',
    howYouGet: 'Що ти отримаєш в результаті?',
    eventWhy: 'навіщо тобі це?',
    coursesProgram: 'Дорожня карта курсу:',
    yourEventIf: 'Це для тебе, якщо...',
    level: 'Рівень',
    subscribeConcult: 'зв\'язатися з менеджером',
    leaveRequest: 'залишити заявку',
    watchWebinar: 'дивитись вебінар',
    speakers: 'З ким ти ліфтуєш',
    goBack: 'Повернутись назад',
    start: 'старт',
    to: 'до',
    after: 'після',
    specialProposition: 'Спеціальна пропозиція',
    aboutCourse: 'Про курс',
    when: 'Коли',
    program: 'Програма',
    time: 'Час проведення',
    price: 'Вартість',
    youSpeaker: 'Твій спікер',
    register: 'Реєстрація',
    coursePrice: 'Вартість курсу',
    withOutDiscount: 'без знижки',
    discountEnd: 'знижка закінчиться',
    promocodeMessage: 'У мене є промокод',
    yourSpeaker: 'З ким ти ліфтуєш?',
    watchTrailer: 'Дивитись трейлер курсу',
    speakerWorld1: 'спікер',
    speakerWorld2: 'спікера',
    speakerWorld3: 'спікерів',
    hintDesc1: 'Спеціальна пропозиція діє для учасників вебінару тільки сьогодні!',
    hintDesc2: 'Зі знижкою -10%',
    hintDesc3: 'Спеціальна пропозиція діє тільки сьогодні!',
    hintDesc4: 'Зі знижкою -30%',
    hintTitle: 'Отримай знижку в 30% на професію IT - Рекрутер',
  },

  //vacancy
  vacancy:{
    desc0: `LIFTA.SPACE — новий digital-простір зростання, розвитку та здобуття професійних та особистісних якостей. Проект — більше ніж база знань. Це справжня освітня екосистема, революційна культура обміну досвідом, навичками та поглядами. Комплексний підхід до комплексного розвитку людини.<br><br>` +
      `Наша мета — не просто створити унікальний простір для самореалізації та саморозвитку для кожного, допомагати досліджувати нові можливості для росту і долати внутрішні та зовнішні бар’єри, а і в цілому змінити підхід та культуру недосконалої системи освіти та розвитку. Ми —  проект нового покоління, який долає усі зайві обмеження. Тому що все можливо.<br><br>` +
      'NO LIMITS — це не просто концепція чи мотиваційний слоган платформи. Це дійсність, яка втілюється з кожним новим кроком освітнього простору LIFTA.SPACE.\n',
    title1: 'Будь ким захочеш, знайди роботу мрії!',
    desc1: `LIFTA.SPACE не тільки надихає, але й пропонує безмежний простір можливостей.<br><br>` +
      'Let’s lift together!',
    btn1: 'Стань частиною команди!',
    title2: 'Можливості - безмежні!',
    desc2: 'Якщо вище не знайшлося пропозиції, яка потрапила б тобі прямо в серце, не біда! Один клік - і ми розповімо про тебе своїм партнерам, крутим компаніям, талановитим HR-ам, тому що ти володієш скілом і ідеями, які сьогодні цінні, як ніколи. Про тебе повинен знати весь світ!',
    btn2: 'Всі можливості світу',
    firstName:"Ім'я",
    lastName: 'Прізвище',
    letter: 'Супровідний лист',
    link : 'Посилання на',
    cvMessage: 'Прикріпити CV (файл, формати .doc, .docx, .pdf, .txt, .pptx)',
    titleMessage1: 'Форма для заповнення вакансії',
    titleMessage2: 'Форма для заповнення без вакансії',
    noVacancyMessage: 'Вільних вакансій немає',
  },



  // payment
  payment:{
    coursePayment: 'Оплата курсу',
    coursePrice: 'Ціна курсу',
    paymentSystem: 'Система оплати',
    paymentSystemName: 'Fondy',
    successBuyProduct: 'Ви оплатили курс ":title" успішно. Наш менеджер зв\'яжеться з вами в перебігу робочого дня. Дякую',
    errorBuyProduct: `Ви не сплатили курс ":title". Спробуйте, будь ласка, знову або ж зв\'яжіться з нами:<br><br>` +
      `- в живому чаті на сайті<br><br>` +
      `- на пошту support@lifta.space<br><br>` +
      '- по телефону +38 (044) 334 61 16 або +7 (495) 266 67 03\n',
    pendingBuyProduct: 'Ваш запит в режимі очікування.',
    buyCourse: 'Купити курс',
    orderCourse: 'Забронювати',
    orderForCourse: 'Отримати знижку',
    buy: 'Купити',
  },


  // translationForm
  translation: {
    title: "Привіт, ліфтер! за 7 днів марафону ти зібрав 7 цифр коду, щоб взяти участь в розіграші MacBook Air 2020. Вводь код сюди і чекай результатів 22 лютого. Щасти!" ,
    firstName: 'Iм’я',
    lastName: 'Прізвище',
    phone: 'Номер телефону',
    code: 'Код марафону',
    button: 'Виграти MacBook Air',
    successSendCode: 'Дякуємо, Ваш код зареєстровано. Очікуйте результатів розіграшу!',
  },


  // test module
  test: {
    passTest: 'Пройти тест',
    prevButton: 'Назад',
    nextButton: 'Далі',
    send: 'Відправити',
    meResult: 'мені результати',
  },
  //privacy
  privacy: {
    content: `<h1>Політика конфіденційності</h1>
        <p>Якщо визначення не вказано у відповідній Політиці конфіденційності, аналогічні терміни використовуються у значенні, зазначеному в Умовах обслуговування ( «Умови»).</p>
        <h2>Оператори даних</h2>
        <p>Терміни «Lifta.Space», «ми» або «нас» означають ТОВ «ББ Технолоджі» або інше агентство, яке несе відповідальність за ваші дані відповідно до застосовної Політикою конфіденційності ( «Оператор даних»).</p>
        <p>Ця сторінка інформує вас про нашу політику щодо збору, використання і розкриття призначених для користувача даних нашим веб-сайтом або мобільним додатком, про процедуру надання користувачами згоди на використання таких даних і про те, яких даних Lifta.Space є контролером.</p>
        <p>Крім того, в цій політиці конфіденційності ми інформуємо вас про тип, обсяг і мету збору і використання даних, які можуть збиратися автоматично при використанні вами веб-сайту https://lifta.space і / або мобільного додатка. Актуальна версія політики конфіденційності розміщена на нашому сайті <a href="https://lifta.space/privacy_policy.pdf" target="_blank">https://lifta.space/privacy_policy.pdf</a></p>
        <p>Якщо ви залишаєте наш веб-сайт або додаток для посилання на інший онлайн-ресурс або відвідуєте наш сайт на платформі соціальної мережі ця політика конфіденційності не поширюється на збір, використання і зберігання ваших даних контролерами інших ресурсів в Інтернеті.</p>
        <p>Зверніть увагу, що програмне забезпечення на вашому мобільному пристрої може отримати доступ до ваших даних. Наші продукти або послуги можуть посилатися на веб-сайти і послуги інших підприємств, у яких є умови конфіденційності, з якою вам може знадобитися ознайомитися при використанні таких послуг.</p>
        <p>Якщо наші продукти або послуги вбудовані в продукти і послуги наших партнерів, ми вимагаємо, щоб наші партнери забезпечували вам прозорість, необхідну для збору і використання вашої інформації. Це може включати посилання на дійсну Політику конфіденційності та пов'язані з нею додатки або прозорість інтегрованих і вбудованих комунікацій, які ідентифікують нас як постачальника послуг або контролера даних.</p>
        <p>Якщо ви не згодні з умовами цієї політики або не розумієте умови цієї політики, ми радимо вам не використовувати наш веб-сайт або мобільний додаток. Отримуючи доступ або використовуючи цей веб-сайт і / або мобільний додаток, ви погоджуєтеся з умовами цієї Політики конфіденційності.</p>
        <p>2.2. Оператор обробляє персональні дані користувачів виключно для таких цілей:</p>
        <p>2.2.1. Реєстрація Користувача на Сайті, надання Користувачеві можливості повноцінного використання сервісів Сайту.</p>
        <p>2.2.2. Ідентифікація Користувача на Сайті.</p>
        <p>2.2.3. Відображення профілю Користувача для інших користувачів Сайту з метою підтримки комунікації, в тому числі при наданні послуг дистанційним способом.</p>
        <p>2.2.4. Встановлення і підтримка зв'язку між Користувачем і Оператором, консультування з питань надання послуг.</p>
        <p>2.2.5. Виконання Оператором зобов'язань перед Користувачем за угодами, укладеними з Оператором (зокрема, Угоді користувача, іншими договорами, виконуваним на Сайті Оператора, розміщеним і доступним за адресою https://lifta.space). Виконання зобов'язань, зокрема, включає в себе інформування про дату і час проведення занять (в тому числі шляхом обдзвону і надсилання смс-повідомлень); направлення документів про освоєння освітньої програми і т.д.</p>
        <p>2.2.6. Напрямок Оператором на адресу електронної пошти Користувача повідомлень рекламного характеру; таргетування рекламних матеріалів.</p>
        <p>2.2.7. Розміщення на Онлайн-платформі Оператора, в офіційних групах соціальних мереж та інших спільнотах Оператора в мережі Інтернет, інших рекламних та інформаційних джерелах, в цілях, не пов'язаних з встановленням особи Користувача: - відеоматеріалів, отриманих в процесі надання послуг; - залишених Користувачем відгуків про послуги, що надаються Оператором.</p>
        <p>2.2.8. Поліпшення якості обслуговування користувачів і модернізація Сайту Оператора шляхом обробки запитів і заявок від Користувача.</p>
        <p>2.2.9. Статистичні та інші дослідження на основі знеособленої інформації, наданої Користувачем.</p>
        <h2>3. Умови і порядок надання згоди на обробку персональних даних Оператором</h2>
        <p>3.1. Оператор не перевіряє надані Користувачем персональні дані. У зв'язку з цим Оператор виходить з того, що при наданні персональних даних на Сайті Користувач:</p>
        <p>3.1.1. Є дієздатною особою. У разі недієздатності особи, яка використовує Сайт, згода на обробку персональних даних надається законним представником Користувача, який ознайомився і прийняв умови обробки персональних даних, зазначені в цій Політиці.</p>
        <p>3.1.2. Вказує достовірну інформацію про себе або про яку подає недієздатному особі (п. 3.1.1) в обсягах, необхідних для використання Сайту. Користувач самостійно підтримує надані персональні дані в актуальному стані. Наслідки надання Користувачем недостовірної або недостатньої інформації визначені в Угоді.</p>
        <p>3.1.3. Усвідомлює, що інформація на Сайті, що розміщується Користувачем про себе, може ставати доступною для інших користувачів Сайту, може бути скопійована і поширена такими Користувачами у випадках і на умовах передбачених Політикою.</p>
        <p>3.2. Користувач приймає умови Політики і дає Оператору інформовану і усвідомлену згоду на обробку своїх персональних даних на умовах, передбачених Політикою і застосовним законодавством.</p>
        <p>3.3. Оператор збирає, зберігає і обробляє таку інформацію про Користувача:</p>
        <ul>
          <li>Прізвище та ім'я;</li>
          <li>Адреса електронної пошти;</li>
          <li>Номер телефону;</li>
          <li>Дані про пристрій, з якого Користувач використовує послуги Оператора;</li>
          <li>Cookies;</li>
          <li>Іншу статистичну неперсоналізована інформацію про Користувача;</li>
          <li>Сплачені курси / заняття і переглянуті безкоштовні уроки.</li>
        </ul>
        <h2>4. Обробка персональних даних</h2>
        <p>4.1. Обробка персональних даних Користувача проводиться Оператором з використанням баз даних на території України.</p>
        <p>4.2. Персональні дані обробляються з використанням автоматизованих систем, за винятком випадків, коли неавтоматизована обробка персональних даних необхідна в зв'язку з виконанням вимог законодавства.</p>
        <p>4.3. Обробка персональних даних Користувача включає вчинення Оператором наступних дій: збір, запис, систематизація, накопичення, зберігання, уточнення (оновлення, зміна), витяг, використання, передача (поширення, надання, доступ), знеособлення, блокування, видалення, знищення.</p>
        <p>4.4. Збір персональних даних Користувача здійснюється у випадках, зазначених в п. 3.2 Політики.</p>
        <p>4.5. Зберігання персональних даних користувачів здійснюється на електронних носіях. При обробці персональних даних з метою виконання зобов'язань за угодами з Користувачем Оператор може витягувати персональні дані і зберігати їх на матеріальних носіях. Зберігання таких персональних даних здійснюється протягом терміну, встановленого законодавством України. Зберігання персональних даних здійснюється (в залежності від того, яка подія настане раніше):</p>
        <p>- до моменту їх видалення Користувачем у відповідному розділі;</p>
        <p>- до моменту їх знищення Оператором;</p>
        <p>- в разі надходження від Користувача відкликання згоди на обробку персональних даних або вимоги про знищення персональних даних;</p>
        <p>- до моменту закінчення терміну дії згоди.</p>
        <p>4.6. Уточнення персональних даних може здійснюватися Користувачем самостійно у відповідних розділах або Оператором на вимогу Користувача.</p>
        <p>4.7. Поширення персональних даних може здійснюватися Оператором виключно у таких випадках:</p>
        <p>4.7.1. При обробці персональних даних з метою відображення профілю Користувача для інших користувачів Сайту для підтримки комунікації, в тому числі при наданні послуг дистанційним способом. В цьому випадку персональні дані Користувача можуть бути доступні Користувачам, зареєстрованим на Сайті.</p>
        <p>4.7.2. З метою розміщення відгуків про послуги, що надаються Оператором, залишених Користувачами, в різних джерелах інформації.</p>
        <p>4.7.3. З метою розміщення відео-матеріалів, отриманих в процесі надання послуг, в різних джерелах інформації.</p>
        <p>4.8. Оператор має право здійснювати передачу персональних даних третім особам з дотриманням наступних умов:</p>
        <p>- Третя особа здійснює обробку персональних даних з використанням баз даних на території України.</p>
        <p>- Третя особа забезпечує конфіденційність персональних даних при їх обробці і використанні; зобов'язується не розкривати іншим особам, а також не поширювати персональні дані користувачів без їх згоди.</p>
        <p>- Третя особа гарантує дотримання наступних заходів щодо забезпечення безпеки персональних даних при їх обробці: використання засобів захисту інформації; виявлення і фіксація фактів несанкціонованого доступу до персональних даних та вжиття заходів з відновлення персональних даних; обмеження доступу до персональних даних; реєстрація та облік дій з персональними даними; контроль і оцінка ефективності застосовуваних заходів щодо забезпечення безпеки персональних даних.</p>
        <p>- Передавати статистичні неперсоналізована дані за якими неможливо ідентифікувати Користувача для поліпшення рівня послуг, що надаються, маркетингових компаній інших рекламних та інших цілях.</p>
        <p>4.8.1. Цілі передачі персональних даних:</p>
        <p>- Оптимізація оператором розсилки повідомлень інформаційного та рекламного характеру. В цьому випадку третій особі передаються такі персональні дані користувачів: прізвище, ім'я, по батькові; номер телефону; Адреса електронної пошти.</p>
        <p>- Напрямок Користувачам інформаційних розсилок про нові можливості в сфері освіти і розвитку. В цьому випадку третій особі передаються такі персональні дані користувачів: прізвище, ім'я, по батькові; Адреса електронної пошти. Кожна інформаційна розсилка надає Користувачеві можливість відмовитися від отримання таких розсилок.</p>
        <p>- Виконання умов договору перед Користувачами Сайту з залученням третіх осіб. Обсяг переданих даних визначається в умовах договору.</p>
        <p>- В інших рекламних цілях або в цілях поліпшення якості обслуговування.</p>
        <p>4.8.2. Перелік дозволених способів обробки персональних даних: збір, запис, систематизація, накопичення, зберігання, уточнення (оновлення, зміна), витяг, використання, передача (поширення, надання, доступ), знеособлення, блокування, видалення, знищення. Третій особі заборонено здійснювати передачу і поширення персональних даних.</p>
        <p>4.8.3. Знищення персональних даних здійснюється Оператором у наступних випадках:</p>
        <p>- видалення Користувачем персональних даних у відповідному розділі;</p>
        <p>- надходження від Користувача відкликання згоди на обробку персональних даних;</p>
        <p>- отримання від Користувача вимоги про знищення персональних даних;</p>
        <p>- закінчення строку дії згоди.</p>
        <h2>5. Заходи для захисту персональних даних</h2>
        <p>Оператор приймає необхідні і достатні правові, організаційні та технічні заходи для захисту інформації, що надається Користувачами, від неправомірного або випадкового доступу, знищення, перекручення, блокування, копіювання, поширення, а також від інших неправомірних дій з нею третіх осіб. Такі дії, зокрема, включають:</p>
        <p>5.1. Призначення особи, відповідальної за обробку персональних даних;</p>
        <p>5.2. Застосування організаційних і технічних заходів щодо забезпечення безпеки персональних даних при їх обробці в інформаційних системах;</p>
        <p>5.3. Контроль фактів несанкціонованого доступу до персональних даних та вжиття заходів щодо недопущення подібних інцидентів у подальшому;</p>
        <p>5.4. Контроль за прийнятими заходами щодо забезпечення безпеки персональних даних і рівнем захищеності інформаційних систем персональних даних.</p>
        <h2>6. Права користувачів</h2>
        <p>Користувач має право:</p>
        <p>6.1. На свій розсуд надавати Оператору персональні дані для їх обробки на умовах, зазначених в Політиці;</p>
        <p>6.2. Самостійно вносити зміни і виправлення в свої персональні дані в особистому кабінеті за умови, що такі зміни і виправлення містять актуальну і достовірну інформацію;</p>
        <p>6.3. Видаляти персональні дані в особистому кабінеті;</p>
        <p>6.4. Звертатися до Оператору з вимогами, в тому числі щодо уточнення персональних даних; про блокування або знищення персональних даних, якщо такі дані є неповними, застарілими, недостовірними, незаконно отриманими або не є необхідними для заявленої мети обробки.</p>
        <p>6.5. На підставі запиту отримувати від Оператора інформацію, що стосується обробки його персональних даних та яка передбачена відповідним законодавством.</p>
        <h2>7. Звернення користувачів</h2>
        <p>7.1. Користувач має право направляти Оператору свої запити і вимоги (далі - Звернення), в тому числі щодо використання його персональних даних, а також відкликання згоди на обробку персональних даних. Звернення може бути направлено наступними способами:</p>
        <p>7.1.1. У письмовій формі за адресою Оператора;</p>
        <p>7.1.2. У формі електронного документа (скан-, фотокопія документа). Документ повинен бути спрямований з адреси електронної пошти Користувача, вказаної ним при реєстрації на Сайті або в договорі в якості уповноваженого адреси електронної пошти, за адресою електронної пошти Оператора: lifta.space@bg.solutions</p>
        <p>7.2. Таке звернення повинно містити: 1) ПІБ заявника. 2) Детальний опис суті Звернення. 3) Правові або фактичні підстави для реалізації дій, зазначених у зверненні.</p>
        <p>7.3. Оператор розглядає Звернення Користувача в наступному порядку:</p>
        <p>7.3.1 Перевіряється наявність всіх обов'язкових реквізитів Звернення;</p>
        <p>7.3.2. Перевіряється обґрунтованість Звернення;</p>
        <p>7.3.3. Надається відповідь на Звернення. Відповідь може направляти в тій же формі що і Звернення або в зручній Оператору формі.</p>
        <p>7.4. Оператор розглядає всі Звернення і надає на них відповіді (виконує Звернення) протягом 30 днів.</p>
        <h2>8. Зміна Політики</h2>
        <p>8.1. Оператор залишає за собою право вносити зміни в Політику. На Користувача лежить обов'язок кожного разу при використанні Сайту знайомитися з текстом Політики.</p>
        <p>8.2. Нова редакція Політики вступає в силу з моменту її розміщення у відповідному розділі сайту Оператора. Продовження користування Сайтом або його сервісами після публікації нової редакції Політики означає прийняття Політики і її умов Користувачем. У разі незгоди з умовами Політики Користувач повинен негайно припинити використання Сайту і його сервісів.</p>`
  },
  terms: {
    content: `<h1>Угода використання</h1>
        <p>Увага! Якщо Ви не згодні з умовами цієї угоди користувача, що не реєструйтеся на Сайті і не використовуйте його сервіси.</p>
        <h2>1. Терміни та визначення</h2>
        <p>1.1. У цьому призначеному для користувача угоді ( «Угода»), якщо з його тексту прямо не випливає інше, такі терміни матимуть такі значення:</p>
        <p>ТОВ «ББ Технолоджі» ( «Lifta.Space», «ми», «нас», «наш», «Адміністратор») надає вам свої послуги (описані нижче) через свій веб-сайт, розташований за адресою https: // lifta .space і через його мобільні додатки і пов'язані служби ( «Сайт»), що містить сукупність інформації, текстів, графічних елементів, дизайну, зображень, фото, аудіо та відеоматеріалів, і інших охоронюваних результатів інтелектуальної діяльності і засобів індивідуалізації адміністратора в відповідно до таких угодою. БУДЬ ЛАСКА, УВАЖНО ПРОЧИТАЙТЕ ЦІ УМОВИ ОБСЛУГОВУВАННЯ, ОСКІЛЬКИ ВОНИ РЕГУЛЮЮТЬ ВАШЕ ВИКОРИСТАННЯ САЙТУ ТА ПОСЛУГ.</p>
        <p>«Користувач», «Кінцевий споживач» - Особа, що уклала з Адміністратором дану угоду користувача шляхом акцепту оферти, розташованої в мережі Інтернет за мережевою адресою https://lifta.space/ua/terms_of_use.pdf. 
«Автор» - Користувач, чиї статті (твори) опубліковані на Сайті в розділі Статті. Автором може стати тільки Користувач, який прийняв умови публікації на Сайті та відповідає вимогам, встановленим Адміністратором.</p>
        <p>«Викладач» - Фізична особа, яка виступає в якості доповідача, лектора, експерта, тренера, автора, що проводить заняття в рамках програм навчання або курсів, що представляє інформацію по темі занять і, у разі необхідності, що коментує виконання Користувачем завдань, які є практичною частиною відповідного заняття.</p>
        <p>«Партнер» - Фізичне і / або юридична особа, яка розмістила свою пропозицію про надання послуг Користувачам. Умови надання послуг кожен Партнер визначає самостійно.</p>
        <p>«Відеокурси» - Сукупність аудіовізуальних (відеолекції), текстових, графічних матеріалів і програм для ЕОМ (програмні засоби для виконання тестових завдань), об'єднаних спільною темою, призначених для навчання Користувача або Кінцевого споживача з метою отримання ними нових або розвитку наявних навичок і знань по зазначеної теми. Відеокурс не є повністю або в частині Програмою навчання.</p>
        <p>«Програма навчання» - Сукупність аудіовізуальних (вебінари), текстових, графічних матеріалів і програм для ЕОМ (програмні засоби для виконання тестових завдань), об'єднаних спільною темою, призначених для навчання Користувача з метою отримання ним нових або розвитку наявних навичок і знань, необхідних для освоєння зазначеної в темі програми навчання.</p>
        <p>«Безкоштовне заняття» - Аудіовізуальний матеріал (вебінар), доступ до якого, а також до всіх матеріалів якого, надається Адміністратором безкоштовно для всіх користувачів, що зареєструвалися на участь у такому вебінарі. Адміністратор проводить безкоштовні заняття для всіх зацікавлених користувачів. Безкоштовне заняття не є повністю або в частині Програмою навчання.</p>
        <p>«Особистий кабінет» - Сукупність захищених сторінок Сайту, створених в результаті реєстрації Користувача, використовуючи які Користувач має можливість оплати доступу до Бібліотеки курсів і програм навчання адміністратора можливість доступу до безкоштовних занять, можливість отримання доступу до Бібліотеки курсів і програм навчання в повному обсязі після оплати такого доступу, можливість отримання інформації про них, можливість зміни відомостей про Користувача, пароля, а також здійснення інших дій, передбачених явними функціями Особистого кабінету. Доступ в Особистий кабінет здійснюється шляхом внесення облікових даних в передбачені для цього поля на Сайті.</p>
        <p>«Центр розвитку кар'єри» - Розділ Сайту, призначений для допомоги Користувачам і іншим третім особам у професійній орієнтації і в працевлаштуванні після проходження Програм навчання Адміністратора. Центр розвитку також призначений для допомоги роботодавцям в пошуку кваліфікованих співробітників серед успішно завершили Програми навчання користувачів.</p>
        <p>1.2. Всі інші терміни та визначення, що зустрічаються в тексті Угоди, тлумачаться Сторонами відповідно до чинного законодавства і що склалися в мережі Інтернет звичайних правил тлумачення відповідних термінів</p>
        <p>1.3. Назви заголовків (статей) Угоди призначені виключно для зручності користування текстом Угоди і буквального юридичного значення не мають.</p>
        <h2>2. ПРЕДМЕТ УГОДИ</h2>
        <p>2.1. Адміністратор надає Користувачеві право використовувати Сайт, особистий кабінет, сервіси Сайту за їх прямим призначенням, в тому числі шляхом здійснення доступу до Сайту, Особистого кабінету за допомогою персональних комп'ютерів і мобільних пристроїв і використання явних функцій Сайту, особистого кабінету на умовах безоплатної простої (невиключної) ліцензії на території доступу до Сайту, особистого профілю і роду їх діяльності, на строк, протягом якого Сайт, особистий кабінет і їх функції залишаються доступні для Користувача.</p>
        <p>2.2. Адміністратор надає Користувачеві послуги з надання доступу до Сайту, відеокурсу, програмами навчання, безкоштовних занять, статей з метою організації самонавчання Користувача навичкам з певної теми або необхідних для освоєння</p>
        <h2>3. ПОРЯДОК РЕЄСТРАЦІЇ НА САЙТІ</h2>
        <p>3.1. Адміністратор надає Користувачеві до його реєстрації на Сайті доступ до інформації про Сайт, курси, програми навчання, про бібліотеку курсів, про безкоштовні заняття, а також доступ до оглядів та статей, розміщених на сайті в розділі «Корисні матеріали».</p>
        <p>3.2. Після реєстрації на Сайті Користувачеві на додаток до доступу, вказаною в пункті 3.1 Угоди, надається доступ до безкоштовних занять, надається можливість придбання за плату доступу до програм навчання і до бібліотеки курсів, доступ і участь в рейтингу користувачів, періодична підписка на послуги, а також доступ до інших функцій Сайту і Особистого кабінету.</p>
        <p>3.3. По завершенні реєстрації на Сайті Користувачеві надається доступ до Особистого кабінету за обліковими даними - адресою електронної пошти та паролю. Пароль може бути змінений Користувачем в будь-який час після реєстрації.</p>
        <p>3.4. На Користувача лежить обов'язок забезпечити безпеку і збереження пароля. При втраті або компрометації пароля, а також в разі незаконного заволодіння третіми особами доступу до Особистого кабінету, Користувач зобов'язаний негайно повідомити про це Адміністратора за телефоном або на адресу електронної пошти lifta.space@bg.solutions. До моменту надходження такого повідомлення всі дії, вчинені з використанням Особистого кабінету Користувача будуть вважатися досконалими самим Користувачем.</p>
        <h2>4. ПОРЯДОК КОРИСТУВАННЯ ОСОБИСТИМ КАБІНЕТОМ І САЙТОМ</h2>
        <p>4.1. По завершенні реєстрації Користувач отримує доступ до Особистого кабінету. В Особистому кабінеті Користувача відображена інформація про розпочаті і завершені Користувачем курси і програми навчання, про можливості доступу Користувача до Бібліотеки курсів за плату і до безкоштовних занять, про заходи що стимулююсь, про партнерів Адміністратора, що надають Користувачам безкоштовно або зі знижкою зазначені на Сайті товари і / або послуги, про пропоновані вакансії партнерів адміністратора про проведені платежі в рахунок оплати доступу до бібліотеки курсів і програм навчання.</p>
        <p>4.2. В Особистому кабінеті Користувач має можливість оплатити доступ до бібліотеки курсів і програм навчання, а також оплатити місячну або річну підписку на наші Послуги.</p>
        <p>4.3. Користувачеві доступні статті та інші матеріали, представлені на Сайті, можливість залишати коментарі до таких статей і матеріалів.</p>
        <p>4.4. Для доступу до певних курсів, програм навчання та безкоштовних занять від Користувача може знадобитися дотримання спеціальних технічних вимог адміністратора розміщених на Сайті.</p>
        <p>4.5. Адміністратор має право в будь-який час на свій розсуд щодо курсів і програм навчання, в тому числі щодо безкоштовних занять, змінювати теми окремих занять, змінювати зміст занять, кількість, найменування і вид матеріалів, що входять до складу курсів, програм навчання, безкоштовних занять, дати і час проведення відповідних занять і їх тривалість, а також, в разі необхідності, замінювати Викладачів на окремих заняттях. При цьому випадку Адміністратор гарантує, що подібні зміни не призведуть до погіршення якості курсів, програм навчання і безкоштовних занять. Інформація про такі зміни публікується на Сайті безпосередньо до початку проведення відповідного заняття, в якому відбулися такі зміни.</p>
        <p>4.6. Користувач має можливість брати участь в партнерській програмі Адміністратора на умовах і в порядку, визначених Адміністратором і зазначених на відповідній сторінці сайту.</p>
        <p>4.7. Користувач зобов'язаний користуватися Сайтом і Особистим кабінетом сумлінно, не порушуючи чинне законодавство, права і свободи третіх осіб, норми моралі і моральності. У разі необхідності Корпоративний користувач зобов'язується забезпечити дотримання відповідного законодавства, норм моралі і моральності Кінцевими споживачами.</p>
        <h2>5. УМОВИ І ПОРЯДОК ДОСТУПУ ДО ВІДЕОКУРСІВ</h2>
        <p>5.1. Користувачеві доступна інформація про кожний відеокурс, його опис, про теми та тривалості занять, про тривалість курсу, викладач курсу, про рейтинг курсу. Користувачеві доступна інформація про вартість курсів і доступна можливість оплати кожного відеокурсу.</p>
        <p>5.2. Доступ до кожного відеокурсу надається Користувачам, які сплатили в порядку, встановленому Угодою та зазначеному на відповідній сторінці Сайту, вартість відеокурсу. Доступ надається через особистий кабінет.</p>
        <p>5.3. Курси передбачають відеолекції і програмні засоби (домашні завдання) у вигляді тесту для перевірки Користувачем засвоєної інформації, наданої в відеолекції. Тестування проводить Викладач в ручному режимі.</p>
        <p>5.4. По завершенню курсу Користувач має право оцінити курс і залишити коментар про курс. Адміністратор залишає за собою право видалити коментарі користувачів, визнані Адміністратором суперечать чинному законодавству або на власний розсуд.</p>
        <h2>6. ПОРЯДОК ДОСТУПУ ДО ПРОГРАМ НАВЧАННЯ</h2>
        <p>6.1. Користувачеві доступна інформація про програми навчання, їх короткий опис, опис у вигляді поширених запитань і відповідей, інформація про викладачів програми навчання, про склад програми навчання, що містить найменування, тривалість, склад кожного з етапів (тем) програми навчання, що включає кількість і назву занять, дату і час їх проведення, інформацію про викладачів по таких занять. Користувачеві доступна докладна інформація про кожне заняття програми навчання, опис його теоретичної і практичної частини, а також навичках, які пропонується освоїти користувачеві в рамках такого заняття. Доступ до програм навчання надається Користувачам, які сплатили такий доступ в установленому Угодою та відповідною офертою, розташованої за мережевою адресою https://lifta.space/terms_of_use.pdf, порядку.</p>
        <p>6.2. Програми навчання, на які Користувач зареєструвався, але які не сплатив, відображаються в Особистому кабінеті як неоплачені. Програми навчання, на які Користувач зареєструвався і які оплатив, доступні в Особистому кабінеті в повному обсязі з усіма відповідними матеріалами з урахуванням майбутніх і пройдених занять.</p>
        <p>6.3. Кожне заняття програми навчання є виідеоуроком (або записом вебінару), дата і час проведення якого відображається на відповідній сторінці на Сайті і в Особистому кабінеті, а також інші матеріали, в тому числі кошти перевірки набутих навичок. Минулі відеоуроки можуть зберігатися в Особистому кабінеті в вигляді відеозаписів в залежності від умов програми навчання. Участь користувача в вебінарі, перегляд відеозапису вебінарів, доступ до матеріалів і проходження тестів і виконання домашніх завдань здійснюється за допомогою Особистого кабінету.</p>
        <p>6.4. Що стосується кожного заняття програми навчання можуть бути передбачені методичні матеріали, що містять список літератури, рекомендованої для засвоєння інформації, отриманої Користувачем в такому занятті. При цьому Сторони визнають і розуміють, що такий список літератури не є рекламою. Зазначені матеріали доступні Користувачеві, яка оплатила відповідну програму навчання протягом проведення програми навчання і після - протягом терміну, встановленого Адміністратором.</p>
        <h2>7. ПОРЯДОК ДОСТУПУ ДО БЕЗКОШТОВНИМ ЗАНЯТЬ</h2>
        <p>7.1. Інформація про майбутні безкоштовні заняття, темаи таких занять, дату і час їх проведення, про викладача і кількість учасників розміщується на відповідній сторінці сайту.</p>
        <p>7.2. Користувач має можливість зареєструватися на майбутнє безкоштовне заняття, перейшовши за відповідним посиланням і заповнивши реєстраційну форму. Про успішну реєстрацію на безкоштовне заняття Користувач інформується повідомленням на сторінці Сайту, що відкрилася після переходу за посиланням, і повідомленням, спрямованим на вказану в Особистому кабінеті адресу електронної пошти.</p>
        <p>7.3. Інформація про минулі безкоштовні заняття, що містять відеозапис вебінару безкоштовного заняття та інші матеріали безкоштовного заняття, так само як і теми таких занять, дата і час їх проведення, викладачі та кількість учасників розміщуються на відповідній сторінці сайту.</p>
        <h2>9. ПОРЯДОК ДОСТУПУ ДО СТАТЕЙ. ПУБЛІКАЦІЯ В РОЗДІЛІ СТАТТІ</h2>
        <p>9.1. У розділі Статті Користувачі, а також будь-які інші треті особи можуть ознайомитися з інформаційними та інформаційно-аналітичними матеріалами, що публікуються в розділі. Доступ до таких матеріалів не обмежений.</p>
        <p>9.2. Користувач, який бажає опублікувати свої матеріали в розділі Статті, повинен ознайомитися з умовами публікації, подати заявку на публікацію в розділі, узгодити свої матеріали з редактором розділу.</p>
        <p>9.3. Користувач, матеріали якого опубліковані в розділі Статті, є Автором.</p>
        <p>9.4. Публікація узгоджених з редактором розділу матеріалів проводиться Адміністратором на свій розсуд і на безоплатній основі, тобто без справляння плати з Автора. Адміністратор має право відмовити Користувачеві в публікації як неузгоджених, так і попередньо погоджених з редактором розділу матеріалів без пояснення причин.</p>
        <p>9.5. Користувач розуміє і погоджується з тим, що Адміністратор не виплачує і не зобов'язується виплатити Авторам будь-яку винагороду за публікацію матеріалів.</p>
        <h2>10. ЦЕНТР РОЗВИТКУ КАР'ЄРИ</h2>
        <p>10.1. Центр розвитку кар'єри призначений для допомоги Користувачам, які успішно закінчили програми навчання, у професійній орієнтації, а також для допомоги роботодавцям (юридичним особам і індивідуальним підприємцям) у пошуку працівників серед користувачів, які успішно закінчили програми навчання.</p>
        <p>10.2. Користувачі, роботодавці, а також будь-які треті особи мають право задати Адміністратору будь-яке питання щодо профорієнтації та пошуку роботи, натиснувши кнопку "Написати повідомлення" і заповнивши всі необхідні поля форми.</p>
        <p>10.3. Адміністратор має право не відповідати на запитання без пояснення причин.</p>
        <p>10.4. Натискаючи кнопку "Відправити" у формі "Повідомлення в центр розвитку кар'єри" фізична особа, яка вказала в даній формі свої персональні дані (ім'я та e-mail), дає згоду на обробку таких персональних даних Адміністратором з метою реалізації функцій розділу центру розвитку кар'єри.</p>
        <h2>12. СТИМУЛЮЮЧІ ЗАХОДИ. ПАРТНЕРИ</h2>
        <p>12.1. Адміністратор має право проводити будь-які стимулюючі заходи (в будь-якій формі і з застосуванням будь-яких технологій), розміщуючи інформацію про такі заходи на будь-яких сторінках і в будь-яких розділах Сайту на свій розсуд.</p>
        <p>12.2. Партнери мають право розміщувати, в тому числі, пільгові пропозиції, призначені тільки для користувачів. Адміністратор не несе жодної відповідальності за зміст пропозицій Партнерів про продаж товарів і / або надання послуг Користувачам, а також не відповідає за якість таких товарів / послуг.</p>
        <h2>13. ФІНАНСОВІ УМОВИ</h2>
        <p>13.1. Адміністратор надає доступ без справляння плати до безкоштовних занять, до матеріалів в розділі Статті, до пропозицій Партнерів, до функціоналу розділів Центр розвитку кар'єри та Експерти, а також до всіх інших функціональних можливостей сайту, в описі яких відсутній або із суті яких не передбачається стягування плати за використання.</p>
        <p>13.2. Адміністратор встановлює вартість кожної програми навчання шляхом публікації такої вартості на сторінці з описом цієї програми навчання. Адміністратор має право змінювати вартість програми навчання в будь-який час на свій розсуд. При цьому вартість такої програми навчання, яка вже сплачена Користувачем, не змінюється ні в бік збільшення, ні в бік зменшення. Нова ціна поширюється на правовідносини Адміністратора та Користувача, що виникли після її зміни.</p>
        <p>13.3. Адміністратор встановлює вартість відеокурсу шляхом публікації такої вартості на відповідній сторінці сайту. Адміністратор має право змінювати вартість відеокурсу в будь-який час на свій розсуд. При цьому вартість такого відеокурсу, вже сплачена Користувачем, не змінюється ні в бік збільшення, ні в бік зменшення. Нова ціна поширюється на правовідносини Адміністратора та Користувача, що виникли після її зміни.</p>
        <p>13.4. Адміністратор має право надавати окремим Користувачам знижки на оплату вартості програм навчання або відеокурсів. Розмір знижки, порядок і умови її надання Адміністратор встановлює на свій розсуд.</p>
        <p>13.5. Адміністратор має право надавати Користувачу будь-які додаткові послуги без справляння окремої плати.</p>
        <h2>ПОРЯДОК ОПЛАТИ ПРОГРАМИ НАВЧАННЯ</h2>
        <p>13.6. За загальним правилом придбання Користувачем доступу до програми навчання здійснюється на умовах передоплати в розмірі повної вартості програми навчання.</p>
        <p>13.7. Залежно від дати здійснення оплати програми навчання вартість програми навчання може змінюватися. Інформація про актуальну вартість вказується на сторінці відповідної програми навчання.</p>
        <p>13.8. На сторінці програми навчання Користувач має можливість зареєструватися на програму навчання, ввівши достовірні дані (ім'я, прізвище, номер телефону, адреса електронної пошти), вказавши, хто буде проводити оплату (Користувач або юридична особа).</p>
        <p>13.9. Якщо Користувач вказав, що оплата буде проводитися Користувачем, то у вікні Користувач вибирає спосіб оплати (систему платежів) та вчиняє дії, передбачені правилами відповідних електронних систем платежів і процесингових компаній. Моментом оплати в цьому випадку вважається момент позитивного результату авторизації платежу в обраній системі платежів.</p>
        <p>13.11. Якщо Користувач вказав, що оплата буде проводитися юридичною особою, то Користувач направляє Адміністратору інформацію, яку Адміністратор запросив у Користувача в листі, направленому Користувачеві після переходу за посиланням «Записатися». Користувач направляє зазначену в запиті інформацію Адміністратору за адресою та у спосіб, зазначеним в тому ж листі. Оплата проводиться в узгодженій валюті шляхом перерахування грошових коштів на розрахунковий рахунок Адміністратора, зазначений в надісланому Користувачеві рахунку в термін, зазначений в тому ж рахунку. Моментом оплати вважається момент надходження грошових коштів на розрахунковий рахунок Адміністратора.</p>
        <p>13.12. Користувач має право одноразово придбати і оплатити як одну програму навчання, так і відразу кілька програм.</p>
        <p>13.13. Сторони мають право узгодити інші унікальні умови за письмовою згодою Сторін.</p>
        <h2>ПОРЯДОК ОПЛАТИ ВІДЕОКУРСІВ</h2>
        <p>13.14. На сторінці відповідного відеокурсу Користувач має можливість оплатити доступ до одного відеокурсу. Доступ до кожного відеокурсу оплачується окремо.</p>
        <p>13.15. Після послідовного натискання Користувачем кнопок "Купити доступ до курсу" на сторінці відповідного відеокурсу, Користувач автоматично перенаправляється на сторінку оплати і після вибору сервісу для оплати вчиняє дії, передбачені правилами відповідних електронних систем платежів і процесингових компаній. Моментом оплати в цьому випадку вважається момент позитивного результату авторизації платежу в обраній системі платежів.</p>
        <h2>ПОРЯДОК ПОВЕРНЕННЯ КОШТІВ</h2>
        <p>13.16. Користувач може отримати відшкодування в разі їх помилкової оплати, або за своїм одностороннім бажанням, але в жодному разі не пізніше 3-х днів до початку відеокурсу і / або програми навчання. Користувач приймає, що банки та / або процесингові системи можуть знімати додаткові комісії за обробку транзакцій, що буде здійснено коштом  клієнта і фінальна сума може відрізнятися від раніше сплаченої Користувачем.</p>
        <h2>ПОЛІТИКА БЕЗПЕКИ ПЕРЕДАЧІ РЕКВІЗИТІВ ПЛАТІЖНОЇ КАРТИ.</h2>
        <p>13.17. При оплаті банківською картою, обробка платежу (включаючи введення номера карти) відбувається на захищеній сторінці процесингової системи. Це означає, що ваші конфіденційні дані (реквізити карти, реєстраційні дані та ін.) Не надходять до нас, їх обробка повністю захищена, і ніхто не може отримати персональні і банківські дані клієнта. При роботі з даними платіжних карт застосовується стандарт інформаційної безпеки (Data Security Standard (PCI DSS), розроблений міжнародними платіжними системами Visa та MasterCard. Data Security Standard (PCI DSS) покликаний забезпечувати безпечну обробку реквізитів держателів платіжних карт.</p>
        <h2>14. ІНТЕЛЕКТУАЛЬНА ВЛАСНІСТЬ І ОБМЕЖЕННЯ ПІДЧАС КОРИСТУВАННЯ САЙТОМ</h2>
        <p>14.1. Використовуючи Сайт, Користувач визнає і погоджується з тим, що весь без виключень вміст сайту (включно, але не обмежуючись: аудіовізуальні твори, текстові та графічні матеріали, програми для ЕОМ, товарні знаки / знаки обслуговування, логотипи), структура вмісту Сайту, програмний код сайту і / або його частин є результатами інтелектуальної діяльності, виняткове право на які в повному обсязі належить Адміністратору. Виключне право, а також інші інтелектуальні права (якщо є) на вищевказані результати інтелектуальної діяльності не переходять до Користувача в результаті користування Сайтом і укладення Угоди.</p>
        <p>14.2. Реєструючись на сайті, подаючи заявку Адміністратору на публікацію матеріалів в розділі Статті та відправляючи будь-які матеріали на перевірку і узгодження Адміністратору, Автор передає не тільки ліцензію, без оплатну, без обмеження по території, на будь-які такі матеріали Адміністратору в повному обсязі. При цьому Автор визнає і підтверджує, що з моменту переходу до Адміністратора такої ліцензії на матеріал, Адміністратор має право поширювати, копіювати, переробляти і іншим чином необмежено використовувати матеріал і / або його частини на свій розсуд. Автор і Адміністратор також розуміють і визнають, що право авторства, а також інші немайнові інтелектуальні права на матеріал є невідчужуваними і належать Автору.</p>
        <p>14.3. Користувачеві забороняється копіювати, модифікувати, змінювати, видаляти, доповнювати, публікувати, передавати містяться на Сайті результати інтелектуальної діяльності, створювати похідні роботи, виготовляти або продавати товари / надавати послуги на їх основі, відтворювати, відображати або будь-яким іншим чином експлуатувати або використовувати такі результати інтелектуальної діяльності без прямого дозволу Адміністратора. При цитуванні матеріалів сайту, якщо це прямо передбачено функціями Сайту, Користувач зобов'язується вказувати посилання на Сайт.</p>
        <p>14.4. Щоб уникнути сумнівів, Користувачеві забороняється:</p>
        <p>- копіювати і / або поширювати будь-яку інформацію (включаючи частини і компоненти занять за програмою навчання, відеокурсів, статей, логотипів і товарних знаків / знаків обслуговування), отриману на Сайті, крім випадків, коли така функція прямо передбачена на Сайті (наприклад, "Поділитися" / "Зробити репост");</p>
        <p>- використовувати інформацію, отриману на Сайті, для здійснення комерційної діяльності, отримання прибутку, або для використання будь-яким таким, що суперечить закону способом, за винятком навичок, набутих на основі отриманої відповідно до Угоди інформації;</p>
        <p>- копіювати, або іншим способом використовувати програмну частину (програмний код або частина коду) Сайту, а також його дизайн;</p>
        <p>- розміщувати на Сайті персональні дані третіх осіб без їх згоди, в тому числі, але не обмежуючись: домашні адреси, телефони, паспортні дані, адреси електронної пошти;</p>
        <p>- розміщувати на Сайті комерційну рекламу, комерційні пропозиції, агітаційну інформацію і будь-яку іншу нав'язливу інформацію, крім випадків, коли розміщення такої інформації прямо погоджено з Адміністратором;</p>
        <p>- змінювати яким би то не було способом програмну частину Сайту, здійснювати дії, спрямовані на зміну функціонування і працездатності Сайту;</p>
        <p>- ображати і іншим чином порушувати права і свободи інших користувачів Сайту, третіх осіб, а також груп осіб;</p>
        <p>- використовувати нецензурну лайку, здійснювати або поширювати інформацію, що містить заклики до масових безладів, здійснення екстремістської діяльності, участі в масових (публічних) заходах, що проводяться з порушенням встановленого порядку, поширювати інформацію, необхідну для отримання результатів інтелектуальної діяльності.</p>
        <h2>15. ПЕРСОНАЛЬНІ ДАНІ І ЇХ ВИКОРИСТАННЯ</h2>
        <p>15.1. Мета, порядок, терміни та інші суттєві умови, при яких Адміністратор обробляє персональні дані користувачів, визначені в Політиці Конфіденційності, розташованої за мережевою адресою https://lifta.space/ua/privacy_policy.pdf.</p>
        <h2>16. ВІДПОВІДАЛЬНІСТЬ</h2>
        <p>16.1. У разі порушення Користувачем умов Угоди, чинного законодавства, норм моралі і моральності, або технічних вимог Адміністратор має право заблокувати або видалити особистий кабінет, заборонити або обмежити доступ за обліковими даними Користувача до певних або всіх функцій Сайту, програмами навчання, курсів, безкоштовних занять.</p>
        <p>16.2. У разі порушення Користувачем порядку оплати, встановленого розділом 13 Угоди, Адміністратор має право на свій розсуд повністю заблокувати доступ Користувача до програм навчання з дня, наступного за днем прострочення оплати, розірвати в односторонньому порядку договір про надання платних освітніх послуг і повернути Користувачу надміру сплачені грошові кошти пропорційно кількості занять, доступних Користувачу в особистому кабінеті.</p>
        <p>16.3. При виявленні випадків надання Користувачем доступу до особистого кабінету третім особам, Адміністратор за своїм вибором застосовує заходи, передбачені пунктом 16.1. Угоди. При цьому Адміністратор має право повністю заблокувати доступ Користувача до особистого кабінету, програмами навчання та курсів, в тому числі, заздалегідь оплаченим Користувачем.</p>
        <p>16.4. Порушення Користувачем умов Угоди, що спричинило несприятливі наслідки для Адміністратора (збиток, адміністративна та інша відповідальність, попередження правоохоронних та інших органів виконавчої влади, претензії третіх осіб), є підставою для Адміністратора припинити доступ Користувача до відеокурсу, при цьому грошові кошти в рахунок оплати курсу поверненню не підлягають і є штрафом за дії Користувача. Аналогічні заходи можуть бути застосовані Адміністратором щодо програми навчання.</p>
        <p>16.5. У разі порушення Користувачем інтелектуальних прав Адміністратора, а також обмежень щодо використання Сайту, зазначених в розділі 14 Угоди, Адміністратор має право в будь-який момент без попередження заблокувати такому Користувачеві повністю або частково доступ на Сайт і в Особистий кабінет, а також, на свій розсуд, звернутися до відповідного Користувача з вимогою про усунення порушень і / або вимагати усунути порушення в судовому порядку.</p>
        <p>16.6. Адміністратор не відповідає за працездатність Сайту і не гарантує його безперебійної роботи. Адміністратор також не гарантує збереження інформації, розміщеної на Сайті і можливості безперебійного доступу до програм навчання, курсів та інших матеріалів.</p>
        <p>16.7. Користувач використовує Сайт в тому вигляді, в якому він представлений, на власний ризик. Адміністратор не гарантує Користувачеві досягнення будь-яких результатів внаслідок використання Сайту.</p>
        <h2>17. ОСОБЛИВІ УМОВИ</h2>
        <p>17.1. Сайт може містити посилання на інші сайти в мережі Інтернет (сайти третіх осіб). Зазначені треті особи та зміст їхніх сайтів / контенту не перевіряються Адміністратором на відповідність тим або іншим вимогам (достовірності, повноти, законності і т.п.). Адміністратор не несе відповідальності за будь-яку інформацію, матеріали, розміщені на сайтах третіх осіб, до яких Користувач отримує доступ з використанням Сайту, в тому числі за будь-які думки або твердження, виражені на сайтах третіх осіб, рекламу і т.п., а також за доступність таких сайтів або контенту і наслідки їх використання Користувачем.</p>
        <p>17.2. Адміністратор не гарантує, що Сайт відповідає вимогам Користувача, що доступ до Сайту буде надаватися безперервно, швидко, надійно і без помилок.</p>
        <p>17.3. Програмно-апаратні помилки як на стороні адміністратора, так і на стороні Користувача, що призвели до неможливості отримання Користувачем доступу до Сайту та / або відеокурсу і програмами навчання, є обставинами непереборної сили і підставою звільнення від відповідальності за невиконання зобов'язань Адміністратором щодо Угоди.</p>
        <h2>18. ПОРЯДОК ВИРІШЕННЯ СПОРІВ</h2>
        <p>18.1. Усі спори, розбіжності і претензії, які можуть виникнути у зв'язку з укладенням, виконанням, зміною, розірванням чи визнанням недійсним Угоди, Сторони будуть прагнути вирішити шляхом переговорів. Сторона, у якої виникли претензії і / або розбіжності, направляє іншій Стороні повідомлення із зазначенням виникли претензій і / або розбіжностей. Повідомлення Користувача про виниклі претензії і / або розбіжності має бути направлено Користувачем Адміністратору за електронною адресою lifta.space@bg.solutions, а також продубльовано в письмовому вигляді за адресою Адміністратора, вказаною в цій Угоді. Повідомлення Адміністратора про виниклі претензії і / або розбіжності направляється Адміністратором Користувачеві за електронною адресою Користувача, вказаною при реєстрації на Сайті, або іншим чином, що дозволяє зафіксувати факт відправки.</p>
        <p>18.2. У разі, якщо відповідь на повідомлення не буде отриманий направила повідомлення Стороною протягом 30 (тридцяти) робочих днів з дати направлення відповідного повідомлення, або якщо Сторони не дійдуть згоди щодо виниклих претензій і / або розбіжностей, спір підлягає вирішенню в судовому порядку за місцем знаходження Адміністратора.</p>
        <h2>19. ЗМІНА УМОВ УГОДИ</h2>
        <p>19.1. Адміністратор має право в будь-який момент на свій розсуд в односторонньому порядку змінювати умови Угоди, при цьому такі зміни набирають чинності в момент опублікування нової версії Угоди на Сайті.</p>
        <p>19.2. При кожному наступному відвідуванні Сайту до початку користування Особистим кабінетом і / або іншими функціональними можливостями Сайту Користувач зобов'язується знайомитися з новою версією Угоди. Продовження використання Сайту і Особистого кабінету буде означати згоду Користувача з умовами нової версії Угоди.</p>
        <p>19.3. Якщо Користувач не згоден з умовами нової версії Угоди, він зобов'язаний припинити користуватися Сайтом.</p>
        <p>19.4. У тому випадку, якщо Користувач оплатив програми навчання і відеокурси, але не згоден з новою версією Угоди, то такий Користувач зобов'язаний повідомити про це адміністратора відправивши повідомлення на адресу електронної пошти lifta.space@bg.solutions.</p>
        <h2>20. ЗАКЛЮЧНІ ПОЛОЖЕННЯ</h2>
        <p>20.1. Угода і всі правовідносини, які виникають з неї, регулюються відповідним законодавством. Питання, що виникають спори вирішуються відповідно до чинного законодавства.</p>
        <p>20.2. Визнання судом будь-якого положення Угоди недійсним або не підлягає примусовому виконанню не тягне недійсності інших положень Угоди.</p>
        <p>20.3. Бездіяльність з боку Адміністратора в разі порушення будь-ким з користувачів положень Угоди не позбавляє Адміністратора права зробити пізніше відповідні дії на захист своїх інтересів і захист інтелектуальних прав на які охороняються відповідно до законодавства результати інтелектуальної діяльності, розміщені на цьому сайті.</p>
        <p>20.4. Користувач підтверджує, що ознайомився з усіма положеннями Угоди, розуміє і приймає їх.</p>`
  },
}
