import Vue from 'vue'
import {$http} from '@/utils/http'
import {
  SEND_SUBSCRIBE_FORM_LOADING,
  SEND_HELP_FORM_LOADING
} from '../mutation-types';

import {
  SEND_SUBSCRIBE_FORM,
  SEND_HELP_FORM
} from '../action-types';


const state = {
  sendSubscribeFormLoading: false,
  sendHelpFormLoading: false,
};

const getters = {
  sendSubscribeFormLoading: state => state.sendSubscribeFormLoading,
  sendHelpFormLoading: state => state.sendHelpFormLoading,
};

const actions = {
  [SEND_SUBSCRIBE_FORM]: async ({commit} , payload) => {
    try {
      commit(SEND_SUBSCRIBE_FORM_LOADING, true);
      const response = await $http.post(`v1/lifta/subscribe`, payload);
      console.log(response)
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SEND_SUBSCRIBE_FORM_LOADING, false);
    }
  },
  [SEND_HELP_FORM]: async ({commit} , payload) => {
    try {
      commit(SEND_HELP_FORM_LOADING, true);
      const response = await $http.post(`v1/lifta/helpme`, payload);
      console.log(response)
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SEND_HELP_FORM_LOADING, false);
    }
  },
};

const mutations = {
  [SEND_SUBSCRIBE_FORM_LOADING](state,status){
    state.sendSubscribeFormLoading = status;
  },
  [SEND_HELP_FORM_LOADING](state,status){
    state.sendHelpFormLoading = status;
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
