const coursesList = [
  {
    id: 0,
    name: 'course-1',
    slug: 'argumentaciya-v-publichnyh-vystupleniyah',
    title: 'Аргументация в публичных выступлениях',
    description: 'Как продать идею: структурность и логика - составляющие убедительности. Научись говорить так, чтобы тебя слышали.',
    image: `https://api.lifta.space/uploads/products/poster/5.jpg`,
    type: 'Онлайн-курс',
    complexity: '27.04.2021',
    period: '19:00(Вт.,Чт.)',
    status: 'Online',
    rating: 5,
    reviews_count: 3243,
    base_price: 150,
    final_price: 150,
    uah_price: 4150,
    discount_rate: 0,
    speaker_name: 'Канат Ногойбаев',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/kanat.jpg',
    category_type: 'samorazvitie',
    payment_variables: [
      {
        id: 0,
        date: '27.04',
        price: '4150',
        price_usd: '150'
      },
    ]
  },
  // {
  //   coming_soon: '1',
  //   id: 1,
  //   name: 'course-2',
  //   slug: 'biohaking-zdorovie-kak-proekt',
  //   title: 'Биохакинг. Здоровье как проект для уселения продуктивности',
  //   description: 'Лифти возможности своего тела и разума: узнай, как работать с настройками здоровья и эффективности.',
  //   image: `https://api.lifta.space/uploads/products/poster/biohacking.png`,
  //   type: 'Онлайн-курс',
  //   // complexity: 'Новичкам',
  //   period: 'Март',
  //   status: 'Запись',
  //   rating: 5,
  //   reviews_count: 3243,
  //   base_price: 'xx',
  //   final_price: 'xx',
  //   uah_price: 'xx',
  //   discount_rate: 0,
  //   speaker_name: 'Святослав Ханенко',
  //   speaker_image: 'https://api.lifta.space/uploads/products/speakers/hanenko.jpeg',
  //   category_type: 'samorazvitie',
  // },
  {
    id: 2,
    name: 'course-3',
    slug: 'postroenie-hr-brenda-kompanii',
    title: 'Построение HR-бренда компании',
    description: 'Почему бренд важен не только для потребителей, но и для сотрудников. Как найти нужного специалиста в кратчайшие сроки, и как с помошью HR-бренда создать команду мечты.',
    image: `https://api.lifta.space/uploads/products/poster/3.jpg`,
    type: 'Интенсив',
    complexity: '10.03',
    period: '7 часов',
    status: 'Online',
    rating: 5,
    reviews_count: 3243,
    base_price: '60',
    final_price: '60',
    uah_price: '1700',
    discount_rate: 0,
    speaker_name: 'Алена Дураева',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/duraeva.jpg',
    category_type: 'menedzhment',
    payment_variables: [
      {
        id: 0,
        date: '10.03',
        price: '1700',
        price_usd: '60'
      }
    ]
  },
  // {
  //   coming_soon: '1',
  //   id: 3,
  //   name: 'course-4',
  //   slug: 'postroenie-biznes-processov',
  //   title: 'Построение бизнес-процессов в digital-компании',
  //   description: 'Узнай как создать рабочубю бизнес-модель в digital-пространстве и как наладить бизнес-процессы, чтобы добиться поставленных целей.',
  //   image: `https://api.lifta.space/uploads/products/poster/busines_foundation.png`,
  //   type: 'Интенсив',
  //   complexity: 'Март',
  //   period: '5 часов',
  //   status: 'Online',
  //   rating: 5,
  //   reviews_count: 3243,
  //   base_price: 'xx',
  //   final_price: 'xx',
  //   uah_price: 'xx',
  //   discount_rate: 0,
  //   speaker_name: 'Анастасия Байдаченко',
  //   speaker_image: 'https://api.lifta.space/uploads/products/speakers/baydachenko.jpg',
  //   category_type: 'menedzhment',
  // },
  {
    id: 4,
    name: 'course-5',
    slug: 'fandrazing-v-korporativnoj-socialnoj-otvetstvennosti',
    title: 'Стратегия эффективной благотворительности',
    description: 'Узнай, как подойти к благотворительности с умом: быстрая и эффективная организайия благотворительного проекта.',
    image: `https://api.lifta.space/uploads/products/poster/7.jpg`,
    type: 'Курс',
    complexity: '19.03.2021',
    period: 'Вт.,Чт. 19:00',
    status: 'Online',
    rating: 5,
    reviews_count: 3243,
    base_price: '30',
    final_price: '30',
    uah_price: '800',
    discount_rate: 0,
    speaker_name: 'Татьяна Гринева',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/grineva.jpg',
    category_type: 'menedzhment',
    payment_variables: [
      {
        id: 0,
        date: '22.02',
        price: '800',
        price_usd: '30'
      },
      {
        id: 1,
        date: '01.03',
        price: '1200',
        price_usd: '40'
      },
      {
        id: 0,
        date: '09.03',
        price: '1400',
        price_usd: '50'
      }
    ]
  },
  {
    id: 5,
    name: 'course-6',
    slug: 'bloger-eto-rabota-monetizaciya-mikroblogov',
    title: 'Как зарабатывать на блоге',
    description: 'Как работая над своим блогом, получать соразмерную финансовую отдачу? Как упаковать контент в сотрудничестве с брендом, чтобы это было интересно аудитории? Как микро инфлюенсеру превратить свой блог в источник дохода и сохранить свою индивидуальность?',
    image: `https://api.lifta.space/uploads/products/poster/taller_cover_blue.jpg`,
    type: 'Интенсив',
    complexity: '20.04.2021',
    period: '18:00-20:00 (Вт. и Чт.)',
    status: 'Online',
    rating: 5,
    reviews_count: 3243,
    base_price: 150,
    final_price: 150,
    uah_price: 4150,
    discount_rate: 0,
    speaker_name: 'Евгений Таллер',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/taller.jpg',
    category_type: 'marketing',
    payment_variables: [
      {
        id: 0,
        date: '20.04',
        price: '4150',
        price_usd: '150'
      },
    ]
  },
  {
    id: 6,
    name: 'course-7',
    slug: 'kontent-marketing',
    title: 'Контент-маркетинг',
    description: 'Узнай, как работает пиар через инфлюэнсеров, и как запустить кампанию, ведущую к созданию юзер-контента.',
    image: `https://api.lifta.space/uploads/products/poster/12.jpg`,
    type: 'Онлайн курс',
    complexity: '13.04.2021',
    period: '-',
    status: 'Запись',
    rating: 5,
    reviews_count: 3243,
    base_price: '80',
    final_price: '80',
    uah_price: '2300',
    discount_rate: 0,
    speaker_name: 'Антон Степанюк',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/stepanuk.jpg',
    category_type: 'marketing',
    payment_variables: [
      {
        id: 0,
        date: '28.02',
        price: '2300',
        price_usd: '80'
      },
      {
        id: 1,
        date: '10.03',
        price: '2800',
        price_usd: '100'
      },
      {
        id: 2,
        date: '19.03',
        price: '3400',
        price_usd: '120'
      }
    ]
  },
  {
    id: 7,
    name: 'course-8',
    slug: 'product-market-fit',
    title: 'Product-Market Fit или нужно ли запускать стартап?',
    description: 'Получи пул знаний о запуске стартапа: как валидировать идеи, как проверять, стоит ли продукт запуска. Получи инструкцию в 10 шагов для того, чтобы твоя концепция превратилась в бизнес-модель.',
    image: `https://api.lifta.space/uploads/products/poster/9.jpg`,
    type: 'Мини-курс',
    complexity: '29.03.2021',
    period: '-',
    status: 'Запись',
    rating: 5,
    reviews_count: 3243,
    base_price: 100,
    final_price: 100,
    uah_price: 2800,
    discount_rate: 0,
    speaker_name: 'Сергей Богословский',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/bogoslovskiy.jpg',
    category_type: 'razrabotka-i-it',
    payment_variables: [
      {
        id: 0,
        date: '29.03',
        price: '2800',
        price_usd: '100'
      },
    ]
  },
  // {
  //   coming_soon: '1',
  //   id: 8,
  //   name: 'course-9',
  //   slug: 'rekruting-v-it',
  //   title: 'Рекрутинг в IT',
  //   description: 'Узнай, кто такой рекрутер в айти и почему они сегодня на вес золота',
  //   image: `https://api.lifta.space/uploads/products/poster/13.jpg`,
  //   type: 'Онлайн-курс',
  //   complexity: '',
  //   period: '',
  //   status: 'Online',
  //   rating: 5,
  //   reviews_count: 3243,
  //   base_price: 'xx',
  //   final_price: 'xx',
  //   uah_price: 'xx',
  //   discount_rate: 0,
  //   speaker_name: 'Виктория Наливайко',
  //   speaker_image: 'https://api.lifta.space/uploads/products/speakers/defspeaker.jpg',
  //   category_type: 'razrabotka-i-it',
  // },
  {
    id: 9,
    name: 'course-10',
    slug: 'kak-effektivno-rabotat-s-podryadchikami',
    title: 'Как эффективно работать с подрядчиками: программисты, дизайнеры, специалисты по продвижению',
    description: 'Поиск и работа с подрядчиками очень часто становится серьезной головной болью для руководителя или собственника бизнеса, не имеющего в штате необходимых специалистов для решения той или иной задачи.',
    image: `https://api.lifta.space/uploads/products/poster/poryad.jpg`,
    type: 'Вебинар',
    complexity: '10.03',
    period: '18:00-20:00',
    status: 'Online',
    rating: 5,
    reviews_count: 3243,
    base_price: '15',
    final_price: '15',
    uah_price: '400',
    discount_rate: 0,
    speaker_name: 'Спикер уточняется',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/defspeaker.jpg',
    category_type: 'menedzhment',
    payment_variables: [
      {
        id: 0,
        date: '21.02',
        price: '400',
        price_usd: '15'
      },
      {
        id: 1,
        date: '28.02',
        price: '550',
        price_usd: '20'
      },
      {
        id: 0,
        date: '10.03',
        price: '700',
        price_usd: '25'
      }
    ]
  },
  {
    id: 10,
    name: 'course-11',
    slug: 'instrumenty-remarketinga',
    title: 'Инструменты ремаркетинга или как вернуть клиента на сайт',
    description: 'В современном мире информации и конкуренции пользователи постоянно серфят интернет в поисках информации, товаров и услуг.',
    image: `https://api.lifta.space/uploads/products/poster/tools.png`,
    type: 'Вебинар',
    complexity: '18.03.2021',
    period: '18:00-21:00',
    status: 'Online',
    rating: 5,
    reviews_count: 3243,
    base_price: '15',
    final_price: '15',
    uah_price: '400',
    discount_rate: 0,
    speaker_name: 'Спикер уточняется',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/defspeaker.jpg',
    category_type: 'marketing',
    payment_variables: [
      {
        id: 0,
        date: '21.02',
        price: '400',
        price_usd: '15'
      },
      {
        id: 1,
        date: '28.02',
        price: '550',
        price_usd: '20'
      },
      {
        id: 0,
        date: '18.03',
        price: '700',
        price_usd: '25'
      }
    ]
  },
  // {
  //   id: 11,
  //   name: 'course-12',
  //   slug: 'nastrojka-i-rabota-s-google-analytics-i-google-tag-manager',
  //   title: 'Интенсив настройка и работа с Google Analytics и Google Tag Manager',
  //   description: 'На интенсиве разберем настройку Google Analytics с нуля. Пройдемся по всем шагам, начиная с установки кода на сайт через Google Tag Manager до создания индивидуальных отчетов.',
  //   image: `https://api.lifta.space/uploads/products/poster/gtm.jpg`,
  //   type: 'Интенсив',
  //   complexity: '',
  //   period: '',
  //   status: 'Online',
  //   rating: 5,
  //   reviews_count: 3243,
  //   base_price: 'xx',
  //   final_price: 'xx',
  //   uah_price: 'xx',
  //   discount_rate: 0,
  //   speaker_name: 'Спикер уточняется',
  //   speaker_image: 'https://api.lifta.space/uploads/products/speakers/defspeaker.jpg',
  //   category_type: 'menedzhment',
  // },
  {
    id: 12,
    name: 'course-13',
    slug: 'intensiv-rim',
    title: 'Запуск Интернет-магазина - с чего начать? ',
    description: 'Продажи товаров в интернете – это один из самых динамично-развивающихся бизнесов в СНГ. Сейчас открыть свой собственный интернет-магазин это дело одной недели, и в рамках интенсива, я расскажу, как запустить свой  интернет-магазин и эффективно им управлять.',
    image: `https://api.lifta.space/uploads/products/poster/10.jpg`,
    type: 'Интенсив',
    complexity: '-',
    period: '11:00-18:00',
    status: 'Online',
    rating: 5,
    reviews_count: 3243,
    base_price: '80',
    final_price: '80',
    uah_price: '2300',
    discount_rate: 0,
    speaker_name: 'Олег Зарицкий',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/zaritskiy.jpg',
    category_type: 'marketing',
    payment_variables: [
      {
        id: 0,
        date: '28.02',
        price: '2300',
        price_usd: '80'
      },
      {
        id: 1,
        date: '10.03',
        price: '2800',
        price_usd: '100'
      },
      {
        id: 0,
        date: '31.03',
        price: '3400',
        price_usd: '120'
      }
    ]
  },
  {
    id: 13,
    name: 'course-14',
    slug: 'osnovy-digital-marketinga-ot-a-do-ya',
    title: 'Основы digital-маркетинга от А до Я для начинающих за 1 день.',
    description: 'Вы владелец бизнеса, но вы далеки от понимания того, как этот бизнес эффективно продвигать в сети Интернет, но есть желание понять, что к чему?',
    image: `https://api.lifta.space/uploads/products/poster/2.jpg`,
    type: 'Интенсив',
    complexity: '15.03',
    period: '11:00-17:00',
    status: 'Online',
    rating: 5,
    reviews_count: 3243,
    base_price: '80',
    final_price: '80',
    uah_price: '2300',
    discount_rate: 0,
    speaker_name: 'Олег Зарицкий',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/zaritskiy.jpg',
    category_type: 'marketing',
    payment_variables: [
      {
        id: 0,
        date: '18.02',
        price: '2300',
        price_usd: '80'
      },
      {
        id: 1,
        date: '23.02',
        price: '2800',
        price_usd: '100'
      },
      {
        id: 2,
        date: '25.02',
        price: '3400',
        price_usd: '120'
      }
    ]
  },
  {
    id: 14,
    name: 'course-15',
    slug: 'osnovy-veb-analitiki-dlya-nachinayushih',
    title: 'Основы веб-аналитики для начинающих за 1 день.',
    description: 'Вы начинающий в Интернет-маркетинга и хотите разобраться в веб-аналитике и ее основных инструментах?',
    image: `https://api.lifta.space/uploads/products/poster/4.jpg`,
    type: 'Интенсив',
    complexity: '02.03.2021',
    period: '11:00-17:00',
    status: 'Online',
    rating: 5,
    reviews_count: 3243,
    base_price: '40',
    final_price: '40',
    uah_price: '1200',
    discount_rate: 0,
    speaker_name: 'Спикер уточняется',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/defspeaker.jpg',
    category_type: 'marketing',
    payment_variables: [
      {
        id: 0,
        date: '21.02',
        price: '1200',
        price_usd: '40'
      },
      {
        id: 1,
        date: '26.02',
        price: '1400',
        price_usd: '50'
      },
      {
        id: 2,
        date: '02.03',
        price: '1700',
        price_usd: '60'
      }
    ]
  },
  {
    id: 15,
    name: 'course-16',
    slug: 'kak-osvobodit-resurs',
    title: 'Как освободить ресурс: трансцендируй от стресса к эффективности.',
    description: 'Как наконец научиться управлять временем? Как сгладить острые углы и научиться не вовлекаться в конфликты? Как повысить личную эффективность не жертвуя сном? Все это доступнее, чем кажется.',
    image: `https://api.lifta.space/uploads/products/poster/11.jpg`,
    type: 'Интенсив',
    complexity: '09.03.2021',
    period: 'Пн-Пт 11:00-12:00',
    status: 'Online + Offline',
    rating: 5,
    reviews_count: 3243,
    base_price: '250',
    final_price: '250',
    uah_price: '7000',
    discount_rate: 0,
    speaker_name: 'Олег Авагян',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/avagyan.jpg',
    category_type: 'samorazvitie',
    payment_variables: [
      {
        id: 0,
        date: '09.03',
        price: '7000',
        price_usd: '250'
      }
    ]
  },
  {
    id: 16,
    name: 'course-17',
    slug: 'zhit-po-sobstvennym-pravilam',
    title: 'Жить по собственным правилам',
    description: 'Как прописать сценарий своей жизни, следовать ему и добиваться результатов: завязка — кульминация — победа. Как прожить свою жизнь, а не чужую.',
    image: `https://api.lifta.space/uploads/products/poster/polyasheva-2.png`,
    type: 'Онлайн-курс',
    complexity: '30.03.2021',
    period: 'Вт.- 19:00 ,Чт. - 11:00',
    status: 'Online',
    rating: 5,
    reviews_count: 3243,
    base_price: '120',
    final_price: '120',
    uah_price: '3400',
    discount_rate: 0,
    speaker_name: 'Ирина Поляшева',
    speaker_image: 'https://api.lifta.space/uploads/products/speakers/polyasheva.jpg',
    category_type: 'samorazvitie',
    payment_variables: [
      {
        id: 0,
        date: '23.03',
        price: '3400',
        price_usd: '120'
      },
      {
        id: 1,
        date: '24.03',
        price: '4200',
        price_usd: '150'
      },
    ]
  },
];

export default coursesList;
