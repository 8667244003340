import {$http} from '@/utils/http'
import {
  CHANGE_PAGE_INDEX,
  SET_ABOUT_LIST,
  SET_ABOUT_LIST_LOADING,
} from '../mutation-types';
import {
  GET_ABOUT_LIST,
} from "../action-types";


const state = {
  pageIndex: 1,
  aboutListLoading: false,
  aboutList: null,
};

const getters = {
  pageIndex: state => state.pageIndex,
  aboutList: state => state.aboutList,
  aboutListLoading: state => state.aboutListLoading,
};

const actions = {
  [GET_ABOUT_LIST]: async ({commit}) => {
    commit(SET_ABOUT_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/pages/about`);
      commit(SET_ABOUT_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_ABOUT_LIST_LOADING, false);
    }
  },
};

const mutations = {
  [CHANGE_PAGE_INDEX](state, index) {
    state.pageIndex = index;
  },
  [SET_ABOUT_LIST_LOADING](state, status) {
    state.aboutListLoading = status;
  },
  [SET_ABOUT_LIST](state, list) {
    state.aboutList = null;
    state.aboutList = list;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
