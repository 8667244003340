import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'about-promo',
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      index: 'about/pageIndex'
    }),
  },
  components: {},
  methods: {
    ...mapActions({}),
    ...mapMutations({
      changeIndex: `about/CHANGE_PAGE_INDEX`,
    }),
    scrollDown(item) {
      this.changeIndex(2);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false,
      });
    }
  },
  beforeDestroy() {
  },
}
