import {VueSlideToggle} from 'vue-slide-toggle';

export default {
  name: 'accordion',
  props: ['item','index'],
  data() {
    return {
      expanded: false
    }
  },
  components: {
    VueSlideToggle
  },
  methods: {
    toggleCardState() {
      this.expanded = !this.expanded;
    },
  },
}
