import {mapGetters, mapActions, mapMutations} from 'vuex';

import SpeakersSlider from '@/components/features/speakers-slider/index.vue';

export default {
  name: 'home-speakers',
  data() {
    return {
      activeCategory: 'all'
    }
  },
  created() {
    if( !this.speakersList ){
      this.fetchSpeakersList();
    }
    if(!this.categoryList){
      this.fetchSpeakersCategoryList();
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      speakersList: 'speakers/speakersList',
      speakersListLength: 'speakers/speakersListLength',
      speakersListLoading: 'speakers/speakersListLoading',
      categoryList: 'speakers/categoryList',
      categoryListLoading: 'speakers/categoryListLoading',
    }),
  },
  components: {
    SpeakersSlider
  },
  methods: {
    ...mapActions({
      fetchSpeakersList: `speakers/GET_SPEAKERS_LIST`,
      fetchSpeakersCategoryList: `speakers/GET_SPEAKERS_CATEGORY_LIST`,
    }),
    ...mapMutations({
      changeActiveCategory: 'speakers/CHANGE_CATEGORY',
    }),
    changeCategory(category){
      this.activeCategory = category;
      this.changeActiveCategory(category);
      this.fetchSpeakersList();
    }
  },
  beforeDestroy() {
  },
}
