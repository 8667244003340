export const translation = {
  successActivationEmail: 'Ваш емейл активирован',
  profile: 'Профиль',
  totalInformation: 'Общая информация',
  noDataNow: 'Данных нет',
  fieldRequired: "Поле :name обязательно для заполнения",
  maxLengthField: "Максимальное количество символов поля :field — :count",
  name: "Имя",
  next: "Далее",
  noData: "Нет данных",
  or: "или",
  city: 'Город',
  country: "Страна",
};




//      {{ $t('successActivationEmail') }}