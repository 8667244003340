import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);


// for multiple parallel requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const $http = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('user_token')}`,
    'Accept': 'application/json',
  }
});

$http.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('user_token')}`;
  config.headers['X-localization'] = store.getters.currentLanguage.translationKey;
  config.headers['X-UTM-SOURCE'] = sessionStorage.getItem('X-UTM-SOURCE');
  config.headers['X-UTM-MEDIUM'] = sessionStorage.getItem('X-UTM-MEDIUM');
  config.headers['X-UTM-CAMPAIGN'] = sessionStorage.getItem('X-UTM-CAMPAIGN');

  if (store.getters['setting/abCampaign'].data.hash) {
    config.headers['X-AB-Hash'] = store.getters['setting/abCampaign'].data.hash;
  }

  return config;
});
$http.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const originalRequest = error.config;
    switch (error.response.status) {
      // case 500: {
      //   router.push({name: 'server-not-found'}).catch(() => {
      //     console.log()
      //   });
      //   break;
      // }
      // case 404: {
      //   router.push({name: 'not-found'}).catch(() => {
      //     console.log()
      //   });
      //   break;
      // }
      case 401: {
        store.commit('auth/CHANGE_POPUP_REGISTER', true);
        store.commit('auth/REMOVE_TOKEN');
        break;
      }
      default: {

        break;
      }
     }
  return Promise.reject(error);
  }
);

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, '$http', {
    get() {
      return $http
    }
  })
}
