import coursesSample from "@/components/features/courses-sample/index.vue";

export default {
  name: 'personal-courses',
  components: {
    coursesSample,
  },
  props: {
    speaker: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: [],
    }
  },
}
