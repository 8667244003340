import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'event',
  data() {
    return {
      height: 485,
      options: {
        height: 485,
        pip: true
      },
      optionSmall: {
        controls: false,
        responsive: true
      },
      activeItem: {
        type: 'video',
        src: '503107963'
      },
      secondItem: {
        type: '',
        src: ''
      }
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    contentData() {
      return [
        {
          id: 0,
          type: 'video',
          src: '501718355'
        },
        {
          id: 1,
          type: 'video',
          src: '501719520'
        },
        {
          id: 2,
          type: 'video',
          src: '501720759'
        },
      ]
    }
  },
  components: {
  },
  methods: {
    changeDataContent(item,index){
      this.secondItem.type = this.activeItem.type;
      this.secondItem.src = this.activeItem.src;

      this.activeItem.type = item.type;
      this.activeItem.src = item.src;


      // change current source
      this.contentData.forEach((e,i)=> {
        if( i === index ){
          this.contentData[i].src = this.secondItem.src;
          this.contentData[i].type = this.secondItem.type;
        }
      })

    }
  },
  beforeDestroy() {
  },
}
