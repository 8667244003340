import { VueSlideToggle } from 'vue-slide-toggle';
import coursesSample from "@/components/features/courses-sample/index.vue";

export default {
  name: 'related-courses',
  components: {
    VueSlideToggle,
    coursesSample,
  },
  props: {
    list: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded;
    }
  }
}
