export default {
  name: 'input-search',
  props: {
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'search',
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      localValue: this.value,
      timeout: null,
    }
  },
  watch: {
    value: function (newValue) {
      this.localValue = newValue ? newValue : '';
    }
  },
  methods: {
    inputHandler($event) {
      this.localValue = $event.target.value;

      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.$emit('input', this.localValue);
      }, 500);
    }
  }
}
