import {$http} from '@/utils/http'
import {
  UPLOAD_IMAGES_SAMPLE_START,
  UPLOAD_IMAGES_SAMPLE_SUCCESS,
  UPLOAD_IMAGES_SAMPLE_FAILURE,
  UPLOAD_IMAGES_SAMPLE_FULFILLED
} from '../mutation-types';

import {
  UPLOAD_IMAGES_SAMPLE_TRIGGER
} from '../action-types';

const state = {
  image: {
    data: null,
    loading: false,
    done: false,
    error: null,
  }
};

const getters = {
  // image
  imageData: state => state.image.data,
  imageLoading: state => state.image.loading,
  imageDone: state => state.image.done,
  imageError: state => state.image.error,
};

const actions = {
  // fetch sample
  [UPLOAD_IMAGES_SAMPLE_TRIGGER]: async ({ commit }, file) => {
    try {
      commit(UPLOAD_IMAGES_SAMPLE_START);

      const response = await $http.post('v1/upload/image', file);
      commit(UPLOAD_IMAGES_SAMPLE_SUCCESS, response.data);

    } catch (error) {
      commit(UPLOAD_IMAGES_SAMPLE_FAILURE, error);
      throw error;
    } finally {
      commit(UPLOAD_IMAGES_SAMPLE_FULFILLED);
    }
  },
};

const mutations = {
  // sample
  [UPLOAD_IMAGES_SAMPLE_START] (state) {
    state.image.loading = true;
    state.image.done = false;
    state.image.error = null;
  },
  [UPLOAD_IMAGES_SAMPLE_SUCCESS] (state, data) {
    state.image.data = data.url;
    state.image.done = true;
  },
  [UPLOAD_IMAGES_SAMPLE_FAILURE] (state, error) {
    state.image.error = error;
  },
  [UPLOAD_IMAGES_SAMPLE_FULFILLED] (state) {
    state.image.loading = false;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
