import { mapGetters, mapActions } from 'vuex';

import speakersSampleFull from '@/components/features/speakers-sample-full/index.vue';
import pagination from '@/components/features/pagination/index.vue';
import mainFooter from '@/components/features/footer/index.vue';
import inputSearch from '@/components/inputs/search/index.vue';
import chooseAssistance from '@/components/features/choose-assistance/index.vue';
import catalogueCategories from '@/components/features/catalogue-categories/index.vue';
import objectIsEmpty from "@/utils/object-is-empty";

export default {
  name: 'speakers',
  components: {
    speakersSampleFull,
    pagination,
    chooseAssistance,
    catalogueCategories,
    mainFooter,
    inputSearch,
  },

  data() {
    return {
      initFetched: false,
      queryParams: {},
      metaParams: {
        title: '',
        description: '',
        keywords: '',
        name: ''
      },
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Lifta.space',
        separator: ' | ',
        complement: this.metaParams.title,
      }
    },
    meta: function () {
      let meta = [
        {n: 'description', c: this.metaParams.description, id: 'description'},
        {n: 'keywords', c: this.metaParams.keywords, id: 'keywords'},
        {p: 'og:title', c: this.metaParams.title, id: 'og:title'},
        {p: 'og:url', c: window.location.origin + this.$route.path, id: 'og:url'},
        {p: 'og:type', c: "website", id: 'og:type'},
        {p: 'og:description', c: this.metaParams.description, id: 'og:description'},
        {p: 'twitter:title', c: this.metaParams.title, id: 'twitter:title'},
        {p: 'twitter:description', c: this.metaParams.description, id: 'twitter:description'},
      ]

      if (Object.keys(this.$route.query).length > 0) {
        meta.push({ n: 'robots', c: 'noindex, nofollow' })
      }

      return meta
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ua/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' },
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'uk-UA', href: window.location.origin+ '/ua' + route, id: 'uk-UA'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    }
  },

  created() {
    this.fetchSpeakersCategoriesList()
    this.fetchSpeakersInfo();
  },

  computed: {
    ...mapGetters({
      // categories list
      categoriesListData: 'speakersNew/categoriesListData',
      categoriesListLoading: 'speakersNew/categoriesListLoading',
      categoriesListFetched: 'speakersNew/categoriesListFetched',
      categoriesListError: 'speakersNew/categoriesListError',

      // speakers list
      speakersListData: 'speakersNew/listData',
      speakersListLoading: 'speakersNew/listLoading',
      speakersListFetched: 'speakersNew/listFetched',
      speakersListError: 'speakersNew/listError',

      // speakers list pagination
      speakersListPaginationOffset: 'speakersNew/listPaginationOffset',
      speakersListPaginationLimit: 'speakersNew/listPaginationLimit',
      speakersListPaginationTotal: 'speakersNew/listPaginationTotal',

      // speakers meta info
      speakersMetaInfo: 'speakers/speakerInfo',
    })
  },

  watch: {
    '$route.fullPath': {
      immediate: true,
      handler(newValue, oldValue) {
        const categoryParams = this.$route.params.category;
        this.queryParams = this.$route.query;

        const payloadParams = {
          ...this.queryParams,
          ...(categoryParams ? { category: categoryParams } : {}),
          include: 'categories',
          limit: this.speakersListPaginationLimit,
        };

        this.fetchSpeakersList(payloadParams);

        if (this.initFetched) {
          this.scrollToTop();
        } else {
          this.initFetched = true;
        }
      }
    },

    speakersMetaInfo:  {
      handler(newValue) {
        if (newValue && !objectIsEmpty(newValue)) {
          this.metaParams.title = newValue.meta_title;
          this.metaParams.description = newValue.meta_description;
          this.metaParams.keywords = newValue.meta_keyword;
          this.metaParams.name = newValue.page_title;

          this.$emit('updateHead')
        }
      }
    },
  },
  methods: {
    ...mapActions({
      fetchSpeakersCategoriesList: `speakersNew/SPEAKERS_FETCH_CATEGORIES_LIST_DATA_TRIGGER`,
      fetchSpeakersList: `speakersNew/SPEAKERS_FETCH_LIST_DATA_TRIGGER`,
      fetchSpeakersInfo: `speakers/GET_SPEAKERS_INFO`,
    }),

    scrollToTop() {
      const $listSectionEl = this.$refs['list-section'];

      if ($listSectionEl)  {
        this.$smoothScroll({
          scrollTo: $listSectionEl,
          offset: -100,
        })
      }
    },

    updateQueryParams({ name, value }) {
      let categoryParams = this.$route.params.category;
      const updatedQueryParams = {...this.queryParams};

      if (name) {
        if (value && value.toString().length > 0) {
          updatedQueryParams[name] = value;
        } else {
          delete updatedQueryParams[name];
        }

        this.queryParams = updatedQueryParams;
      }

      this.$router.replace({
        name: 'speakers',
        ...(categoryParams ? { params: { category: categoryParams }} : {}),
        ...(!objectIsEmpty(this.queryParams) ? { query: this.queryParams } : {}),
      });
    }
  },
}
