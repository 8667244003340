
const speakersFaqList = [
  {
    id: 0,
    question: 'Запланируй обучение прямо сейчас, не упусти момент',
    content: 'Ответ 1',
  },
  {
    id: 1,
    question: 'LIFTA-приложение – глобус поиска и роста в твоем ',
    content: 'Ответ 2',
  },
  {
    id: 2,
    question: 'Развития на странице новых достижений, сохранит',
    content: 'Ответ 3',
  },
  {
    id: 3,
    question: 'Приложении LIFTA.SPACE. Установи',
    content: 'Ответ 4',
  },
  {
    id: 4,
    question: 'LIFTA-приложение – глобус поиска и роста в твоем ',
    content: 'Ответ 5',
  }
];

export default speakersFaqList;
