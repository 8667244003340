// store
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {
  SPEAKERS_FETCH_FAQ_LIST_DATA_TRIGGER, SPEAKERS_FETCH_LIST_DATA_TRIGGER,
  SPEAKERS_FETCH_SAMPLE_DATA_TRIGGER,
} from "@/store/action-types";

// global
import mainFooter from '@/components/features/footer/index.vue';
import mainButton from '@/components/buttons/main-button/index.vue';
import premiumSubscription from '@/components/features/premium-subscription/index.vue';
import faqSection from '@/components/features/faq-section/index.vue';
import personalCourses from '../../components/personal-courses/index.vue';
import speakersList from '../../components/speakers-list/index.vue';

// 3d parties
import getVideoId from 'get-video-id';
import { Player, Youtube, Vimeo, Video, DefaultUi, Ui } from '@vime/vue';

// utils
import 'moment';

export default {
  name: 'speakers-sample',
  components: {
    mainFooter,
    mainButton,
    premiumSubscription,
    personalCourses,
    speakersList,
    faqSection,
    Player,
    Youtube,
    Vimeo,
    Video,
    DefaultUi,
    Ui,
  },
  head: {
    title: function () {
      return {
        inner: this.params.name ? this.params.name : 'Lifta.space',
        separator: ' '
      }
    },
    meta: function () {
      let meta = [
        {n: 'description', c: this.params.description, id: 'description'},
        {n: 'keywords', c: this.params.keywords, id: 'keywords'},
        {p: 'og:title', c: this.params.title, id: 'og:title'},
        {p: 'og:url', c: window.location.origin + this.$route.path, id: 'og:url'},
        {p: 'og:type', c: "website", id: 'og:type'},
        {p: 'og:image', c: this.params.image, id: 'og:image'},
        {p: 'og:description', c: this.params.description, id: 'og:description'},
        {p: 'twitter:title', c: this.params.title, id: 'twitter:title'},
        {p: 'twitter:description', c: this.params.description, id: 'twitter:description'},
      ]

      if (Object.keys(this.$route.query).length > 0) {
        meta.push({n: 'robots', c: 'noindex, nofollow'})
        return meta
      } else {
        return meta
      }
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ua/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        {rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical'},
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'uk-UA', href: window.location.origin+ '/ua' + route, id: 'uk-UA'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    }
  },
  data() {
    return {
      params: {
        title: '',
        description: '',
        keywords: '',
        name: '',
        image: ''
      },
    }
  },
  computed: {
    ...mapGetters({
      // sample
      speakersSampleData: 'speakersNew/sampleData',
      speakersSampleLoading: 'speakersNew/sampleLoading',
      speakersSampleFetched: 'speakersNew/sampleFetched',
      speakersSampleError: 'speakersNew/sampleError',

      // list
      speakersListData: 'speakersNew/listData',
      speakersListLoading: 'speakersNew/listLoading',
      speakersListFetched: 'speakersNew/listFetched',
      speakersListError: 'speakersNew/listError',

      // faq
      faqListData: 'speakersNew/faqListData',
      faqListLoading: 'speakersNew/faqListLoading',
      faqListFetched: 'speakersNew/faqListFetched',
      faqListError: 'speakersNew/faqListError',
    }),

    posterVideo() {
      return this.speakersSampleData && this.speakersSampleData.poster && this.speakersSampleData.poster.length > 0
        ? getVideoId(this.speakersSampleData.poster)
        : null;
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(newVal) {
        this.initSampleFetch();
      }
    },
    speakersSampleData:  {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          var self = this
          self.params.title = newVal.meta_title;
          self.params.description = newVal.meta_description;
          self.params.keywords = newVal.meta_keyword;
          self.params.name = newVal.page_title;
          self.params.image = newVal.image_thumb;
          self.$emit('updateHead')
        }
      }
    },
  },
  created() {
    this.fetchListData();
    this.fetchFaqListData();
  },
  methods: {
    ...mapActions({
      fetchSampleData: `speakersNew/${SPEAKERS_FETCH_SAMPLE_DATA_TRIGGER}`,
      fetchListData: `speakersNew/${SPEAKERS_FETCH_LIST_DATA_TRIGGER}`,
      fetchFaqListData: `speakersNew/${SPEAKERS_FETCH_FAQ_LIST_DATA_TRIGGER}`,
    }),

    initSampleFetch () {
      const params = {
        include: 'products',
      };
      this.fetchSampleData({ slug: this.$route.params.slug, params });
    },

    buySampleHandler() {

    },

    buySubscriptionHandler() {

    }
  }
}
