import {mapGetters, mapActions, mapMutations} from 'vuex';
import Moment from 'moment';
import {validationMixin} from "vuelidate";
import {email, maxLength, required} from "vuelidate/lib/validators";

export default {
  name: 'event-course',
  mixins: [validationMixin],
  props:['item'],
  data() {
    return {
      activeCard: 1,
      finalPrice: 0,
      priceItemCount: 0,
      isShowPromocode: false,
      payload: {
        email: '',
        name: '',
        phone: '',
        promocode: ''
      },
      utm_medium: '',
      utm_source: '',
      isValidPhone: false,
      validationErrors: {
        phone: []
      }
    }
  },
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
      promocode: {
        maxLength: maxLength(100)
      },
    }
  },
  created() {
    if( this.user ){
      this.setData();
    }
    this.setUtm();
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      buyEventLoading: 'event/buyEventLoading',
      recordPopupContent: 'event/recordEventPopup',
      recordLoading: 'event/recordToConsultationLoading',
      user: 'profile/user',
      eventItem: 'event/eventItem',

      abCampaign: 'setting/abCampaign',
    }),
    disableButton() {
      return this.payload.name === '' ||
        this.payload.email === '' ||
        !this.isValidPhone;
    },
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    promocodeErrors() {
      let error = [];
      if (!this.$v.payload.promocode.$dirty) {
        return error;
      }
      if (!this.$v.payload.promocode.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 100));
      }
      if (this.validationErrors.promocode) {
        this.validationErrors.promocode.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  watch: {
    'item': {
      immediate: true,
      handler(){
        this.priceItemCount = this.item.payment_variables.length;
      }
    },
    'user'(){
      this.setData();
    }
  },
  components: {

  },
  methods: {
    ...mapActions({
      buyProduct: `event/BUY_PRODUCT`,
      recordToConsultation: `event/RECORD_TO_CONSULTATION`,
    }),
    ...mapMutations({
      changePopupSignIn: `auth/CHANGE_POPUP_SIGN_IN`,
      changeShowBuyPopup: `event/SHOW_POPUP_BUY_FORM`,
      changeShowPopupBuyForm: `event/CHANGE_SHOW_POPUP_BUY_FORM`,
      changeShowSubscribeEventPopup: `event/CHANGE_SHOW_SUBSCRIBE_EVENT_POPUP`,
      changeSubscribeEventPopupInfo: `event/CHANGE_RECORD_POPUP_INFO`,
      changeRecordPopupContent: `event/CHANGE_RECORD_POPUP_INFO`,
      changeShowRecordPopup: `event/CHANGE_SHOW_RECORD_POPUP`,
      changePopupRegisterInfo: `auth/CHANGE_POPUP_REGISTER_INFO`,
      changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,

      changePaymentPage: `payment/CHANGE_PAYMENT_PAGE`,

      changeContentPopup: `system/POPUP_TEXT_CONTENT`,
      changeShowContentPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
    }),
    changeCard(index){
      this.activeCard = index;
    },
    buyEvent(price){
      this.changePaymentPage(true);
      let newPrice = [];
      for (let i = 0; i < this.item.payment_variables.length; i++) {
        let dif =  Moment(Moment()).isBefore(Moment(this.item.payment_variables[i].date, 'DD.MM'));
        if(dif){
          newPrice.push(this.item.payment_variables[i]);
        }
      }

      if( newPrice.length ){
        this.finalPrice = newPrice[0].price_uah;
      }else{
        this.finalPrice = this.item.payment_variables[this.item.payment_variables.length - 1].price_uah;
      }
      console.log(this.finalPrice)
      if( this.isAuthenticated && this.finalPrice ){
        let obj = {};
        obj.slug = this.item.slug;
        obj.price = this.finalPrice;
        if (this.utm_medium) {
          obj.utm_medium = this.utm_medium;
          obj.utm_source = this.utm_source;
          obj.site_url = window.location.origin + window.location.pathname;
        }
        this.buyProduct(obj)
          .then(() => {

            this.changeShowBuyPopup({price: this.finalPrice, title: this.item.title});
            setTimeout(() => {
              this.changeShowPopupBuyForm(true);
            })
          }).catch(error => {
            if (error.response.status === 418) {
              this.$toasted.error(error.response.data.message);
            }
          });
      }else{
        if(this.finalPrice){
          this.changePopupRegister(true);
          this.changePopupRegisterInfo({course: this.item.name});
        }
      }

      this.gtm(this.item);
    },
    getRecord(){
      this.changeRecordPopupContent({title: this.item.title})
      setTimeout(()=> {
        this.changeShowRecordPopup(true);
      },200)
    },
    gtm(item) {
      this.$gtm.trackEvent({
        'event': 'addToCart',
        'ecommerce': {
          'currencyCode': 'EUR',
          'add': {
            'products': [{
              'name': item.title,
              'id': item.id,
              'price': item.final_price,
              'category': item.category,
              'autor': item.speakers.data[0].name,
              'type': item.status,
              'quantity': 1
            }],
          }
        }
      });
    },
    changePhoneInput(number, info) {
      this.isValidPhone = info.valid;
      if (this.isValidPhone) {
        this.payload.phone = info.number.e164.replace('+', '');
        this.validationErrors.phone = [];
      }
    },
    changeValidationPhone() {
      this.validationErrors.phone = [];
      if (this.payload.phone.length > 0) {
        if (!this.isValidPhone) {
          this.validationErrors.phone.push(this.$t('enterCorrectPhone'))
        } else {
          this.validationErrors.phone = [];
        }
      } else {
        this.validationErrors.phone.push(this.$t('enterCorrectPhone'))
      }
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {};
        obj.name = this.payload.name;
        obj.email = this.payload.email;
        obj.promocode = this.payload.promocode;
        obj.phone = this.payload.phone.replace(/[\s.,%()-]/g, '');
        obj.course = this.item.title;
        if( this.utm_medium ){
          obj.utm_medium = this.utm_medium;
          obj.utm_source = this.utm_source;
          obj.site_url = window.location.origin+window.location.pathname;
        }
        this.recordToConsultation(obj)
          .then(() => {

            this.resetForm();
            this.$nextTick(() => {
              this.changeContentPopup({
                text: this.$t('successRecordToEvent'),
              });
            })
            setTimeout(() => {
              this.changeShowContentPopup(true);
            }, 200)
          }).catch(error => {
          let formattedMessage = null;

          if (error && error.response) {
            if (error.response.data.message) {
              formattedMessage = error.response.data.message;
            }

            if (error.response.data.error) {
              formattedMessage = error.response.data.error.message;
            }

            if (error.response.data.errors) {
              Object.keys(error.response.data.errors).forEach(errorKey => {
                error.response.data.errors[errorKey].forEach((line) => {
                  formattedMessage += `\r\n${line}`;
                });
              });
            }
          }

          formattedMessage && this.$toasted.error(formattedMessage);
        });
      }
    },
    setData(){
      if( this.user ){
        this.payload.name = this.user.first_name;
        this.payload.email = this.user.email;
      }
    },
    resetForm(){
      this.$v.$reset();
      this.payload.name = '';
      this.payload.phone = '';
      this.payload.email = '';
      this.payload.promocode = '';
    },
    setUtm(){
      this.utm_medium = this.$route.query.utm_medium;
      this.utm_source = this.$route.query.utm_source;
    }
  },
  beforeDestroy() {
  },
}
