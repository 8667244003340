// store
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {
  COURSES_FETCH_FAQ_LIST_DATA_TRIGGER, COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_TRIGGER,
  COURSES_FETCH_SAMPLE_DATA_TRIGGER, COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_TRIGGER,
  COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_TRIGGER
} from "@/store/action-types";

// global
import mainFooter from '@/components/features/footer/index.vue';
import mainButton from '@/components/buttons/main-button/index.vue';
import whatYouGet from '@/components/features/what-you-get/index.vue';
import howItWorks from '@/components/features/how-it-works/index.vue';
import techAndTools from '@/components/features/tech-and-tools/index.vue';
import premiumSubscription from '@/components/features/premium-subscription/index.vue';
import discountForm from '@/components/features/discount-form/index.vue';
import faqSection from '@/components/features/faq-section/index.vue';

// local
import speakersList from '../../components/speakers-list/index.vue';
import relatedCourses from '../../components/related-courses/index.vue';
import lecturesSample from '../../components/lectures-sample/index.vue';

// utils
import 'moment';

export default {
  name: 'courses-sample',
  components: {
    mainFooter,
    mainButton,
    whatYouGet,
    howItWorks,
    techAndTools,
    premiumSubscription,
    discountForm,
    faqSection,
    speakersList,
    relatedCourses,
    lecturesSample
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      // sample
      coursesSampleData: 'courses/sampleData',
      coursesSampleLoading: 'courses/sampleLoading',
      coursesSampleFetched: 'courses/sampleFetched',
      coursesSampleError: 'courses/sampleError',

      // what you get
      whatYouGetListData: 'courses/whatYouGetListData',
      whatYouGetListLoading: 'courses/whatYouGetListLoading',
      whatYouGetListFetched: 'courses/whatYouGetListFetched',
      whatYouGetListError: 'courses/whatYouGetListError',

      // how it works
      howItWorksListData: 'courses/howItWorksListData',
      howItWorksListLoading: 'courses/howItWorksListLoading',
      howItWorksListFetched: 'courses/howItWorksListFetched',
      howItWorksListError: 'courses/howItWorksListError',

      // tech and tools
      techAndToolsListData: 'courses/techAndToolsListData',
      techAndToolsListLoading: 'courses/techAndToolsListLoading',
      techAndToolsListFetched: 'courses/techAndToolsListFetched',
      techAndToolsListError: 'courses/techAndToolsListError',

      // faq
      faqListData: 'courses/faqListData',
      faqListLoading: 'courses/faqListLoading',
      faqListFetched: 'courses/faqListFetched',
      faqListError: 'courses/faqListError',

      // campaigns
      abCampaign: 'setting/abCampaign',
    }),

    lecturesHoursDuration: function () {
      return this.coursesSampleData.lectures.data.reduce((sum, lecture) => sum + lecture.duration, 0);
    },
  },
  created() {
    this.initSampleFetch();

    this.fetchWhatYouGetListData();
    this.fetchHowItWorksListData();
    this.fetchTechAndToolsListData();
    this.fetchFaqListData();
  },
  methods: {
    ...mapActions({
      fetchSampleData: `courses/${COURSES_FETCH_SAMPLE_DATA_TRIGGER}`,
      fetchWhatYouGetListData: `courses/${COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_TRIGGER}`,
      fetchHowItWorksListData: `courses/${COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_TRIGGER}`,
      fetchTechAndToolsListData: `courses/${COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_TRIGGER}`,
      fetchFaqListData: `courses/${COURSES_FETCH_FAQ_LIST_DATA_TRIGGER}`,
    }),

    initSampleFetch () {
      const params = {
        include: 'lectures,speakers,related',
      };
      this.fetchSampleData({ slug: this.$route.params.slug, params });
    },

    buySampleHandler() {

    },

    buySubscriptionHandler() {

    }
  }
}
