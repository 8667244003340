import {$http} from '@/utils/http'

import {
  PAYMENT_POPUP_TEXT_CONTENT,
  CHANGE_SHOW_PAYMENT_POPUP,
  CHANGE_PAYMENT_PAGE
} from '../mutation-types';

import {
} from '../action-types';


const state = {
  paymentPopup: {
    title: '',
    text: ''
  },
  isShowPaymentPopup: false,
  isPaymentPage: false
};

const getters = {
  paymentPopup: state => state.paymentPopup,
  isShowPaymentPopup: state => state.isShowPaymentPopup,


  isPaymentPage: state => state.isPaymentPage,
};

const actions = {
};

const mutations = {
  [PAYMENT_POPUP_TEXT_CONTENT](state, content) {
    state.paymentPopup = content;
  },
  [CHANGE_SHOW_PAYMENT_POPUP](state, status) {
    state.isShowPaymentPopup = status
  },

  [CHANGE_PAYMENT_PAGE](state, status) {
    state.isPaymentPage = status
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
