import {mapGetters, mapActions, mapMutations} from 'vuex';
import Accordion from '../../components/accordion/index.vue'

export default {
  name: 'about',
  props:['item','vacancyInfo'],
  data() {
    return {
      data: []
    }
  },
  created() {
    if( !this.vacancyList.length  ){
      this.getVacancyList();
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      vacancyList: 'vacancy/vacancyList',
      vacancyListLoading: 'vacancy/vacancyListLoading',
    }),
  },
  components: {
    Accordion,
  },
  methods: {
    ...mapActions({
      getVacancyList: 'vacancy/GET_VACANCY_LIST',
    }),
    ...mapMutations({
      changeShowVacancyPopup: `vacancy/CHANGE_SHOW_FORM_WITH_VACANCY`,
    })
  },
  beforeDestroy() {
  },
}
