import Vue from 'vue'
import Router from 'vue-router';
import store from '@/store'

import Home from '@/modules/home/index.vue'
import ServerNotFound from '@/modules/system/server-not-found/index.vue'
import Event from '@/modules/event/index.vue'
import About from '@/modules/about/index.vue'
import Vacancy from '@/modules/vacancy/index.vue'
import Marathon from '@/modules/marathon/index.vue'
import NewsMain from '@/modules/news/pages/main/index.vue'
import NewsList from '@/modules/news/pages/list/index.vue'
import NewsDetail from '@/modules/news/pages/detail/index.vue'
import SpeakersList from '@/modules/speakers/pages/list/index.vue'
import SpeakersSample from '@/modules/speakers/pages/sample/index.vue'
import Test from '@/modules/test/index.vue'
import CoursesList from '@/modules/courses/pages/list/index.vue'
import CoursesSample from '@/modules/courses/pages/sample/index.vue'
import TranslationSample from '@/modules/translations/pages/sample/index.vue'
import Faq from '@/modules/faq/index.vue'
import Promo from '@/modules/landing/pages/detail/index.vue'
import Profile from '@/modules/profile/index.vue'
import ProfileSettings from '@/modules/profile/pages/setting/index.vue'
import Privacy from '@/modules/privacy/index.vue'
import Terms from '@/modules/terms/index.vue'

Vue.use(Router);


const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next();
    return
  }
  next('/')
};

const isNotAuthenticated = (to, from, next) => {
  console.log(store.state.auth.isAuthenticated);
  if (!store.state.auth.isAuthenticated) {
    next();
    return
  }
  next('/')
};

/**
 * https://github.com/vuejs/vue-router/issues/2881
 */
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:lang(.{2})?/',
      name: 'home',
      component: Home,
    },
    {
      path: '/:lang?/server-not-found', // 500
      name: 'server-not-found',
      component: ServerNotFound,
    },
    {
      path: '/:lang?/about',
      name: 'about',
      component: About,
    },
    {
      path: '/:lang?/vacancy',
      name: 'vacancy',
      component: Vacancy,
    },
    {
      path: '/:lang?/marathon',
      name: 'marathon',
      component: Marathon,
    },
    // {
    //   path: '/:lang?/news',
    //   name: 'news',
    //   component: NewsMain,
    // },
    // {
    //   path: '/:lang?/news/list',
    //   name: 'news-list',
    //   component: NewsList,
    // },
    // {
    //   path: '/:lang?/news/:slug',
    //   name: 'news-detail',
    //   component: NewsDetail,
    // },
    {
      path: '/:lang?/speakers/:category?',
      name: 'speakers',
      component: SpeakersList,
    },
    {
      path: '/:lang?/speakers/:category/:slug',
      name: 'speakers-sample',
      component: SpeakersSample,
    },
    {
      path: '/:lang?/test',
      name: 'test',
      component: Test,
    },
    // {
    //   path: '/:lang?/courses/:category?',
    //   name: 'courses',
    //   component: CoursesList,
    // },
    {
      path: '/:lang?/translation/:slug',
      name: 'translation-sample',
      component: TranslationSample,
    },
    {
      path: '/:lang?/faq',
      name: 'faq',
      component: Faq,
    },
    {
      path: '/:lang?/promo/:slug',
      name: 'promo',
      component: Promo,
    },
    {
      path: '/:lang?/profile',
      name: 'profile',
      component: Profile,
      beforeEnter: isAuthenticated,
      children: [
        {
          path: '/settings',
          name: 'setting',
          component: ProfileSettings,
        },
      ]
    },
    {
      path: '/:lang?/privacy-policy',
      name: 'privacy',
      component: Privacy,
    },
    {
      path: '/:lang?/terms-of-use',
      name: 'terms',
      component: Terms,
    },
    // {
    //   path: '/:lang?/:slug',
    //   name: 'event',
    //   component: Event,
    // },
    {
      path: '/:lang?/*',
      redirect: { name: 'home' },
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    let el = document.querySelector('.app');
    if (to.path !== from.path && !to.fullPath.includes('profile')) {
      if (savedPosition) {
        return savedPosition
      } else {
        el.scrollLeft = 0;
        el.scrollTop = 0;

        return {x: 0, y: 0}
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  store.state.system.openMenu = false;
  const body = document.querySelector('body');
  body.classList.remove('fix');
  if (!to.matched.length) {
    next('/not-found');
    next();
  } else {
    // if(!router.history.current.path.includes('categories')){
    //     window.scroll({
    //         top: 0,
    //         left: 0,
    //         behavior: 'smooth'
    //     });
    // }
    next();
  }

});

export default router;
