import {fixBody} from '@/helpers/variables/index';

import {
  FIX_BODY,
  SHOW_GLOBAL_LOADER,
  POPUP_TEXT_CONTENT,
  SHOW_POPUP_TEXT_CONTENT,
  CHANGE_PAGE_INDEX,
  CHANGE_TRANSITION_NAME,
  SHOW_POPUP_THANKS,
  CHANGE_FORM_THANKS_POPUP,
  CHAT_REGISTRATION_POPUP_CHANGE_AVAILABILITY_STATUS,
  CHAT_SIGN_IN_POPUP_CHANGE_AVAILABILITY_STATUS,
  CHAT_RESTORE_PASSWORD_POPUP_CHANGE_AVAILABILITY_STATUS,
  CHANGE_SHOW_COOKIES_POPUP,
} from '../mutation-types';

const state = {
  pageIndex: 1,
  transitionName: null,
  globalLoader: false,
  fixBody: false,
  popup: {
    show: false,
    title: '',
    text: ''
  },
  popupTextShow: false,
  popupThanks: false,
  formThanksPopup:{
    show: false,
    content: ''
  },
  chatRegistrationPopup: {
    show: false,
    title: '',
    content: '',
  },
  chatSignInPopup: {
    show: false,
    title: '',
    content: '',
  },
  chatRestorePasswordPopup: {
    show: false,
    title: '',
    content: '',
  },
  isShowCookiesPopup: false,
};

const getters = {
  pageIndex: state => state.pageIndex,
  transitionName: state => state.transitionName,
  fixBody: state => state.fixBody,
  globalLoader: state => state.globalLoader,
  popup: state => state.popup,
  popupTextShow: state => state.popupTextShow,
  popupThanks: state => state.popupThanks,
  formThanksPopup: state => state.formThanksPopup,

  chatRegistrationPopup: state => state.chatRegistrationPopup,
  chatSignInPopup: state => state.chatSignInPopup,
  chatRestorePasswordPopup: state => state.chatRestorePasswordPopup,

  isShowCookiesPopup: state => state.isShowCookiesPopup,
};

const mutations = {
  [CHANGE_PAGE_INDEX](state, index) {
    state.pageIndex = index;
  },
  [SHOW_GLOBAL_LOADER](state, status) {
    state.globalLoader = status
  },
  [FIX_BODY](state, param) {
    const body = document.querySelector('body');
    if (param) {
      body.classList.add('fix');
    } else {
      body.classList.remove('fix');
    }
    state.fixBody = param
  },
  [POPUP_TEXT_CONTENT](state, content) {
    state.popup = content;
    fixBody(state.popup.show);
  },
  [SHOW_POPUP_TEXT_CONTENT](state, status) {
    state.popupTextShow = status
  },
  [CHANGE_TRANSITION_NAME](state, list) {
    state.transitionName = list;
  },
  [SHOW_POPUP_THANKS](state, status) {
    state.popupThanks = status
  },
  [CHANGE_FORM_THANKS_POPUP](state, content) {
    state.formThanksPopup = content
  },
  [CHAT_REGISTRATION_POPUP_CHANGE_AVAILABILITY_STATUS] (state, status) {
    state.chatRegistrationPopup.show = status;
  },
  [CHAT_SIGN_IN_POPUP_CHANGE_AVAILABILITY_STATUS] (state, status) {
    state.chatSignInPopup.show = status;
  },
  [CHAT_RESTORE_PASSWORD_POPUP_CHANGE_AVAILABILITY_STATUS] (state, status) {
    state.chatRestorePasswordPopup.show = status;
  },
  [CHANGE_SHOW_COOKIES_POPUP](state, status) {
    state.isShowCookiesPopup = status;
  },
};

export default {
  state,
  getters,
  mutations,
};
