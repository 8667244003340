import {$http} from '@/utils/http'
import { CometChat } from '@cometchat-pro/chat';

import {
  TRANSLATIONS_FETCH_SAMPLE_DATA_START,
  TRANSLATIONS_FETCH_SAMPLE_DATA_SUCCESS,
  TRANSLATIONS_FETCH_SAMPLE_DATA_FAILURE,
  TRANSLATIONS_FETCH_SAMPLE_DATA_FULFILLED,
  TRANSLATIONS_COMETCHAT_INIT_START,
  TRANSLATIONS_COMETCHAT_INIT_SUCCESS,
  TRANSLATIONS_COMETCHAT_INIT_FAILURE,
  TRANSLATIONS_COMETCHAT_INIT_FULFILLED,
  TRANSLATIONS_COMETCHAT_USER_LOGIN_START,
  TRANSLATIONS_COMETCHAT_USER_LOGIN_SUCCESS,
  TRANSLATIONS_COMETCHAT_USER_LOGIN_FAILURE,
  TRANSLATIONS_COMETCHAT_USER_LOGIN_FULFILLED,
  TRANSLATIONS_USER_REGISTRATION_START,
  TRANSLATIONS_USER_REGISTRATION_SUCCESS,
  TRANSLATIONS_USER_REGISTRATION_FAILURE,
  TRANSLATIONS_USER_REGISTRATION_FULFILLED,
  TRANSLATIONS_USER_SIGN_IN_START,
  TRANSLATIONS_USER_SIGN_IN_SUCCESS,
  TRANSLATIONS_USER_SIGN_IN_FAILURE,
  TRANSLATIONS_USER_SIGN_IN_FULFILLED,
  TRANSLATIONS_FETCH_BANNERS_LIST_START,
  TRANSLATIONS_FETCH_BANNERS_LIST_SUCCESS,
  TRANSLATIONS_FETCH_BANNERS_LIST_FAILURE, TRANSLATIONS_FETCH_BANNERS_LIST_FULFILLED,
  SEND_CODE_LOADING
} from '../mutation-types';

import {
  TRANSLATIONS_FETCH_SAMPLE_DATA_TRIGGER,
  TRANSLATIONS_COMETCHAT_INIT_TRIGGER,
  TRANSLATIONS_COMETCHAT_USER_LOGIN_TRIGGER,
  TRANSLATIONS_USER_REGISTRATION_TRIGGER, TRANSLATIONS_USER_SIGN_IN_TRIGGER, TRANSLATIONS_FETCH_BANNERS_LIST_TRIGGER,
  SEND_CODE
} from '../action-types';
import bannersList from "@/assets/static/translations/banners-list";
import bannersData from "@/assets/static/translations/banners-list";

const state = {
  // translation sample
  sample: {
    data: null,
    loading: false,
    done: false,
    error: null,
  },

  // translation chat
  chat: {
    config: {
      appId: process.env.VUE_APP_COMETCHAT_APP_ID,
      region: process.env.VUE_APP_COMETCHAT_REGION,
    },
    init: {
      loading: false,
      done: false,
      error: null,
    },
    login: {
      loading: false,
      done: false,
      error: null,
    },
  },

  // user
  user: {
    credentials: !!localStorage.getItem('chat_credentials')
      ? JSON.parse(localStorage.getItem('chat_credentials')) : [],

    data: null,

    login: {
      loading: false,
      done: false,
      error: null,
    },

    registration: {
      loading: false,
      done: false,
      error: null,
    }
  },

  // banners
  banners: {
    timeout: 5000,
    data: [],
    loading: false,
    done: false,
    error: null,
  },

  // send code
  sendCodeLoading: false
};

const getters = {
  // sample
  sampleData: state => state.sample.data,
  sampleLoading: state => state.sample.loading,
  sampleFetched: state => state.sample.done,
  sampleError: state => state.sample.error,

  // user
  userCredentials: state => state.user.credentials,
  userData: state => state.user.data,
  userLogin: state => state.user.login,
  userRegistration: state => state.user.registration,

  // chat
  chatInitLoading: state => state.chat.init.loading,
  chatInitDone: state => state.chat.init.done,
  chatInitError: state => state.chat.init.error,

  chatLoginLoading: state => state.chat.login.loading,
  chatLoginDone: state => state.chat.login.done,
  chatLoginError: state => state.chat.login.error,

  // banners
  bannersData: state => state.banners.data,
  bannersTimeout: state => state.banners.timeout,
  bannersLoading: state => state.banners.loading,
  bannersDone: state => state.banners.done,
  bannersError: state => state.banners.error,

  //send code
  sendCodeLoading: state => state.sendCodeLoading,
};

const actions = {
  // fetch sample
  [TRANSLATIONS_FETCH_SAMPLE_DATA_TRIGGER]: async ({ commit }, { slug }) => {
    try {
      commit(TRANSLATIONS_FETCH_SAMPLE_DATA_START);

      const response = await $http.get(`v1/broadcasts/${slug}?include=chats`);
      commit(TRANSLATIONS_FETCH_SAMPLE_DATA_SUCCESS, response.data.data);

      // data mock
      // commit(TRANSLATIONS_FETCH_SAMPLE_DATA_SUCCESS, translationsSample);
    } catch (error) {
      commit(TRANSLATIONS_FETCH_SAMPLE_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(TRANSLATIONS_FETCH_SAMPLE_DATA_FULFILLED);
    }
  },

  // register user to system
  [TRANSLATIONS_USER_REGISTRATION_TRIGGER]: async ({ state, commit }, params) => {
    commit(TRANSLATIONS_USER_REGISTRATION_START);

    try {
      // register request
      const updatedParams = {
        ...params,
        broadcast: state.sample.data.id,
      }

      // image upload
      if (params.avatar) {
        const formData = new FormData();

        formData.append('photo', params.avatar);

        const { data: { url: image }} = await $http.post('v1/avatar/upload', formData);
        updatedParams.avatar = image;
      } else {
        delete updatedParams.avatar;
      }

      console.log(updatedParams);

      const response = await $http.post('v1/broadcasts/chat/register', updatedParams);

      commit(TRANSLATIONS_USER_REGISTRATION_SUCCESS, { ...response.data, broadcast: state.sample.data.id });
    } catch (error) {
      commit(TRANSLATIONS_USER_REGISTRATION_FAILURE, error);
      throw error;
    } finally {
      commit(TRANSLATIONS_USER_REGISTRATION_FULFILLED);
    }
  },

  // login user to system
  [TRANSLATIONS_USER_SIGN_IN_TRIGGER]: async ({ state, commit }, params) => {
    commit(TRANSLATIONS_USER_SIGN_IN_START);

    try {
      const updatedParams = {
        ...params,
        broadcast: state.sample.data.id,
      }
      const response = await $http.post('v1/broadcasts/chat/login', updatedParams);

      commit(TRANSLATIONS_USER_SIGN_IN_SUCCESS, { ...response.data, broadcast: state.sample.data.id });
    } catch (error) {
      commit(TRANSLATIONS_USER_SIGN_IN_FAILURE, error);
      throw error;
    } finally {
      commit(TRANSLATIONS_USER_SIGN_IN_FULFILLED);
    }
  },

  // chat init
  [TRANSLATIONS_COMETCHAT_INIT_TRIGGER]: ({ state, commit }) => {
    commit(TRANSLATIONS_COMETCHAT_INIT_START);

    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(state.chat.region)
      .build();

    CometChat.init(state.chat.config.appId, appSetting).then(
      () => {
        console.log("Initialization completed successfully");
        commit(TRANSLATIONS_COMETCHAT_INIT_SUCCESS);
      },
      error => {
        console.log("Initialization failed with error:", error);
        commit(TRANSLATIONS_COMETCHAT_INIT_FAILURE, error);
      }
    );
  },

  // chat user login
  [TRANSLATIONS_COMETCHAT_USER_LOGIN_TRIGGER]: ({ state, commit }) => {
    commit(TRANSLATIONS_COMETCHAT_USER_LOGIN_START);

    const currentBroadcastId = state.sample.data.id;
    const foundCredential = state.user.credentials.find(credential => credential.broadcast === currentBroadcastId);

    if (foundCredential && 'authToken' in foundCredential) {
      console.log(foundCredential.authToken);

      CometChat.login(foundCredential.authToken).then(
        user => {
          console.log("Login Successful:", { user });
          commit(TRANSLATIONS_COMETCHAT_USER_LOGIN_SUCCESS, user);
        },
        error => {
          console.log("Login failed with exception:", { error });
          commit(TRANSLATIONS_COMETCHAT_USER_LOGIN_FAILURE, error);
        }
      );
    }
  },

  // fetch banners list
  [TRANSLATIONS_FETCH_BANNERS_LIST_TRIGGER]: async ({ state, commit }) => {
    try {
      commit(TRANSLATIONS_FETCH_BANNERS_LIST_START);

      const response = await $http.get('v1/banners');
      console.log(response);
      commit(TRANSLATIONS_FETCH_BANNERS_LIST_SUCCESS, response.data);

      // commit(TRANSLATIONS_FETCH_BANNERS_LIST_SUCCESS, bannersData);
    } catch (error) {
      commit(TRANSLATIONS_FETCH_BANNERS_LIST_FAILURE, error);
      throw error;
    } finally {
      commit(TRANSLATIONS_FETCH_BANNERS_LIST_FULFILLED);
    }
  },

  //send marathon code
  [SEND_CODE]: async ({commit} , payload) => {
    try {
      commit(SEND_CODE_LOADING, true);
      const response = await $http.post(`v1/lottery/register`, payload);
      console.log(response)
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SEND_CODE_LOADING, false);
    }
  },
};

const mutations = {
  // sample
  [TRANSLATIONS_FETCH_SAMPLE_DATA_START] (state) {
    state.sample.loading = true;
    state.sample.done = false;
    state.sample.error = null;
  },
  [TRANSLATIONS_FETCH_SAMPLE_DATA_SUCCESS] (state, data) {
    state.sample.data = data;
    state.sample.done = true;
  },
  [TRANSLATIONS_FETCH_SAMPLE_DATA_FAILURE] (state, error) {
    state.sample.error = error;
  },
  [TRANSLATIONS_FETCH_SAMPLE_DATA_FULFILLED] (state) {
    state.sample.loading = false;
  },

  // register user to system
  [TRANSLATIONS_USER_REGISTRATION_START] (state) {
    state.user.registration.loading = true;
    state.user.registration.done = false;
    state.user.registration.error = null;
  },
  [TRANSLATIONS_USER_REGISTRATION_SUCCESS] (state, payload) {
    const credentialIndex = state.user.credentials.findIndex(credential => credential.broadcast === payload.broadcast);
    const updatedUserCredentials = state.user.credentials;

    if (credentialIndex !== -1) {
      updatedUserCredentials[credentialIndex] = payload;
    } else {
      updatedUserCredentials.push(payload);
    }

    state.user.credentials = updatedUserCredentials;
    localStorage.setItem('chat_credentials', JSON.stringify(updatedUserCredentials));

    localStorage.setItem('chat_user_has_been_registered', JSON.stringify(true));

    state.user.registration.done = true;
  },
  [TRANSLATIONS_USER_REGISTRATION_FAILURE] (state, error) {
    state.user.registration.error = error;
  },
  [TRANSLATIONS_USER_REGISTRATION_FULFILLED] (state) {
    state.user.registration.loading = false;
  },

  // login user to system
  [TRANSLATIONS_USER_SIGN_IN_START] (state) {
    state.user.login.loading = true;
    state.user.login.done = false;
    state.user.login.error = null;
  },
  [TRANSLATIONS_USER_SIGN_IN_SUCCESS] (state, payload) {
    const credentialIndex = state.user.credentials.findIndex(credential => credential.broadcast === payload.broadcast);
    const updatedUserCredentials = state.user.credentials;

    if (credentialIndex !== -1) {
      updatedUserCredentials[credentialIndex] = payload;
    } else {
      updatedUserCredentials.push(payload);
    }

    state.user.credentials = updatedUserCredentials;
    localStorage.setItem('chat_credentials', JSON.stringify(updatedUserCredentials));

    state.user.login.done = true;
  },
  [TRANSLATIONS_USER_SIGN_IN_FAILURE] (state, error) {
    state.user.login.error = error;
  },
  [TRANSLATIONS_USER_SIGN_IN_FULFILLED] (state) {
    state.user.login.loading = false;
  },

  // init chat
  [TRANSLATIONS_COMETCHAT_INIT_START] (state) {
    state.chat.init.loading = true;
    state.chat.init.done = false;
    state.chat.init.error = null;
  },
  [TRANSLATIONS_COMETCHAT_INIT_SUCCESS] (state) {
    state.chat.init.done = true;
    state.chat.init.loading = false;
  },
  [TRANSLATIONS_COMETCHAT_INIT_FAILURE] (state, error) {
    state.chat.init.error = error;
    state.chat.init.loading = false;
  },
  [TRANSLATIONS_COMETCHAT_INIT_FULFILLED] (state) {
    state.chat.init.loading = false;
  },

  // login chat
  [TRANSLATIONS_COMETCHAT_USER_LOGIN_START] (state) {
    state.chat.login.loading = true;
    state.chat.login.done = false;
    state.chat.login.error = null;
  },
  [TRANSLATIONS_COMETCHAT_USER_LOGIN_SUCCESS] (state, user) {
    state.user.data = user;
    state.chat.login.done = true;
    state.chat.login.loading = false;
  },
  [TRANSLATIONS_COMETCHAT_USER_LOGIN_FAILURE] (state, error) {
    state.chat.login.error = error;
    state.chat.login.loading = false;
  },
  [TRANSLATIONS_COMETCHAT_USER_LOGIN_FULFILLED] (state) {
    state.chat.login.loading = false;
  },

  // banners list
  [TRANSLATIONS_FETCH_BANNERS_LIST_START] (state) {
    state.banners.loading = true;
    state.banners.done = false;
    state.banners.error = null;
  },
  [TRANSLATIONS_FETCH_BANNERS_LIST_SUCCESS] (state, payload) {
    state.banners.data = payload.data.banners.data;
    state.banners.timeout = payload.data.timeout;
    state.banners.done = true;
    state.banners.loading = false;
  },
  [TRANSLATIONS_FETCH_BANNERS_LIST_FAILURE] (state, error) {
    state.banners.error = error;
    state.banners.loading = false;
  },
  [TRANSLATIONS_FETCH_BANNERS_LIST_FULFILLED] (state) {
    state.banners.loading = false;
  },
  //send code
  [SEND_CODE_LOADING](state,status){
    state.sendCodeLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
