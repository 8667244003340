import {mapMutations} from "vuex";

export default {
  name: 'event-info',
  props: ['item'],
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  components: {},
  methods: {
    ...mapMutations({
      changeShowTrailerPopup: `event/CHANGE_SHOW_TRAILER_POPUP`,
      changeTrailerPopupInfo: `event/CHANGE_TRAILER_POPUP_INFO`,
    }),
    changeTrailerPopup() {
      this.changeTrailerPopupInfo({
        videoId: this.item.trailer,
        title: this.item.title
      });
      setTimeout(()=>{
        this.changeShowTrailerPopup(true);
      },150)
    }
  },
  beforeDestroy() {
  },
}
