import Vue from 'vue';
import {mapActions, mapGetters, mapMutations} from 'vuex';

import mainFooter from '@/components/features/footer/index.vue'
import stepItem from './components/step/index.vue'
import {email, maxLength, required} from "vuelidate/lib/validators";
import {camelCase} from "lodash";

export default {
  name: 'test',
  data() {
    return {
      startTest: false,
      showResult: false,
      userResponses: [
        {
          answer: 5,
          id: 0
        }
      ],
      setShowLoader: false,
      setShowLoaderBg: false,
      payload: {
        email: '',
      },
      validationErrors: {}
    }
  },
  validations: {
    payload: {
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
    }
  },
  components: {
    mainFooter,
    stepItem
  },
  watch: {
    testList: function (newVal) {
      if (newVal) {
        this.userResponses = Array(this.testList[0].questions.data.length).fill(null);
      }
    },
  },
  computed: {
    ...mapGetters({
      testList: 'test/testList',
      testListLoading: 'test/testListLoading',
      activeStep: 'test/activeStep',
      testResult: 'test/testResult',
      testResultLoading: 'test/testResultLoading',
      sendResultLoading: 'test/sendResultToEmail',
    }),
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('email')))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('maxLengthField').replace(':field', this.$t('email')).replace(':count', 60));
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    }
  },
  created() {
    if( !this.testList ){
      this.fetchTestList().then(()=> {
        this.userResponses = Array(this.testList[0].questions.data.length).fill({answer: null,id: null});
      });
    }else{
      this.userResponses = Array(this.testList[0].questions.data.length).fill({answer: null,id: null});
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      fetchTestList: `test/GET_TEST_LIST`,
      getTestResult: `test/GET_TEST_RESULT`,
      sendResultToEmail: `test/SEND_RESULT_TO_MAIL`,
    }),
    ...mapMutations({
      incrementActiveCount: `test/INCREMENT_TEST_COUNTER`,
      decrementActiveCount: `test/DECREMENT_TEST_COUNTER`,
      changeActiveCounter: `test/CHANGE_TEST_COUNTER`,
      changeContentPopup: `system/POPUP_TEXT_CONTENT`,
      changeShowContentPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
    }),
    changeOption(index) {
      Vue.set(this.userResponses, this.activeStep , {answer: index,id: this.testList[0].questions.data[this.activeStep].id});
      if( this.activeStep + 1 <  this.testList[0].questions.data.length){
        this.nextQuestion();
      }else{
        let resultArray = [];

        for (let i = 0; i < this.userResponses.length; i++) {
          let elemObj = {
            id: this.userResponses[i].id,
            answer: this.userResponses[i].answer
          }
          resultArray.push(elemObj)
        }


        let obj = {};
        obj.slug = 'vektor';
        obj.result = resultArray;
        this.getTestResult(obj)
        this.showResult = true;
        this.showLoaderBg(1000);
      }
    },
    changeStartTest(){
      this.showLoaderBg(1000);
      this.startTest = true;
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    prevQuestion(){
      if( this.activeStep > 0 ){
        this.decrementActiveCount();
        this.showLoader(300);
        // window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
    nextQuestion(){
      if( this.activeStep < this.testList[0].questions.data.length ){
        this.incrementActiveCount();
        this.showLoader(300);
        // window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
    changeShowResult(){
      let resultArray = [];

      for (let i = 0; i < this.userResponses.length; i++) {
        let elemObj = {
          id: this.userResponses[i].id,
          answer: this.userResponses[i].answer
        }
        resultArray.push(elemObj)
      }


      let obj = {};
      obj.slug = 'vektor';
      obj.result = resultArray;
      this.getTestResult(obj)
      this.showResult = true;
      this.showLoaderBg(1000);
    },
    showLoader(time){
      this.setShowLoader = true;
      setTimeout(()=> {
        this.setShowLoader = false;
      },time)
    },
    showLoaderBg(time){
      this.setShowLoaderBg = true;
      setTimeout(()=> {
        this.setShowLoaderBg = false;
      },time)
    },
    scrollToResult(item){
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false,
        offset: -90,
      });
    },
    resetForm(){
      this.$v.$reset();
      this.payload.email = '';
    },
    submit(){
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let obj = {};
        obj.email = this.payload.email;
        obj.quiz = 'vektor';
        obj.total = this.testResult.total;

        this.sendResultToEmail(obj).then((response) => {

          this.$nextTick(()=>{
            this.changeContentPopup({
              text: this.$t('successSendTestResult'),
            });
          })
          setTimeout(()=> {
            this.changeShowContentPopup(true);
          },200)

          this.resetForm();
        }).catch(error => {
          console.log(error);
          if (error.response.status === 418) {
            this.$toasted.error(error.response.data.message);
            const errors = error.response.data.errors;
            for (const i in errors) {
              Vue.set(this.validationErrors, camelCase(i), errors[i]);
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            const errors = error.response.data.errors;
            for (const i in errors) {
              Vue.set(this.validationErrors, camelCase(i), errors[i]);
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        });
      }
    }
  }
  ,
  destroyed(){
    this.changeActiveCounter(0);
    this.userResponses=Array(this.testList[0].questions.data.length).fill(null);
  }
}
