import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'news-top',
  props:['list'],
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      topNewsListLoading: 'news/topNewsListLoading',
    }),
  },
  components: {
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),
  },
  beforeDestroy() {
  },
}
