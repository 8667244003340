import {$http} from '@/utils/http'
import {
  SET_SPEAKERS_LIST,
  SET_SPEAKERS_LIST_LOADING,
  SET_SPEAKERS_ITEM,
  SET_SPEAKERS_ITEM_LOADING,
  CHANGE_SKIP,
  SET_LIST_LENGTH,
  CHANGE_CATEGORY,
  SET_SPEAKERS_CATEGORY_LIST,
  SET_SPEAKERS_CATEGORY_LIST_LOADING,
  SET_SPEAKERS_LIST_BY_CATEGORY,
  SET_SPEAKERS_LIST_BY_CATEGORY_LOADING,
  SET_SPEAKERS_INFO_LOADING,
  SET_SPEAKERS_INFO
} from '../mutation-types';

import {
  GET_SPEAKERS_LIST,
  GET_SPEAKERS_ITEM,
  GET_SPEAKERS_CATEGORY_LIST,
  GET_SPEAKERS_LIST_BY_CATEGORY,
  GET_SPEAKERS_INFO
} from '../action-types';


const state = {
  speakersList: null,
  speakersListLoading: false,
  speakersListLength: 0,

  speakersItem: null,
  speakersItemLoading: false,

  skip: 0,
  limit: 6,
  listLength: 0,
  activeCategory: 'all',
  categoryList: null,
  categoryListLoading: false,

  speakerInfo: null,
  speakerInfoLoading: false
};

const getters = {
  speakersList: state => state.speakersList,
  speakersListLoading: state => state.speakersListLoading,
  speakersListLength: state => state.speakersListLength,

  speakersItem: state => state.speakersItem,
  speakersItemLoading: state => state.speakersItemLoading,

  skip: state => state.skip,
  limit: state => state.limit,
  listLength: state => state.listLength,
  activeCategory: state => state.activeCategory,

  categoryList: state => state.categoryList,
  categoryListLoading: state => state.categoryListLoading,


  speakerInfo: state => state.speakerInfo,
  speakerInfoLoading: state => state.speakerInfoLoading,
};

const actions = {
  [GET_SPEAKERS_LIST]: async ({commit}) => {
    commit(SET_SPEAKERS_LIST_LOADING, true);
    try {
      commit(CHANGE_SKIP);
      const response = await $http.get(`v1/speakers`)
      commit(SET_SPEAKERS_LIST, response.data.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_SPEAKERS_LIST_LOADING, false);
    }
  },
  [GET_SPEAKERS_LIST_BY_CATEGORY]: async ({commit}, payload) => {
    commit(SET_SPEAKERS_LIST_LOADING, true);
    try {
      commit(CHANGE_SKIP);
      const response = await $http.get(`v1/speaker/categories/${payload.slug}/speakers`)
      commit(SET_SPEAKERS_LIST, response.data.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_SPEAKERS_LIST_LOADING, false);
    }
  },
  [GET_SPEAKERS_ITEM]: async ({commit}, payload) => {
    commit(SET_SPEAKERS_ITEM_LOADING, true);
    try {
      const response = await $http.get(`v1/speaker/${payload.slug}`);
      commit(SET_SPEAKERS_ITEM, response.data.data);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_SPEAKERS_ITEM_LOADING, false);
    }
  },
  [GET_SPEAKERS_CATEGORY_LIST]: async ({commit}) => {
    commit(SET_SPEAKERS_CATEGORY_LIST_LOADING, true);
    try {
      commit(CHANGE_SKIP);
      const response = await $http.get(`v1/speaker/categories`)
      commit(SET_SPEAKERS_CATEGORY_LIST, response.data.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_SPEAKERS_CATEGORY_LIST_LOADING, false);
    }
  },
  [GET_SPEAKERS_INFO]: async ({commit}) => {
    commit(SET_SPEAKERS_INFO_LOADING, true);
    try {
      commit(CHANGE_SKIP);
      const response = await $http.get(`v1/pages/speaker-list`)
      commit(SET_SPEAKERS_INFO, response.data.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SET_SPEAKERS_INFO_LOADING, false);
    }
  },
};

const mutations = {
  [SET_SPEAKERS_LIST](state, list) {
    state.speakersList = list;
  },
  [SET_SPEAKERS_LIST_LOADING](state, status) {
    state.speakersListLoading = status;
  },
  [SET_SPEAKERS_LIST_BY_CATEGORY](state, list) {
    state.speakersListByCategory = list;
  },
  [SET_SPEAKERS_LIST_BY_CATEGORY_LOADING](state, status) {
    state.speakersListByCategoryLoading = status;
  },
  [SET_SPEAKERS_ITEM](state, data) {
    state.speakersItem = data;
  },
  [SET_SPEAKERS_ITEM_LOADING](state, status) {
    state.speakersItemLoading = status;
  },
  [SET_LIST_LENGTH](state, data) {
    state.speakersListLength = data;
  },
  [CHANGE_SKIP](state) {
    state.skip += state.limit;
  },
  [CHANGE_CATEGORY](state, category) {
    state.activeCategory = category;
    state.skip = 3;
    state.listLength = 0;
    state.list = null;
  },
  [SET_LIST_LENGTH](state, data) {
    state.speakersListLength = data;
  },
  [SET_SPEAKERS_CATEGORY_LIST](state, list) {
    state.categoryList = list;
  },
  [SET_SPEAKERS_CATEGORY_LIST_LOADING](state, status) {
    state.categoryListLoading = status;
  },
  [SET_SPEAKERS_INFO](state, list) {
    state.speakerInfo = list;
  },
  [SET_SPEAKERS_INFO_LOADING](state, status) {
    state.speakerInfoLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
