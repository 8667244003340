import {mapActions, mapGetters, mapMutations} from 'vuex';
import mainFooter from '@/components/features/footer/index.vue';
import Moment from 'moment';
export default {
  name: 'promo',
  data() {
    return {
      item: [],
      optionsSmall: {
        rel: 0,
        showinfo: 0,
        modestbranding: 1,
        muted: false,
      },
      utm_medium: '',
      utm_source: '',
    }
  },
  components: {
    mainFooter
  },
  watch: {
    'showRestorePassword'(){
      if( !this.isAuthenticated && !this.showRestorePassword){
        setTimeout(()=> {
          if(!this.popupTextShow){
            this.changePromoPopupRegister(true);
          }
        },400)
      }
    },
    'popupTextShow'(){
      if( !this.isAuthenticated && !this.popupTextShow ){
        this.changePromoPopupRegister(true);
      }
    },
    'isShowPromoSignInPopup'() {
      if (!this.isShowPromoSignInPopup && this.isAuthenticated) {
        this.$refs.youtubePlayer.player.seekTo(0);
        this.$refs.youtubePlayer.player.playVideo();
      }
    },
    'isAuthenticated'(){
      if( this.isAuthenticated ){
        this.$refs.youtubePlayer.player.seekTo(0);
        this.$refs.youtubePlayer.player.playVideo();
        document.body.addEventListener('click', this.sendClickRequest, true);
      }
    }
  },
  computed: {
    ...mapGetters({
      index: 'event/pageIndex',
      eventItem: 'event/eventItem',
      isAuthenticated: 'auth/isAuthenticated',
      buyEventLoading: 'event/buyEventLoading',
      showRestorePassword: 'auth/showRestorePassword',
      popupTextShow: 'system/popupTextShow',
      isShowPromoSignInPopup: 'landing/isShowPromoSignInPopup',
      registerFromLandingInfo: 'landing/isRegisterFromLandingPage'
    }),
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
  },
  created() {
    let lang = this.currentLanguage.translationKey
    console.log(this.$route.params.slug);
    this.getEventItem().then(()=>{
      console.log(this.eventItem);

      for(var i = 0; i < this.eventItem.length; i++) {
        if(this.eventItem[i].slug === this.$route.params.slug) {
          this.item = this.eventItem[i][lang.toString()];
          console.log(this.item);
          break;
        }
      }

      this.gtm(this.item);


      // set register from landing page label
      this.changeRegisterFromLanding({value: true, course: this.item.name});

    });

    if (!this.isAuthenticated) {
      this.changePromoPopupRegister(true);
    } else {
      this.fetchUserData();
      document.body.addEventListener('click', this.sendClickRequest, true);

    }
    this.setUtm();
  },
  mounted() {
    console.log(this.$refs.youtubePlayer);
  },
  methods: {
    ...mapActions({
      getEventItem: `event/GET_EVENT_ITEM`,
      buyProduct: `event/BUY_PRODUCT`,
      fetchUserData: `profile/FETCH_USER_DATA`,
      sendClickRequestAction: `landing/SEND_CLICK_REQUEST`,
    }),
    ...mapMutations({
      changeTransitionName: `system/CHANGE_TRANSITION_NAME`,
      changeIndex: `system/CHANGE_PAGE_INDEX`,
      changeShowSubscribePopup: `marathon/CHANGE_SHOW_SUBSCRIBE_POPUP`,
      changePopupSignIn: `auth/CHANGE_POPUP_SIGN_IN`,
      changeBuyPopupContent: `event/SHOW_POPUP_BUY_FORM`,
      changeShowRecordPopup: `event/CHANGE_SHOW_RECORD_POPUP`,
      changeRecordPopupContent: `event/CHANGE_RECORD_POPUP_INFO`,
      changeShowSubscribeEventPopup: `event/CHANGE_SHOW_SUBSCRIBE_EVENT_POPUP`,
      changeSubscribeEventPopupInfo: `event/CHANGE_RECORD_POPUP_INFO`,
      changeShowPopupBuyForm: `event/CHANGE_SHOW_POPUP_BUY_FORM`,
      changeShowBuyPopup: `event/SHOW_POPUP_BUY_FORM`,
      changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
      changePopupRegisterInfo: `auth/CHANGE_POPUP_REGISTER_INFO`,

      changePromoPopupRegister: 'landing/CHANGE_SHOW_PROMO_REGISTER_POPUP',
      changePromoPopupSignIn: 'landing/CHANGE_SHOW_PROMO_SIGNIN_POPUP',
      changeRegisterFromLanding: 'landing/CHANGE_REGISTER_FROM_LANDING'
    }),
    gtm(item) {
      this.$gtm.trackEvent({
        'event': 'addToCart',
        'ecommerce': {
          'currencyCode': 'EUR',
          'add': {
            'products': [{
              'name': item.title,
              'id': item.id,
              'price': item.uah_price,
              'category': item.category_type,
              'autor': item.speaker_name,
              'type': item.status,
              'quantity': 1
            }],
          }
        }
      });
    },
    buyEvent(price) {

      let newPrice = [];
      for (let i = 0; i < this.item.payment_variables.length; i++) {
        let dif = Moment(Moment()).isBefore(Moment(this.item.payment_variables[i].date, 'DD.MM'));
        console.log('dif', dif);
        if (dif) {
          newPrice.push(this.item.payment_variables[i]);
        }
      }
      console.log('newPrice.length', newPrice.length);
      if (newPrice.length) {
        this.finalPrice = newPrice[0].price;
        console.log('newPrice', this.finalPrice);
      } else {
        this.finalPrice = this.item.payment_variables[2].price;
      }
      console.log('finalPrice------', this.finalPrice)
      if (this.isAuthenticated && this.finalPrice) {
        let obj = {};
        obj.slug = this.item.slug;
        obj.price = this.finalPrice;
        if( this.utm_medium ){
          obj.utm_medium = this.utm_medium;
          obj.utm_source = this.utm_source;
          obj.site_url = window.location.origin+window.location.pathname;
        }
        this.buyProduct(obj)
          .then(() => {

            this.changeShowBuyPopup({price: this.finalPrice, title: this.item.name});
            setTimeout(() => {
              this.changeShowPopupBuyForm(true);
            })
          }).catch(error => {
          if (error.response.status === 418) {
            this.$toasted.error(error.response.data.message);
          }
        });
      } else {
        if (this.finalPrice) {
          this.changePopupRegister(true);
          this.changePopupRegisterInfo({course: this.item.name});
        }
      }

      this.gtm(this.item);
    },
    getRecord(){
      this.changeRecordPopupContent({title: this.item.name})
      setTimeout(()=> {
        this.changeShowRecordPopup(true);
      },200)
    },
    sendClickRequest(){
      let obj = {};
      obj.course = this.item.name;
      if( this.utm_medium ){
        obj.utm_medium = this.utm_medium
        obj.utm_source = this.utm_source
        obj.site_url = window.location.origin+window.location.pathname;
      }
      this.sendClickRequestAction(obj);
    },
    setUtm(){
      this.utm_medium = this.$route.query.utm_medium;
      this.utm_source = this.$route.query.utm_source;
    }
  },
  destroyed() {
    this.changeRegisterFromLanding({value: false, course: ''});
    document.body.removeEventListener('click', this.sendClickRequest, true);
  }
}
