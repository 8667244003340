import {$http} from '@/utils/http'

import {
  SET_FAQ_LIST_LOADING,
  SET_FAQ_LIST,
  SET_LOADING_ASK,
} from '../mutation-types';

import {
  GET_FAQ_LIST,
  SEND_ASK,
} from '../action-types';


const state = {
  list: null,
  listLoading: false,
  loadingAsk: false
};

const getters = {
  list: state => state.list,
  listLoading: state => state.listLoading,
  loadingAsk: state => state.loadingAsk,
};

const actions = {
  [GET_FAQ_LIST]: async ({commit}) => {
    commit(SET_FAQ_LIST_LOADING, true);
    try {
      const response = await $http.get('v1/faq');
      commit(SET_FAQ_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_FAQ_LIST_LOADING, false);
    }
  },
  [SEND_ASK]: async ({commit}, payload) => {
    commit(SET_LOADING_ASK, true);
    try {
      return await $http.post('v1/feedback', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_LOADING_ASK, false);
    }
  },
};

const mutations = {
  [SET_FAQ_LIST](state, list) {
    state.list = list;
  },
  [SET_FAQ_LIST_LOADING](state, status) {
    state.listLoading = status;
  },
  [SET_LOADING_ASK](state, status) {
    state.loadingAsk = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
