import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'profile',
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      user: 'profile/user',
      loadingUser: 'profile/loadingUser',
    })
  },
  created() {
    if (!this.user) {
      this.fetchUser();
    }
  },
  methods: {
    ...mapActions({
      fetchUser: `profile/FETCH_USER_DATA`,
      logout: `auth/LOGOUT`
    }),
    removeToken() {
      this.logout();
      this.$router.push({name: 'home'}).catch(()=> {console.log()})
    }
  }
}
