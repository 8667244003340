import {mapActions, mapGetters, mapMutations} from 'vuex';

import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'translation-form',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        code: '',
      },
      isValidPhone: false,
      validationErrors: {
        phone: []
      }
    }
  },
  validations: {
    payload: {
      first_name: {
        required,
        maxLength: maxLength(80),
      },
      last_name: {
        required,
        maxLength: maxLength(80),
      },
      email: {
        required,
        maxLength: maxLength(80),
        email,
      },
      code: {
        required,
        maxLength: maxLength(7),
        minLength: minLength(7),
      },
    },
  },
  computed: {
    ...mapGetters({
      sendCodeLoading: 'translations/sendCodeLoading',
    }),
    firstNameErrors() {
      let error = [];
      if (!this.$v.payload.first_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.first_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 80));
      }
      if (!this.$v.payload.first_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.first_name) {
        this.validationErrors.first_name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    lastNameErrors() {
      let error = [];
      if (!this.$v.payload.last_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 80));
      }
      if (!this.$v.payload.last_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    codeErrors() {
      let error = [];
      if (!this.$v.payload.code.$dirty) {
        return error;
      }
      if (!this.$v.payload.code.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 7));
      }
      if (!this.$v.payload.code.minLength) {
        error.push(this.$t('validationMin').replace(':count', 7));
      }
      if (!this.$v.payload.code.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.code) {
        this.validationErrors.code.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 80));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  components: {
  },
  created() {

  },
  methods: {
    ...mapActions({
      sendCode: `translations/SEND_CODE`
    }),

    ...mapMutations({
      showSendConfirmRegistrationPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
      setDataToContentPopup: `system/POPUP_TEXT_CONTENT`,
      changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
      changePopupSignIn: `auth/CHANGE_POPUP_SIGN_IN`,
      changeShowVacancyPopup: `vacancy/CHANGE_SHOW_FORM_WITH_VACANCY`,
      changeContentPopup: `system/POPUP_TEXT_CONTENT`,
      changeShowContentPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
    }),
    clearForm() {
      this.$v.$reset();
      this.payload.first_name = '';
      this.payload.last_name = '';
      this.payload.phone = '';
      this.payload.email = '';
      this.payload.code = '';
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        this.sendCode(this.payload)
          .then((response) => {

            this.changeShowVacancyPopup({show:false});
            this.clearForm();
            this.$nextTick(()=>{
              this.changeContentPopup({
                text: this.$t('translation.successSendCode'),
              });
            })
            setTimeout(()=> {
              this.changeShowContentPopup(true);
            },200)

          }).catch(error => {
            let formattedMessage = null;

            if (error && error.response) {
              if (error.response.data.message) {
                formattedMessage = error.response.data.message;
              }

              if (error.response.data.error) {
                formattedMessage = error.response.data.error.message;
              }

              if (error.response.data.errors) {
                Object.keys(error.response.data.errors).forEach(errorKey => {
                  error.response.data.errors[errorKey].forEach((line) => {
                    formattedMessage += `\r\n${line}`;
                  });
                });
              }
            }

            formattedMessage && this.$toasted.error(formattedMessage);
          });
      }
    },
    changePhoneInput(number, info) {
      this.isValidPhone = info.valid;
      if (this.isValidPhone) {
        this.payload.phone = info.number.e164.replace('+', '');
        this.validationErrors.phone = [];
      }
    },
    changeValidationPhone() {
      this.validationErrors.phone = [];
      if (this.payload.phone.length > 0) {
        if (!this.isValidPhone) {
          this.validationErrors.phone.push(this.$t('enterCorrectPhone'))
        } else {
          this.validationErrors.phone = [];
        }
      } else {
        this.validationErrors.phone.push(this.$t('enterCorrectPhone'))
      }
    },
  },
}
