import {mapGetters} from "vuex";

export default {
  name: 'test-item',
  props: ['item'],
  data() {
    return {
    }
  },
  components: {
  },
  computed: {
    ...mapGetters({
      testList: 'test/testList',
      testListLoading: 'test/testListLoading',
      activeStep: 'test/activeStep',
    })
  },
  methods: {
  },
}
