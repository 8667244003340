import {mapGetters, mapActions, mapMutations} from 'vuex';
import Accordion from '../../components/accordion/index.vue'
export default {
  name: 'home-faq',
  data() {
    return {
    }
  },
  components: {
    Accordion,
  },
  computed: {
    ...mapGetters({
      list: 'faq/list',
      listLoading: 'faq/listLoading'
    })
  },
  created() {
    if( !this.list ){
      this.fetchList()
    }
  },
  methods: {
    ...mapActions({
      fetchList: `faq/GET_FAQ_LIST`
    })
  }
}
