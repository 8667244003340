export default {
  name: 'video-section',
  props: ['item'],
  data() {
    return {
      videoId: 'nvDY05GAsd8',
    }
  },
  computed: {
    player: function () {
      return this.$refs.player;
    },
  }
}
