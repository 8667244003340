import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'about',
  props: {
    list: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  components: {
  },
  methods: {
  },
  beforeDestroy() {
  },
}
