import {$http} from '@/utils/http'
import {
  GET_MAIN_SLIDER_LOADING,
  SET_MAIN_SLIDER,
  SET_ABOUT_LIST_LOADING,
  SET_ABOUT_LIST,
  SET_CATEGORY_LIST_LOADING,
  SET_CATEGORY_LIST,
  SET_HOME_LIST_LOADING,
  SET_HOME_LIST,
  SET_CALENDAR_SLIDER_LOADING,
  SET_CALENDAR_SLIDER,
  CHANGE_SHOW_HOME_SECTION,
  CHANGE_SHOW_HOME_SVG_ANIMATION,
  CHANGE_SHOW_HOME_SVG_ANIMATION_ONCE,
  SET_ACTIVE_STREAM_VIDEO_LOADING,
  SET_ACTIVE_STREAM_VIDEO
} from '../mutation-types';

import {
  GET_MAIN_SLIDER,
  GET_ABOUT_LIST,
  GET_CATEGORY_LIST,
  GET_HOME_LIST,
  GET_CALENDAR_SLIDER,
  GET_ACTIVE_STREAM_VIDEO
} from '../action-types';


const state = {
  mainSlider: null,
  mainSliderLoading: false,
  aboutList: null,
  aboutListLoading: false,
  categoryListLoading: false,
  categoryList: null,
  newsListLoading: false,
  newsList: null,
  calendarSliderLoading: false,
  calendarSlider: null,
  showHomeSection: false,
  showSvgAnimation: false,
  showSvgAnimationOnce: true,

  // stream video id
  activeStreamVideo: null,
  activeStreamVideoLoading: false,
};

const getters = {
  mainSlider: state => state.mainSlider,
  mainSliderLoading: state => state.mainSliderLoading,
  aboutList: state => state.aboutList,
  aboutListLoading: state => state.aboutListLoading,
  categoryListLoading: state => state.categoryListLoading,
  categoryList: state => state.categoryList,
  newsListLoading: state => state.newsListLoading,
  newsList: state => state.newsList,
  calendarSliderLoading: state => state.calendarSliderLoading,
  calendarSlider: state => state.calendarSlider,
  showHomeSection: state => state.showHomeSection,
  showSvgAnimation: state => state.showSvgAnimation,
  showSvgAnimationOnce: state => state.showSvgAnimationOnce,


  // stream video id
  activeStreamVideo: state => state.activeStreamVideo,
  activeStreamVideoLoading: state => state.activeStreamVideoLoading,
};

const actions = {
  [GET_MAIN_SLIDER]: async ({commit}) => {
    commit(GET_MAIN_SLIDER_LOADING, true);
    try {
      // const response = await $http.get(`v1/faq`);
      // commit(SET_MAIN_SLIDER, response.data.data);
      commit(SET_MAIN_SLIDER, []);
    } catch (e) {
      throw e;
    } finally {
      commit(GET_MAIN_SLIDER_LOADING, false);
    }
  },
  [GET_ABOUT_LIST]: async ({commit}) => {
    commit(SET_ABOUT_LIST_LOADING, true);
    try {
      // const response = await $http.get(`v1/faq`);
      // commit(SET_MAIN_SLIDER, response.data.data);
      commit(SET_ABOUT_LIST, []);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_ABOUT_LIST_LOADING, false);
    }
  },
  [GET_CATEGORY_LIST]: async ({commit}) => {
    commit(SET_CATEGORY_LIST_LOADING, true);
    try {
      // const response = await $http.get(`v1/faq`);
      // commit(SET_MAIN_SLIDER, response.data.data);
      commit(SET_CATEGORY_LIST, []);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_CATEGORY_LIST_LOADING, false);
    }
  },
  [GET_HOME_LIST]: async ({commit}) => {
    commit(SET_HOME_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/pages/home`);
      commit(SET_HOME_LIST, []);
      commit(SET_HOME_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_HOME_LIST_LOADING, false);
    }
  },
  [GET_CALENDAR_SLIDER]: async ({commit}) => {
    commit(SET_CALENDAR_SLIDER_LOADING, true);
    try {
      // const response = await $http.get(`v1/faq`);
      // commit(SET_MAIN_SLIDER, response.data.data);
      commit(SET_CALENDAR_SLIDER, [
        {
          id: 2,
          img: `https://api.lifta.space/uploads/products/poster/5.jpg`,
          title: 'Аргументация в публичных выступлениях',
          desc: 'Как продать идею: структурность и логика - составляющие убедительности. Научись говорить так, чтобы тебя слышали.',
          speaker: 'Канат Ногойбаев',
          position: '',
          date: '25.03.2021',
          time: '',
          type: 'Online',
          link_type: 'external',
          link: 'https://lifta.space/ru/event/argumentaciya-v-publichnyh-vystupleniyah',
          button_date: '25.03'
        },
        {
          id: 4,
          img: `https://api.lifta.space/uploads/products/poster/7.jpg`,
          title: 'Стратегия эффективной благотворительности',
          desc: 'Узнай, как подойти к благотворительности с умом: быстрая и эффективная организайия благотворительного проекта.',
          speaker: 'Татьяна Гринева',
          position: '',
          date: '19.03.2021',
          time: '',
          type: 'Online',
          link_type: 'external',
          link: 'https://lifta.space/ru/event/fandrazing-v-korporativnoj-socialnoj-otvetstvennosti',
          button_date: '19.03'
        },
        {
          id: 5,
          img: `https://api.lifta.space/uploads/products/poster/8.jpg`,
          title: 'Как зарабатывать на блоге',
          desc: 'Как работая над своим блогом, получать соразмерную финансовую отдачу? Как упаковать контент в сотрудничестве с брендом, чтобы это было интересно аудитории?',
          speaker: 'Евгений Таллер',
          position: '',
          date: '23.03.2021',
          time: '',
          type: 'Online',
          link_type: 'external',
          link: 'https://lifta.space/ru/event/bloger-eto-rabota-monetizaciya-mikroblogov',
          button_date: '23.03'
        },
        {
          id: 6,
          img: `https://api.lifta.space/uploads/products/poster/12.jpg`,
          title: 'Контент-маркетинг',
          desc: 'Узнай, как работает пиар через инфлюэнсеров, и как запустить кампанию, ведущую к созданию юзер-контента.',
          speaker: 'Антон Степанюк',
          position: '',
          date: '13.04.2021',
          time: '',
          type: 'Online',
          link_type: 'external',
          link: 'https://lifta.space/ru/event/kontent-marketing',
          button_date: '13.04'
        },
        {
          id: 7,
          img: `https://api.lifta.space/uploads/products/poster/9.jpg`,
          title: 'Product-Market Fit или нужно ли запускать стартап?',
          desc: 'Получи пул знаний о запуске стартапа: как валидировать идеи, как проверять, стоит ли продукт запуска. Получи инструкцию в 10 шагов для того, чтобы твоя концепция превратилась в бизнес-модель.',
          speaker: 'Сергей Богословский',
          position: '',
          date: '29.03.2021',
          time: '',
          type: 'Запись',
          link_type: 'external',
          link: 'https://lifta.space/ru/event/product-market-fit',
          button_date: '29.03'
        },
        {
          id: 8,
          img: `https://api.lifta.space/uploads/products/poster/poryad.jpg`,
          title: 'Как эффективно работать с подрядчиками',
          desc: 'Поиск и работа с подрядчиками очень часто становится серьезной головной болью для руководителя или собственника бизнеса.',
          speaker: '',
          position: '',
          date: '10.03',
          time: '',
          type: 'Online',
          link_type: 'external',
          link: 'https://lifta.space/ru/event/kak-effektivno-rabotat-s-podryadchikami',
          button_date: '10.03'
        },
        {
          id: 9,
          img: `https://api.lifta.space/uploads/products/poster/tools.png`,
          title: 'Инструменты ремаркетинга или как вернуть клиента на сайт',
          desc: 'В современном мире информации и конкуренции пользователи постоянно серфят интернет в поисках информации, товаров и услуг.',
          speaker: '',
          position: '',
          date: '18.03.2021',
          time: '',
          type: 'Online',
          link_type: 'external',
          link: 'https://lifta.space/ru/event/instrumenty-remarketinga',
          button_date: '18.03'
        },
        {
          id: 10,
          img: `https://api.lifta.space/uploads/products/poster/10.jpg`,
          title: 'Запуск Интернет-магазина - с чего начать?',
          desc: 'Продажи товаров в интернете – это один из самых динамично-развивающихся бизнесов в СНГ.',
          speaker: 'Олег Зарицкий',
          position: '',
          date: '-',
          time: '',
          type: 'Online',
          link_type: 'external',
          link: 'https://lifta.space/ru/event/intensiv-rim',
          button_date: '-'
        },
        {
          id: 10,
          img: `https://api.lifta.space/uploads/products/poster/2.jpg`,
          title: 'Основы digital-маркетинга от А до Я для начинающих за 1 день.',
          desc: 'Вы владелец бизнеса, но вы далеки от понимания того, как этот бизнес эффективно продвигать в сети Интернет, но есть желание понять, что к чему?',
          speaker: 'Олег Зарицкий',
          position: '',
          date: '15.03',
          time: '',
          type: 'Online',
          link_type: 'external',
          link: 'https://lifta.space/ru/event/osnovy-digital-marketinga-ot-a-do-ya',
          button_date: '15.03'
        },
        {
          id: 11,
          img: `https://api.lifta.space/uploads/products/poster/4.jpg`,
          title: 'Основы веб-аналитики для начинающих за 1 день.',
          desc: 'Вы начинающий в Интернет-маркетинга и хотите разобраться в веб-аналитике и ее основных инструментах?',
          speaker: '',
          position: '',
          date: '02.03.2021',
          time: '',
          type: 'Online',
          link_type: 'external',
          link: 'https://lifta.space/ru/event/osnovy-veb-analitiki-dlya-nachinayushih',
          button_date: '02.03'
        },
        {
          id: 12,
          img: `https://api.lifta.space/uploads/products/poster/11.jpg`,
          title: 'Как освободить ресурс: трансцендируй от стресса к эффективности.',
          desc: 'Как наконец научиться управлять временем? Как сгладить острые углы и научиться не вовлекаться в конфликты?',
          speaker: 'Олег Авагян',
          position: '',
          date: '09.03.2021',
          time: '',
          type: 'Online',
          link_type: 'external',
          link: 'https://lifta.space/ru/event/kak-osvobodit-resurs',
          button_date: '09.03'
        },
        {
          id: 13,
          img: `https://api.lifta.space/uploads/products/poster/14.jpg`,
          title: 'Жить по собственным правилам',
          desc: 'Как прописать сценарий своей жизни, следовать ему и добиваться результатов: завязка — кульминация — победа. Как прожить свою жизнь, а не чужую.',
          speaker: 'Ирина Поляшева',
          position: '',
          date: '30.03.2021',
          time: '',
          type: 'Online',
          link_type: 'external',
          link: 'https://lifta.space/ru/event/zhit-po-sobstvennym-pravilam',
          button_date: '30.03'
        },
      ]);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_CALENDAR_SLIDER_LOADING, false);
    }
  },
  [GET_ACTIVE_STREAM_VIDEO]: async ({commit}) => {
    commit(SET_ACTIVE_STREAM_VIDEO_LOADING, true);
    try {
      const response = await $http.get(`v1/broadcasts/active`);
      commit(SET_ACTIVE_STREAM_VIDEO, response.data.data);
      console.log(response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_ACTIVE_STREAM_VIDEO_LOADING, false);
    }
  },
};

const mutations = {
  [GET_MAIN_SLIDER_LOADING](state, status) {
    state.mainSliderLoading = status;
  },
  [SET_MAIN_SLIDER](state, list) {
    state.mainSlider = list;
  },
  [SET_ABOUT_LIST_LOADING](state, status) {
    state.aboutListLoading = status;
  },
  [SET_ABOUT_LIST](state, list) {
    state.aboutList = list;
  },
  [SET_CATEGORY_LIST_LOADING](state, status) {
    state.categoryListLoading = status;
  },
  [SET_CATEGORY_LIST](state, list) {
    state.categoryList = list;
  },
  [SET_HOME_LIST_LOADING](state, status) {
    state.newsListLoading = status;
  },
  [SET_HOME_LIST](state, list) {
    state.newsList = list;
  },
  [SET_CALENDAR_SLIDER](state, status) {
    state.calendarSlider = status;
  },
  [SET_CALENDAR_SLIDER_LOADING](state, status) {
    state.calendarSliderLoading = status;
  },
  [CHANGE_SHOW_HOME_SECTION](state, status) {
    state.showHomeSection = status;
  },
  [CHANGE_SHOW_HOME_SVG_ANIMATION](state, status) {
    state.showSvgAnimation = status;
  },
  [CHANGE_SHOW_HOME_SVG_ANIMATION_ONCE](state, status) {
    state.showSvgAnimationOnce = status;
  },

  //active stream video id
  [SET_ACTIVE_STREAM_VIDEO](state, list) {
    state.activeStreamVideo = list;
  },
  [SET_ACTIVE_STREAM_VIDEO_LOADING](state, status) {
    state.activeStreamVideoLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
