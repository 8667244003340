
const speakersSample = {
  id: 0,
  name: 'Test Test',
  description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
  image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
  speciality: 'Test',
  slug: 'test_test',
  reviews_count: 3243,
  rating: 4,
  social_media: [
    {
      id: 0,
      name: 'instagram',
      external_link: 'https://instagram.com',
    },
    {
      id: 1,
      name: 'facebook',
      external_link: 'https://facebook.com',
    },
    {
      id: 2,
      name: 'twitter',
      external_link: 'https://twitter.com',
    }
  ],
  products: {
    data: [
      {
        id: 0,
        name: 'course-1',
        title: 'Работа с брендами',
        description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
        type: 'Курс',
        complexity: 'Новичкам',
        period: '12 месяцев',
        status: 'Online',
        rating: 5,
        reviews_count: 3243,
        base_price: 123,
        final_price: 32,
        discount_rate: 40,
      },
      {
        id: 1,
        name: 'course-2',
        title: 'Работа с брендами',
        description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
        type: 'Курс',
        complexity: 'Новичкам',
        period: '12 месяцев',
        status: 'Online',
        rating: 5,
        reviews_count: 3243,
        base_price: 123,
        final_price: 32,
        discount_rate: 40,
      },
      {
        id: 2,
        name: 'course-3',
        title: 'Работа с брендами',
        description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
        type: 'Курс',
        complexity: 'Новичкам',
        period: '12 месяцев',
        status: 'Online',
        rating: 5,
        reviews_count: 3243,
        base_price: 123,
        final_price: 32,
        discount_rate: 40,
      },
      {
        id: 3,
        name: 'course-4',
        title: 'Работа с брендами',
        description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
        type: 'Курс',
        complexity: 'Новичкам',
        period: '12 месяцев',
        status: 'Online',
        rating: 5,
        reviews_count: 3243,
        base_price: 123,
        final_price: 32,
        discount_rate: 40,
      },
    ]
  },
};

export default speakersSample;
