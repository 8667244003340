<template>
  <div class="main-popup">
    <div class="main-popup__wrap">
      <div class="main-popup__bg"></div>

      <div class="main-popup__container" :style="{'max-width': `${maxWidth}px`}">
        <div class="main-popup__top">
          <div class="main-popup__top-title">
            {{ title }}
          </div>
        </div>
        <div class="main-popup__content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'unclosed-popup-wrap',
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: Number,
      default: 500
    }
  },
}
</script>

<style lang="scss" scoped>

.main-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  overflow-y: hidden;

  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 151;
    overflow-y: hidden;
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 152;
    background: rgba(97, 97, 97, 0.6);
    backdrop-filter: blur(10px);
  }

  &__container {
    position: relative;
    width: 100%;
    max-height: 90%;
    max-width: 640px;
    background: #010203;
    z-index: 153;
    padding: 0 10px;
    border: 1px solid $black;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    overflow-y: auto;
  }

  &__top {
    width: 100%;
    border-bottom: solid 2px $black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;

    &-title {
      text-align: center;
      width: 100%;
      max-width: 90%;
      margin-bottom: 30px;
      font-family: HeliosExtBlack;
      font-style: normal;
      font-weight: 900;
      color: #FFFFFF;
      text-align: center;
      text-transform: uppercase;
      font-size: 24px;
      line-height: 30px;
    }
  }
}

@media screen and (max-width: 900px) {
  .main-popup {
    &__bg {
      position: fixed;
    }
    &__wrap {
      height: 100vh;
      padding-left: 10px;
      padding-right: 10px;
    }
    &__wrap {
      overflow-y: auto;
    }
  }
}

@media screen and (max-width: 500px) {
  .main-popup__top {
    padding: 0 30px;
  }
  .main-popup__top-title {
    font-size: 20px;
    line-height: 24px;
  }
}
</style>

