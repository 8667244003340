import {fixBody} from "@/helpers/variables";
import {$http} from "@/utils/http";

import {
  PROMO_POPUP_TEXT_CONTENT,
  CHANGE_SHOW_PROMO_REGISTER_POPUP,
  CHANGE_SHOW_PROMO_SIGNIN_POPUP,
  RECORD_TO_CONSULTATION_LOADING,
  CHANGE_REGISTER_FROM_LANDING,
  SEND_CLICK_REQUEST_LOADING
} from '../mutation-types';

import {
  RECORD_TO_CONSULTATION,
  SEND_CLICK_REQUEST
} from "@/store/action-types";



const state = {
  promoPopupContent: {
    course: '',
    text: '',
    showPopup: true
  },
  isRegisterFromLandingPage: {
    value: false,
    course: ''
  },
  isShowPromoRegisterPopup: false,
  isShowPromoSignInPopup: false,
  recordToConsultationLoading: false,

  sendClickRequestLoading: false
};

const getters = {
  promoPopupContent: state => state.promoPopupContent,
  isShowPromoRegisterPopup: state => state.isShowPromoRegisterPopup,
  isShowPromoSignInPopup: state => state.isShowPromoSignInPopup,
  recordToConsultationLoading: state => state.recordToConsultationLoading,
  isRegisterFromLandingPage: state => state.isRegisterFromLandingPage,


  sendClickRequestLoading: state => state.sendClickRequestLoading,
};

const actions = {
  [RECORD_TO_CONSULTATION]: async ({commit} , payload) => {
    try {
      commit(RECORD_TO_CONSULTATION_LOADING, true);
      const response = await $http.post(`jump/v1/consult`, payload);
      console.log(response)
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(RECORD_TO_CONSULTATION_LOADING, false);
    }
  },
  [SEND_CLICK_REQUEST]: async ({commit} , payload) => {
    try {
      commit(SEND_CLICK_REQUEST_LOADING, true);
      const response = await $http.post(`v1/action/click`, payload);
      console.log(response)
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit(SEND_CLICK_REQUEST_LOADING, false);
    }
  },
};

const mutations = {
  [PROMO_POPUP_TEXT_CONTENT](state, content) {
    state.promoPopupContent = content;
  },
  [CHANGE_SHOW_PROMO_REGISTER_POPUP](state, status) {
    state.isShowPromoRegisterPopup = status;
    fixBody(status)
  },
  [CHANGE_SHOW_PROMO_SIGNIN_POPUP](state, status) {
    state.isShowPromoSignInPopup = status;
    fixBody(status)
  },
  [RECORD_TO_CONSULTATION_LOADING](state, status) {
    state.recordToConsultationLoading = status;
  },
  [CHANGE_REGISTER_FROM_LANDING](state, params) {
    state.isRegisterFromLandingPage = params;
  },


  [SEND_CLICK_REQUEST_LOADING](state, params) {
    state.sendClickRequestLoading = params;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
