import {$http} from '@/utils/http';
import {globalLoader,fixBody} from '@/helpers/variables/index';
import {
  SET_TOKEN,
  REMOVE_TOKEN,
  CHANGE_POPUP_REGISTER,
  CHANGE_POPUP_RESTORE_PASSWORD,
  CHANGE_POPUP_NEW_PASSWORD,
  SET_LOADING_REGISTRATION,
  CHANGE_POPUP_LDAP,
  SING_IN_WITH_LDAP_LOADING,
  CHECK_EMAIL_LOADING,
  CHANGE_POPUP_SIGN_IN,
  SET_RESTORE_LOADING,
  SET_RESET_LOADING,
  CHANGE_POPUP_ACTIVATION_EMAIL,
  SET_ACTIVATION_LOADING,
  CHANGE_AUTH_LOADING,
  CHANGE_POPUP_REGISTER_INFO
} from '../mutation-types';

import {
  GET_TOKEN,
  REGISTRATION,
  LOGOUT,
  RESTORE_PASSWORD,
  SET_NEW_PASSWORD,
  ACTIVATION_EMAIL,
  CHECK_EMAIL
} from '../action-types';

const state = {
  isAuthenticated: false,
  authLoading: false,
  popupRegister: false,
  popupRegisterInfo: {
    course: '',
    promo: ''
  },
  popupSignIn: false,
  showRestorePassword: false,
  showSetNewPassword: false,
  loadingRegistration: false,
  showPopupLdap: false,
  singInLdapLoading: false,
  popupLdapWarning: false,
  checkEmailLoading: false,
  restoreLoading: false,
  resetLoading: false,
  showActivationEmail: false,
  showActivationEmailLoading: false,
};

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  authLoading: state => state.authLoading,
  popupRegister: state => state.popupRegister,
  popupRegisterInfo: state => state.popupRegisterInfo,
  popupSignIn: state => state.popupSignIn,
  checkEmailLoading: state => state.checkEmailLoading,
  singInLdapLoading: state => state.singInLdapLoading,
  showPopupLdap: state => state.showPopupLdap,
  showRestorePassword: state => state.showRestorePassword,
  showSetNewPassword: state => state.showSetNewPassword,
  singIn: state => state.singIn,
  loadingRegistration: state => state.loadingRegistration,
  popupLdapWarning: state => state.popupLdapWarning,
  restoreLoading: state => state.restoreLoading,
  resetLoading: state => state.resetLoading,
  showActivationEmail: state => state.showActivationEmail,
  showActivationEmailLoading: state => state.showActivationEmailLoading,
};

const actions = {
  [GET_TOKEN]: async ({commit}, payload) => {
    commit(CHANGE_AUTH_LOADING, true);
    try {
      const result = await $http.post('v1/login', payload);
      commit(SET_TOKEN, result.data.access_token);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_AUTH_LOADING, false);
    }
  },
  [CHECK_EMAIL]: async ({commit}, payload) => {
    try {
      commit(CHECK_EMAIL_LOADING, true);

      return await $http.post('v1/faq', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHECK_EMAIL_LOADING, false);
    }
  },
  [LOGOUT]: async ({commit}) => {
    try {

      return await $http.post('v1/logout');
    }catch (e) {
      throw e;
    }finally {
      commit(REMOVE_TOKEN);
    }
  }, // eslint-disable-next-line
  [REGISTRATION]: async ({commit}, payload) => {
    try {
      commit(SET_LOADING_REGISTRATION, true);

      // register request
      const updatedParams = {
        ...payload,
      }

      // image upload
      if (payload.avatar) {
        const formData = new FormData();

        formData.append('photo', payload.avatar);

        const { data: { url: image }} = await $http.post('v1/avatar/upload', formData);
        updatedParams.avatar = image;
      } else {
        delete updatedParams.avatar;
      }


      return await $http.post('v1/register', updatedParams);



    }catch (e) {
      throw e;
    }finally {
      commit(SET_LOADING_REGISTRATION, false);
    }
  },// eslint-disable-next-line
  [RESTORE_PASSWORD]: async ({commit}, payload) => {
    try {
      commit(SET_RESTORE_LOADING, true);

      return await $http.post('v1/password/remind', payload);
    }catch (e) {
      throw e;
    }finally {
      commit(SET_RESTORE_LOADING, false);

    }
  },// eslint-disable-next-line
  [SET_NEW_PASSWORD]: async ({commit}, payload) => {
    try {
      commit(SET_RESET_LOADING, true);

      return await $http.post('v1/password/reset', payload);
    }catch (e) {
      throw e;
    }finally {
      commit(SET_RESET_LOADING, false);
    }
  },
  // eslint-disable-next-line
  [ACTIVATION_EMAIL]: async ({commit}, payload) => {
    try {
      commit(SET_ACTIVATION_LOADING, true);
      return await $http.get(`v1/activation?activation_code=${payload.activation_code}&email=${payload.email}`, payload);
    }catch (e) {
      throw e;
    }finally {
      commit(SET_ACTIVATION_LOADING, false);
    }
  },
};

const mutations = {
  [SET_TOKEN](state, token) {
    localStorage.setItem('user_token', token);
    state.isAuthenticated = true;
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem('user_token');
    window.location.reload();
    state.isAuthenticated = false
  },
  [CHANGE_POPUP_REGISTER](state, status) {
    state.popupRegister = status;
    fixBody(status);
  },
  [CHANGE_POPUP_REGISTER_INFO](state, params) {
    state.popupRegisterInfo = params;
  },
  [CHANGE_POPUP_SIGN_IN](state, status) {
    state.popupSignIn = status;
    fixBody(status);
  },
  [CHANGE_POPUP_RESTORE_PASSWORD](state, status) {
    state.showRestorePassword = status;
    fixBody(status)
  },
  [CHANGE_POPUP_ACTIVATION_EMAIL](state, status) {
    state.showActivationEmail = status;
    fixBody(status)
  },
  [CHECK_EMAIL_LOADING](state, status) {
    state.checkEmailLoading = status;
  },
  [SET_ACTIVATION_LOADING](state, status) {
    state.showActivationEmailLoading = status;
  },
  [CHANGE_AUTH_LOADING](state, status) {
    state.authLoading = status;
  },
  [SET_RESTORE_LOADING](state, status) {
    state.restoreLoading = status;
  },
  [SET_RESET_LOADING](state, status) {
    state.resetLoading = status;
  },
  [SET_LOADING_REGISTRATION](state, status) {
    state.loadingRegistration = status;
  },
  [CHANGE_POPUP_NEW_PASSWORD](state, status) {
    state.showSetNewPassword = status;
    fixBody(status)
  },
  [CHANGE_POPUP_LDAP](state, status) {
    state.showPopupLdap = status;
    fixBody(status)

  },
  [SING_IN_WITH_LDAP_LOADING](state, status) {
    state.singInLdapLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
