const objectToQueryString = (params) =>
  Object.keys(params).map(key => key + '=' + params[key]).join('&');

const objectToEncodedQueryString = (params) =>
  Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

export {
  objectToQueryString,
  objectToEncodedQueryString
};
