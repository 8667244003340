
const speakersList = [
  {
    id: 0,
    name: 'Test Test',
    description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
    image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
    speciality: 'Test',
    slug: 'test_test',
    reviews_count: 3243,
    rating: 4,
    categories: {
      data: [
        {
          id: 0,
          name: 'Test 1',
          slug: 'test',
        },
        {
          id: 1,
          name: 'Test 2',
          slug: 'test',
        },
        {
          id: 2,
          name: 'Test 3',
          slug: 'test',
        }
      ],
    },
    social_media: [
      {
        id: 0,
        name: 'instagram',
        external_link: 'https://instagram.com',
      },
      {
        id: 1,
        name: 'facebook',
        external_link: 'https://facebook.com',
      },
      {
        id: 2,
        name: 'twitter',
        external_link: 'https://twitter.com',
      }
    ]
  },
  {
    id: 1,
    name: 'Test Test',
    description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
    image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
    speciality: 'Test',
    slug: 'test_test',
    reviews_count: 3243,
    rating: 4,
    categories: {
      data: [
        {
          id: 0,
          name: 'Test 1',
          slug: 'test',
        },
        {
          id: 1,
          name: 'Test 2',
          slug: 'test',
        },
        {
          id: 2,
          name: 'Test 3',
          slug: 'test',
        }
      ],
    },
    social_media: [
      {
        id: 0,
        name: 'instagram',
        external_link: 'https://instagram.com',
      },
      {
        id: 1,
        name: 'facebook',
        external_link: 'https://facebook.com',
      },
      {
        id: 2,
        name: 'twitter',
        external_link: 'https://twitter.com',
      }
    ]
  },
  {
    id: 2,
    name: 'Test Test',
    description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
    image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
    speciality: 'Test',
    slug: 'test_test',
    reviews_count: 3243,
    rating: 4,
    categories: {
      data: [
        {
          id: 0,
          name: 'Test 1',
          slug: 'test',
        },
        {
          id: 1,
          name: 'Test 2',
          slug: 'test',
        },
        {
          id: 2,
          name: 'Test 3',
          slug: 'test',
        }
      ],
    },
    social_media: [
      {
        id: 0,
        name: 'instagram',
        external_link: 'https://instagram.com',
      },
      {
        id: 1,
        name: 'facebook',
        external_link: 'https://facebook.com',
      },
      {
        id: 2,
        name: 'twitter',
        external_link: 'https://twitter.com',
      }
    ]
  },
  {
    id: 3,
    name: 'Test Test',
    description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
    image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
    speciality: 'Test',
    slug: 'test_test',
    reviews_count: 3243,
    rating: 4,
    social_media: [
      {
        id: 0,
        name: 'instagram',
        external_link: 'https://instagram.com',
      },
      {
        id: 1,
        name: 'facebook',
        external_link: 'https://facebook.com',
      },
      {
        id: 2,
        name: 'twitter',
        external_link: 'https://twitter.com',
      }
    ]
  },
  {
    id: 4,
    name: 'Test Test',
    description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
    image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
    speciality: 'Test',
    slug: 'test_test',
    reviews_count: 3243,
    rating: 4,
    social_media: [
      {
        id: 0,
        name: 'instagram',
        external_link: 'https://instagram.com',
      },
      {
        id: 1,
        name: 'facebook',
        external_link: 'https://facebook.com',
      },
      {
        id: 2,
        name: 'twitter',
        external_link: 'https://twitter.com',
      }
    ]
  },
  {
    id: 5,
    name: 'Test Test',
    description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
    image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
    speciality: 'Test',
    slug: 'test_test',
    reviews_count: 3243,
    rating: 4,
    social_media: [
      {
        id: 0,
        name: 'instagram',
        external_link: 'https://instagram.com',
      },
      {
        id: 1,
        name: 'facebook',
        external_link: 'https://facebook.com',
      },
      {
        id: 2,
        name: 'twitter',
        external_link: 'https://twitter.com',
      }
    ]
  },
];

export default speakersList;
