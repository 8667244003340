
export default {
  name: 'pagination',
  props: {
    offset: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    pagesCount: function () {
      return Math.ceil(this.total / this.limit);
    },
    currentPage: function () {
      return this.offset >= this.total ? -1 : Math.floor(this.offset / this.limit);
    },
  },
  methods: {
    pageClickHandler (pageNumber) {
      const nextOffset = this.limit * pageNumber;

      this.$emit('change', nextOffset);
    },

    prevClickHandler () {
      const nextOffset = this.offset - this.limit;

      if (nextOffset >= 0) {
        this.$emit('change', nextOffset);
      }
    },

    nextClickHandler () {
      const nextOffset = this.offset + this.limit;

      if (nextOffset < this.total) {
        this.$emit('change', nextOffset);
      }
    }
  }
}
