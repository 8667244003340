import {$http} from '@/utils/http'
import {
  SPEAKERS_FETCH_LIST_DATA_START,
  SPEAKERS_FETCH_LIST_DATA_SUCCESS,
  SPEAKERS_FETCH_LIST_DATA_FAILURE,
  SPEAKERS_FETCH_LIST_DATA_FULFILLED,
  SPEAKERS_FETCH_SAMPLE_DATA_START,
  SPEAKERS_FETCH_SAMPLE_DATA_SUCCESS,
  SPEAKERS_FETCH_SAMPLE_DATA_FAILURE,
  SPEAKERS_FETCH_SAMPLE_DATA_FULFILLED,
  SPEAKERS_FETCH_FAQ_LIST_DATA_START,
  SPEAKERS_FETCH_FAQ_LIST_DATA_SUCCESS,
  SPEAKERS_FETCH_FAQ_LIST_DATA_FAILURE,
  SPEAKERS_FETCH_FAQ_LIST_DATA_FULFILLED,
  SPEAKERS_FETCH_FILTERS_LIST_DATA_START,
  SPEAKERS_FETCH_FILTERS_LIST_DATA_SUCCESS,
  SPEAKERS_FETCH_FILTERS_LIST_DATA_FAILURE,
  SPEAKERS_FETCH_FILTERS_LIST_DATA_FULFILLED,
  SPEAKERS_FETCH_CATEGORIES_LIST_DATA_START,
  SPEAKERS_FETCH_CATEGORIES_LIST_DATA_SUCCESS,
  SPEAKERS_FETCH_CATEGORIES_LIST_DATA_FAILURE, SPEAKERS_FETCH_CATEGORIES_LIST_DATA_FULFILLED,
} from '../mutation-types';

import {
  SPEAKERS_FETCH_CATEGORIES_LIST_DATA_TRIGGER,
  SPEAKERS_FETCH_FAQ_LIST_DATA_TRIGGER, SPEAKERS_FETCH_FILTERS_LIST_DATA_TRIGGER,
  SPEAKERS_FETCH_LIST_DATA_TRIGGER,
  SPEAKERS_FETCH_SAMPLE_DATA_TRIGGER,
} from '../action-types';
import objectIsEmpty from "@/utils/object-is-empty";
import {objectToEncodedQueryString} from "@/utils/object-to-query-string";
import speakersList from "@/assets/static/speakers/list";
import speakersSample from "@/assets/static/speakers/sample";
import speakersFaqList from "@/assets/static/speakers/faq-list";

const state = {
  categories: {
    data: [],
    loading: false,
    done: false,
    error: null,
  },
  list: {
    data: [],
    loading: false,
    done: false,
    error: null,
    filters: {
      data: [],
      loading: false,
      done: false,
      error: null,
    },
    pagination: {
      offset: 0,
      limit: 10, // by default
      total: 1,
    }
  },
  sample: {
    data: null,
    loading: false,
    done: false,
    error: null,
  },
  faq: {
    data: [],
    loading: false,
    done: false,
    error: null,
  },
};

const getters = {
  // categories
  categoriesListData: state => state.categories.data,
  categoriesListLoading: state => state.categories.loading,
  categoriesListFetched: state => state.categories.done,
  categoriesListError: state => state.categories.error,

  // list
  listData: state => state.list.data,
  listLoading: state => state.list.loading,
  listFetched: state => state.list.done,
  listError: state => state.list.error,

  // list pagination
  listPaginationOffset: state => state.list.pagination.offset,
  listPaginationLimit: state => state.list.pagination.limit,
  listPaginationTotal: state => state.list.pagination.total,

  // list filters
  filtersListData: state => state.list.filters.data,
  filtersListLoading: state => state.list.filters.loading,
  filtersListFetched: state => state.list.filters.done,
  filtersListError: state => state.list.filters.error,

  // sample
  sampleData: state => state.sample.data,
  sampleLoading: state => state.sample.loading,
  sampleFetched: state => state.sample.done,
  sampleError: state => state.sample.error,

  // faq
  faqListData: state => state.faq.data,
  faqListLoading: state => state.faq.loading,
  faqListFetched: state => state.faq.done,
  faqListError: state => state.faq.error,
};

const actions = {
  // fetch categories list
  [SPEAKERS_FETCH_CATEGORIES_LIST_DATA_TRIGGER]: async ({ commit }) => {
    try {
      commit(SPEAKERS_FETCH_CATEGORIES_LIST_DATA_START);

      const response = await $http.get('v1/speaker/categories');
      commit(SPEAKERS_FETCH_CATEGORIES_LIST_DATA_SUCCESS, response.data.data);
    } catch (error) {
      commit(SPEAKERS_FETCH_CATEGORIES_LIST_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(SPEAKERS_FETCH_CATEGORIES_LIST_DATA_FULFILLED);
    }
  },

  // fetch filters list
  [SPEAKERS_FETCH_FILTERS_LIST_DATA_TRIGGER]: async ({ commit }) => {
    try {
      commit(SPEAKERS_FETCH_FILTERS_LIST_DATA_START);

      // const response = await $http.get('v1/courses/filters');
      // commit(SPEAKERS_FETCH_FILTERS_LIST_DATA_SUCCESS, response.data.data);

      // mock data
      commit(SPEAKERS_FETCH_FILTERS_LIST_DATA_SUCCESS, speakersFiltersList);
    } catch (error) {
      commit(SPEAKERS_FETCH_FILTERS_LIST_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(SPEAKERS_FETCH_FILTERS_LIST_DATA_FULFILLED);
    }
  },

  // fetch list
  [SPEAKERS_FETCH_LIST_DATA_TRIGGER]: async ({ commit }, params) => {
    try {
      commit(SPEAKERS_FETCH_LIST_DATA_START);

      // includes query formatting
      const query = params && !objectIsEmpty(params) ? `?${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`v1/speakers${query}`);

      commit(SPEAKERS_FETCH_LIST_DATA_SUCCESS, response.data);

      // mock data
      // commit(SPEAKERS_FETCH_LIST_DATA_SUCCESS, { data: speakersList });
    } catch (error) {
      commit(SPEAKERS_FETCH_LIST_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(SPEAKERS_FETCH_LIST_DATA_FULFILLED);
    }
  },

  // fetch sample
  [SPEAKERS_FETCH_SAMPLE_DATA_TRIGGER]: async ({ commit }, { slug, params }) => {
    try {
      commit(SPEAKERS_FETCH_SAMPLE_DATA_START);

      const query = params && !objectIsEmpty(params) ? `?${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`v1/speakers/${slug}${query}`);
      commit(SPEAKERS_FETCH_SAMPLE_DATA_SUCCESS, response.data.data);

      // data mock
      // commit(SPEAKERS_FETCH_SAMPLE_DATA_SUCCESS, speakersSample);
    } catch (error) {
      commit(SPEAKERS_FETCH_SAMPLE_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(SPEAKERS_FETCH_SAMPLE_DATA_FULFILLED);
    }
  },

  // fetch faq
  [SPEAKERS_FETCH_FAQ_LIST_DATA_TRIGGER]: async ({ commit }) => {
    try {
      commit(SPEAKERS_FETCH_FAQ_LIST_DATA_START);

      // const response = await $http.get(`v1/faq/speakers`);
      // commit(SPEAKERS_FETCH_FAQ_LIST_DATA_SUCCESS, response.data.data);

      // data mock
      commit(SPEAKERS_FETCH_FAQ_LIST_DATA_SUCCESS, speakersFaqList);
    } catch (error) {
      commit(SPEAKERS_FETCH_FAQ_LIST_DATA_FAILURE, error);
      throw error;
    } finally {
      commit(SPEAKERS_FETCH_FAQ_LIST_DATA_FULFILLED);
    }
  },
};

const mutations = {
  // categories list
  [SPEAKERS_FETCH_CATEGORIES_LIST_DATA_START] (state) {
    state.categories.loading = true;
    state.categories.done = false;
    state.categories.error = null;
  },
  [SPEAKERS_FETCH_CATEGORIES_LIST_DATA_SUCCESS] (state, data) {
    state.categories.data = data;
    state.categories.done = true;
  },
  [SPEAKERS_FETCH_CATEGORIES_LIST_DATA_FAILURE] (state, error) {
    state.categories.error = error;
  },
  [SPEAKERS_FETCH_CATEGORIES_LIST_DATA_FULFILLED] (state) {
    state.categories.loading = false;
  },

  // filters list
  [SPEAKERS_FETCH_FILTERS_LIST_DATA_START] (state) {
    state.list.filters.loading = true;
    state.list.filters.done = false;
    state.list.filters.error = null;
  },
  [SPEAKERS_FETCH_FILTERS_LIST_DATA_SUCCESS] (state, data) {
    state.list.filters.data = data;
    state.list.filters.done = true;
  },
  [SPEAKERS_FETCH_FILTERS_LIST_DATA_FAILURE] (state, error) {
    state.list.filters.error = error;
  },
  [SPEAKERS_FETCH_FILTERS_LIST_DATA_FULFILLED] (state) {
    state.list.filters.loading = false;
  },

  // list
  [SPEAKERS_FETCH_LIST_DATA_START] (state) {
    state.list.loading = true;
    state.list.done = false;
    state.list.error = null;
  },
  [SPEAKERS_FETCH_LIST_DATA_SUCCESS] (state, { data, meta }) {
    state.list.data = data;

    //todo remove parse int
    state.list.pagination.offset = parseInt(meta.offset);
    state.list.pagination.limit = parseInt(meta.limit);
    state.list.pagination.total = parseInt(meta.total);

    state.list.done = true;
  },
  [SPEAKERS_FETCH_LIST_DATA_FAILURE] (state, error) {
    state.list.error = error;
  },
  [SPEAKERS_FETCH_LIST_DATA_FULFILLED] (state) {
    state.list.loading = false;
  },

  // sample
  [SPEAKERS_FETCH_SAMPLE_DATA_START] (state) {
    state.sample.data = null;
    state.sample.loading = true;
    state.sample.done = false;
    state.sample.error = null;
  },
  [SPEAKERS_FETCH_SAMPLE_DATA_SUCCESS] (state, data) {
    state.sample.data = data;
    state.sample.done = true;
  },
  [SPEAKERS_FETCH_SAMPLE_DATA_FAILURE] (state, error) {
    state.sample.error = error;
  },
  [SPEAKERS_FETCH_SAMPLE_DATA_FULFILLED] (state) {
    state.sample.loading = false;
  },

  // faq
  [SPEAKERS_FETCH_FAQ_LIST_DATA_START] (state) {
    state.faq.loading = true;
    state.faq.done = false;
    state.faq.error = null;
  },
  [SPEAKERS_FETCH_FAQ_LIST_DATA_SUCCESS] (state, data) {
    state.faq.data = data;
    state.faq.done = true;
  },
  [SPEAKERS_FETCH_FAQ_LIST_DATA_FAILURE] (state, error) {
    state.faq.error = error;
  },
  [SPEAKERS_FETCH_FAQ_LIST_DATA_FULFILLED] (state) {
    state.faq.loading = false;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
