import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'home-development',
  data() {
    return {
      hoverItemClass: 1
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
    }),
  },
  components: {
  },
  methods: {
    ...mapActions({
    }),
    hoverElement(element){
      this.hoverItemClass = element;
    },
    removeActiveClass(){
      this.hoverItemClass = 0;
    }
  },
  beforeDestroy() {
  },
}
