import {mapGetters, mapActions, mapMutations} from 'vuex';

//sections
import promoSection from '@/modules/about/section/promo/index.vue';
import solutionSection from '@/modules/about/section/solution/index.vue';
import howSection from '@/modules/about/section/how/index.vue';
import teamSection from '@/modules/about/section/team/index.vue';
import mockSection from '@/modules/about/section/mock/index.vue';
import contactForm from './components/contact-form/index.vue';
import mainFooter from  '@/components/features/footer/index.vue'

export default {
  name: 'home',
  data() {
    return {
      params: {
        title: '',
        description: '',
        keywords: '',
        name: ''
      },
    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.name ? this.params.name : 'Lifta.space',
        separator: ' '
      }
    },
    meta: function () {
      let meta = [
        {n: 'description', c: this.params.description, id: 'description'},
        {n: 'keywords', c: this.params.keywords, id: 'keywords'},
        {p: 'og:title', c: this.params.title, id: 'og:title'},
        {p: 'og:url', c: window.location.origin + this.$route.path, id: 'og:url'},
        {p: 'og:type', c: "website", id: 'og:type'},
        {p: 'og:description', c: this.params.description, id: 'og:description'},
        {p: 'twitter:title', c: this.params.title, id: 'twitter:title'},
        {p: 'twitter:description', c: this.params.description, id: 'twitter:description'},
      ]

      if (Object.keys(this.$route.query).length > 0) {
        meta.push({n: 'robots', c: 'noindex, nofollow'})
        return meta
      } else {
        return meta
      }
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ua/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        {rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical'},
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'uk-UA', href: window.location.origin+ '/ua' + route, id: 'uk-UA'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    }
  },
  watch: {
    aboutList:  {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          var self = this
          self.params.title = newVal.meta_title;
          self.params.description = newVal.meta_description;
          self.params.keywords = newVal.meta_keyword;
          self.params.name = newVal.page_title;
          self.$emit('updateHead')
        }
      }
    },
  },
  created() {
    this.fetchAboutList();
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      aboutList: 'about/aboutList',
      aboutListLoading: 'about/aboutListLoading',
    }),
  },
  components: {
    promoSection,
    solutionSection,
    howSection,
    teamSection,
    mockSection,
    mainFooter,
    contactForm
  },
  methods: {
    ...mapActions({
      fetchAboutList: 'about/GET_ABOUT_LIST'
    }),
    ...mapMutations({
      changeIndex: `about/CHANGE_PAGE_INDEX`,
    }),
  },
  destroyed() {
  },
}
