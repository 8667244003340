import {mapGetters, mapActions, mapMutations} from 'vuex';

import CalendarSlider from '../../components/calendar-slider/index.vue';

export default {
  name: 'home-calendar',
  data() {
    return {
      activeDateIndex: 1
    }
  },
  created() {
    if( !this.calendarSlider ){
      this.fetchCalendarSlider();
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      calendarSlider: 'home/calendarSlider',
      calendarSliderLoading: 'home/calendarSliderLoading',
    }),
    totalItemsDay1 () {
      return parseInt(this.$t('marathonDay1.totalItems'))
    },
    totalItemsDay2 () {
      return parseInt(this.$t('marathonDay2.totalItems'))
    },
    totalItemsDay3 () {
      return parseInt(this.$t('marathonDay3.totalItems'))
    },
    totalItemsDay4 () {
      return parseInt(this.$t('marathonDay4.totalItems'))
    },
    totalItemsDay5 () {
      return parseInt(this.$t('marathonDay5.totalItems'))
    },
    totalItemsDay6 () {
      return parseInt(this.$t('marathonDay6.totalItems'))
    },
    totalItemsDay7 () {
      return parseInt(this.$t('marathonDay7.totalItems'))
    },
  },
  components: {
    CalendarSlider
  },
  methods: {
    ...mapActions({
      fetchCalendarSlider: `home/GET_CALENDAR_SLIDER`,
    }),
    ...mapMutations({
      changeShowSubscribePopup: `marathon/CHANGE_SHOW_SUBSCRIBE_POPUP`,
    }),
    changeDayIndex(index){
      this.activeDateIndex = index;
    },
    changePopup(id){
      this.changeShowSubscribePopup({show: true,eventId: id})
    }
  },
  beforeDestroy() {
  },
}
