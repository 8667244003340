import {mapGetters, mapActions, mapMutations} from 'vuex';


export default {
  name: 'partners',
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  components: {},
  methods: {},
  beforeDestroy() {
  },
}
