
const coursesSample = {
  id: 0,
  name: 'course-1',
  title: 'Работа с брендами',
  description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
  image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
  type: 'Курс',
  complexity: 'Новичкам',
  period: '12 месяцев',
  status: 'Online',
  rating: 5,
  reviews_count: 3243,
  base_price: 123,
  final_price: 32,
  discount_rate: 40,
  startDate: '2015-03-25T12:00:00Z',
  endDate: '2015-06-25T12:00:00Z',
  branchType: 'Маркетинг',
  certificateType: 'Диплом об окончании курса',
  lectures: {
    data: [
      {
        id: 0,
        name: 'Test 1',
        title: '1. Вступление',
        description: 'Вступление описание',
        duration: '00:12:12',
      },
      {
        id: 2,
        name: 'Test 2',
        title: '2. Как начать работать в соц. сетях',
        description: '2. Как начать работать в соц. сетях',
        duration: '00:12:12',
      },
      {
        id: 3,
        name: 'Test 3',
        title: '3. Что такое Маркетинг',
        description: '3. Что такое Маркетинг',
        duration: '00:12:12',
      },
      {
        id: 4,
        name: 'Test 54',
        title: '4. Как привлекать новых клиентов',
        description: '4. Как привлекать новых клиентов',
        duration: '00:12:12',
      },
    ]
  },
  related: {
    data: [
      {
        id: 0,
        name: 'course-1',
        title: 'Работа с брендами',
        description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
        type: 'Курс',
        complexity: 'Новичкам',
        period: '12 месяцев',
        status: 'Online',
        rating: 5,
        reviews_count: 3243,
        base_price: 123,
        final_price: 32,
        discount_rate: 40,
      },
      {
        id: 1,
        name: 'course-2',
        title: 'Работа с брендами',
        description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
        type: 'Курс',
        complexity: 'Новичкам',
        period: '12 месяцев',
        status: 'Online',
        rating: 5,
        reviews_count: 3243,
        base_price: 123,
        final_price: 32,
        discount_rate: 40,
      },
      {
        id: 2,
        name: 'course-3',
        title: 'Работа с брендами',
        description: 'Общение в пространстве LIFTA позволит делится идеями и наращивать социальные контакты, с помощью которых ты реализуешься в новой профессии.',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/1920/1080`,
        type: 'Курс',
        complexity: 'Новичкам',
        period: '12 месяцев',
        status: 'Online',
        rating: 5,
        reviews_count: 3243,
        base_price: 123,
        final_price: 32,
        discount_rate: 40,
      },
    ]
  },
  speakers: {
    data: [
      {
        id: 0,
        name: 'Иван Смирнов',
        speciality: 'Illustrator',
        slug: 'ivan-smirnov',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/240/320`,
      },
      {
        id: 1,
        name: 'Иван Смирнов',
        speciality: 'Illustrator',
        slug: 'ivan-smirnov',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/240/320`,
      },
      {
        id: 2,
        name: 'Иван Смирнов',
        speciality: 'Illustrator',
        slug: 'ivan-smirnov',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/240/320`,
      },
      {
        id: 3,
        name: 'Иван Смирнов',
        speciality: 'Illustrator',
        slug: 'ivan-smirnov',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/240/320`,
      },
      {
        id: 4,
        name: 'Иван Смирнов',
        speciality: 'Illustrator',
        slug: 'ivan-smirnov',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/240/320`,
      },
      {
        id: 5,
        name: 'Иван Смирнов',
        speciality: 'Illustrator',
        slug: 'ivan-smirnov',
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 100)}/240/320`,
      },
    ],
  },
};

export default coursesSample;
