// import components
import Vue from 'vue';

import Loader from '@/components/misc/loader.vue';
import smallLoader from '@/components/misc/small-loader.vue';
import PopupWrap from '@/components/misc/popup-wrap.vue';
import UnclosedPopupWrap from '@/components/misc/unclosed-popup-wrap.vue';
import SubscribePopupWrap from '@/components/misc/subscribe-popup.vue';
import mainButton from '@/components/buttons/main-button/index.vue';

import VueSmoothScroll from 'vue2-smooth-scroll';
import VueDisqus from 'vue-disqus';
import VueDisqusCount from 'vue-disqus-count';




// use global components
Vue.use(VueSmoothScroll, {
  duration: 400,
  updateHistory: false,
});
Vue.component('loader', Loader);
Vue.component('small-loader', smallLoader);
Vue.component('popup-wrap', PopupWrap);
Vue.component('unclosed-popup-wrap', UnclosedPopupWrap);
Vue.component('subscribe-popup-wrap', SubscribePopupWrap);
Vue.use(VueDisqus, {
  shortname: 'lifta'
});
Vue.use(VueDisqusCount, {
  shortname: 'lifta'
})
Vue.component('main-button', mainButton);
