import {mapGetters, mapActions, mapMutations} from 'vuex';

import CalendarSlider from '../../components/calendar-slider/index.vue';

export default {
  name: 'home-calendar',
  props:{
    list: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
    }
  },
  created() {
    if( !this.calendarSlider ){
      this.fetchCalendarSlider();
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      newsListLoading: 'home/newsListLoading',
    }),
  },
  components: {
    CalendarSlider
  },
  methods: {
    ...mapActions({
      fetchCalendarSlider: `home/GET_CALENDAR_SLIDER`,
    }),
  },
  beforeDestroy() {
  },
}
