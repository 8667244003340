import {mapGetters, mapActions, mapMutations} from 'vuex';
import VueAdsPagination, {VueAdsPageButton} from 'vue-ads-pagination';
import objectIsEmpty from "@/utils/object-is-empty";
import {objectToEncodedQueryString} from "@/utils/object-to-query-string";
import pagination from '@/components/features/pagination/index.vue';

export default {
  name: 'top-news',
  props:['list'],
  data() {
    return {
      page: 0,
      queryParams: {},
    }
  },
  watch: {
    queryParams: function (newParams) {
      const updatedParams = {
        ...newParams,
        limit: this.limit,
      };
      this.fetchNewsList(updatedParams);
    }
  },
  created() {
    // this.page = 0;
    // this.changeSkip(this.page * this.limit);
    this.initSetQueryParams();
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      newsList: 'news/listData',
      newsListLoading: 'news/listLoading',
      listLength: 'news/listPaginationTotal',
      limit: 'news/listLimit',
      offset: 'news/listPaginationOffset'
    })
  },
  components: {
    VueAdsPagination,
    VueAdsPageButton,
    pagination
  },
  methods: {
    ...mapActions({
      fetchNewsList: `news/GET_NEWS_LIST`,
    }),
    ...mapMutations({
      changeSkip: `news/CHANGE_SKIP`,
    }),
    changePage(page) {
      this.page = page;
      this.changeSkip(this.page * this.limit);
      this.fetchNewsList({ slug: '', tag: this.$route.params.tag });
    },

    initSetQueryParams() {
      this.queryParams = this.$route.query;
    },

    updateQueryParams({ name, value }) {
      if (name) {
        const updatedQueryParams = {...this.queryParams};

        if (value && value.toString().length > 0) {
          updatedQueryParams[name] = value;
        } else {
          delete updatedQueryParams[name];
        }

        this.queryParams = updatedQueryParams;
      }

      if (objectIsEmpty(this.queryParams)) {
        history.pushState({}, null, this.$route.path)
      } else {
        history.pushState(
          {},
          null,
          this.$route.path + '?' + objectToEncodedQueryString(this.queryParams),
        )
      }
    }
  },
  beforeDestroy() {
  },
}
