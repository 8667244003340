import {mapGetters, mapActions, mapMutations} from 'vuex';

import Slider from './components/slider/index.vue';
import homePagination from './components/home-sidebar/index.vue';
import Vivus from 'vivus'

//sections
import promoSection from '@/modules/home/section/promo/index.vue';
import developmentSection from '@/modules/home/section/development/index.vue';
import startSection from '@/modules/home/section/start/index.vue';
import speakersSection from '@/modules/home/section/speakers/index.vue';
import calendarSection from '@/modules/home/section/calendar/index.vue';
import aboutSection from '@/modules/home/section/about/index.vue';
import knowSection from '@/modules/home/section/knowwhere/index.vue';
import faqSection from '@/modules/home/section/faq/index.vue';
import footerSection from '@/modules/home/section/lift/index.vue';
// import homeNews from '@/modules/home/section/news/index.vue';

export default {
  name: 'home',
  data() {
    return {
      routesArray: [
        'promo-section',
        'marathon-development',
        'start-section',
        'speakers-section',
        'calendar-section',
        'about-section',
        // 'news-section',
        'knowwhere-section',
        'faq-section',
        'lift-section'
      ],
      intersectionOptions: {
        threshold: [0.25, 0.75]
      },
      params: {
        title: '',
        description: '',
        keywords: '',
        name: '',
      },
    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.name ? this.params.name : 'Lifta.space',
        separator: ' '
      }
    },
    meta: function () {
      let meta = [
        {n: 'description', c: this.params.description, id: 'description'},
        {n: 'keywords', c: this.params.keywords, id: 'keywords'},
        {p: 'og:title', c: this.params.title, id: 'og:title'},
        {p: 'og:url', c: window.location.origin + this.$route.path, id: 'og:url'},
        {p: 'og:type', c: "website", id: 'og:type'},
        {p: 'og:description', c: this.params.description, id: 'og:description'},
        {p: 'twitter:title', c: this.params.title, id: 'twitter:title'},
        {p: 'twitter:description', c: this.params.description, id: 'twitter:description'},
      ]

      if (Object.keys(this.$route.query).length > 0) {
        meta.push({n: 'robots', c: 'noindex, nofollow'})
        return meta
      } else {
        return meta
      }
    },
    link: function () {
      let route = this.$route.path
      return [
        {rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical'},
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin, id: 'ru'},
        {rel: 'alternate', hreflang: 'uk-UA', href: window.location.origin+  '/ua', id: 'uk-UA'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin + '/en', id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin, id: 'x-default'},
      ]
    }
  },
  watch: {
    homeList: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          var self = this
          self.params.title = newVal.meta_title;
          self.params.description = newVal.meta_description;
          self.params.keywords = newVal.meta_keyword;
          self.params.name = newVal.page_title;
          self.$emit('updateHead')
        }
      }
    },
  },
  created() {
    if( !this.homeList ){
      this.getHomeList();
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      index: 'system/pageIndex',
      isShowSvgAnimation: 'home/showSvgAnimation',
      isShowSvgAnimationOnce: 'home/showSvgAnimationOnce',
      homeList: 'home/newsList'
    }),
  },
  components: {
    Slider,
    homePagination,
    promoSection,
    developmentSection,
    startSection,
    speakersSection,
    calendarSection,
    aboutSection,
    knowSection,
    footerSection,
    faqSection,
    // homeNews
  },
  methods: {
    ...mapActions({
      getHomeList: 'home/GET_HOME_LIST'
    }),
    ...mapMutations({
      changeIndex: `system/CHANGE_PAGE_INDEX`,
      changeShowHomeSection: `home/CHANGE_SHOW_HOME_SECTION`,
      changeSvgAnimation: `home/CHANGE_SHOW_HOME_SVG_ANIMATION`,
      changeSvgAnimationOnce: `home/CHANGE_SHOW_HOME_SVG_ANIMATION_ONCE`,
    }),
    goToPage(index, item) {
      this.changeIndex(index);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false
      });
    },
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        if(entry.target.id !== 'promo-section'){
          this.changeShowHomeSection(true);
        }else{
          this.changeShowHomeSection(false);
        }

        if(entry.target.id !== 'knowwhere-section'){
          this.changeSvgAnimation(true);
        }else{
          this.changeSvgAnimation(true);
        }

        switch (entry.target.id) {
          case 'promo-section': {
            this.changeIndex(1);
            break;
          }
          case 'marathon-development': {
            this.changeIndex(5);
            break;
          }
          case 'start-section': {
            this.changeIndex(3);
            break;
          }
          case 'speakers-section': {
            this.changeIndex(4);
            break;
          }
          case 'calendar-section': {
            this.changeIndex(2);
            break;
          }
          case 'about-section': {
            this.changeIndex(6);
            break;
          }
          // case 'news-section': {
          //   this.changeIndex(7);
          //   break;
          // }
          case 'knowwhere-section': {
            this.changeIndex(7);
            if( this.isShowSvgAnimationOnce ){
              let svgPc = document.getElementById('svgPc')
              let svgPhone = document.getElementById('svgPhone')
              let svgTablet = document.getElementById('svgTablet')
              let svgLaptop = document.getElementById('svgLaptop')
              new Vivus(svgPc, { duration: 150, delay: 15,  type: 'delayed', reverseStack: true })
              new Vivus(svgPhone, { duration: 150, delay: 60,  type: 'delayed', reverseStack: true })
              new Vivus(svgTablet, { duration: 150, delay: 90,  type: 'delayed', reverseStack: true })
              new Vivus(svgLaptop, { duration: 150, delay: 100,  type: 'delayed', reverseStack: true })
              this.changeSvgAnimation(false);
            }
            this.changeSvgAnimationOnce(false);
            break;
          }
          case 'faq-section': {
            this.changeIndex(8);
            break;
          }
          case 'lift-section': {
            this.changeIndex(9);
            break;
          }
        }
      }
    }
  },
  destroyed() {
  },
}
