import { VueSlideToggle } from 'vue-slide-toggle';

export default {
  name: 'faq-section-sample',
  components: {
    VueSlideToggle,
  },
  props: {
    data: {
      type: Object,
      default: {},
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded;
    }
  }
}
