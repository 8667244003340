import Vue from 'vue';
import VueI18nManager from 'vue-i18n-manager';
import store from '@/store';
import router from '@/router';

import locales from '@/locales'

// const proxy = {
//   getTranslation: async () => {
//     return await store.dispatch(`setting/GET_TRANSLATIONS`);
//   }
// };

Vue.use(VueI18nManager, {
  store,
  router,
  // proxy,
  config: {
    defaultCode: 'ru-RU',
    languages: [
      {
        name: 'Ukrainian',
        title: 'Укр',
        key: 'ua',
        code: 'ua-UA',
        urlPrefix: 'ua',
        translationKey: 'ua'
      },
      {
        name: 'Russian',
        title: 'Рус',
        key: 'ru',
        code: 'ru-RU',
        urlPrefix: null,
        translationKey: 'ru',
      },
      {
        name: 'English',
        title: 'Eng',
        key: 'en',
        code: 'en-US',
        urlPrefix: 'en',
        translationKey: 'en'
      },
    ],
    // translations: proxy,
    translations: locales
  },
});

Vue.initI18nManager();
