export default {
  name: 'catalogue-categories',
  props: {
    routeType: {
      type: String,
      default: 'courses', // courses | speakers
    },
    data: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
