//auth
export const CHANGE_POPUP_REGISTER = 'CHANGE_POPUP_REGISTER';
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const CHANGE_POPUP_RESTORE_PASSWORD = 'CHANGE_POPUP_RESTORE_PASSWORD';
export const CHANGE_POPUP_NEW_PASSWORD = 'CHANGE_POPUP_NEW_PASSWORD';
export const SET_LOADING_REGISTRATION = 'SET_LOADING_REGISTRATION';
export const CHANGE_POPUP_LDAP = 'CHANGE_POPUP_LDAP';
export const SING_IN_WITH_LDAP_LOADING = 'SING_IN_WITH_LDAP_LOADING';
export const CHECK_EMAIL_LOADING = 'CHECK_EMAIL_LOADING';
export const CHANGE_POPUP_SIGN_IN = 'CHANGE_POPUP_SIGN_IN';
export const SET_RESTORE_LOADING = 'SET_RESTORE_LOADING';
export const SET_RESET_LOADING = 'SET_RESET_LOADING';
export const CHANGE_POPUP_ACTIVATION_EMAIL = 'CHANGE_POPUP_ACTIVATION_EMAIL';
export const SET_ACTIVATION_LOADING = 'SET_ACTIVATION_LOADING';
export const CHANGE_AUTH_LOADING = 'CHANGE_AUTH_LOADING';
export const CHANGE_POPUP_REGISTER_INFO = 'CHANGE_POPUP_REGISTER_INFO';


//system
export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const FIX_BODY = 'FIX_BODY';
export const POPUP_TEXT_CONTENT = 'POPUP_TEXT_CONTENT';
export const SHOW_POPUP_TEXT_CONTENT = 'SHOW_POPUP_TEXT_CONTENT';
export const CHANGE_PAGE_INDEX = 'CHANGE_PAGE_INDEX';
export const CHANGE_TRANSITION_NAME = 'CHANGE_TRANSITION_NAME';
export const SHOW_POPUP_THANKS = 'SHOW_POPUP_THANKS';
export const CHANGE_SHOW_COOKIES_POPUP = 'CHANGE_SHOW_COOKIES_POPUP';

export const CHAT_REGISTRATION_POPUP_CHANGE_AVAILABILITY_STATUS = 'CHAT_REGISTRATION_POPUP_CHANGE_AVAILABILITY_STATUS';
export const CHAT_SIGN_IN_POPUP_CHANGE_AVAILABILITY_STATUS = 'CHAT_SIGN_IN_POPUP_CHANGE_AVAILABILITY_STATUS';
export const CHAT_RESTORE_PASSWORD_POPUP_CHANGE_AVAILABILITY_STATUS = 'CHAT_RESTORE_PASSWORD_POPUP_CHANGE_AVAILABILITY_STATUS';

export const SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_START = 'SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_START';
export const SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_SUCCESS = 'SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_SUCCESS';
export const SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_FAILURE = 'SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_FAILURE';
export const SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_FULFILL = 'SETTINGS_FETCH_CAMPAIGN_AB_SAMPLE_FULFILL';

//settings
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_CATALOG_MENU = 'SET_CATALOG_MENU';
export const SET_CATALOG_MENU_LOADING = 'SET_CATALOG_MENU_LOADING';
export const CHANGE_FORM_THANKS_POPUP = 'CHANGE_FORM_THANKS_POPUP';

//home
export const GET_MAIN_SLIDER_LOADING = 'GET_MAIN_SLIDER_LOADING';
export const SET_MAIN_SLIDER = 'SET_MAIN_SLIDER';
export const SET_CATEGORY_LIST_LOADING = 'SET_CATEGORY_LIST_LOADING';
export const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST';
export const SET_HOME_LIST_LOADING = 'SET_HOME_LIST_LOADING';
export const SET_HOME_LIST = 'SET_HOME_LIST';
export const SET_CALENDAR_SLIDER_LOADING = 'SET_CALENDAR_SLIDER_LOADING';
export const SET_CALENDAR_SLIDER = 'SET_CALENDAR_SLIDER';
export const CHANGE_SHOW_HOME_SECTION = 'CHANGE_SHOW_HOME_SECTION';
export const CHANGE_SHOW_HOME_SVG_ANIMATION = 'CHANGE_SHOW_HOME_SVG_ANIMATION';
export const CHANGE_SHOW_HOME_SVG_ANIMATION_ONCE = 'CHANGE_SHOW_HOME_SVG_ANIMATION_ONCE';
export const SET_ACTIVE_STREAM_VIDEO_LOADING = 'SET_ACTIVE_STREAM_VIDEO_LOADING';
export const SET_ACTIVE_STREAM_VIDEO = 'SET_ACTIVE_STREAM_VIDEO';


// news
export const SET_NEWS_LIST = 'SET_NEWS_LIST';
export const SET_NEWS_LIST_LOADING = 'SET_NEWS_LIST_LOADING';
export const SET_NEWS_ITEM = 'SET_NEWS_ITEM';
export const SET_NEWS_ITEM_LOADING = 'SET_NEWS_ITEM_LOADING';
export const CHANGE_SKIP = 'CHANGE_SKIP';
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH';
export const CHANGE_CATEGORY = 'CHANGE_CATEGORY';
export const SET_RANDOM_NEWS_LIST_LOADING = 'SET_RANDOM_NEWS_LIST_LOADING';
export const SET_RANDOM_NEWS_LIST = 'SET_RANDOM_NEWS_LIST';
export const SET_RANDOM_LIST_LENGTH = 'SET_RANDOM_LIST_LENGTH';
export const SET_POPULAR_NEWS_LIST = 'SET_POPULAR_NEWS_LIST';
export const SET_POPULAR_NEWS_LIST_LOADING = 'SET_POPULAR_NEWS_LIST_LOADING';
export const SET_TOP_NEWS_LIST = 'SET_TOP_NEWS_LIST';
export const SET_TOP_NEWS_LIST_LOADING = 'SET_TOP_NEWS_LIST_LOADING';
export const CHANGE_LIST_PAGE_INDEX = 'CHANGE_LIST_PAGE_INDEX';
export const SET_NEWS_INFO_LOADING = 'SET_NEWS_INFO_LOADING';
export const SET_NEWS_INFO = 'SET_NEWS_INFO';


//blog
export const SET_BLOG_LIST = 'SET_BLOG_LIST';
export const SET_BLOG_LIST_LOADING = 'SET_BLOG_LIST_LOADING';
export const SET_BLOG_ITEM = 'SET_BLOG_ITEM';
export const SET_BLOG_ITEM_LOADING = 'SET_BLOG_ITEM_LOADING';
export const SET_RANDOM_BLOG_LIST_LOADING = 'SET_RANDOM_BLOG_LIST_LOADING';
export const SET_RANDOM_BLOG_LIST = 'SET_RANDOM_BLOG_LIST';

//faq
export const SET_FAQ_LIST_LOADING = 'SET_FAQ_LIST_LOADING';
export const SET_FAQ_LIST = 'SET_FAQ_LIST';
export const SET_LOADING_ASK = 'SET_LOADING_ASK';
//program
export const SET_PROGRAM_LIST_LOADING = 'SET_PROGRAM_LIST_LOADING';
export const SET_PROGRAM_LIST = 'SET_PROGRAM_LIST';
//faq
export const SET_USER_DATA = 'SET_USER_DATA';
export const LOADING_USER_DATA = 'LOADING_USER_DATA';
export const UPDATE_USER_DATA_LOADING = 'UPDATE_USER_DATA_LOADING';
export const SHOW_POPUP_UPDATE_PASSWORD = 'SHOW_POPUP_UPDATE_PASSWORD';
export const UPDATE_USER_PASSWORD_LOADING = 'UPDATE_USER_PASSWORD_LOADING';

//catalog
export const SET_CATALOG_LIST = 'SET_CATALOG_LIST';
export const SET_CATALOG_LIST_LOADING = 'SET_CATALOG_LIST_LOADING';
export const SET_CATALOG_ITEM = 'SET_CATALOG_ITEM';
export const SET_CATALOG_ITEM_LOADING = 'SET_CATALOG_ITEM_LOADING';
export const SET_RANDOM_CATALOG_LIST_LOADING = 'SET_RANDOM_CATALOG_LIST_LOADING';
export const SET_RANDOM_CATALOG_LIST = 'SET_RANDOM_CATALOG_LIST';
export const SET_RELATED_LIST_LOADING = 'SET_RELATED_LIST_LOADING';
export const SET_RELATED_LIST = 'SET_RELATED_LIST';
export const SET_COURSES_INFO_LOADING = 'SET_COURSES_INFO_LOADING';
export const SET_COURSES_INFO = 'SET_COURSES_INFO';
export const REDIRECT_TO_TEACHBASE_LOADING = 'REDIRECT_TO_TEACHBASE_LOADING';

//speakers
export const SET_SPEAKERS_ITEM = 'SET_SPEAKERS_ITEM';
export const SET_SPEAKERS_ITEM_LOADING = 'SET_SPEAKERS_ITEM_LOADING';
export const SET_SPEAKERS_LIST = 'SET_SPEAKERS_LIST';
export const SET_SPEAKERS_LIST_LOADING = 'SET_SPEAKERS_LIST_LOADING';
export const SET_SPEAKERS_CATEGORY_LIST = 'SET_SPEAKERS_CATEGORY_LIST';
export const SET_SPEAKERS_CATEGORY_LIST_LOADING = 'SET_SPEAKERS_CATEGORY_LIST_LOADING';
export const SET_SPEAKERS_LIST_BY_CATEGORY = 'SET_SPEAKERS_LIST_BY_CATEGORY';
export const SET_SPEAKERS_LIST_BY_CATEGORY_LOADING = 'SET_SPEAKERS_LIST_BY_CATEGORY_LOADING';
export const SET_SPEAKERS_INFO_LOADING = 'SET_SPEAKERS_INFO_LOADING';
export const SET_SPEAKERS_INFO = 'SET_SPEAKERS_INFO';

// speakers new
export const SPEAKERS_FETCH_CATEGORIES_LIST_DATA_START = 'SPEAKERS_FETCH_CATEGORIES_LIST_DATA_START';
export const SPEAKERS_FETCH_CATEGORIES_LIST_DATA_SUCCESS = 'SPEAKERS_FETCH_CATEGORIES_LIST_DATA_SUCCESS';
export const SPEAKERS_FETCH_CATEGORIES_LIST_DATA_FAILURE = 'SPEAKERS_FETCH_CATEGORIES_LIST_DATA_FAILURE';
export const SPEAKERS_FETCH_CATEGORIES_LIST_DATA_FULFILLED = 'SPEAKERS_FETCH_CATEGORIES_LIST_DATA_FULFILLED';

export const SPEAKERS_FETCH_FILTERS_LIST_DATA_START = 'SPEAKERS_FETCH_FILTERS_LIST_DATA_START';
export const SPEAKERS_FETCH_FILTERS_LIST_DATA_SUCCESS = 'SPEAKERS_FETCH_FILTERS_LIST_DATA_SUCCESS';
export const SPEAKERS_FETCH_FILTERS_LIST_DATA_FAILURE = 'SPEAKERS_FETCH_FILTERS_LIST_DATA_FAILURE';
export const SPEAKERS_FETCH_FILTERS_LIST_DATA_FULFILLED = 'SPEAKERS_FETCH_FILTERS_LIST_DATA_FULFILLED';

export const SPEAKERS_FETCH_LIST_DATA_START = 'SPEAKERS_FETCH_LIST_DATA_START';
export const SPEAKERS_FETCH_LIST_DATA_SUCCESS = 'SPEAKERS_FETCH_LIST_DATA_SUCCESS';
export const SPEAKERS_FETCH_LIST_DATA_FAILURE = 'SPEAKERS_FETCH_LIST_DATA_FAILURE';
export const SPEAKERS_FETCH_LIST_DATA_FULFILLED = 'SPEAKERS_FETCH_LIST_DATA_FULFILLED';

export const SPEAKERS_FETCH_SAMPLE_DATA_START = 'SPEAKERS_FETCH_SAMPLE_DATA_START';
export const SPEAKERS_FETCH_SAMPLE_DATA_SUCCESS = 'SPEAKERS_FETCH_SAMPLE_DATA_SUCCESS';
export const SPEAKERS_FETCH_SAMPLE_DATA_FAILURE = 'SPEAKERS_FETCH_SAMPLE_DATA_FAILURE';
export const SPEAKERS_FETCH_SAMPLE_DATA_FULFILLED = 'SPEAKERS_FETCH_SAMPLE_DATA_FULFILLED';

export const SPEAKERS_FETCH_FAQ_LIST_DATA_START = 'SPEAKERS_FETCH_FAQ_LIST_DATA_START';
export const SPEAKERS_FETCH_FAQ_LIST_DATA_SUCCESS = 'SPEAKERS_FETCH_FAQ_LIST_DATA_SUCCESS';
export const SPEAKERS_FETCH_FAQ_LIST_DATA_FAILURE = 'SPEAKERS_FETCH_FAQ_LIST_DATA_FAILURE';
export const SPEAKERS_FETCH_FAQ_LIST_DATA_FULFILLED = 'SPEAKERS_FETCH_FAQ_LIST_DATA_FULFILLED';

// marathon
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';
export const CHANGE_SHOW_SUBSCRIBE_POPUP = 'CHANGE_SHOW_SUBSCRIBE_POPUP';
export const CHANGE_POPUP_INFO = 'CHANGE_POPUP_INFO';
export const SEND_SUBSCRIBE_DATA_LOADING = 'SEND_SUBSCRIBE_DATA_LOADING';
export const CLEAR_USER_INFO_DATA = 'CLEAR_USER_INFO_DATA';


// category
export const SET_CATEGORY_ITEM_LOADING = 'SET_CATEGORY_ITEM_LOADING';
export const SET_CATEGORY_ITEM = 'SET_CATEGORY_ITEM';
export const PRODUCTS_SET_COUNT = 'PRODUCTS_SET_COUNT';
export const PRODUCTS_CHANGE_LIMIT = 'PRODUCTS_CHANGE_LIMIT';
export const PRODUCTS_CHANGE_SKIP = 'PRODUCTS_CHANGE_SKIP';

// courses
export const COURSES_FETCH_CATEGORIES_LIST_DATA_START = 'COURSES_FETCH_CATEGORIES_LIST_DATA_START';
export const COURSES_FETCH_CATEGORIES_LIST_DATA_SUCCESS = 'COURSES_FETCH_CATEGORIES_LIST_DATA_SUCCESS';
export const COURSES_FETCH_CATEGORIES_LIST_DATA_FAILURE = 'COURSES_FETCH_CATEGORIES_LIST_DATA_FAILURE';
export const COURSES_FETCH_CATEGORIES_LIST_DATA_FULFILLED = 'COURSES_FETCH_CATEGORIES_LIST_DATA_FULFILLED';

export const COURSES_FETCH_FILTERS_LIST_DATA_START = 'COURSES_FETCH_FILTERS_LIST_DATA_START';
export const COURSES_FETCH_FILTERS_LIST_DATA_SUCCESS = 'COURSES_FETCH_FILTERS_LIST_DATA_SUCCESS';
export const COURSES_FETCH_FILTERS_LIST_DATA_FAILURE = 'COURSES_FETCH_FILTERS_LIST_DATA_FAILURE';
export const COURSES_FETCH_FILTERS_LIST_DATA_FULFILLED = 'COURSES_FETCH_FILTERS_LIST_DATA_FULFILLED';

export const COURSES_FETCH_LIST_DATA_START = 'COURSES_FETCH_LIST_DATA_START';
export const COURSES_FETCH_LIST_DATA_SUCCESS = 'COURSES_FETCH_LIST_DATA_SUCCESS';
export const COURSES_FETCH_LIST_DATA_FAILURE = 'COURSES_FETCH_LIST_DATA_FAILURE';
export const COURSES_FETCH_LIST_DATA_FULFILLED = 'COURSES_FETCH_LIST_DATA_FULFILLED';

export const COURSES_FETCH_SAMPLE_DATA_START = 'COURSES_FETCH_SAMPLE_DATA_START';
export const COURSES_FETCH_SAMPLE_DATA_SUCCESS = 'COURSES_FETCH_SAMPLE_DATA_SUCCESS';
export const COURSES_FETCH_SAMPLE_DATA_FAILURE = 'COURSES_FETCH_SAMPLE_DATA_FAILURE';
export const COURSES_FETCH_SAMPLE_DATA_FULFILLED = 'COURSES_FETCH_SAMPLE_DATA_FULFILLED';

export const COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_START = 'COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_START';
export const COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_SUCCESS = 'COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_SUCCESS';
export const COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_FAILURE = 'COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_FAILURE';
export const COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_FULFILLED = 'COURSES_FETCH_WHAT_YOU_GET_LIST_DATA_FULFILLED';

export const COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_START = 'COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_START';
export const COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_SUCCESS = 'COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_SUCCESS';
export const COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_FAILURE = 'COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_FAILURE';
export const COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_FULFILLED = 'COURSES_FETCH_HOW_IT_WORKS_LIST_DATA_FULFILLED';

export const COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_START = 'COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_START';
export const COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_SUCCESS = 'COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_SUCCESS';
export const COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_FAILURE = 'COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_FAILURE';
export const COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_FULFILLED = 'COURSES_FETCH_TECHNOLOGIES_AND_TOOLS_LIST_DATA_FULFILLED';

export const COURSES_FETCH_FAQ_LIST_DATA_START = 'COURSES_FETCH_FAQ_LIST_DATA_START';
export const COURSES_FETCH_FAQ_LIST_DATA_SUCCESS = 'COURSES_FETCH_FAQ_LIST_DATA_SUCCESS';
export const COURSES_FETCH_FAQ_LIST_DATA_FAILURE = 'COURSES_FETCH_FAQ_LIST_DATA_FAILURE';
export const COURSES_FETCH_FAQ_LIST_DATA_FULFILLED = 'COURSES_FETCH_FAQ_LIST_DATA_FULFILLED';

export const SET_BUY_PRODUCT_LOADING = 'SET_BUY_PRODUCT_LOADING';
export const SET_PRODUCT_BUY_FORM = 'SET_PRODUCT_BUY_FORM';
export const SHOW_POPUP_BUY_FORM = 'SHOW_POPUP_BUY_FORM';

export const CHANGE_COURSE_PAGE = 'CHANGE_COURSE_PAGE';

// translations
export const TRANSLATIONS_FETCH_SAMPLE_DATA_START = 'TRANSLATIONS_FETCH_SAMPLE_DATA_START';
export const TRANSLATIONS_FETCH_SAMPLE_DATA_SUCCESS = 'TRANSLATIONS_FETCH_SAMPLE_DATA_SUCCESS';
export const TRANSLATIONS_FETCH_SAMPLE_DATA_FAILURE = 'TRANSLATIONS_FETCH_SAMPLE_DATA_FAILURE';
export const TRANSLATIONS_FETCH_SAMPLE_DATA_FULFILLED = 'TRANSLATIONS_FETCH_SAMPLE_DATA_FULFILLED';

export const TRANSLATIONS_USER_REGISTRATION_START = 'TRANSLATIONS_USER_REGISTRATION_START';
export const TRANSLATIONS_USER_REGISTRATION_SUCCESS = 'TRANSLATIONS_USER_REGISTRATION_SUCCESS';
export const TRANSLATIONS_USER_REGISTRATION_FAILURE = 'TRANSLATIONS_USER_REGISTRATION_FAILURE';
export const TRANSLATIONS_USER_REGISTRATION_FULFILLED = 'TRANSLATIONS_USER_REGISTRATION_FULFILLED';

export const TRANSLATIONS_USER_SIGN_IN_START = 'TRANSLATIONS_USER_SIGN_IN_START';
export const TRANSLATIONS_USER_SIGN_IN_SUCCESS = 'TRANSLATIONS_USER_SIGN_IN_SUCCESS';
export const TRANSLATIONS_USER_SIGN_IN_FAILURE = 'TRANSLATIONS_USER_SIGN_IN_FAILURE';
export const TRANSLATIONS_USER_SIGN_IN_FULFILLED = 'TRANSLATIONS_USER_SIGN_IN_FULFILLED';

export const TRANSLATIONS_COMETCHAT_INIT_START = 'TRANSLATIONS_COMETCHAT_INIT_START';
export const TRANSLATIONS_COMETCHAT_INIT_SUCCESS = 'TRANSLATIONS_COMETCHAT_INIT_SUCCESS';
export const TRANSLATIONS_COMETCHAT_INIT_FAILURE = 'TRANSLATIONS_COMETCHAT_INIT_FAILURE';
export const TRANSLATIONS_COMETCHAT_INIT_FULFILLED = 'TRANSLATIONS_COMETCHAT_INIT_FULFILLED';

export const TRANSLATIONS_COMETCHAT_USER_LOGIN_START = 'TRANSLATIONS_COMETCHAT_USER_LOGIN_START';
export const TRANSLATIONS_COMETCHAT_USER_LOGIN_SUCCESS = 'TRANSLATIONS_COMETCHAT_USER_LOGIN_SUCCESS';
export const TRANSLATIONS_COMETCHAT_USER_LOGIN_FAILURE = 'TRANSLATIONS_COMETCHAT_USER_LOGIN_FAILURE';
export const TRANSLATIONS_COMETCHAT_USER_LOGIN_FULFILLED = 'TRANSLATIONS_COMETCHAT_USER_LOGIN_FULFILLED';

export const TRANSLATIONS_FETCH_BANNERS_LIST_START = 'TRANSLATIONS_FETCH_BANNERS_LIST_START';
export const TRANSLATIONS_FETCH_BANNERS_LIST_SUCCESS = 'TRANSLATIONS_FETCH_BANNERS_LIST_SUCCESS';
export const TRANSLATIONS_FETCH_BANNERS_LIST_FAILURE = 'TRANSLATIONS_FETCH_BANNERS_LIST_FAILURE';
export const TRANSLATIONS_FETCH_BANNERS_LIST_FULFILLED = 'TRANSLATIONS_FETCH_BANNERS_LIST_FULFILLED';


export const SEND_CODE_LOADING = 'SEND_CODE_LOADING';

// upload
export const UPLOAD_IMAGES_SAMPLE_START = 'UPLOAD_IMAGES_SAMPLE_START';
export const UPLOAD_IMAGES_SAMPLE_SUCCESS = 'UPLOAD_IMAGES_SAMPLE_SUCCESS';
export const UPLOAD_IMAGES_SAMPLE_FAILURE = 'UPLOAD_IMAGES_SAMPLE_FAILURE';
export const UPLOAD_IMAGES_SAMPLE_FULFILLED = 'UPLOAD_IMAGES_SAMPLE_FULFILLED';

//contact
export const SEND_SUBSCRIBE_FORM_LOADING = 'SEND_SUBSCRIBE_FORM_LOADING';
export const SEND_HELP_FORM_LOADING = 'SEND_HELP_FORM_LOADING';


//event
export const SET_EVENT_ITEM = 'SET_EVENT_ITEM';
export const SET_EVENT_ITEM_LOADING = 'SET_EVENT_ITEM_LOADING';
export const CHANGE_SHOW_POPUP_BUY_FORM = 'CHANGE_SHOW_POPUP_BUY_FORM';
export const CHANGE_SHOW_RECORD_POPUP = 'CHANGE_SHOW_RECORD_POPUP';
export const CHANGE_RECORD_POPUP_INFO = 'CHANGE_RECORD_POPUP_INFO';
export const CHANGE_SHOW_SUBSCRIBE_EVENT_POPUP = 'CHANGE_SHOW_SUBSCRIBE_EVENT_POPUP';
export const RECORD_TO_CONSULTATION_LOADING = 'RECORD_TO_CONSULTATION_LOADING';
export const CHANGE_TRAILER_POPUP_INFO = 'CHANGE_TRAILER_POPUP_INFO';
export const CHANGE_SHOW_TRAILER_POPUP = 'CHANGE_SHOW_TRAILER_POPUP';
export const CHANGE_DOWNLOAD = 'CHANGE_DOWNLOAD';
export const CHANGE_DISCOUNT = 'CHANGE_DISCOUNT';


// payment
export const PAYMENT_POPUP_TEXT_CONTENT = 'PAYMENT_POPUP_TEXT_CONTENT';
export const CHANGE_SHOW_PAYMENT_POPUP = 'CHANGE_SHOW_PAYMENT_POPUP';
export const CHANGE_PAYMENT_PAGE = 'CHANGE_PAYMENT_PAGE';

// test
export const SET_TEST_LIST = 'SET_TEST_LIST';
export const SET_TEST_LIST_LOADING = 'SET_TEST_LIST_LOADING';
export const INCREMENT_TEST_COUNTER = 'INCREMENT_TEST_COUNTER';
export const DECREMENT_TEST_COUNTER = 'DECREMENT_TEST_COUNTER';
export const CHANGE_TEST_COUNTER = 'CHANGE_TEST_COUNTER';
export const SET_TEST_RESULT_LOADING = 'SET_TEST_RESULT_LOADING';
export const SET_TEST_RESULT = 'SET_TEST_RESULT';
export const SET_SEND_RESULT_LOADING = 'SET_SEND_RESULT_LOADING';

// vacancy
export const CHANGE_SHOW_FORM_WITH_VACANCY = 'CHANGE_SHOW_FORM_WITH_VACANCY';
export const SEND_RESUME_LOADING = 'SEND_RESUME_LOADING';
export const SET_VACANCY_LIST = 'SET_VACANCY_LIST';
export const SET_VACANCY_LIST_LOADING = 'SET_VACANCY_LIST_LOADING';
export const SET_VACANCY_INFO_LOADING = 'SET_VACANCY_INFO_LOADING';
export const SET_VACANCY_INFO = 'SET_VACANCY_INFO';

// promo
export const PROMO_POPUP_TEXT_CONTENT = 'PROMO_POPUP_TEXT_CONTENT';
export const CHANGE_SHOW_PROMO_REGISTER_POPUP = 'CHANGE_SHOW_PROMO_REGISTER_POPUP';
export const CHANGE_SHOW_PROMO_SIGNIN_POPUP = 'CHANGE_SHOW_PROMO_SIGNIN_POPUP';
export const CHANGE_REGISTER_FROM_LANDING = 'CHANGE_REGISTER_FROM_LANDING';
export const SEND_CLICK_REQUEST_LOADING = 'SEND_CLICK_REQUEST_LOADING';


// about
export const SET_ABOUT_LIST = 'SET_ABOUT_LIST';
export const SET_ABOUT_LIST_LOADING = 'SET_ABOUT_LIST_LOADING';
