import {mapActions, mapGetters, mapMutations} from "vuex";
import {directive as onClickaway} from 'vue-clickaway';
import Timer from '@/components/features/timer/index.vue';
import moment from 'moment'

import changeHtmlLang from "@/utils/change-html-lang";

export default {
  name: 'main-header',
  data() {
    return {
      showLangList: false,
      start: '',
      changeOverlay: false,
      openBurger: false,
      showMobileLangList: false,
      showUserAvatar: false,
      isShowProfileNav: false,
      isShowProfileNavMobile: false
    }
  },
  directives: {
    onClickaway: onClickaway,
  },
  components: {
    Timer
  },
  computed: {
    ...mapGetters({
      popupRegister: 'auth/popupRegister',
      isAuthenticated: `auth/isAuthenticated`,
      isShowHomeSection: `home/showHomeSection`,
      index: 'system/pageIndex',
      menuList: 'setting/menu',
      user: 'profile/user',
    }),
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
  },
  watch: {
    '$route' (to, from) {
      this.openBurger = false;
    },
    'user'(newVal){
      this.showUserAvatar = !!newVal.avatar;
    }
  },
  created() {
    let date = '15-02-2021';
    this.start = (moment(date , 'DD-MM-YYYY HH:mm:ss Z').format('YYYY-MM-DD'));

  },
  mounted() {

    if (window.addEventListener) {
      window.addEventListener('mousewheel', this.doScroll, {passive: false});
      window.addEventListener('scroll', this.doScroll, {passive: false});
      window.addEventListener('wheel', this.doScroll, {passive: false}); // for Mozilla Firefox
    } else {
      window.attachEvent('onmousewheel', this.doScroll);
    }
  },
  methods: {
    ...mapActions({
      logout: `auth/LOGOUT`,
      fetchUserData: `profile/FETCH_USER_DATA`,
    }),
    ...mapMutations({
      changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
      changePopupSignIn: `auth/CHANGE_POPUP_SIGN_IN`,
      changeTransitionName: `system/CHANGE_TRANSITION_NAME`,
      changeIndex: `system/CHANGE_PAGE_INDEX`,
      changeShowSubscribePopup: `marathon/CHANGE_SHOW_SUBSCRIBE_POPUP`,
    }),
    showPopupRegister(){
      if(!this.isAuthenticated){
        this.changePopupSignIn(true);
      }else{
        this.isShowProfileNav = !this.isShowProfileNav;
      }
    },
    showPopupRegisterMobile(){
      if(!this.isAuthenticated){
        this.changePopupSignIn(true);
      }else{
        this.isShowProfileNavMobile = !this.isShowProfileNavMobile;
      }
    },
    userLogout(){
      this.logout();
      this.$router.push({name: 'home'}).catch(()=> {console.log()})
    },
    away(){
      this.isShowProfileNav = false;
    },
    goToTeachBase(){
      if( this.isAuthenticated ){
        let token = localStorage.getItem('user_token');
        window.location.href = `https://api.lifta.space/api/oauth/redirect?token=${token}`;
      }
    },
    changeHeaderLogo(){
      if (this.$route.name === 'home' || this.$route.name === 'marathon') {
        window.scrollTo({top: 0, behavior: 'smooth'});
      } else {
        this.$router.push({name: 'home'}).catch(() => {
          console.log();
        });
      }
      this.changeTransitionName('slide-out');
      this.changeIndex(1);
    },
    changeLanguage(language){
      const selectedLanguage = this.languages.find(item => item.key === language.key);

      this.$setLanguage(selectedLanguage.code);
      localStorage.setItem('localization', selectedLanguage.key);
      changeHtmlLang(selectedLanguage.key);

      this.showLangList = false;

      setTimeout(() => {
        window.location.reload();
      }, 800)
    },
    closeLangList(){
      this.showLangList = false;
    },
    scrollDown(item){
      this.changeIndex(5);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false,
        offset: -90,
      });
    },
    doScroll(){
      var html = document.documentElement;
      this.changeOverlay = html.scrollTop > 150;
    }
  },
  destroyed() {
    window.removeEventListener('mousewheel', this.doScroll, {passive: false});
    window.removeEventListener('wheel', this.doScroll, {passive: false}) // for Mozilla Firefox
  }
}
