
const bannersData = {
  timeout: 5000,
  banners: {
    data: [
      {
        id: 0,
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 101)}/1366/320`,
        external_link: 'https://lifta.space/en/courses',
      },
      {
        id: 1,
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 101)}/1366/320`,
        external_link: 'https://lifta.space/ru/courses',
      },
      {
        id: 2,
        image: `https://picsum.photos/id/${Math.floor(Math.random() * 101)}/1366/320`,
        external_link: 'https://lifta.space/ua/courses',
      }
    ]
  },
};

export default bannersData;
