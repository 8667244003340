import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'top-news',
  props:['item'],
  data() {
    return {
      activeUrl: ''
    }
  },
  created() {
    this.activeUrl = window.location.href + this.item.slug;
  },
  mounted() {
  },
  computed: {},
  components: {},
  methods: {},
  beforeDestroy() {
  },
}
