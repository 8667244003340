
const coursesTechAndTools = [
  {
    id: 0,
    name: 'tech and tools',
    image: require('../../img/courses/tech-and-tools/tech-and-tools-1.png'),
    external_link: 'https://google.com',
  },
  {
    id: 1,
    name: 'tech and tools',
    image: require('../../img/courses/tech-and-tools/tech-and-tools-2.png'),
    external_link: 'https://google.com',
  },
  {
    id: 2,
    name: 'tech and tools',
    image: require('../../img/courses/tech-and-tools/tech-and-tools-3.png'),
    external_link: 'https://google.com',
  },
  {
    id: 3,
    name: 'tech and tools',
    image: require('../../img/courses/tech-and-tools/tech-and-tools-4.png'),
    external_link: 'https://google.com',
  },
  {
    id: 4,
    name: 'tech and tools',
    image: require('../../img/courses/tech-and-tools/tech-and-tools-5.png'),
    external_link: 'https://google.com',
  },
  {
    id: 5,
    name: 'tech and tools',
    image: require('../../img/courses/tech-and-tools/tech-and-tools-6.png'),
    external_link: 'https://google.com',
  },
  {
    id: 6,
    name: 'tech and tools',
    image: require('../../img/courses/tech-and-tools/tech-and-tools-7.png'),
    external_link: 'https://google.com',
  },
  {
    id: 7,
    name: 'tech and tools',
    image: require('../../img/courses/tech-and-tools/tech-and-tools-8.png'),
    external_link: 'https://google.com',
  },
  {
    id: 8,
    name: 'tech and tools',
    image: require('../../img/courses/tech-and-tools/tech-and-tools-9.png'),
    external_link: 'https://google.com',
  },
  {
    id: 9,
    name: 'tech and tools',
    image: require('../../img/courses/tech-and-tools/tech-and-tools-10.png'),
    external_link: 'https://google.com',
  },
  {
    id: 10,
    name: 'tech and tools',
    image: require('../../img/courses/tech-and-tools/tech-and-tools-11.png'),
    external_link: 'https://google.com',
  },
  {
    id: 11,
    name: 'tech and tools',
    image: require('../../img/courses/tech-and-tools/tech-and-tools-12.png'),
    external_link: 'https://google.com',
  },
];

export default coursesTechAndTools;
