import SpeakersSlider from '@/components/features/speakers-slider/index.vue';

export default {
  name: 'speakers-list',
  components: {
    SpeakersSlider,
  },
  props: {
    list: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
}
