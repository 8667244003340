import Vue from 'vue'
import { Swiper as SwiperClass, Pagination, Navigation } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
// import '../../../../assets/scss/swiper.scss'
import '../../../../assets/scss/swiper-bundle.scss'

SwiperClass.use([Pagination, Navigation])
Vue.use(getAwesomeSwiper(SwiperClass))
const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)
export default {
  name: 'calendar-slider',
  props: ['sliders', 'items'],
  data() {
    return {
      currentSlide: 0,
      swiperOptions: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          dynamicMainBullets: 4,
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      },
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  // directives: {
  //   swiper: directive
  // },
  methods: {
    changeArrow(value) {
      const carousel = this.$refs['slider-items'];
      const currentPage = carousel.currentPage;
      const pageCount = carousel.pageCount;
      if (value === 'prev') {
        currentPage !== 0 ? carousel.goToPage(currentPage - 1) : carousel.goToPage(pageCount - 1);
      } else {
        currentPage < pageCount - 1 ? carousel.goToPage(currentPage + 1) : carousel.goToPage(0);
      }
    },
    gotToSlide(index) {
      const carousel = this.$refs['slider-items'];
      carousel.goToPage(index)
    },
    pageChange(slide) {
      this.currentSlide = slide;
    },

  }
}
