import {mapMutations, mapGetters} from 'vuex';

const registerPopup = () => import('@/components/popups/registration/index.vue');
const thanks = () => import('@/components/popups/thanks/index.vue');
const restorePasswordEmail = () => import('@/components/popups/restore-password-email/index.vue');
const setNewPassword = () => import('@/components/popups/set-new-password/index.vue');
const subscribePopup = () => import('@/components/popups/subscribe/index.vue');
const formThanks = () => import('@/components/popups/form-thanks/index.vue');
const signIn = () => import('@/components/popups/sing-in/index.vue');
const thanksPopup = () => import('@/components/popups/popup-thanks/index.vue');
const activationEmail = () => import('@/components/popups/activation-email/index.vue');
const chatRegistration = () => import('@/components/popups/chat-registration/index.vue');
const chatSignIn = () => import('@/components/popups/chat-sign-in/index.vue');
const chatRestorePassword = () => import('@/components/popups/chat-restore-password/index.vue');
const buyProduct = () => import('@/components/popups/buy-product/index.vue');
const paymentStatus = () => import('@/components/popups/payment-status/index.vue');
const recordConsultation = () => import('@/components/popups/record-consultation/index.vue');
const subscribeEventPopup = () => import('@/components/popups/subscribe-event/index.vue');
const vacancyPopup = () => import('@/components/popups/vacancy/index.vue');
const promoRegisterPopup = () => import('@/components/popups/promo-registration/index.vue');
const promoSignInPopup = () => import('@/components/popups/promo-sing-in/index.vue');
const cookiesPopup = () => import('@/components/popups/cookies/index.vue');
const trailerPopup = () => import('@/components/popups/course-trailer/index.vue');

export default {
  name: 'hidden',
  components: {
    registerPopup,
    thanks,
    restorePasswordEmail,
    setNewPassword,
    subscribePopup,
    formThanks,
    signIn,
    thanksPopup,
    activationEmail,
    chatRegistration,
    chatSignIn,
    chatRestorePassword,
    buyProduct,
    paymentStatus,
    recordConsultation,
    subscribeEventPopup,
    vacancyPopup,
    promoRegisterPopup,
    promoSignInPopup,
    cookiesPopup,
    trailerPopup
  },
  computed: {
    ...mapGetters({
      discount: 'event/discount',
      popupRegister: 'auth/popupRegister',
      popupSignIn: 'auth/popupSignIn',
      showRestorePassword: 'auth/showRestorePassword',
      showSetNewPassword: 'auth/showSetNewPassword',
      showPopupUpdatePassword: 'profile/showPopupUpdatePassword',
      showSubscribePopup: 'marathon/showSubscribePopup',

      showActivationEmail: 'auth/showActivationEmail',

      popup: 'system/popup',
      popupTextShow: 'system/popupTextShow',

      popupThanks: 'system/popupThanks',
      showThanksPopup: 'system/formThanksPopup',
      chatRegistrationPopup: 'system/chatRegistrationPopup',
      chatSignInPopup: 'system/chatSignInPopup',
      chatRestorePasswordPopup: 'system/chatRestorePasswordPopup',

      popupBuyForm: 'event/popupBuyProduct',
      isShowPopupBuyProduct: 'event/isShowPopupBuyProduct',

      isShowPaymentPopup: 'payment/isShowPaymentPopup',

      isShowRecordEventPopup: 'event/isShowRecordEventPopup',

      isShowSubscribeEventPopup: 'event/isShowSubscribeEventPopup',

      isShowFormWithVacancy: 'vacancy/isShowFormWithVacancy',

      isShowPromoRegisterPopup: 'landing/isShowPromoRegisterPopup',
      isShowPromoSignInPopup: 'landing/isShowPromoSignInPopup',
      promoPopupContent: 'landing/promoPopupContent',

      isShowCookiesPopup: 'system/isShowCookiesPopup',

      isShowPopupTrailer: 'event/isShowPopupTrailer',
      popupTrailerInfo: 'event/popupTrailerInfo',
    })
  },
  methods: {
    ...mapMutations({
      changeDiscount: `event/CHANGE_DISCOUNT`,
      changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
      changePopupSignIn: `auth/CHANGE_POPUP_SIGN_IN`,

      changePopupContent: `system/POPUP_TEXT_CONTENT`,
      changeShowTextPopup: `system/SHOW_POPUP_TEXT_CONTENT`,

      changePopupActivationEmail: `auth/CHANGE_POPUP_ACTIVATION_EMAIL`,

      changePopupRestorePasswordEmail: `auth/CHANGE_POPUP_RESTORE_PASSWORD`,
      changePopupNewPassword: `auth/CHANGE_POPUP_NEW_PASSWORD`,
      changePopupUpdatePassword: `profile/SHOW_POPUP_UPDATE_PASSWORD`,
      changeShowSubscribePopup: `marathon/CHANGE_SHOW_SUBSCRIBE_POPUP`,
      changeShowThanksPopup: `system/SHOW_POPUP_THANKS`,
      changeShowThanksPopupShow: `system/CHANGE_FORM_THANKS_POPUP`,
      changeChatRegistrationAvailabilityStatus: 'system/CHAT_REGISTRATION_POPUP_CHANGE_AVAILABILITY_STATUS',
      changeChatSignInAvailabilityStatus: 'system/CHAT_SIGN_IN_POPUP_CHANGE_AVAILABILITY_STATUS',
      changeRestorePasswordInAvailabilityStatus: 'system/CHAT_RESTORE_PASSWORD_POPUP_CHANGE_AVAILABILITY_STATUS',

      changeShowBuyPopup: `event/SHOW_POPUP_BUY_FORM`,
      changeShowPopupBuyForm: `event/CHANGE_SHOW_POPUP_BUY_FORM`,


      changeShowPaymentPopup: `payment/CHANGE_SHOW_PAYMENT_POPUP`,


      changeShowRecordPopup: `event/CHANGE_SHOW_RECORD_POPUP`,


      changeShowSubscribeEventPopup: `event/CHANGE_SHOW_SUBSCRIBE_EVENT_POPUP`,


      changeShowVacancyPopup: `vacancy/CHANGE_SHOW_FORM_WITH_VACANCY`,


      changePromoPopupRegister: `landing/CHANGE_SHOW_PROMO_REGISTER_POPUP`,
      changePromoPopupSignIn: `landing/CHANGE_SHOW_PROMO_SIGNIN_POPUP`,

      changeShowTrailerPopup: `event/CHANGE_SHOW_TRAILER_POPUP`,
    }),
    closeWithRedirect(){
      if (this.discount === true) {
        this.changeShowTextPopup(false)
        this.changeDiscount(false)
        window.location = 'https://itrecruiter.lifta.space/'
      }
      else {
        this.changeShowTextPopup(false)
      }
    }
  }
}
