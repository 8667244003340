export default {
  name: 'input-text',
  props: {
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
  },
}
