import {mapGetters, mapActions, mapMutations} from 'vuex';

import Slider from './components/slider/index.vue';
import homePagination from './components/marathon-sidebar/index.vue';

//sections
import promoSection from '@/modules/event/section/promo/index.vue';
import videoSection from '@/modules/event/section/video/index.vue';
import eventSection from '@/modules/event/section/event/index.vue';
import missionSection from '@/modules/event/section/mission/index.vue';
import speakersSection from '@/modules/event/section/speakers/index.vue';
import utilitySection from '@/modules/event/section/utility/index.vue';
import courseSection from '@/modules/event/section/course/index.vue';
import programSection from '@/modules/event/section/program/index.vue';
import howSection from '@/modules/event/section/how/index.vue';
import footerSection from '@/components/features/footer/index.vue';

export default {
  name: 'home',
  data() {
    return {
      params: {
        title: '',
        description: '',
        keywords: '',
        name: '',
        image: ''
      },
      routesArray: [
        'arrangement-promo',
        'event-info',
        'utility-section',
        'event-speaker',
        'event-program',
        'event-how',
        'event-course',
        'footer',
      ],
      intersectionOptions: {
        threshold: [0.25, 0.75]
      }
    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.name ? this.params.name : 'Lifta.space',
        separator: ' '
      }
    },
    meta: function () {
      let meta = [
        {n: 'description', c: this.params.description, id: 'description'},
        {n: 'keywords', c: this.params.keywords, id: 'keywords'},
        {p: 'og:title', c: this.params.title, id: 'og:title'},
        {p: 'og:url', c: window.location.origin + this.$route.path, id: 'og:url'},
        {p: 'og:type', c: "website", id: 'og:type'},
        {p: 'og:image', c: this.params.image, id: 'og:image'},
        {p: 'og:description', c: this.params.description, id: 'og:description'},
        {p: 'twitter:title', c: this.params.title, id: 'twitter:title'},
        {p: 'twitter:description', c: this.params.description, id: 'twitter:description'},
      ]

      if (Object.keys(this.$route.query).length > 0) {
        meta.push({n: 'robots', c: 'noindex, nofollow'})
        return meta
      } else {
        return meta
      }
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ua/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        {rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical'},
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'uk-UA', href: window.location.origin+ '/ua' + route, id: 'uk-UA'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    }
  },
  created() {
    let lang = this.currentLanguage.translationKey
    console.log(this.$route.params.slug);
    this.getEventItem({slug: this.$route.params.slug}).then(() => {
      this.gtm(this.eventItem);
    });
    // this.getEventItem().then(()=>{
    //   console.log(this.eventItem);
    //
    //   for(var i = 0; i < this.eventItem.length; i++) {
    //     if(this.eventItem[i].slug === this.$route.params.slug) {
    //      this.item = this.eventItem[i][lang.toString()];
    //       console.log(this.item);
    //       break;
    //     }
    //   }
    //
    //
    // });
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      index: 'event/pageIndex',
      eventItem: 'event/eventItem',
      eventItemLoading: 'event/eventItemLoading',

      // campaigns
      abCampaign: 'setting/abCampaign',
    }),
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
  },
  watch: {
    eventItem: {
      immediate: true,
      handler(newVal) {
        if( newVal ){
          console.log('adsadsadsads', this.eventItem);
          console.log('newVal', this.eventItem);
          var self = this
          self.params.title = newVal.meta_title;
          self.params.description = newVal.meta_description;
          self.params.keywords = newVal.meta_keyword;
          self.params.name = newVal.page.data.page_title;
          self.params.image = newVal.image_thumb;
          self.$emit('updateHead')
        }
      }
    },
  },
  components: {
    Slider,
    homePagination,
    promoSection,
    missionSection,
    programSection,
    utilitySection,
    videoSection,
    eventSection,
    speakersSection,
    howSection,
    courseSection,
    footerSection,
  },
  methods: {
    ...mapActions({
      getEventItem: `event/GET_EVENT_ITEM`,
    }),
    ...mapMutations({
      changeIndex: 'event/CHANGE_PAGE_INDEX',
      changePaymentPage: `payment/CHANGE_PAYMENT_PAGE`,
      setEventItem: `event/SET_EVENT_ITEM`,
    }),
    gtm(item) {
      this.$gtm.trackEvent({
        'event': 'productClick',
        'ecommerce': {
          'click': {
            'name': item.title,
            'id': item.id,
            'price': item.final_price,
            'category': item.category,
            'autor': item.speakers.data[0].name,
            'type': item.status,
          }
        },
        'eventCallback': function () {
          // document.location = item.slug
        }
      })

    },
    goToPage(index, item) {
      this.changeIndex(index);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false
      });
    },
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        switch (entry.target.id) {
          case 'arrangement-promo': {
            this.changeIndex(1);
            break;
          }
          case 'event-info': {
            this.changeIndex(2);
            break;
          }
          case 'utility-section': {
            this.changeIndex(3);
            break;
          }
          case 'event-speaker': {
            this.changeIndex(4);
            break;
          }
          case 'event-program': {
            this.changeIndex(5);
            break;
          }
          case 'event-how': {
            this.changeIndex(6);
            break;
          }
          case 'event-course': {
            this.changeIndex(7);
            break;
          }
          case 'footer': {
            this.changeIndex(8);
            break;
          }
        }
      }
    }
  },
  destroyed() {
    this.changePaymentPage(false);
    this.setEventItem(null)
  },
}
