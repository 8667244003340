import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'mainSidebar',
  data() {
    return {
      routesArray: [
        'promo-section',
        'development-section',
        'start-section',
        'speakers-section',
        'calendar-section',
        'about-section',
        'knowwhere-section',
        'faq-section',
        'lift-section'
      ]
    }
  },
  components: {},
  created() {
  },
  computed: {
    ...mapGetters({
      index: 'system/pageIndex',
    }),
  },
  methods: {
    ...mapMutations({
      changeIndex: `system/CHANGE_PAGE_INDEX`,
      changeTransitionName: `system/CHANGE_TRANSITION_NAME`,
    }),
    goToPage(index,item) {
      this.changeIndex(index);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false
      });
    }
  }
}
