import inputRadio from '@/components/inputs/radio/index.vue'
import inputRange from '@/components/inputs/range/index.vue'

export default {
  name: 'courses-catalogue-filters',
  components: {
    inputRadio,
    inputRange,
  },
  props: {
    values: {
      type: Object,
      default: {},
    },
    data: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    formattedFiltersList: function() {
      let updatedFiltersList = [];


      if (this.data && this.values) {
        updatedFiltersList = this.data.map(filter => {
          filter.value = '';

          Object.keys(this.values).map(key => {
            if (filter.name === key) {
              filter.value = this.values[key];
            }
          })

          return filter;
        })
      }

      return updatedFiltersList;
    }
  },
  methods: {
    changeHandler (name, value) {
      const payload = { name, value };

      this.$emit('change', payload);
    }
  }
}
