import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import {mapActions, mapGetters, mapMutations} from "vuex";

import _ from 'lodash';

export default {
  name: 'setting',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      },
      validationErrors: {
        first_name: [],
        last_name: [],
        email: [],
        phone: [],
      },
    }
  },
  validations: {
    payload: {
      first_name: {
        required,
        maxLength: maxLength(30),
      },
      last_name: {
        required,
        maxLength: maxLength(30),
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
      phone: {
        required,
        minLength: minLength(9)
      },
    }
  },
  components: {
  },
  watch: {
    user() {
      if (this.user) {
        this.setFormData(this.user);
      }
    },
  },
  created() {
    if (this.user) {
      this.setFormData(this.user);
    }
  },
  computed: {
    ...mapGetters({
      user: 'profile/user',
      checkEmailLoading: 'auth/checkEmailLoading',
      updateUserLoading: 'profile/updateUserLoading',
    }),
    firstNameErrors() {
      let error = [];
      if (!this.$v.payload.first_name.$dirty) {
        return error;
      }
      if (this.$v.payload.first_name.required === false) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('name')))
      }

      if (this.$v.payload.first_name.maxLength === false) {
        error.push(this.$t('maxLengthField').replace(':field', this.$t('name')).replace(':count', 30));
      }
      if (this.validationErrors.first_name) {
        this.validationErrors.first_name.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
    lastNameErrors() {
      let error = [];
      if (!this.$v.payload.last_name.$dirty) {
        return error;
      }
      if (this.$v.payload.last_name.required === false) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('lastName')))
      }

      if (this.$v.payload.last_name.maxLength === false) {
        error.push(this.$t('maxLengthField').replace(':field', this.$t('lastName')).replace(':count', 30));
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (this.$v.payload.email.required === false) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('email')))
      }
      if (!this.$v.payload.email.email) {
        error.push('Введите корректный email')
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
    phoneError() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (this.$v.payload.phone.required === false) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('phone')))
      }

      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationLengthPhone'));
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },

    disableButton() {
      if (
        this.payload.first_name === '' ||
        this.payload.last_name === '' ||
        this.payload.phone === '' ||
        this.payload.email === ''
      ) {
        return true
      }
      if (
        this.payload.first_name === this.user.first_name &&
        this.payload.last_name === this.user.last_name &&
        this.payload.email === this.user.email &&
        this.payload.phone === this.user.phone
      ) {
        return true
      }

      return false
    },
    errorsMessages() {
      let arrayErrors = [];
      for (let i in this.validationErrors) {
        this.validationErrors[i].forEach(e => {
          arrayErrors.push(e);
        })
      }
      return arrayErrors;
    },
  },
  methods: {
    ...mapActions({
      updateUser: `profile/UPDATE_USER_DATA`,
      fetchUser: `profile/FETCH_USER_DATA`,
      checkEmail: `auth/CHECK_EMAIL`,
    }),
    ...mapMutations({
      changePopupRestorePasswordEmail: `auth/CHANGE_POPUP_RESTORE_PASSWORD`,
    }),
    setFormData(user) {
      this.payload.first_name = user.first_name;
      this.payload.last_name = user.last_name;
      this.payload.phone = user.phone;
      this.payload.email = user.email;
    },
    checkProvider(name) {
      return this.user.social.data.find(e => e.provider === name);
    },
    changeEmailInput: _.debounce(function () {
      if (this.user.inner) {
        if (this.$v.payload.email.email) {
          this.checkEmail({email: this.payload.email})
            .catch(error => {
              if (error.response.status === 422) {
                if (this.payload.email === this.user.email) {

                } else {
                  this.$toasted.error(this.$t('validationRepeatEmailError'));
                }
              }
            })
        }
      }
    }, 1000),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const obj = {
          first_name: this.payload.first_name,
          last_name: this.payload.last_name,
          phone: this.payload.phone,
          email: this.payload.email,
        };
        this.updateUser(obj)
          .then(() => {
            this.$toasted.success(this.$t('dataUpdate'));
          })
          .catch(error => {
            if (error.response.status === 422) {
              for (let field in error.response.data.errors) {
                this.validationErrors[field] = error.response.data.errors[field];
              }
            }
          })
      }
    }
  }
}
