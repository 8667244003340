import {mapGetters, mapActions, mapMutations} from 'vuex';


export default {
  name: 'lift',
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  components: {
  },
  methods: {
    changeFooterLogo(){
      if( this.$route.name === 'home' || this.$route.name === 'marathon' ){
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }else{
        this.$router.push({name: 'home'}).catch(() => {
          console.log();
        });
      }
    }
  },
  beforeDestroy() {
  },
}
