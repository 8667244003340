import {mapGetters, mapActions, mapMutations} from 'vuex';

import newsItem from '../../components/news-item/index.vue'

//sections
import promoSection from '@/modules/news/pages/list/section/promo/index.vue';
import listSection from '@/modules/news/pages/list/section/list/index.vue';
import mainFooter from '@/components/features/footer/index.vue'


export default {
  name: 'news-list',
  data() {
    return {
      routesArray: [
        'news-list__promo',
        'news-list__content',
        'footer'
      ],
      intersectionOptions: {
        threshold: [0.25, 0.75]
      },
      params: {
        title: '',
        description: '',
        keywords: '',
        name: '',
      },
    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.name ? this.params.name : 'Lifta.space',
        separator: ' '
      }
    },
    meta: function () {
      let meta = [
        {n: 'description', c: this.params.description, id: 'description'},
        {n: 'keywords', c: this.params.keywords, id: 'keywords'},
        {p: 'og:title', c: this.params.title, id: 'og:title'},
        {p: 'og:url', c: window.location.origin + this.$route.path, id: 'og:url'},
        {p: 'og:type', c: "website", id: 'og:type'},
        {p: 'og:description', c: this.params.description, id: 'og:description'},
        {p: 'twitter:title', c: this.params.title, id: 'twitter:title'},
        {p: 'twitter:description', c: this.params.description, id: 'twitter:description'},
      ]

      if (Object.keys(this.$route.query).length > 0) {
        meta.push({n: 'robots', c: 'noindex, nofollow'})
        return meta
      } else {
        return meta
      }
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ua/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        {rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical'},
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'uk-UA', href: window.location.origin+ '/ua' + route, id: 'uk-UA'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    }
  },
  watch: {
    newsInfo:  {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          var self = this
          self.params.title = newVal.meta_title;
          self.params.description = newVal.meta_description;
          self.params.keywords = newVal.meta_keyword;
          self.params.name = newVal.page_title;
          self.$emit('updateHead')
        }
      }
    },
  },
  created() {
   this.fetchNewsInfo();
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      index: 'news/listPageIndex',
      newsList: 'news/newsList',
      newsListLoading: 'news/newsListLoading',
      newsInfo: 'news/newsInfo',
      newsInfoLoading: 'news/newsInfoLoading',
    })
  },
  components: {
    newsItem,
    promoSection,
    listSection,
    mainFooter
  },
  methods: {
    ...mapActions({
      fetchNewsList: `news/GET_NEWS_LIST`,
      fetchNewsInfo: `news/GET_NEWS_INFO`,
    }),
    ...mapMutations({
      changeIndex: `news/CHANGE_LIST_PAGE_INDEX`,
    }),
    goToPage(index, item) {
      this.changeIndex(index);
      this.$smoothScroll({
        scrollTo: document.getElementById(item),
        duration: 800,
        updateHistory: false
      });
    },
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        switch (entry.target.id) {
          case 'news-list__promo': {
            this.changeIndex(1);
            break;
          }
          case 'news-list__content': {
            this.changeIndex(2);
            break;
          }
          case 'footer': {
            this.changeIndex(3);
            break;
          }
        }
      }
    }
  },
  beforeDestroy() {
  },
}
