//utils
import moment from 'moment';

import './utils/plugins'
import './utils/translations'
import './utils/global-components'

// moment
Vue.prototype.moment = moment

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtm from 'vue-gtm';
import VueSmoothScroll from 'vue2-smooth-scroll';


Vue.use(VueGtm, {
  id: 'GTM-T6J5K8H', // Your GTM ID
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [] // If router, you can exclude some routes name (case insensitive) (optional)
});

Vue.use(VueSmoothScroll)

Vue.config.productionTip = false

// set auth status
if (localStorage.getItem('user_token')) {
  store.state.auth.isAuthenticated = true
}

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app');

