import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'signup-form',
  data() {
    return {
      payload: {
        name: '',
        email: '',
      },
      validationErrors: {}
    }
  },
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(60),
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
    }
  },
  watch: {
    showSubscribePopup: function (newVal) {
      if (newVal) {
        this.setData();
        this.$v.$reset();
      }
    },
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      userData: 'marathon/userInfo',
      showSubscribePopup: 'marathon/isChangePopupInfo',
    }),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('name')))
      }

      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('maxLengthField').replace(':field', this.$t('name')).replace(':count', 60));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('email')))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('maxLengthField').replace(':field', this.$t('email')).replace(':count', 60));
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
  },
  components: {},
  methods: {
    ...mapActions({}),
    ...mapMutations({
      changeUserInfo: 'marathon/CHANGE_USER_INFO',
      changeShowSubscribePopup: `marathon/CHANGE_SHOW_SUBSCRIBE_POPUP`,
      changePopupInfo: `marathon/CHANGE_POPUP_INFO`,
    }),
    setData(){
      this.payload.name = this.userData.name;
      this.payload.email = this.userData.email;
    },
    submit(){
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.changeShowSubscribePopup({show: true, eventId: null});
        this.changePopupInfo(false);
      }
    }
  },
  beforeDestroy() {
  },
}
