import {email, maxLength, minLength, required } from "vuelidate/lib/validators";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: 'choose-assistance',
  data() {
    return {
      payload: {
        name: '',
        email: '',
        phone: '',
      },
      validationErrors: {}
    }
  },
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(60),
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
      phone: {
        required,
        minLength: minLength(9)
      },
    }
  },
  components: {
  },
  computed:{
    ...mapGetters({
      sendHelpFormLoading: 'contactForm/sendHelpFormLoading',
    }),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('name')))
      }

      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('maxLengthField').replace(':field', this.$t('name')).replace(':count', 60));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('email')))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('maxLengthField').replace(':field', this.$t('email')).replace(':count', 60));
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
    phoneError() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('phone')))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationLengthPhone'));
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      sendHelpForm: `contactForm/SEND_HELP_FORM`,
    }),
    ...mapMutations({
      changeShowThanksPopupShow: `system/CHANGE_FORM_THANKS_POPUP`,
    }),
    resetForm(){
      this.$v.$reset();
      this.payload.name = '';
      this.payload.phone = '';
      this.payload.email = '';
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let obj = {};
        obj.name = this.payload.name;
        obj.email = this.payload.email;
        obj.phone = this.payload.phone;

        this.sendHelpForm(obj).then((response) => {
          this.resetForm();
          this.changeShowThanksPopupShow({show: true})
        }).catch(error => {
          console.log(error);
          if (error.response.status === 418) {
            this.$toasted.error(error.response.data.message);
            const errors = error.response.data.errors;
            for (const i in errors) {
              Vue.set(this.validationErrors, camelCase(i), errors[i]);
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            const errors = error.response.data.errors;
            for (const i in errors) {
              Vue.set(this.validationErrors, camelCase(i), errors[i]);
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        });
      }
    }
  }
}
