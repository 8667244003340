import {mapGetters, mapActions, mapMutations} from 'vuex';


export default {
  name: 'knowwhere',
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      isShowSvgAnimation: 'home/showSvgAnimation',
      isShowSvgAnimationOnce: 'home/showSvgAnimationOnce',
    })
  },
  components: {},
  methods: {},
  beforeDestroy() {
  },
}
